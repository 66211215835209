import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Row,
  Col,
  Form,
  Input,
  Checkbox,
  Button,
  Radio,
  Spin,
  InputNumber,
  TimePicker,
  notification,
  message, Select,
} from "antd";
import {
  CityCountyLookupSelect,
  LookupSelect,
} from "components/UIComponents/FormElements";
import useGet from "hooks/useGet"; 
import { postData } from "redux/services/request";

import Banner from "components/UIComponents/Banner";
import DisabilityStatus from './DisabilityStatus';
import ForeignLanguage from './ForeignLanguage';
import {useHistory} from 'react-router-dom';
import Modal from 'antd/lib/modal/Modal';
import JobPreview from './JobPreview';
import {Helmet} from "react-helmet";
import TextEditorQuill from "./TextEditorQuill/TextEditorQuill";
import {confirmMessage} from "../../../../../../helpers/methods";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyData } from "redux/actions/companyActions";

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [jobPreviewModal, setJobPreviewModal] = useState(false);

  

  const [languageDisabledBtn,setLanguageDisabledBtn] = useState(false)


  


  const [workingExperienceType, setWorkingExperienceType] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(loadCompanyData());
    
  }, [dispatch]);
  const myCompany = useSelector(state => state.data.companyResult);
  const loadingMyCompany = useSelector(state => state.data.pageLoading);

  const {data: workingTypeIds, loading: loadingWorkingTypes} = useGet({url: '/working-types', key: 'workingType'});
  const {data: salaryRanges, loading: loadingSalaryRanges} = useGet({url: '/salary-ranges', key: 'salaryRanges'});
  const {data: workingPlaceIds, loading: loadingWorkingPlaces} = useGet({
    url: '/working-places',
    key: 'workingPlace'
  });
  const {data: weekDays, loading: loadingWeekDays} = useGet({url: '/types/weekday', key: 'weekDays'});
  const {data: lookupEducationLevels, loading: loadingEducationLevels} = useGet({
    url: '/types/education-levels',
    key: 'educationLevels'
  });
  const {data: applicantRights, loading: loadingApplicantRights} = useGet({
    url: '/jobs/applicant-rights',
    key: 'applicantRights'
  });
  const {data: smokingOptions, loading: loadingSmokingOptions} = useGet({
    url: '/types/smoking-option',
    key: 'smokingOptions'
  });

  const onSubmit = async (values) => {
    const data = {
      ...values,
      workStartAt: moment(values.workStartAt).format('HH:mm'),
      workEndAt: moment(values.workEndAt).format('HH:mm'),
      cityIds:  items.filter(el => el.type === 'CITY').map(x => {
        return x.id
      })
    };
    data.disabilities = data.disabilities
      ? Object.fromEntries(data.disabilities.entries())
      : null;
    data.countyIds = items
      .filter((el) => el.type === "COUNTY")
      .map((x) => {
        return x.id;
      });
      setLoading(true);
      try {
        await postData({url: '/jobs', data});
        setLoading(false);
        message.success('İş ilanı başarılı bir şekilde oluşturuldu');
        history.push('/account/employer/jobs/list');
      } catch (e) {
        notification.error({message: `İş ilanı oluşturulurken bir hata meydana geldi.${e.data.message}`});
        setLoading(false);
      }
  }

  const handleItemSelection = (items) => {
    setItems(items)
  }
  const validateKeyword = (_, value) => {
    if (value && value.includes('www')) {
      return Promise.reject('Değerde "www" bulunamaz');
    }else if(value && value.includes('.com')){
      return Promise.reject('Değerde ".com" bulunamaz');
    }else if(value && value.replace(/<[^>]+>/g, '').trim()===""){
      return Promise.reject('Lütfen boşluk haricinde bir değer giriniz');
    }
    return Promise.resolve();
  };
  return (
    myCompany && myCompany.code !== 200 ? (
      confirmMessage( myCompany?.message )
    ) :(
      <Spin spinning={loading}>
        <div className="JobCreate">
          <Helmet>
            <title>İş Ara İş Bul | Firma İlan Oluştur</title>
            <meta
              name="description"
              content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
            />
            <meta
              name="keywords"
              content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
            />
          </Helmet>
          <Banner text="İlan Oluştur" className="mb-0" />
          <div className="content md content-detail pl-100">
            <h1 className="text-4xl fw-bold ls-full mb-30">FİRMA BİLGİLERİ</h1>
            <Row className="full-width">
              <Form className="full-width" form={form} onFinish={onSubmit}>
                <Spin spinning={loadingMyCompany}>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                    <Input
                      style={{
                        textTransform:'capitalize'
                      }}
                      value={myCompany?.result?.companyName.toLowerCase()}
                      disabled
                      className="form-input mb-20"
                      placeholder="Firma Adı"
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                    <Input
                      value={myCompany?.result?.city?.city}
                      className="form-input mb-20"
                      placeholder="İl Seçiniz"
                      disabled
                    />
                  </Col>
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                    <Input
                      value={myCompany?.result?.county?.county}
                      className="form-input mb-20"
                      placeholder="İlçe Seçiniz"
                      disabled
                    />
                  </Col>
                </Spin>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="hiddenCompany"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox className="form-check blue mb-20">
                      Firma Bilgilerini Gizle
                    </Checkbox>
                  </Form.Item>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-30">İLAN BİLGİLERİ</h1>
                <Col sxs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="positionTitle"
                    rules={[
                      { required: true, message: "Lütfen İlan Başlığı Giriniz" }, { whitespace:true, message: "Bir firma adı belirtiniz" }
                    ]}
                  >
                    <Input
                      className="form-input mb-20"
                      placeholder="İlan Başlığını Yazınız"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="personalCount"
                    rules={[
                      {required: true, message: 'İstihdam edilcek kişi sayısı boş geçilemez.'},
                      { validator: (_, value) => 
                        value === 0 ? 
                        Promise.reject(new Error("İstihdam edilecek kişi sayısı 0'dan büyük olmalı.")) : 
                        Promise.resolve() 
                    }]}
                  >
                    <InputNumber
                      min={1}
                      type="number"
                      className="form-input full-width mb-20"
                      placeholder="İstihdam Edilecek Kişi Sayısını Giriniz"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name="qualifications"
                    rules={[
                      {
                        required: true,
                        message: "Lütfen Genel Nitelik ve İş Tanımı Giriniz",
                      },
                      {
                        validator: validateKeyword,
                      }
                    ]}
                  >
                    <TextEditorQuill placeholder="İş Tanımını Yazınız"/>
                  </Form.Item>
                </Col>
                {/* <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                <Form.Item name="jobDescription" rules={[{ required: true }]}>
                  <Input.TextArea className="mb-20" placeholder="İş tanımını yazınız" rows={8} />
                </Form.Item>
              </Col> */}
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="countyIds"
                    rules={[{ required: true, message: "Lütfen İlçe Seçiniz." }]}
                  >
                    <CityCountyLookupSelect
                      url="/public/counties"
                      labelKey="value"
                      valueKey="id"
                      valueType="type"
                      className="form-select full-width mt-30 mb-20"
                      stateKey="counties"
                      placeholder="İstihdam Edilecek İstanbul İlçelerini Seçiniz"
                      onChangeObject={handleItemSelection}
                      mode="multiple"
                    />
                  </Form.Item>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mt-30 mb-0">
                  Eğitim Seviyesi
                </h1>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                  <Spin spinning={loadingEducationLevels}>
                    <Form.Item
                      name="educationLevels"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen Eğitim Seviyesi Seçiniz",
                        },
                      ]}
                    >
                      <Checkbox.Group className="form-check blue full-width mb-20">
                        <Row className="full-width mt-20" gutter={[10, 20]}>
                          {lookupEducationLevels &&
                            lookupEducationLevels.map((x) => (
                              <Col
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 6 }}
                                key={x.name}
                              >
                                <Checkbox value={x.name}>{x.label}</Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Spin>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="sectorId"
                    rules={[
                      { required: true, message: "Lütfen Sektör Seçiniz" },
                    ]}
                  >
                    <LookupSelect
                      url="/sectors"
                      valueKey="sectorId"
                      labelKey="sectorName"
                      stateKey="sectors"
                      className="mb-20"
                      placeholder="Sektör Seçiniz"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item
                    name="professionId"
                    rules={[
                      { required: true, message: "Lütfen Pozisyon Seçiniz" },
                    ]}
                  >
                    <LookupSelect
                      url="/professions"
                      labelKey="profession"
                      valueKey="id"
                      className="form-select mb-20"
                      placeholder="Pozisyon seçiniz"
                      stateKey="professions"
                    />
                  </Form.Item>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  İŞ TECRÜBESİ
                </h1>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                  <Form.Item
                    name="workingExperienceType"
                    rules={[
                      { required: true, message: "Lütfen İş Tecrübesi Seçiniz" },
                    ]}
                  >
                    <Radio.Group
                      className="form-radio full-width"
                      onChange={(e) => setWorkingExperienceType(e.target.value)}
                    >
                      <Row className="full-width" align="middle">
                        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                          <Radio value="F">Tecrübesiz</Radio>
                        </Col>
                        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                          <Radio value="T">Tecrübeli</Radio>
                        </Col>
                        <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                          <Radio value="A">Hepsi</Radio>
                        </Col>
                      </Row>
                    </Radio.Group>
                  </Form.Item>
                  {workingExperienceType === "T" && (
                    <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                      <Form.Item
                        name="minExperienceYear"
                        rules={[
                          { required: true, message: "Lütfen Tecrübe Giriniz" },
                        ]}
                      >
                        <InputNumber
                         min={1}
                          type="number"
                          className="form-input full-width"
                          placeholder="Tecrübe"
                        />
                      </Form.Item>
                    </Col>
                  )}
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  ÇALIŞMA ŞEKLİ
                </h1>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                  <Spin spinning={loadingWorkingTypes} className="full-width">
                    <Form.Item
                      name="workingTypeIds"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen Çalışma Şekli Seçiniz",
                        },
                      ]}
                    >
                      <Checkbox.Group className="form-check blue full-width mb-20">
                        <Row
                          className="full-width"
                          align="middle"
                          gutter={[0, 20]}
                        >
                          {workingTypeIds &&
                            workingTypeIds.map((x) => (
                              <Col
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 6 }}
                                key={x.name}
                              >
                                <Checkbox value={x.id} >{x.description}</Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Spin>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  ÇALIŞMA YERİ
                </h1>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }}>
                  <Spin spinning={loadingWorkingPlaces} className="full-width">
                    <Form.Item
                      name="workingPlaceIds"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen Çalışma Yeri Seçiniz",
                        },
                      ]}
                    >
                      <Checkbox.Group className="form-check blue full-width mb-20">
                        <Row
                          className="full-width"
                          align="middle"
                          gutter={[0, 20]}
                        >
                          {workingPlaceIds &&
                            workingPlaceIds.map((x) => (
                              <Col
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 6 }}
                                key={x.name}
                              >
                                <Checkbox value={x.id}>{x.description}</Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Spin>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">ÇALIŞMA SAATİ</h1>
                <Row gutter={20}>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="workStartAt" rules={[{required: true, message:"Lütfen Başlangıç Saati Giriniz."}]}>
                      { /** HH:mm format ediliecek */}
                      <TimePicker className="form-input full-width" minuteStep={15} secondStep={10} format="HH:mm"
                                  placeholder="Başlangıç"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="workEndAt" rules={[{required: true, message:"Lütfen Bitiş Saati Giriniz."}]}>
                      { /** HH:mm format ediliecek */}
                      <TimePicker className="form-input full-width" minuteStep={15} secondStep={10} format="HH:mm"
                                  placeholder="Bitiş"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="weeklyWorkingHours" rules={[{required: true, message:"Lütfen Haftalık Saati Giriniz."}]}>
                      <InputNumber className="form-input full-width"  placeholder="Haftalık Çalışma Saatini Giriniz" min={1}/>
                    </Form.Item>
                  </Col>
                </Row>

                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">HAFTALIK İZİN GÜNLERİ</h1>
                <Spin spinning={loadingWeekDays}>
                  <Form.Item name="weeklyOffDays">
                    <Checkbox.Group className="full-width">
                      <Row className="full-width" gutter={[0, 20]} align="middle">
                        {
                          weekDays &&
                          weekDays.map(x =>
                            <Col xs={{span: 12}} sm={{span: 12}} md={{span: 6}} key={x.name}>
                              <Checkbox value={x.name} className="form-check blue">{x.label}</Checkbox>
                            </Col>
                          )
                        }
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Spin>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">MAAŞ ARALIĞI</h1>
                <Row gutter={20} align="middle">
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="salaryRangeId">
                      <Select>
                        {
                          salaryRanges?.map( (item, index) => {
                            return <Select.Option key={index} value={item.hashId}> {item.label} </Select.Option>
                          } )
                        }
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    {/** TODO: Buraya ne gelecek */}
                    <Form.Item name="showSalaryToCandidate" valuePropName="checked" initialValue={false}>
                      <Checkbox className="form-check blue">Aday Görebilsin</Checkbox>
                    </Form.Item>
                  </Col>
                </Row>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">SÜRÜCÜ BELGESİ</h1>
                <Row gutter={20}>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="drivingLicenceTypes">
                      <LookupSelect className="mb-20" url="/types/driving-license" placeholder="Sürücü Belgesi" mode="multiple" stateKey="drivingLicences"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 24}} className="d-flex a-center">
                    <p className="text-xl w-250 mr-20 mb-0">Aktif Araç Kullanımı:</p>
                    <Form.Item name="activeDriving" initialValue={false}>
                      <Radio.Group className="form-radio full-width">
                        <Radio value={true}>Evet</Radio>
                        <Radio value={false}>Hayır</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="foreignLanguages">
                  <ForeignLanguage setLanguageDisabledBtn={setLanguageDisabledBtn}/>
                </Form.Item>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">YAŞ ARALIĞI</h1>
                <Row gutter={20} className="full-width">
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 5}}>
                    <Form.Item name="ageMin">
                      <InputNumber className="form-input full-width pl-10 d-flex a-center" placeholder="En az" min={1}
                                   max={100}/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 5}}>
                    <Form.Item name="ageMax">
                      <InputNumber className="form-input full-width pl-10 d-flex a-center" placeholder="En çok" min={1}
                                   max={100}/>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item name="disabilities">
                  <DisabilityStatus/>
                </Form.Item>
                <Row>
                  <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">SOSYAL VE YAN HAKLAR</h1>
                  <Spin spinning={loadingApplicantRights}>
                    <Col span={24}>
                      <Form.Item name="applicantRightsId">
                        <Checkbox.Group className="form-check blue full-width">
                          <Row className="full-width" gutter={[10, 20]}>
                            {applicantRights && applicantRights.map(x => (
                              <Col xs={{span: 12}} sm={{span: 12}} md={{span: 6}} key={x.id}>
                                <Checkbox value={x.id}>{x.title}</Checkbox>
                              </Col>
                            ))}
                          </Row>
                        </Checkbox.Group>
                      </Form.Item>
                    </Col>
                  </Spin>
                </Row>
                <Row>
                  <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                    SİGARA OPSİYONU
                  </h1>
                  <Spin spinning={loadingSmokingOptions}>
                    <Col span={24}>
                      <Form.Item
                        name="smokingOption"
                        rules={[
                          {
                            required: true,
                            message: "Lütfen Sigara Opsiyonu Seçiniz",
                          },
                        ]}
                      >
                        <Radio.Group className="form-radio full-width">
                          <Row className="full-width" gutter={[10, 20]}>
                            {smokingOptions &&
                              smokingOptions.map((x) => (
                                <Col
                                  xs={{ span: 12 }}
                                  sm={{ span: 12 }}
                                  md={{ span: 6 }}
                                  key={x.name}
                                >
                                  <Radio value={x.name}>{x.label}</Radio>
                                </Col>
                              ))}
                          </Row>
                        </Radio.Group>
                      </Form.Item>
                    </Col>
                  </Spin>
                </Row>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  ÖN YAZI TALEP
                </h1>
                <Col span={24}>
                  <Form.Item name="coverLetterRequested" valuePropName="checked">
                    <Checkbox className="form-check blue">Evet</Checkbox>
                  </Form.Item>
                </Col>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  İLAN SINIFI
                </h1>
                <Row gutter={20} align="middle">
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                    <Form.Item
                      name="jobAdvertisementClass"
                      rules={[
                        {
                          required: true,
                          message: "Lütfen İlanı Sınıfı Seçiniz",
                        },
                      ]}
                    >
                      <LookupSelect
                        url="/types/job-advertisement-class"
                        placeholder="Beyaz-Mavi Yaka"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <div className="d-flex j-center mt-50">
                  <Button
                    className="BtnPreview"
                    onClick={() => setJobPreviewModal(true)}
                  >
                    Ön İzleme
                  </Button>
                  <Button className="BtnSave" htmlType="submit" disabled={languageDisabledBtn}>
                    Yayınla
                  </Button>
                </div>
              </Form>
            </Row>
          </div>
        </div>
        <Modal
          open={jobPreviewModal}
          footer={null}
          onCancel={() => setJobPreviewModal(false)}
          closeIcon={<div />}
          width={900}
        >
          <JobPreview data={form.getFieldsValue()} />
        </Modal>
      </Spin>
    )
  );
};
