import React, { useEffect, useRef, useState } from "react";
import moment from "moment";
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  notification,
  Radio,
  Row,
  Select,
  Spin,
  TimePicker,
} from "antd";
import {
  CityCountyLookupSelect,
  LookupSelect,
} from "components/UIComponents/FormElements";
import useGet from "hooks/useGet";
import { get } from "lodash";

import Banner from "components/UIComponents/Banner";
import DisabilityStatus from "./DisabilityStatus";
import ForeignLanguage from "./ForeignLanguage";
import { useParams } from "react-router-dom";
import { handleFormErrors } from "../../../../../../helpers/methods";
import ReactQuill from "react-quill";
import Api from "../../../../../../api/Api";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyData } from "redux/actions/companyActions";

export default () => {
  const ref = useRef();
  const MAX_COUNT = 4000;

  const [form] = Form.useForm();
  const [id] = useState(useParams().id);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [cityIds, setCityIds] = useState([]);
  const [newData, setNewData] = useState({});

  const [disabilities, setDisabilities] = useState(null);
  const [foreignLanguages, setForeignLanguages] = useState(null);
  const [workingExperienceType, setWorkingExperienceType] = useState(null);

  const { data, loading: loadingUpdate } = useGet({
    url: `/jobs/${id}`,
    isArray: false,
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (localStorage.getItem("type") === "CORPORATE") {
      dispatch(loadCompanyData());
    }
  }, [dispatch]);
  const company = useSelector((state) => state.data.companyResult);
  const myCompany = company?.result;
  const loadingMyCompany = useSelector((state) => state.data.pageLoading);

  const { data: salaryRanges, loading: loadingSalaryRanges } = useGet({
    url: "/salary-ranges",
    key: "salaryRanges",
  });
  const { data: workingT, loading: loadingWorkingTypes } = useGet({
    url: "/working-types",
    key: "workingT",
  });
  const { data: workingPlaces, loading: loadingWorkingPlaces } = useGet({
    url: "/working-places",
    key: "workingPlaces",
  });
  const { data: weekDays, loading: loadingWeekDays } = useGet({
    url: "/types/weekday",
    key: "weekDays",
  });
  const { data: lookupEducationLevels, loading: loadingEducationLevels } =
    useGet({
      url: "/types/education-levels",
      key: "educationLevels",
    });
  const { data: applicantRights, loading: loadingApplicantRights } = useGet({
    url: "/jobs/applicant-rights",
    key: "applicantRights",
  });
  const { data: smokingOptions, loading: loadingSmokingOptions } = useGet({
    url: "/types/smoking-option",
    key: "smokingOptions",
  });
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["clean"],
    ],
  };

  useEffect(() => {
    if (data) {
      const modifiedData = modifyData(data);

      form.setFieldsValue({
        ...modifiedData,
      });

      setNewData(modifiedData);

      // setItems(modifiedData.countyIds.map((id) => ({ id, type: "COUNTY" })));
    }
  }, [form, data]);

  useEffect(() => {
    const updatedItems = newData?.countyIds?.map((id) => ({
      id,
      type: cityIds?.includes(id) ? "CITY" : "COUNTY",
    }));

    setItems(updatedItems);
  }, [newData.countyIds, cityIds]);

  console.log("items", items);

  const onChangeDisability = (x) => {
    setDisabilities(x);
  };

  const onChangeForeignLanguage = (x) => {
    setForeignLanguages(x);
  };

  const modifyData = (data) => {
    setCityIds(data.cities.map((x) => x.id));
    data.applicantRightsId =
      data.applicantRights && data.applicantRights.map((x) => x.id);
    data.countyIds = data.counties.map((x) => x.countId);
    data.cities.map((x) => data.countyIds.push(x.id));
    data.coverLetterRequested = data.coverLetterRequest;
    data.drivingLicenceTypes =
      data.licenseTypes && data.licenseTypes.map((x) => x.id);
    data.weeklyOffDays = data.offDays && data.offDays.map((x) => x.id);
    data.workStartAt = moment(data.workStartAt, "HH:mm");
    data.workEndAt = moment(data.workEndAt, "HH:mm");
    data.sectorId = data.sector && data.sector.id;
    data.workingExperienceType =
      data.workingExperienceType && data.workingExperienceType.id;
    data.workingTypeIds =
      data.workingTypes && data.workingTypes.map((x) => x.id);
    data.workingPlaceIds =
      data.workingPlaces && data.workingPlaces.map((x) => x.id);
    data.genderType = data.genderOption && data.genderOption.id;
    data.smokingOption = data.smokeOption && data.smokeOption.id;
    data.educationLevels =
      data.educationLevels && data.educationLevels.map((x) => x.id);
    data.militaryStatuses =
      data.militaryStatus && data.militaryStatus.map((x) => x.id);
    data.jobAdvertisementClass =
      data.jobAdvertisementClass && data.jobAdvertisementClass.id;

    delete data.applicantRights;

    return { ...data };
  };

  const onSubmit = async (values) => {
    const data = {
      ...values,
      workStartAt: moment(values.workStartAt).format("HH:mm"),
      workEndAt: moment(values.workEndAt).format("HH:mm"),
      cityIds: items
        .filter((el) => el.type === "CITY")
        .map((x) => {
          return x.id;
        }),
      foreignLanguages,
      disabilities,
    };
    data.countyIds = items
      .filter((el) => el.type === "COUNTY")
      .map((x) => {
        return x.id;
      });
    setLoading(true);
    try {
      const response = await new Api().putData({
        url: `/jobs/${id}`,
        _data: data,
      });
      if (response.debugMessage) {
        message.error(response.message);
      } else {
        message.success("İş ilanı güncellendi");
      }
    } catch (e) {
      notification.error({
        message: `İş ilanı oluşturulurken bir hata meydana geldi.${e.data.message}`,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleItemSelection = (items) => {
    setItems(items);
  };

  const checkCharacterCount = (e) => {
    if (
      ref.current &&
      ref.current.getEditor().getLength() > MAX_COUNT &&
      e.key !== "Backspace"
    )
      e.preventDefault();
  };
  const validateKeyword = (_, value) => {
    if (value && value.includes("www")) {
      return Promise.reject('Değerde "www" bulunamaz');
    } else if (value && value.includes(".com")) {
      return Promise.reject('Değerde ".com" bulunamaz');
    } else if (value && value.replace(/<[^>]+>/g, "").trim() === "") {
      return Promise.reject("Lütfen boşluk haricinde bir değer giriniz");
    }
    return Promise.resolve();
  };

  const [languageDisabledBtn, setLanguageDisabledBtn] = useState(false);

  return (
    <Spin spinning={loadingUpdate}>
      <div className="JobCreate">
        <Helmet>
          <title>İş Ara İş Bul | Firma İlan Düzenle</title>
          <meta
            name="description"
            content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
          />
          <meta
            name="keywords"
            content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
          />
        </Helmet>
        <Banner text="İlan Düzenle" className="mb-0" />
        <div className="content md content-detail pl-100">
          <h1 className="text-4xl fw-bold ls-full mb-30">FİRMA BİLGİLERİ</h1>
          <Row className="full-width">
            <Form
              className="full-width"
              form={form}
              onFinish={onSubmit}
              onFinishFailed={handleFormErrors}
            >
              <Spin spinning={loadingMyCompany}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Input
                    value={get(myCompany, "companyName")}
                    disabled
                    className="form-input mb-20"
                    placeholder="Firma Adı"
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Input
                    value={get(myCompany, "city.city")}
                    className="form-input mb-20"
                    placeholder="İl"
                    disabled
                  />
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Input
                    value={get(myCompany, "county.county")}
                    className="form-input mb-20"
                    placeholder="İlçe"
                    disabled
                  />
                </Col>
              </Spin>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="hiddenCompany"
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox className="form-check blue mb-20">
                    Firma Bilgilerini Gizle
                  </Checkbox>
                </Form.Item>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-30">İLAN BİLGİLERİ</h1>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="positionTitle"
                  rules={[
                    { required: true },
                    {
                      validator: (_, value) =>
                        !value || value.trim() === ""
                          ? Promise.reject(
                              new Error(
                                "İlan başlığı sadece boşluklardan oluşamaz"
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <Input
                    className="form-input mb-20"
                    placeholder="İlan Başlığını Yazınız"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="personalCount"
                  rules={[
                    {
                      required: true,
                      message: "İstihdam edilcek kişi sayısı boş geçilemez.",
                    },
                    {
                      validator: (_, value) =>
                        value === 0
                          ? Promise.reject(
                              new Error(
                                "İstihdam edilecek kişi sayısı 0'dan büyük olmalı."
                              )
                            )
                          : Promise.resolve(),
                    },
                  ]}
                >
                  <InputNumber
                    type="number"
                    className="form-input full-width mb-20"
                    placeholder="İstihdam edilecek kişi sayısını yazınız"
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="qualifications"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen Genel Nitelik ve İş Tanımı Giriniz",
                    },
                    {
                      validator: validateKeyword,
                    },
                  ]}
                >
                  <ReactQuill
                    ref={ref}
                    modules={modules}
                    onKeyDown={checkCharacterCount}
                    style={{ height: "300px" }}
                    value=""
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="countyIds"
                  rules={[{ required: true, message: "İlçe boş geçilemez" }]}
                >
                  <CityCountyLookupSelect
                    value={form.getFieldValue("countyIds")} // Burada formdaki mevcut değeri bileşene ata
                    url="/public/counties"
                    stateKey="counties"
                    labelKey="value"
                    valueKey="id"
                    valueType="type"
                    onChangeObject={handleItemSelection}
                    className="form-select full-width mt-30 mb-20"
                    placeholder="İstihdam Edilecek İstanbul İlçeleri"
                    mode="multiple"
                  />
                </Form.Item>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mt-30 mb-0">
                Eğitim Seviyesi
              </h1>
              <Col span={24}>
                <Spin spinning={loadingEducationLevels}>
                  <Form.Item
                    name="educationLevels"
                    rules={[
                      {
                        required: true,
                        message: "Eğitim seviyesi boş geçilemez",
                      },
                    ]}
                  >
                    <Checkbox.Group className="form-check blue full-width mb-20">
                      <Row className="full-width mt-20" gutter={[10, 20]}>
                        {lookupEducationLevels &&
                          lookupEducationLevels.map((x) => (
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 6 }}
                              key={x.name}
                            >
                              <Checkbox value={x.name}>{x.label}</Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Spin>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="sectorId"
                  rules={[{ required: true, message: "Sektör boş geçilemez" }]}
                >
                  <LookupSelect
                    url="/sectors"
                    valueKey="sectorId"
                    labelKey="sectorName"
                    stateKey="sectors"
                    className="mb-20"
                    placeholder="Sektör"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item
                  name="professionId"
                  rules={[
                    { required: true, message: "Pozisyon boş geçilemez" },
                  ]}
                >
                  <LookupSelect
                    url="/professions"
                    labelKey="profession"
                    valueKey="id"
                    className="form-select mb-20"
                    placeholder="Pozisyon"
                    stateKey="professions"
                  />
                </Form.Item>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                İŞ TECRÜBESİ
              </h1>
              <Col span={24}>
                <Form.Item
                  name="workingExperienceType"
                  rules={[
                    { required: true, message: "İş tecrübesi boş geçilemez." },
                  ]}
                >
                  <Radio.Group
                    className="form-radio full-width"
                    onChange={(e) => setWorkingExperienceType(e.target.value)}
                  >
                    <Row className="full-width" align="middle">
                      <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                        <Radio value="F">Tecrübesiz</Radio>
                      </Col>
                      <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                        <Radio value="T">Tecrübeli</Radio>
                      </Col>
                      <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 4 }}>
                        <Radio value="A">Hepsi</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>
                {workingExperienceType === "T" && (
                  <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
                    <Form.Item name="minExperienceYear">
                      <InputNumber
                        min={1}
                        type="number"
                        className="form-input full-width"
                        placeholder="Tecrübe"
                      />
                    </Form.Item>
                  </Col>
                )}
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                ÇALIŞMA ŞEKLİ
              </h1>
              <Col span={24}>
                <Spin spinning={loadingWorkingTypes} className="full-width">
                  <Form.Item
                    name="workingTypeIds"
                    rules={[
                      {
                        required: true,
                        message: "Çalışma şekli boş geçilemez.",
                      },
                    ]}
                  >
                    <Checkbox.Group className="form-check blue full-width mb-20">
                      <Row
                        className="full-width"
                        align="middle"
                        gutter={[0, 20]}
                      >
                        {workingT &&
                          workingT.map((x) => (
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 6 }}
                              key={x.name}
                            >
                              <Checkbox value={x.id}>{x.description}</Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Spin>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                ÇALIŞMA YERİ
              </h1>
              <Col span={24}>
                <Spin spinning={loadingWorkingPlaces} className="full-width">
                  <Form.Item
                    name="workingPlaceIds"
                    rules={[
                      {
                        required: true,
                        message: "Çalışma yeri boş geçilemez.",
                      },
                    ]}
                  >
                    <Checkbox.Group className="form-check blue full-width mb-20">
                      <Row
                        className="full-width"
                        align="middle"
                        gutter={[0, 20]}
                      >
                        {workingPlaces &&
                          workingPlaces.map((x) => (
                            <Col
                              xs={{ span: 12 }}
                              sm={{ span: 12 }}
                              md={{ span: 6 }}
                              key={x.name}
                            >
                              <Checkbox value={x.id}>{x.description}</Checkbox>
                            </Col>
                          ))}
                      </Row>
                    </Checkbox.Group>
                  </Form.Item>
                </Spin>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                ÇALIŞMA SAATİ
              </h1>
              <Row gutter={20}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="workStartAt">
                    <TimePicker
                      className="form-input full-width"
                      minuteStep={15}
                      secondStep={10}
                      format="HH:mm"
                      placeholder="Başlangıç"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="workEndAt">
                    <TimePicker
                      className="form-input full-width"
                      minuteStep={15}
                      secondStep={10}
                      format="HH:mm"
                      placeholder="Bitiş"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={20}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                  <Form.Item name="weeklyWorkingHours">
                    <InputNumber
                      min={1}
                      className="form-input full-width mt-20"
                      placeholder="Haftalık Çalışma Saatini Giriniz"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                HAFTALIK İZİN GÜNLERİ
              </h1>
              <Spin spinning={loadingWeekDays}>
                <Form.Item name="weeklyOffDays">
                  <Checkbox.Group className="full-width">
                    <Row className="full-width" gutter={[0, 20]} align="middle">
                      {weekDays &&
                        weekDays.map((x) => (
                          <Col
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 6 }}
                            key={x.name}
                          >
                            <Checkbox
                              value={x.name}
                              className="form-check blue"
                            >
                              {x.label}
                            </Checkbox>
                          </Col>
                        ))}
                    </Row>
                  </Checkbox.Group>
                </Form.Item>
              </Spin>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                MAAŞ ARALIĞI
              </h1>
              <Row gutter={20} align="middle">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="salaryRangeId">
                    <Select>
                      {salaryRanges?.map((item, index) => {
                        return (
                          <Select.Option key={index} value={item.hashId}>
                            {" "}
                            {item.label}{" "}
                          </Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  {/** TODO: Buraya ne gelecek */}
                  <Form.Item
                    name="showSalaryToCandidate"
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox className="form-check blue">
                      Aday Görebilsin
                    </Checkbox>
                  </Form.Item>
                </Col>
              </Row>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                SÜRÜCÜ BELGESİ
              </h1>
              <Row gutter={20}>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item name="drivingLicenceTypes">
                    <LookupSelect
                      className="mb-20"
                      url="/types/driving-license"
                      placeholder="Sürücü Belgesi"
                      mode="multiple"
                      stateKey="drivingLicences"
                    />
                  </Form.Item>
                </Col>
                <Col span={24} className="d-flex a-center">
                  <p className="text-xl w-250 mr-20 mb-0">
                    Aktif Araç Kullanımı:
                  </p>
                  <Form.Item name="activeDriving" initialValue={false}>
                    <Radio.Group className="form-radio full-width">
                      <Radio value={true}>Evet</Radio>
                      <Radio value={false}>Hayır</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
              <ForeignLanguage
                value={get(data, "foreignLanguages", [])}
                onChange={onChangeForeignLanguage}
                setLanguageDisabledBtn={setLanguageDisabledBtn}
              />
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                YAŞ ARALIĞI
              </h1>
              <Row gutter={20} className="full-width">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
                  <Form.Item name="ageMin">
                    <InputNumber
                      type="number"
                      className="form-input full-width pl-10 d-flex a-center"
                      placeholder="En az"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
                  <Form.Item name="ageMax">
                    <InputNumber
                      type="number"
                      className="form-input full-width pl-10 d-flex a-center"
                      placeholder="En çok"
                    />
                  </Form.Item>
                </Col>
              </Row>
              {/*<Form.Item name="genderType">*/}
              {/*  <Gender />*/}
              {/*</Form.Item>*/}
              <DisabilityStatus
                value={get(data, "disabilities", [])}
                onChange={onChangeDisability}
              />
              {/*<Form.Item name="militaryStatuses">*/}
              {/*  <MilitaryStatus />*/}
              {/*</Form.Item>*/}
              <Row>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  SOSYAL VE YAN HAKLAR
                </h1>
                <Spin spinning={loadingApplicantRights}>
                  <Col span={24}>
                    <Form.Item name="applicantRightsId">
                      <Checkbox.Group className="form-check blue full-width">
                        <Row className="full-width" gutter={[10, 20]}>
                          {applicantRights &&
                            applicantRights.map((x) => (
                              <Col
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 6 }}
                                key={x.id}
                              >
                                <Checkbox value={x.id}>{x.title}</Checkbox>
                              </Col>
                            ))}
                        </Row>
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Spin>
              </Row>
              <Row>
                <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                  SİGARA OPSİYONU
                </h1>
                <Spin spinning={loadingSmokingOptions}>
                  <Col span={24}>
                    <Form.Item
                      name="smokingOption"
                      rules={[
                        {
                          required: true,
                          message: "Sigara opsiyonu boş geçilemez.",
                        },
                      ]}
                    >
                      <Radio.Group className="form-radio full-width">
                        <Row className="full-width" gutter={[10, 20]}>
                          {smokingOptions &&
                            smokingOptions.map((x) => (
                              <Col
                                xs={{ span: 12 }}
                                sm={{ span: 12 }}
                                md={{ span: 6 }}
                                key={x.name}
                              >
                                <Radio value={x.name}>{x.label}</Radio>
                              </Col>
                            ))}
                        </Row>
                      </Radio.Group>
                    </Form.Item>
                  </Col>
                </Spin>
              </Row>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                ÖN YAZI TALEP
              </h1>
              <Col span={24}>
                <Form.Item name="coverLetterRequested" valuePropName="checked">
                  <Checkbox className="form-check blue">Evet</Checkbox>
                </Form.Item>
              </Col>
              <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">
                İLAN SINIFI
              </h1>
              <Row gutter={20} align="middle">
                <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
                  <Form.Item
                    name="jobAdvertisementClass"
                    rules={[
                      { required: true, message: "İlan Sınıfı boş geçilemez." },
                    ]}
                  >
                    <LookupSelect
                      url="/types/job-advertisement-class"
                      placeholder="Beyaz-Mavi Yaka"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <div className="d-flex j-center mt-50">
                {/* <Button className="BtnPreview">Ön İzleme</Button> */}
                <Button
                  className="BtnSave"
                  htmlType="submit"
                  loading={loading}
                  disabled={languageDisabledBtn}
                >
                  Güncelle
                </Button>
              </div>
            </Form>
          </Row>
        </div>
      </div>
    </Spin>
  );
};
