import { Row, Col, Button, Modal, message } from 'antd';
import { useHistory } from 'react-router-dom';
import queryString from 'query-string';
import { get } from 'lodash';
import { useState } from 'react';
import { deleteData } from 'redux/services/request';
import moment from "moment";

const SearchItem = ({ data, refresh }) => {

  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const replaySearch = () => {
    const query = queryString.stringify(data.keyword);
    history.push(`/jobs/list?${query}`);
  };

  const deleteSearch = async () => {
    setLoading(true);
    try {
      await deleteData({ url: `/filters/jobs/${data.id}` });
      message.success('Arama silindi');
      setLoading(false);
      setVisible(false);
      refresh && refresh();
    } catch (e) {
      setLoading(false);
      setVisible(false);
      message.error("Arama silinirken bir hata meydana geldi");
      refresh && refresh();
    }
  }

  return (
    <Row className="SearchItem" justify="space-between">
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
        <h1 className="text-4xl text-blue fw-bold">{get(data, 'keyword.keyword', '-')}</h1>
        <p className="text-xl ls-half mb-10">Sektör: <b>{get(data, 'explicit.sectors', ['-']).join(", ")}</b></p>
        <p className="text-xl ls-half mb-10">İlçe: <b>{get(data, 'explicit.countyOptions', ['-']).join(", ")}</b></p>
        <p className="text-xl ls-half mb-10">Tarih: <b>{moment(get(data, 'createdAt')).format('DD/MM/YYYY')}</b></p>
        <p className="text-xl ls-half mb-10">Çalışma Şekli: <b>{get(data, 'explicit.workingTypeOptions', ['-']).join(", ")}</b></p>
        <p className="text-xl ls-half mb-10">Tecrübe: <b>{get(data, 'explicit.experienceType', '-')}</b></p>
        <p className="text-xl ls-half mb-10">Eğitim: <b>{get(data, 'explicit.educationLevelOptions', ['-']).join(", ")}</b></p>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} className="d-flex fd-column j-between a-end">
        <div className="BtnActions d-flex full-width j-end">
          <Button type="default" className="btn btn-pink radius-md text-xl ls-half mr-15" onClick={replaySearch}>Aramayı Tekrarla</Button>
          <Button className="btn btn-blue-filled radius-md text-md ls-half" onClick={() => setVisible(true)}>Sil</Button>
        </div>
        <p className="text-xl ls-half mb-10">Son Arama Tarihi: {moment(get(data, 'startDate')).format('DD/MM/YYYY')}</p>
      </Col>
      <Modal visible={visible} footer={null} onCancel={() => setVisible(false)} className="modal-question" closeIcon={<div></div>}>
        <p className="text-4xl">
          <b className="text-blue">"{get(data, 'keyword.keyword', '-')}"</b> isimli kayıtlı arama silinsin mi?
        </p>
        <div className="d-flex j-center">
          <Button className="ModalBtn btn-blue " onClick={() => setVisible(false)}>İptal</Button>
          <Button className="ModalBtn btn-pink-filled" onClick={deleteSearch} loading={loading}>Evet</Button>
        </div>
      </Modal>
    </Row >
  );
};

export default SearchItem;

