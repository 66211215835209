import { Row, Col, Rate } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { LookupSelect } from "components/UIComponents/FormElements";
import { useEffect, useState } from "react";
import ForeignLanguage from ".";

export default ({ onChange, remove, index,setLanguageDisabledBtn }) => {

  const [languageId, setLanguageId] = useState(null);
  const [readingLevel, setReadingLevel] = useState(0);
  const [writingLevel, setWritingLevel] = useState(0);
  const [speakingLevel, setSpeakingLevel] = useState(0);
  const [translatingLevel, setTranslatingLevel] = useState(0);

  const [languageErrorMessage,setLanguageErrorMessage] = useState('');

  useEffect(() => {
    if(languageId && (!readingLevel || !writingLevel || !speakingLevel || !translatingLevel)){
      setLanguageDisabledBtn(true);
      setLanguageErrorMessage('Boş bırakılamaz');
    }else{
      setLanguageDisabledBtn(false);
      setLanguageErrorMessage('');
    }
  },[languageId,readingLevel,writingLevel,speakingLevel,translatingLevel,setLanguageDisabledBtn]);
  

  useEffect(() => {
    onChange(index, {
      languageId,
      readingLevel,
      writingLevel,
      speakingLevel,
      translatingLevel
    });
    // eslint-disable-next-line
  }, [languageId, readingLevel, writingLevel, speakingLevel, translatingLevel]);

  return (
    <div className="ForeignLanguage">
      <Row className="full-width" gutter={30} justify="space-between" align="middle">
        <Col>
          <LookupSelect url="/languages" valueKey="id" value={languageId} labelKey="name" className="form-select w-200" placeholder="Dil" onChange={languageId => setLanguageId(languageId)} />
          <DeleteOutlined onClick={() => remove(languageId)} className="ml-10" />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Okuma</p>
          <Rate
            className="text-4xl l-1"
            value={readingLevel}
            onChange={x => setReadingLevel(x)}
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>}
          />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Yazma</p>
          <Rate
            value={writingLevel}
            onChange={x => setWritingLevel(x)}
            className="text-4xl l-1" character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>} />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Konuşma</p>
          <Rate
            value={speakingLevel}
            onChange={x => setSpeakingLevel(x)}
            className="text-4xl l-1"
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>} />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Tercüme</p>
          <Rate
            value={translatingLevel}
            onChange={x => setTranslatingLevel(x)}
            className="text-4xl l-1"
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
            <g fill="none" fillRule="evenodd">
              <g fill="currentColor">
                <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
              </g>
            </g>
          </svg>} />
        </Col>
      </Row>
          {languageErrorMessage && <div className="text-lg text-red">{languageErrorMessage}</div>}
    </div>
  );
}
