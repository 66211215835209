import React from "react";
import { Form } from "antd";
import { validations } from "./utility";

export default function AuthLayoutInput({
  label,
  type,
  name,
  input,
  validation = true,
}) {
  console.log(validations);
  return (
    <Form.Item
      label={label}
      type={type}
      name={name}
      rules={
        validation
          ? validations.find((input) => input?.name === name)?.rules
          : null
      }
      className="auth-form-input-wrapper"
    >
      {input}
    </Form.Item>
  );
}
