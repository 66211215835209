import {useEffect, useState} from "react";
import {Col, Form, Radio, Row} from 'antd';
import {LookupSelect} from "components/UIComponents/FormElements";
import {Icon} from "components/UIComponents";
import StartDate from "../components/StartDate";
import EndDate from "../components/EndDate";

export default ({data}) => {
  const [city, setCity] = useState(null);
  const [ins, setIns] = useState(null);
  const [ongoing, setOngoing] = useState();

  useEffect(() => {
    if (data) {
      setOngoing(data.status)
      setCity(data.city)
      setIns(data.instituteId)
    }
  }, [data])

  return (
    <Row>
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Row className="full-width">
          <Col span={24}>
            <Form.Item name="city" rules={[{required: true, message:"İl"}]}>
              <LookupSelect url={data && `/locations/cities`} labelKey="city" valueKey="id" value={city} placeholder="İl"
                            className="mb-20" onChange={setCity}/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="instituteId" rules={[{required: true, message: "Lise"}]}>
              <LookupSelect url={city && `/educations/${city}/high-schools`} labelKey="name" valueKey="id"
                            placeholder="Okuduğunuz Lisenin Adı" className="mb-20" value={ins} onChange={setIns}/>
            </Form.Item>
          </Col>
          <Col span={24} className="d-flex a-center mb-20">
            <Icon name="calendar" size={42}/>
            <StartDate/>
            {ongoing !== 'ONGOING' && <EndDate/>}
          </Col>
          <Col span={24}>
            <h1 className="text-xl fw-bold ls-full mb-20 mt-30">Devam Durumu <span className="text-orange-bio">(*)</span></h1>
            <Form.Item name="status" rules={[{required: true, message:"Devam Durumu"}]}>
              <Radio.Group className="form-radio mb-50" onChange={e => setOngoing(e.target.value)}>
                <Radio value="ONGOING">Devam Ediyor</Radio>
                <Radio value="GRADUATED">Mezun</Radio>
                <Radio value="ABANDON">Terk</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
