import { Space } from "antd";
import Hero from "views/Dashboard/Home/Hero";
import Portal from "./Home/Portal";
import Statistics from "./Home/Statistics";
import Cookies from "../../views/Dashboard/Home/Cookies";
import Cookie from "./Home/Cookie";
import Fair from "./Home/Fair";

const Home = () => {
  return (
    <div className="Home">
      <Hero />
      {/* {!localStorage.getItem('cookieAllowed') && <Space wrap><Cookies/></Space>} */}
      {/*<Highlights />
      <Announcements />*/}
      {/* <Fair /> */}
      <Portal />
      <Statistics />
      {/*<Firms />
      <Testimonials />*/}
    </div>
  );
};

export default Home;
