import moment from 'moment';
import {Row, Col, Input, DatePicker, Button, Modal, Form, message} from 'antd';
import {Icon} from 'components/UIComponents';
import {get} from 'lodash';
import {postData2} from 'redux/services/request';
import {useState} from 'react';
import {LookupSelect} from 'components/UIComponents/FormElements';

const Invite = ({candidate, applicationId, visible, setVisible,refresh}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ faceToFace, setFaceToFace ] = useState( false );

  const invite = async (data) => {
    const dataformat=moment(data.interviewAt).format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';
    data.interviewAt = dataformat
    data.applicationId = applicationId;
    setLoading(true);
    try {
      let result = await postData2({url: '/interviews', data});
      if(result.code === 200){
        message.success('Aday mülakata davet edildi');
      }else{
        message.info(result.debugMessage);
      }
      setLoading(false);
      setVisible(false);
      refresh()
    } catch (e) {
      message.error('Aday mülakata davet edilirken bir hata meydana geldi');
      setLoading(false);
      setVisible(false);
    }
  }

  return (
    <Modal className="InviteModal" open={visible} width={800} onCancel={() => setVisible(false)} footer={null}
           closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri"/>}>
      <Form form={form} onFinish={invite}>
        <Row className="full-width">
          <Col span={24}>
            <h1 className="fw-bold mb-20">
              <span className="text-blue mr-10">{get(candidate, 'firstName')} {get(candidate, 'lastName')}</span>isimli
              adayı Mülakata Davet Et
            </h1>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 13}}>
            <Form.Item name="type">
              <LookupSelect
                location={faceToFace}
                setLocation={setFaceToFace}
                url="/interviews/types/interviews"
                labelKey="label"
                valueKey="name"
                placeholder="Görüşme Tipi"/>
            </Form.Item>
          </Col>
          <Col span={24} className="d-flex a-center mb-15">
            <Icon name="calendar" size={42}/>
            <p className="text-red-pink text-4xl fw-bold ls-full mb-0 ml-10">Mülakat Tarihi</p>
          </Col>
          <Col span={13}>
            <Form.Item name="interviewAt">
              <DatePicker className="form-input full-width mb-20" suffixIcon={<Icon name="double-arrow" size={30} />} placeholder="Tarih" showTime showSecond={false} minuteStep={15} />
            </Form.Item>
          </Col>
          {
            faceToFace && (
              <Col span={13}>
                <Form.Item name="location">
                  <Input className="form-input full-width mb-20" placeholder="Konum" maxLength={200} />
                </Form.Item>
              </Col>
            )
          }
          <Button htmlType="submit" className="InviteBtn" loading={loading}>Davet Et</Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default Invite;
