import React, { useEffect, useState, useRef } from "react";
import { Spin, Image } from "antd";
import useGet from "hooks/useGet";
import { get } from "lodash";
import CountUp from "react-countup";
import skills from "assets/static/icons/skills.svg";
import team from "assets/static/icons/team.svg";
import profile from "assets/static/icons/profile.svg";
import user from "assets/static/icons/user.svg";

const Statistics = () => {
  const { data, loading } = useGet({ url: "/public/stats", isArray: false });

  const counterElement = useRef();

  const [pageCounter, setPageCounter] = useState({
    advertisementCount: 0,
    companyCount: 0,
    resumeCount: 0,
    employmentCount: 0,
  });

  console.log("counterData", data);
  console.log("pageCounter", pageCounter);

  const counterData = [
    {
      name: "İş İlanı",
      duration: 2,
      end: pageCounter.advertisementCount,
      imgAlt: "İş İlanı",
      img: skills,
    },
    {
      name: "İşveren",
      duration: 5,
      end: pageCounter.companyCount,
      imgAlt: "İş Veren",
      img: team,
    },
    {
      name: "Öz Geçmiş",
      duration: 5,
      end: pageCounter.resumeCount,
      imgAlt: "Özgeçmiş",
      img: profile,
    },
    {
      name: "İstihdam",
      duration: 5,
      end: pageCounter.employmentCount,
      imgAlt: "İstihdam",
      img: user,
    },
  ];

  useEffect(() => {
    setPageCounter({
      advertisementCount: get(data, "advertisementCount", 0),
      companyCount: get(data, "companyCount", 0),
      resumeCount: get(data, "resumeCount", 0),
      employmentCount: get(data, "employmentCount", 0),
    });
  }, [data]);

  // const handleScroll = () => {
  //   if (window.pageYOffset >= counterElement?.current.clientHeight) {
  //     return setPageCounter({
  //       advertisementCount: get(data, "advertisementCount", 0),
  //       companyCount: get(data, "companyCount", 0),
  //       resumeCount: get(data, "resumeCount", 0),
  //       employmentCount: get(data, "employmentCount", 0),
  //     });
  //   }
  // };

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);

  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, [handleScroll]);

  return (
    <>
      <div className="bg-orange"></div>
      <div className="Statistics">
        <div className="content md d-flex fd-column a-center">
          <h1 className="section-header text-white mb-10">
            Rakamlarla Bölgesel İstihdam Ofisleri
          </h1>
          <p className="section-info text-white mb-70">
            İstanbul'un nitelikli iş ve kalifiye işgücünü, Bölgesel İstihdam
            Ofisleri Portalı'nda buluşturuyoruz.
          </p>
          <Spin spinning={loading}>
            <div
              className="StatCardContainer d-flex j-around full-width"
              ref={counterElement}
            >
              {Object.values(counterData).map((data, key) => {
                return (
                  <div className="StatCard" key={key}>
                    <img
                      src={data.img}
                      alt={data.imgAlt}
                      width={80}
                      height={80}
                    />
                    <h1 className="fw-extrabold text-white mb-0">
                      <CountUp end={data.end} duration={data.duration} />
                    </h1>
                    <h2 className="text-white mb-0">{data.name}</h2>
                  </div>
                );
              })}
            </div>
          </Spin>
        </div>
      </div>
      <div className="bg-yellow"></div>
    </>
  );
};

export default Statistics;
