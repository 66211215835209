import React,{ useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Checkbox, Input, message } from "antd";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import { useHistory } from "react-router-dom";
import { postNewLogin, updateState } from "../../redux/services/request";
import { dataLayerPush } from "../../helpers/methods";
import axios from "axios";
import { selectRoute } from "../../helpers/methods";
import {fetchToken} from "../../helpers/methods";

export default function LoginCitizen() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const query = useQuery();

  useEffect(() => {
    query.get('code') !== null && fetchToken( query.get('code'), setLoading, history );
  },[query]);

  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const handleSubmit =  async(data) => {
    setLoading(true);
    const newData = {
      ...data,
      userType: 'CITIZEN'
    }

    try {
      updateState({ loginInit : { data: null, loading: true }});
      const response = await postNewLogin({ url: "/auth/login", data: newData });

      if( response?.code !== 200 ){
        message.error( response?.message )
        setLoading( false )
        return;
      }

      const route = selectRoute( response?.result?.routeTo, response?.result?.stateId, response?.result?.uid, '/login-citizen' );

      // local pushes
      localStorage.setItem( 'accessToken', response?.result?.accessToken );
      localStorage.setItem("type", response?.result?.userType );
      localStorage.setItem("fullName", response?.result?.fullName );
      localStorage.setItem("userId", response?.result?.id );

      // datalayer
      dataLayerPush({
        'event' : "uyeGiris",
        'result' : "uyeGirisBasarili"
      })

      // bearer token
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
      localStorage.getItem('type') && (
        axios.defaults.headers.common['userType'] = localStorage.getItem('type')
      );

      if( response?.result?.routeTo !== 'PROFILE_UPDATE' ){
        message.success( `Giriş bilgileri başarılı teşekkür ederiz ${ response?.result?.fullName }.` );
      }
      updateState({ loginInit : { data: response, loading: false }});
      setLoading( false );
      history.push( route );

    } catch {
      setLoading(false);
    }
  }

  const initialValues = {
    idNo: '',
    password: ''
  }

  return (
    <>
      <AuthLayoutForm
        formTitle="Üye Girişi"
        button="GİRİŞ YAP"
        formName="login-citizen"
        onFinish={ handleSubmit }
        initialValues={ initialValues }
        redirect={ true }
        redirectQuestion="Üyeliğiniz yok mu ?"
        redirectUrl="/register-citizen"
        redirectButton="Kayıt Ol"
        loading={ loading }
        istanbulSenin={ true }
        seoDescription="Bölgesel İstihdam Ofisi Aday Giriş"
        seoTitle="Bölgesel İstihdam Ofisi Aday Giriş"
        seoKeywords="Aday, Aday Girişi, Üye Girişi, Vatandaş, Vatandaş Giriş"
      >
        <AuthLayoutInput
          name="idNo"
          label="TC Kimlik No:"
          input = {<Input className="auth-form-input" maxLength={11} />}
        />
        <AuthLayoutInput
          name="password"
          label="Şifre:"
          input={ <Input.Password/> }
        />
        <AuthLayoutInput
          input={
            <>
              <Checkbox>Beni bu bilgisayarda hatırla</Checkbox>
              <Link style={{ color: "#0358AC" }} to={{ pathname:"/forgot-password", state: { from: 'CITIZEN' }}}>
                Şifremi Unuttum
              </Link>
            </>
          }/>
      </AuthLayoutForm>
    </>
  );
}
