import { Collapse } from 'antd';
import Banner from "components/UIComponents/Banner"
import { Icon } from 'components/UIComponents';
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div className="Faq">
      <Helmet>
        <title>İş Ara İş Bul | Genel Koşullar</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Genel Koşullar" className="mb-0" />
      <div className="content md content-detail pl-100 pr-100 pb-80 pt-80">
        <Collapse accordion expandIconPosition="right" expandIcon={() => <Icon name="arrow-down" size={32} />}>
          <Collapse.Panel key="1" header="GENEL KOŞULLAR">
            <p>Bu bölüm BÖLGESEL İSTİHDAM OFİSLERİ PORTALI'nın genel ve kanuni sorumluluklarını içerir. Lütfen, portalımızı kullanmadan önce aşağıda yazılı olanları dikkatli bir şekilde okuyunuz. Aşağıda belirtilen şartları kısmen ve/veya tamamen kabul etmiyorsanız, Bölgesel İstihdam Ofisleri Portalı’nı kullanmayınız.
            Bölgesel İstihdam Ofisleri Portalı, bu sayfalarda belirtilen genel koşulları dilediği zaman ve şekilde değiştirme hakkını kendinde saklı tutar. Bölgesel İstihdam Ofisleri Portalı’nın www.bio.ibb.istanbul adresini ziyaret ettiğinizde Genel Koşullar sayfasını da ziyaret etmeniz gerekmektedir. Bu nedenle www.bio.ibb.istanbull adresini ziyaret ettiğinizde bu sayfayı da ziyaret ettiğiniz kabul edilir.</p>
          </Collapse.Panel>
          <Collapse.Panel key="2" header="KOPYALAMA">
            <p>Bölgesel İstihdam Ofisleri Portalı içeriğinde yer alan bütün bilgilerin, makalelerin, yazıların, logoların, grafiklerin ve bunlarla sınırlı olmamak üzere her türlü görsel ve resimlerin her hakkı saklıdır. Bunlar hiçbir şekilde izinsiz kullanılamaz. Bu portalda yer alan herhangi bir unsuru diğer bir internet sitesinde ve/veya başka bir mecrada yazılı, sözlü ve/veya elektronik olarak ya da başka şekilde yayımlamak ve/veya Bölgesel İstihdam Ofisleri Portalı’nın haberi olmadan link vermek yasaktır.
            Ayrıca portal sayfalarının tasarımında ve veritabanı oluşturulmasında kullanılan bilgi ve/veya yazılımın kopyalanması ve/veya kullanılması yasaktır.
            Portalda bulunan yazılım, görsel ve tasarımların her türlü hakkı Bölgesel İstihdam Ofisleri Portalı’na aittir.</p>
          </Collapse.Panel>
          <Collapse.Panel key="3" header="KULLANIM">
            <p>Bölgesel İstihdam Ofisleri Portalı herkese açıktır. Yalnızca aşağıdaki hallerde, Bölgesel İstihdam Ofisleri Portalı’nın kullanımını geçici ve/veya sürekli olarak engelleyebilir:</p>
            <ol>
              <li>Yanlış, düzensiz, eksik ve/veya yanıltıcı bilgi ve/veya uygun olmayan fotoğraf içeren özgeçmişin portala kaydedilmesi halinde,</li>
              <li>Özgeçmiş haricindeki bilgilerin; özel ve/veya genel duyuruların, reklam amaçlı firma bilgilerinin ve/veya üyelik satış formasyonları gibi bilgilerin ilave edilmesi halinde,</li>
              <li>Diğer bir şahıs ve/veya kurumlar tarafından ilan edilen her türlü bilgi, görsel ve/veya logonun silinmesi tahrif edilmesi ve/veya değiştirilmesi halinde,</li>
            </ol>
          </Collapse.Panel>
          <Collapse.Panel key="4" header="BÖLGESEL İSTİHDAM OFİSLERİ PORTALI GÜVENLİK KURALLARI">
            <p>Belirtilen güvenlik kurallarına kısmen ve/veya tamamen uyulmaması halinde ya da kasıtlı veya kasıtsız olarak aşağıda belirtilenlerden herhangi birini yapan kişi, kişiler ve/veya kurum, kurumlar hakkında Bölgesel İstihdam Ofisleri Portalı her türlü kanuni hakkını saklı tutar.</p>
            <ol>
              <li>Kullanıcıların diğer özgeçmiş bilgilerine girme çabaları ve/veya portalın genel güvenliğini tehdit edecek her türlü doğrudan ve/veya dolaylı çalışmalar,</li>
              <li>Portalda kullanılan yazılımların çalışmasını engelleyebilecek her türlü doğrudan ve/veya dolaylı faaliyetler,</li>
              <li>Virüs bulaştırarak portalın çalışmasına engel olma,</li>
              <li>Portalın genel kurallarına uygun olmayan her türlü elektronik postanın yollanması ve/veya portalın kilitlenmesini sağlama amacıyla aynı anda oldukça fazla ticari ve/veya kişisel elektronik postanın yollanması gibi.</li>
            </ol>
          </Collapse.Panel>
          <Collapse.Panel key="5" header="BÖLGESEL İSTİHDAM OFİSLERİ PORTALI’NIN SORUMLULUKLARI">
            <p>Bölgesel İstihdam Ofisleri Portalı, portal üzerinde yer alan firma ve aday bilgilerinin içeriğinden hiçbir şekilde sorumlu tutulamaz. Bu sitede yer alan bilgilerle ilgili her türlü risk kullanıcılara aittir. Portal üzerinde önceden herhangi bir haber vermeye gerek duyulmadan, istediği değişiklikleri yapma hakkı bizzat Bölgesel İstihdam Ofisleri Portalı’na aittir.
            Özgeçmişini sitede bulunduran kullanıcı; özgeçmişinde bulunan her türlü bilginin tek sorumlusudur.
            Bölgesel İstihdam Ofisleri Portalı işveren değildir; sitede yer alan özgeçmişler Bölgesel İstihdam Ofisleri Portalı sorumluluğu altında yer almamaktadır. İşveren firma ile kendi imkânlarıyla irtibata geçen özgeçmiş sahibi, bütün sorumluluğu üzerine almış olur.
            Bölgesel İstihdam Ofisleri Portalı, portalın yazılımının her türlü hatadan arınmış olduğunu ve portalda herhangi bir virüs yer alıp almadığı konusunda herhangi bir sorumluluk yüklenmemektedir. Eğer bu portalda yer alan herhangi bir yazılım sebebi ile kullandığınız yazılım ve/veya donanım unsurlarına herhangi bir zarar gelirse Bölgesel İstihdam Ofisleri Portalı bu konuda hiçbir sorumluluk yüklenmez.
            Bu portalda bulunan internet bağlantıları (linkler) ile ilgili her türlü risk kullanıcıya aittir. Bölgesel İstihdam Ofisleri Portalı özgeçmişinize bağlı olan bütün bilgileri kendi faaliyetleri ile ilgili olarak kullanma hakkına sahiptir.
            Bölgesel İstihdam Ofisleri Portalı’nı tercih ederek bize gösterdiğiniz ilgi ve güven için teşekkür ederiz.</p>
          </Collapse.Panel>
        </Collapse>
      </div>
    </div>
  );
}