import { Form, Row, Col, Input, Button, message } from "antd";
import Banner from "components/UIComponents/Banner";
import { generateRecaptcha } from "helpers/methods";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { postData } from "redux/services/request";
import { Helmet } from "react-helmet";
import Kvkk from "../../../../assets/policy/EK-I İSPER_BİO_İletişim Formu Aydınlatma Metni.pdf";

export default () => {
  const history = useHistory();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);

  const onSubmit = async (data) => {
    setLoading(true);
    if (recaptcha === data.recaptcha) {
      delete data.recaptcha;
      try {
        await postData({ url: "/public/coming-from-you", data });
        setLoading(false);
        message.success("Mesajınız İBB portalına gönderildi");
        history.push("/");
      } catch (e) {
        message.error("Mesajınız İBB portalına gönderilirken bir hata oluştu");
        setLoading(false);
      }
    } else {
      message.error("Doğrulama kodunu doğru girdiğinizden emin olunuz");
      setLoading(false);
    }
  };

  useEffect(() => {
    const code = generateRecaptcha();
    setRecaptcha(code);
  }, []);

  return (
    <div className="ContactUs">
      <Helmet>
        <title>İş Ara İş Bul | Bize Ulaşın</title>
        <meta
          name="description"
          content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
        />
        <meta
          name="keywords"
          content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
        />
      </Helmet>
      <Banner text="Bize Ulaşın" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        <Form form={form} onFinish={onSubmit}>
          <Row className="full-width" gutter={[0, 10]}>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                name="fullName"
                className="mb-0 full-width"
                rules={[{ required: true }]}
              >
                <Input
                  className="form-input"
                  placeholder="Adınız ve  Soyadınız"
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                name="email"
                className="mb-0 full-width"
                rules={[{ required: true }, { type: "email" }]}
              >
                <Input className="form-input" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item
                name="subject"
                className="mb-0 full-width"
                rules={[{ required: true }]}
              >
                <Input className="Topic form-input" placeholder="Konu" />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <Form.Item name="message full-width">
                <Input.TextArea
                  className="Message full-width"
                  placeholder="Lütfen Mesajınızı Yazınız"
                  rows={10}
                  cols={100}
                  maxLength={4000}
                  showCount
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={16} xl={16}>
              <p className="Code">{recaptcha}</p>
            </Col>
            <Col xs={12} sm={12} md={24} lg={16} xl={16}>
              <Form.Item name="recaptcha" rules={[{ required: true }]}>
                <Input
                  className="form-input"
                  placeholder="Kodu Giriniz"
                  style={{
                    width: 175,
                  }}
                />
              </Form.Item>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <Button className="BtnSend" loading={loading} htmlType="submit">
                Gönder
              </Button>
            </Col>
            <Col xs={24} sm={24} md={24} lg={24} xl={24}>
              <span>
                *Gönder butonuna tıklayarak Kişisel Verilerin İşlenmesi Hakkında{" "}
                <a
                  className="clarification-button text-underline fw-bold d-iblock"
                  target="_blank"
                  href={Kvkk}
                >
                  Aydınlatma Metni
                </a>
                ’ni okuduğunuzu beyan ediyorsunuz
              </span>
            </Col>
          </Row>
        </Form>
      </div>
    </div>
  );
};
