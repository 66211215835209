import {useState} from "react";
import { useHistory } from "react-router-dom";
import { postNewLogin, updateState } from "../../redux/services/request";
import { Input, message } from 'antd';
import MaskedInput from 'react-maskedinput';
import AuthLayoutForm from './Components/AuthLayoutForm';
import AuthLayoutInput from "./Components/AuthLayoutInput";

export default function ForgotPassword() {
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit =  async data => {
    setLoading(true);
    updateState({ registerInit : {data: null, loading: true }});

    const newData = {
      ...data,
      phone: "0" + data?.phone?.replace(/[()\s]/g,'')
    }

    try {
      const response = await postNewLogin({ url: "/auth/reset-password", data: newData });

      if( response.code !== 200 ){
        message.error( response?.message )
        setLoading(false )
        return;
      }

      message.success( 'şifre sıfırlamaya yönlendiriliyorsunuz' )
      setLoading(false );
      updateState({ registerInit : { data: { ...response, userType: history.location.state.from, idNo: data.idNo }, loading: true }});
      history.push( '/reset-password' );

    } catch {
      setLoading(false);
    }
  }

  // form input initial values
  const initialValues = {
    idNo: '',
    phone: ''
  }

  return (
    <>
      <AuthLayoutForm
        formTitle="Şifremi Unuttum"
        formName="forgot-password"
        button="Şifremi Unuttum"
        onFinish={ handleSubmit }
        initialValues={ initialValues }
        loading={loading}
      >
        <AuthLayoutInput
          name="idNo"
          label="T.C. Kimlik No:"
          input = {<Input className="auth-form-input" maxLength={11} />}
        />
        <AuthLayoutInput
          name="phone"
          label="Telefon Numarası"
          input={<MaskedInput mask="(111) 111 11 11" name="phone" size="10" placeholder="Telefon" className="masked-input" />}
        />
      </AuthLayoutForm>
    </>
  )
}
