import { useState, useEffect } from 'react';
import {getDataNew} from 'redux/services/request';

export default ({ url, key, params, loadOnce, isArray = true, dataContainerKey = undefined }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);

  const get = async () => {
    setLoading(true);
    try {
      const _data = await getDataNew({ url, key, params });
      setData( _data )
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    url && get();
    // eslint-disable-next-line
    
  }, [url, params]);

  return ({
    data,
    loading,
    refresh: get
  });
}
