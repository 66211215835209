import React from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import moment from "moment";
import "moment/locale/tr";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import store from "./redux/store";
import locale from "antd/lib/locale/tr_TR";
import "styles/antd.less";
import "styles/main.less";
import "leaflet/dist/leaflet.css";
import { ConfigProvider } from "antd";

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "accessToken"
)}`;

if (localStorage.getItem("type")) {
  axios.defaults.headers.common["userType"] = localStorage.getItem("type");
}

moment.locale("tr");

ReactDOM.render(
  <BrowserRouter>
    <ConfigProvider locale={locale}>
      <Provider store={store}>
        <App />
      </Provider>
    </ConfigProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
