import { useState } from 'react';
import { useHistory, withRouter } from 'react-router-dom';
import { Row, Col, Divider, Button, Checkbox, Dropdown, Menu } from 'antd';
import { Icon } from 'components/UIComponents';
import { get } from 'lodash';
import Invite from './Actions/Invite';
import CoverLetter from './Actions/CoverLetter';
import InterviewResult from './Actions/InterviewResult';
import NotSuitable from './Actions/NotSuitable';

const SettingsMenu = ({jobId, candidateId}) => {
  const history = useHistory();

  return (
    <Menu>
      <Menu.Item onClick={() => history.push(`/printcv?candidateId=${candidateId}&jobId=${jobId}&source=APP`)}>
        PDF İndir
      </Menu.Item>
    </Menu>
  );
};

const ListItem = ({ data, history, onCheckChange, disabled, refresh }) => {

 

  const [inviteVisible, setInviteVisible] = useState(false);
  const [coverLetterVisible, setCoverLetterVisible] = useState(false);
  const [notSuitableVisible, setNotSuitableVisible] = useState(false);
  const [interviewModal, setInterviewModal] = useState(false);

  console.log('listItem',data)

  return (
    <Row className="CandidateListItem full-width">
      <Row className="full-width">
        <Col span={24} className="pl-60">
          <span
            className={`fw-bold text-md mr-20 d-iflex text-uppercase
            ${get(data, 'status.id') === 'APPLIED' ? 'text-success' : ''}
            ${get(data, 'status.id') === 'SUGGESTED' ? 'text-blue' : ''}
            ${get(data, 'status.id') === 'SUITABLE' ? 'text-red-pink' : ''}
            ${get(data, 'status.id') === '' ? '' : ''}
            `}
          >
            {get(data, 'status.description', '-')}
          </span>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 17 }}>
          <Divider className="Divider grey mt-10" />
        </Col>
      </Row>
      <Row className="full-width" justify="space-between">
        <Col sxs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
          <Row className="full-width p-relative pl-60">
            <div className="CandidateItemCheck">
              <Checkbox className="form-check blue text-white pl-30" onChange={e => onCheckChange(e.target.checked, get(data, 'applicationId'))} />
            </div>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }}>
              <p className="fw-bold text-md ls-half mb-5 full-width">AD SOYAD</p>
              <p className="text-xl ls-half mb-30 full-width">{get(data, 'candidate.firstName')} {get(data, 'candidate.lastName')}</p>
            </Col>
            <Col className="Settings">
              <Dropdown overlay={<SettingsMenu jobId={get(data, 'applicationId')} candidateId={get(data, 'candidate.candidateId.candidateId')} />} placement="bottomRight" arrow>
                <button>
                  <Icon className="c-pointer" name="gear" size={23} />
                </button>
              </Dropdown>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 17 }}>
              <p className="fw-bold text-md ls-half mb-5">İLGİLENDİĞİ POZİSYONLAR</p>
              <p className="text-xl ls-half mb-30 full-width">{get(data, 'candidate.preferredPositions', []).map(x => x.profession).join(', ')}</p>
            </Col>

            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }}>
              <p className="text-md ls-half fw-bold mb-5">ÇALIŞTIĞI POZİSYONLAR</p>
              <p className="text-md ls-half mb-5 full-width">{get(data, 'candidate.workingPositions', []).map(x => x.profession).join(', ')}</p>
            </Col>
            
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
              <p className="text-md ls-half fw-bold mb-5">İLÇE</p>
              <p className="text-md ls-half mb-5 full-width">{get(data, 'candidate.county.county', '-')}/{get(data, 'candidate.county.city', '-')}</p>
            </Col>
          </Row>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }} className="d-flex fd-column a-center">
          <Button disabled={disabled} className="btn-account-operation blue" onClick={() => setInviteVisible(true)}>Mülakata Davet Et</Button>
          <Button disabled={disabled} className="btn-account-operation blue" onClick={() => history.push(`/account/employer/jobs/${get(data, 'applicationId')}/candidates/${get(data, 'candidate.candidateId.candidateId')}?source=APP&advertisementNo=${get(data, 'advertisementId', '-')}&applicationStatus=APPLIED`)}>Özgeçmişi Görüntüle</Button>
          <Button disabled={disabled} className="btn-account-operation blue" onClick={() => setCoverLetterVisible(true)}>Önyazı Görüntüle</Button>
          <Button disabled={disabled || get(data, 'interviews', []).length === 0} className="btn-account-operation blue" onClick={() => setInterviewModal(true)}>Mülakat Sonucunu Gir</Button>
          <Button disabled={disabled} className="btn-account-operation red mb-0" onClick={() => setNotSuitableVisible(true)}>Uygun Değil</Button>
        </Col>
      </Row>
      <Invite visible={inviteVisible} setVisible={setInviteVisible} applicationId={get(data, 'applicationId')} candidate={get(data, 'candidate')} refresh={refresh}/>
      <CoverLetter visible={coverLetterVisible} setVisible={setCoverLetterVisible} candidate={get(data, 'candidate')}/>
      <InterviewResult visible={interviewModal} interviews={get(data, 'interviews')} setVisible={setInterviewModal} data={get(data, 'candidate')} refresh={refresh}/>
      <NotSuitable applicationId={get(data, 'applicationId')} visible={notSuitableVisible} setVisible={setNotSuitableVisible} data={get(data, 'candidate')} refresh={refresh}/>
    </Row>
  );
};

export default withRouter(ListItem);
