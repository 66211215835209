import { Link, withRouter } from "react-router-dom";
import { Menu, Layout, Button, Dropdown, message, Image } from "antd";
import { DownOutlined, UserOutlined } from "@ant-design/icons";
import { HamburgerMenu } from "../../UIComponents";
import { useState, useEffect } from "react";
import logo from "assets/static/img/logo.png";
import logoIbb from "assets/static/img/logo-ibb.png";
import user from "assets/static/icons/user.svg";
import portfolio from "assets/static/icons/portfolio.svg";
import menu from "assets/static/icons/menu.svg";
import useGet from "../../../hooks/useGet";
import { checkMaster, logoutIstSenin } from "../../../helpers/methods";
import useGetNew from "../../../hooks/useGetNew";
import Notifications from "../../UIComponents/Notifications";
import { getDataNew } from "redux/services/request";
import { useSelector } from "react-redux";

const { Header } = Layout;

const AppHeader = ({ history, candidateName, companyName }) => {
  const [visible, setVisible] = useState(false);
  const userType = localStorage.getItem("type");
  const userData = useSelector((state) => state.data.resumeData);
  const data = useSelector((state) => state.data.companyResult);

  const [responsibleTitle, setResponsibleTitle] = useState(
    data?.responsibleTitle
  );

  useEffect(() => {
    if (data && userData) {
      if (userData.code !== 100) {
        checkMaster(userData.result.firstName, data.responsibleName)
          ? setResponsibleTitle("firma yetkilisi")
          : setResponsibleTitle("firma çalışanı");
      } else {
        logout();
      }
    }
  }, [data, userData, setResponsibleTitle]);

  const logout = async () => {
    localStorage.clear();
    logoutIstSenin();
    try {
      //await OAuth.logout();
      window.location.replace("/");
    } catch (e) {
      message.error("Çıkış işleminde bir hata oluştu");
    }
  };

  const candidate = (
    <Menu>
      <Menu.Item>
        <Link to="/account/candidate/account">Hesap Profili</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/candidate/cv">Özgeçmiş</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/candidate/profile">İlan İşlemleri</Link>
      </Menu.Item>
      <Menu.Item onClick={logout}>Çıkış Yap</Menu.Item>
    </Menu>
  );

  const employer = (
    <Menu style={{ padding: 0 }}>
      {userType === "CORPORATE" && (
        <Menu.Item
          style={{
            fontSize: 16,
            color: "#FFF",
            backgroundColor: "#295fa6",
            margin: "0",
            padding: "18px 12px",
            fontWeight: "bold",
            letterSpacing: "1px",
          }}
        >
          <Image
            src={portfolio}
            alt="Bölgesel İstihdam Ofisleri"
            style={{ marginRight: 10, width: 26 }}
            preview={false}
          />
          <span>
            {companyName?.length < 30
              ? companyName
              : companyName?.slice(0, 30) + "..."}
          </span>
        </Menu.Item>
      )}
      <Menu.Item>
        <Link to="/account/employer/profile">Firma Profili</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/employer/jobs/list">Firma İlan Listesi</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/employer/firm-employer">Firma Çalışanları</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/employer/firm-reports">Firma Raporları</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/employer/jobs/create">İlan Oluştur</Link>
      </Menu.Item>
      <Menu.Item>
        <Link to="/account/employer/notes/create">Not Oluştur</Link>
      </Menu.Item>
      <Menu.Item onClick={logout}>Çıkış Yap</Menu.Item>
    </Menu>
  );

  return (
    <Header className="header">
      <div className="logo" onClick={() => history.push("/")}>
        <Image
          src={logo}
          className="logo-left c-pointer"
          alt="Bölgesel İstihdam Ofisleri Logo"
          preview={false}
        />
      </div>
      <Menu mode="horizontal" className="navmenu">
        <Menu.Item onClick={() => history.push("/")}>Anasayfa</Menu.Item>
        <Menu.Item onClick={() => history.push("/about-us")}>
          Hakkımızda
        </Menu.Item>
        <Menu.Item onClick={() => history.push("/offices")}>
          Ofislerimiz
        </Menu.Item>
        {userType !== "CORPORATE" && (
          <Menu.Item onClick={() => history.push("/jobs/list")}>
            İş İlanları
          </Menu.Item>
        )}
        <Menu.Item onClick={() => history.push("/news/list")}>
          Haberler
        </Menu.Item>
        <Menu.SubMenu popupClassName="HeaderSubmenu" title="İletişim">
          <Menu.Item
            className="HeaderSubmenuItem"
            onClick={() => history.push("/communication/contact-us")}
          >
            Bize Ulaşın
          </Menu.Item>
          <Menu.Item
            className="HeaderSubmenuItem"
            onClick={() => history.push("/communication/faq")}
          >
            Sıkça Sorulan Sorular
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
      <div className="ml-auto d-flex">
        {userType === "CITIZEN" && <Notifications />}

        <div className="buttons">
          {!localStorage.getItem("accessToken") ? (
            <>
              <Button
                type="primary"
                icon={
                  <Image
                    src={user}
                    className="mr-10"
                    alt="Bölgesel İstihdam Ofisleri Üye"
                    preview={false}
                  />
                }
                alt="Bölgesel İstihdam Ofisi"
                onClick={() => history.push("/login-citizen")}
              >
                Üye Giriş
              </Button>
              <Button
                type="primary"
                icon={
                  <Image
                    src={portfolio}
                    className="mr-10"
                    alt="Bölgesel İstihdam Ofisleri"
                    preview={false}
                  />
                }
                onClick={() => history.push("/login-corporate")}
              >
                İşveren Giriş
              </Button>
            </>
          ) : (
            <>
              {userType === "CITIZEN" && (
                <Dropdown overlay={candidate}>
                  <Button
                    type="primary"
                    icon={
                      <Image
                        src={user}
                        className="mr-10"
                        alt="Bölgesel İstihdam Ofisleri"
                        preview={false}
                      />
                    }
                  >
                    {candidateName}
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )}
              {userType === "CORPORATE" && (
                <Dropdown overlay={employer}>
                  <Button type="primary" icon={<UserOutlined />}>
                    {candidateName}{" "}
                    <span>{responsibleTitle && `/ ${responsibleTitle}`} </span>
                    <DownOutlined />
                  </Button>
                </Dropdown>
              )}
            </>
          )}
        </div>
        <div className="logo-right">
          <Image
            src={logoIbb}
            className="logo-right-img"
            alt="Bölgesel İstihdam Ofisleri Logo"
            preview={false}
          />
        </div>
        <div className="HamburgerMenu" onClick={() => setVisible(true)}>
          <Image
            src={menu}
            alt="Bölgesel İstihdam Ofisleri Menü"
            preview={false}
          />
        </div>
      </div>
      <HamburgerMenu
        visible={visible}
        setVisible={setVisible}
        candidateName={candidateName}
        companyName={companyName}
      />
    </Header>
  );
};

export default withRouter(AppHeader);
