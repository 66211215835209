import { List } from "antd";
import { get } from "lodash";
import { Link } from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import config from "plugins/ibb/config";
import { changeUrl } from "helpers/methods";

const NewsItem = ({ item }) => {
  return (
    <List.Item
      key={get(item, "title")}
      extra={
        item.imageUrl && (
          <img
            width={272}
            alt="Bölgesel İstihdam Ofisleri Logo"
            src={changeUrl(
              `${item?.imageUrl
                ?.split("/")
                ?.filter((el) => el)
                ?.join("/")}`
            )}
          />
        )
      }
    >
      <List.Item.Meta
        title={
          <Link to={`/news/detail/${get(item, "id")}`}>
            <p className="fw-bold">{get(item, "title")}</p>
          </Link>
        }
        description={ReactHtmlParser(get(item, "summary"))}
      />
      {ReactHtmlParser(get(item, "content"))}
    </List.Item>
  );
};

export default NewsItem;
