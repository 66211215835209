import React from 'react'
import "./style.css"
import login7 from "../../../../assets/login7.svg"
import login6 from "../../../../assets/login6.svg"
import login8 from "../../../../assets/login8.svg"
import login9 from "../../../../assets/login9.svg"
import login10 from "../../../../assets/login10.svg"
import loginfooter1 from "../../../../assets/loginfooter1.svg";
import loginfooter2 from "../../../../assets/loginfooter2.svg"
import loginfooter3 from "../../../../assets/logincizgituruncu.svg"

const CandidateScreen = () => {
    return (
       <div className='container candidate'>
            <div className='big-title'>
                BÖLGESEL İSTİHDAM OFİSİ’NDE <br/>
                <span>
                    SİZİ NELER BEKLİYOR?
                </span>
            </div>
            <div className='desc'>
                <div>
                    <div className='desc-item'>
                        <img src={login7} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <div>Özel Sektörde Binlerce Yeni İş Fırsatı</div> 
                    </div>
                    <div className='desc-item'>
                        <img src={login6} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <div>Kariyer Danışmanları ile Yüz Yüze Görüşmeler</div>
                    </div>
                    <div className='desc-item'>
                        <img src={login8} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        Ücretsiz Cv Hazırlama ve Mülakat Teknikleri Desteği
                    </div>
                    <div className='desc-item'>
                        <img src={login9} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        Eğitim Destekli İstihdam Programları
                    </div>
                    <div className='desc-item'>
                        <img src={login10} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        Yarı Zamanlı İş veya Staj İmkanı
                    </div>
                </div>
                <div className='banner-footer'>
                    <div className='left-side'>
                        <img src={loginfooter1} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <img src={loginfooter2} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                    </div>
                    <div className='right-footer'>
                        <img src={loginfooter3} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                    </div>
                </div>
            </div>
           
       </div>
    )
}

export default CandidateScreen