import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from '../reducers';

// Redux DevTools için destek
const composeEnhancers =
  typeof window === 'object' &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
    : compose;

let store;

// Geliştirme modunda logger ve DevTools desteği ekleniyor
if (process.env.NODE_ENV === 'development') {
  const logger = require('redux-logger').default; 
  store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, logger))
  );
} else {
  store = createStore(rootReducer, applyMiddleware(thunk));
}

export default store;
