import { useEffect, useState } from 'react';
import {useHistory, useParams} from 'react-router-dom';
import { Row, Col, Button, Spin, Modal, message } from 'antd';
import { get } from 'lodash';
import moment from 'moment';
import useGet from 'hooks/useGet';
import { OAuth } from 'plugins/ibb/oauth';
import Api from "../../../api/Api";
import config from 'plugins/ibb/config'
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";

const Job = () => {
  const [visible, setVisible] = useState(false);
  const [isApplied, setApplied] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);
  const [authModalVisible, setAuthModalVisible] = useState(false);
  const [id] = useState(useParams().id);
  const history = useHistory();
  const { data, loading } = useGet({ url: localStorage.getItem('accessToken') ? `/jobs/${id}` : `/public/jobs/${id}`, isArray: false });

  const apply = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        const _data = { jobId: id };
        const res = await new Api().postData({ url: `/applications`, _data: _data });

        if (res.debugMessage) {
          message.error(res.debugMessage);
        } else {
          message.success('İş ilanına başvuruldu');
          setApplied(!isApplied);
        }
      } catch (e) {
        message.error("İş ilanına başvurulurken bir hata meydana geldi." + e.data.message);
      } finally {
        setLoadingApply(false);
      }
    } else {
      setAuthModalVisible(true);
    }
  };

  const login = () => {
    localStorage.setItem('type', 'CITIZEN');
    OAuth.login();
  }

  useEffect(() => {
    setApplied(get(data, 'isApplied', false));
  }, [data]);

  return (
    <>
      <div className="Banner">
        <div className="content md">
          <h1 className="Title">
            İş İlanları
          </h1>
        </div>
      </div>
      <div className="Job pt-70 pl-30 pr-30 content md">
        <Spin spinning={loading} size="large" delay={500} tip="Yükleniyor...">
        <Helmet>
          <title>İş Ara İş Bul | İlan Detayı</title>
          <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
        </Helmet>
          <Row className='mb-20' justify='space-between' align='middle'>
              <Col sm={12}>
                <h1 className="text-red-pink fw-bold mb-0">{get(data, 'positionTitle', '-')}</h1>
              </Col>
              <Col sm={12} xs={{span:24}}>
                <h2 className="Title jobAdsNumber">İlan No: {get(data, 'advertisementNo', '-')}</h2>
              </Col>
          </Row>
          <Row justify="space-between" className="mb-20">
            <Col sm={{ span: 24 }} md={{ span: 12 }}>
              <div className="CompanyAvatar" />
              <h1 className="Title">{get(data, 'profession', '-')}</h1>
              <h2 className="Hashtag">{get(data, 'sector.sector', '-')}</h2>
              <p className="CompanyName">{get(data, 'company.name', '-') || 'Şirket bilgisi gizli'}</p>
            </Col>
            <Col sm={{ span: 24 }} md={{ span: 12 }} className="d-flex full-width">
              {
                localStorage.getItem('type') !== 'CORPORATE' &&
                <>
                  {!isApplied ?
                    <Button type="default" className="btn btn-pink-filled btn-rounded mt-50 ml-auto" onClick={apply} loading={loadingApply}>Başvur</Button>
                    :
                    <Button type="default" className="btn btn-rounded mt-50 ml-auto disabled" disabled>Başvuruldu</Button>
                  }
                </>
              }
            </Col>
          </Row>
          <Row gutter={[10, 10]} className="mb-20">
            <Col span={24} className="d-flex a-center">
              <img src="/static/icons/pin-pink.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
              <span className="Info">{get(data, 'countiesAsString', '-')}</span>
            </Col>
            <Col span={24} className="d-flex a-center">
              <img src="/static/icons/calendar.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
              <span className="Info">Yayınlanma Tarihi: {data && data.publishedAt ? moment(get(data, 'publishedAt')).format('DD/MM/YYYY') : '-'}</span>
            </Col>
            {data && data.lastApplicationDate &&<Col span={24} className="d-flex a-center">
              <img src="/static/icons/calendar.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
              <span className="Info">Son Başvuru Tarihi: {moment(get(data, 'lastApplicationDate')).format('DD/MM/YYYY')}</span>
            </Col>}
          </Row>
          <Row gutter={{ sm: 0, md: [10, 10] }} className='detailRow' >
            <Col sm={{ span: 24 }} style={{border:'2px solid #de6c0b',borderRadius:'8px'}} className="Info full-width">
              <div className="Conditions">
                <div className="Condition">
                  <img src="/static/icons/notification.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
                  <div className="ConditionTitle">
                    <div className="Text">Çalışma Şekli</div>
                    {get(data, 'workingTypes', []).map(x =>
                      <div className="Value">{x.description || '-'}</div>
                    )}
                  </div>
                </div>
                <div className="Condition">
                  <img src="/static/icons/wall-clock.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
                  <div className="ConditionTitle">
                    <div className="Text">Mesai Saatleri</div>
                    <div className="Value">{get(data, 'workStartAt', '-') || '-'} - {get(data, 'workEndAt', '-') || '-'}</div>
                  </div>
                </div>
                {data && data.applicantRights && <div className="Condition">
                  <img src="/static/icons/megaphone.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
                  <div className="ConditionTitle">
                    <div className="Text">Yan Haklar</div>
                    <div className="Value">{data && data.applicantRights && data.applicantRights.length > 0 ? get(data, 'applicantRightsAsString', '-') : '-'}</div>
                  </div>
                </div>}
                {data && data.offDays && <div className="Condition">
                  <img src="/static/icons/calendar-white.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri İş İlanı" />
                  <div className="ConditionTitle">
                    <div className="Text">İzin Günleri</div>
                    {get(data, 'offDays', []).map(x =>
                      <div className="Value mb-0">{x.description}</div>
                    )}
                  </div>
                </div>}
              </div>
            </Col>
            <Col sm={{ span: 24 }} className="full-width">
              <div className="Section">
                <h1 className="Header mb-10">GENEL NİTELİKLER VE İŞ TANIMI</h1>
                {data && data.qualifications && <p className="Description mb-30 text-md d-block">{ReactHtmlParser(get(data, 'qualifications', '-').replaceAll('?',''))}</p>}
                {data && data.jobDescription && <p className="Description mb-30 text-md">{get(data, 'jobDescription', '-')}</p>}
              </div>
              <div className="Section">
                <h1 className="Header mb-10">ADAY KRİTERLERİ</h1>
                <p className="Criteria">Aktif araç kullanımı: <span>{get(data, 'activeDriving') ? 'Gerekli' : 'Gerekli Değil'}</span></p>
                {data && data.ageMin && data.ageMax && <p className="Criteria">Yaş Aralığı: <span>{get(data, 'ageMin', '-')} - {get(data, 'ageMax', '-')}</span></p>}
                {data && data.disabilities && data.disabilities.length > 0 && <p className="Criteria">Engellilik Durumu: <br />{get(data, 'disabilities', []).map(x => <><span className="ml-0">{x.disability} ({x.percentage.description})</span><br /></>)}</p>}
                {data && data.educationLevels && data.educationLevels.length > 0 && <p className="Criteria">Eğitim Seviyesi: <span>{get(data, 'educationLevels', []).map(x => x.description).join(",")}</span></p>}
                {data && data.licenseTypes && data.licenseTypes.length > 0 && <p className="Criteria">Ehliyet Tipi: <span>{get(data, 'licenseTypes', []).map(x => x.description).join(",")}</span></p>}
                {data && data.foreignLanguages && data.foreignLanguages.length > 0 && <p className="Criteria">Yabancı Dil: <br /> {get(data, 'foreignLanguages', []).map(x => <span className="ml-0 mt-10">{x.language} (Okuma: {x.readingLevel.description}, Yazma: {x.writingLevel.description}, Konuşma: {x.speakingLevel.description}, Tercüme: {x.translatingLevel.description}) <br /></span>)}</p>}
                {
                  get(data, 'workingExperienceType.id') !== 'F' &&
                  <p className="Criteria">
                    Minimum Tecrübe Yılı:
                    <span>
                      {get(data, 'minExperienceYear', '-')}
                    </span>
                  </p>
                }
                {data && data.profession && <p className="Criteria">Meslek: <span>{get(data, 'profession', '-')}</span></p>}
                {get(data, 'showSalaryToCandidate', false) &&
                  <p className="Criteria">
                    Maaş Aralığı(₺):
                    <span>
                      {get(data, 'salaryMin', '-')} - {get(data, 'salaryMax', '-')}
                    </span>
                  </p>
                }
                {data && data.smokeOption && <p className="Criteria">Sigara Opsiyonu: <span>{get(data, 'smokeOption.description', '-')}</span></p>}
                {data && data.weeklyWorkingHours && <p className="Criteria">Haftalık Çalışma Saati: <span>{get(data, 'weeklyWorkingHours', '-')}</span></p>}
                {data && data.workingExperienceType && <p className="Criteria">Tecrübe Tipi: <span>{get(data, 'workingExperienceType.description', '-')}</span></p>}
                {data && data.workingTypes && <p className="Criteria">Çalışma Şekli: <span>{get(data, 'workingTypes', []).map(x => x.description).join(",")}</span></p>}
                {data && data.workingPlaces && data.workingPlaces.length > 0 && <p className="Criteria">Çalışma Yeri: <span>{get(data, 'workingPlaces', []).map(x => x.description).join(",")}</span></p>}
                <p className="Criteria">Ön Yazı Talebi: <span>{get(data, 'coverLetterRequest', '-') ? 'Gerekli' : 'Gerekli Değil'}</span></p>
              </div>
            </Col>
          </Row>
        </Spin>
      </div>
      <Modal className="modal-info" open={visible} onCancel={() => setVisible(false)} closeIcon={<img src="/static/icons/cancel.svg" alt="istihdam-close" />} footer={null}>
        <img src="/static/icons/check.svg" className="mb-10" alt="ibb-tick" />
        <p className="modal-text mb-20">İlan başvurunuz başarıyla gerçekleşmiştir.</p>
        <p className="modal-parameter">İlan No: {get(data, 'advertisementNo', '-')}</p>
      </Modal>
      <Modal footer={null} className="modal-info" width={630} open={authModalVisible} onCancel={() => setAuthModalVisible(false)} closeIcon={<img src="/static/icons/cancel.svg" alt="kapat" />}>
        <p className="text-center text-4xl mb-50">Bu ilana başvurabilmek için üye olarak giriş yapmanız gerekmektedir</p>
        <Button className="BtnUserType pink" onClick={()=> history.push('/login-citizen')}>Üye Girişi</Button>
        <Button className="BtnUserType blue" onClick={() => setVisible(false)}>Vazgeç</Button>
      </Modal>
    </>
  );
};

export default Job;
