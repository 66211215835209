import { CountdownCircleTimer } from 'react-countdown-circle-timer'

const OtpTimerComponent = () => (
  <CountdownCircleTimer
    isPlaying
    duration={300}
    size={100}
    colors={['#004777', '#F7B801', '#A30000', '#A30000']}
    colorsTime={[300, 125, 50, 0]}
  >
    {({ remainingTime }) => remainingTime}
  </CountdownCircleTimer>
)
export default OtpTimerComponent;
