import {useEffect, useState} from "react";
import { Input, message } from "antd";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import { useHistory } from "react-router-dom";
import {postNewLogin, updateState} from "../../redux/services/request";
import {useSelector} from "react-redux";
import OtpTimerComponent from './Components/OtpTimerComponent';
import {dataLayerPush} from "../../helpers/methods";
import axios from "axios";

export default function ResetPassword() {
  const [loading, setLoading] = useState(false);
  const [ stateId, setStateId ] = useState(null);
  const [ userType, setUserType ] = useState(null);
  const [ idNo, setIdNo ] = useState(null);
  const history = useHistory();
  const registerInitData = useSelector( state => state.data.registerInit );

  useEffect(() => {
      setStateId( registerInitData?.data?.result?.stateId )
      setIdNo( registerInitData?.data?.idNo )
      setUserType( registerInitData?.data?.userType )
    },[ registerInitData ]
  );

  const handleSubmit = async (data) => {
    updateState({ loginInit : {data: null, loading: true }});
    setLoading(true);

    const newData = {
      ...data,
      stateId: stateId ?? ''
    };

    try {
      const resetResponse = await postNewLogin({ url: "/auth/reset-password/verify", data: newData });

      if( resetResponse?.code !== 200 ) {
        message.error( resetResponse?.message );
        setLoading(false );
        return;
      }

      const loginData = {
          idNo,
          userType,
          password: data?.newPassword
      }

      updateState({ loginInit : {data: null, loading: true }});

      const loginResponse = await postNewLogin({ url: "/auth/login", data: loginData });

      if( loginResponse.code !== 200 ) {
        message.error( loginResponse?.message );
        setLoading(false);
        history.push('/')
        return;
      }

      // local pushes
      localStorage.setItem( 'accessToken', loginResponse?.result?.accessToken );
      localStorage.setItem("type", loginResponse?.result?.userType );
      localStorage.setItem("fullName", loginResponse.result.fullName );

      // datalayer
      dataLayerPush({
        'event' : "isVerenGiris",
        'result' : "isVerenGirisBasarili"
      })

      // bearer token
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
      localStorage.getItem('type') && (
        axios.defaults.headers.common['userType'] = localStorage.getItem('type')
      );

      message.success( `İşleminiz gerçekleşti ve şifreniz güncellendi ${ loginResponse?.result?.fullName }.` )
      updateState({ loginInit : {data: loginResponse, loading: true }});
      setLoading( false );
      history.push('/');

    } catch {
      setLoading(false);
    }
  };

  const initialValues = {
    code: "",
    newPassword: "",
    confirmNewPassword: "",
    stateId: ""
  };

  return (
    <>
      <AuthLayoutForm
        formTitle="Şifre Yenileme"
        button="Onayla"
        onFinish={ handleSubmit }
        initialValues={initialValues}
        validateFirst={false}
        formName="reset-password"
        loading={ loading }
      >
        <AuthLayoutInput
          name="code"
          label="Doğrulama Kodu:"
          input={<Input />}
        />
        <AuthLayoutInput
          name="newPassword"
          label="Yeni Şifre:"
          input={<Input.Password />}
        />

        <AuthLayoutInput
          name="confirmNewPassword"
          label="Yeni Şifre Tekrar:"
          dependencies={["password"]}
          hasFeedback
          input={<Input.Password />}
        />
        <div className="auth-timer" >
             <OtpTimerComponent/>
          </div>
      </AuthLayoutForm>
    </>
  );
}
