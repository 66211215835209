import { Modal } from "antd";
import React from "react";
import banner from "../../../assets/bio-bus.jpg";
import "../../../styles/layouts/authLayout.less";

const ModalAuthCommercial = ({ visible, setVisible }) => {
  const url = 'https://enstitu.ibb.istanbul/portal/kurs_icerik.aspx?KursMerkezi=1134';

  return (
    <Modal
      wrapClassName="commercial-modal"
      bodyStyle={{ overflowY: 'auto', padding:'0' }}
      centered
      open= { visible }
      onOk = { () => setVisible(false) }
      onCancel={ () => setVisible(false) }
      closable={ true }
      footer={false}
      className={'commercial-modal-body'}
      okText="DETAYLI BİLGİ İÇİN TIKLAYINIZ"
      okButtonProps={{
        href: url, target:'_blank',  className:'ant-modal-footer-button'
      }}
      cancelButtonProps={{ style: { display: 'none' }}}

    >
      <div className="homepage-modal">
        <a
          href={url}
          target="_blank"
        >
          <img
            src={banner}
            alt="İBB Bölgesel İstihdam Ofisleri"
            width={'100%'}
          />
        </a>
      </div>
    </Modal>
  )
}
export default ModalAuthCommercial;



