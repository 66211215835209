import { useEffect, useState } from 'react';
import { Button, Col, Divider, message, Modal, Row, Spin, Image } from 'antd';
import {Link, useHistory} from 'react-router-dom';
import moment from 'moment';
import { get } from 'lodash';
import {EyeOutlined} from '@ant-design/icons';
import { putData } from 'redux/services/request';
import Api from "../../../../api/Api";
import { dataLayerPush } from 'helpers/methods';
import logo from '../../../../assets/static/img/logo.png'
import IstanbulSeninButton from "../../../Auth/Components/IstanbulSenin";


const Job = ({ job }) => {
  const history = useHistory();
  const [visible, setVisible] = useState(false);
  const [applied, setApplied] = useState(false);
  const [favorite, setFavorite] = useState(false);
  const [loadingFavorite, setLoadingFavorite] = useState(false);
  const [loadingApply, setLoadingApply] = useState(false);

  useEffect(() => {
    job && setApplied(job.applied || job.isApplied);
    job && setFavorite(job.favorite);
  }, [job]);

  const favoriteOperation = async (action) => {
    setLoadingFavorite(true);
    if (localStorage.getItem('accessToken')) {
      try {
        await putData({ url: `/jobs/${job.id}/favorites/${favorite ? 'unmark' : 'mark'}` });
        message.success(favorite ? 'İş ilanı favorilerden çıkarıldı' : 'İş ilanı favorilere eklendi');
        setFavorite(!favorite);
      } catch (err) {
        message.error('İş ilanı favori işlemlerinde bir hata meydana geldi. '+ err.data.message);
      } finally {
        setLoadingFavorite(false);
      }
    } else {
      setVisible(true);
      setLoadingFavorite(false);
    }
  }

  const apply = async () => {
    if (localStorage.getItem('accessToken')) {
      try {
        const data = { jobId: job.id };
        const res = await new Api().postData({ url: `/applications`, _data: data });
        if (res.debugMessage) {
          message.error(res.debugMessage);
        } else {
          message.success('İş ilanına başvuruldu');
          setApplied(!applied);
          dataLayerPush({
            'event' : "ilanBasvuru",
            'result' : "ilanBasvuruBasarili"
          })
        }
      } catch (e) {
        message.error("İş ilanına başvurulurken bir hata meydana geldi. " + e.data.message);
      } finally {
        setLoadingApply(false);
      }
    } else {
      setVisible(true);
    }
  }

  return (
    <Row className="Post pl-35" gutter={{ sm: 0, md: 50 }}>
      <Spin spinning={loadingFavorite}>
        <div className="Star c-pointer" onClick={favoriteOperation}>
          <img src={`/static/icons/${favorite ? 'star-filled' : 'star'}.svg`} alt="Bölgesel İstihdam Ofisleri" />
        </div>
      </Spin>
      <Col sm={{ span: 24 }} md={{ span: 12 }}>
        <h1 className="Title mb-50">{get(job, 'profession.profession', '-')}</h1>
        <p className="text-md mb-20">{get(job, 'companyName', '-')}</p>
        <p className="Location d-flex a-center">
          <img src="/static/icons/pin-pink.svg" className="mr-10" alt="Bölgesel İstihdam Ofisleri" />
          <span className="text-md text-black">{get(job, 'county', '-').split(',').join(", ")}</span>
        </p>
      </Col>
      <Col sm={{ span: 24 }} md={{ span: 12 }} className="JobColumn text-right full-width">
        <div className="Actions mb-10">
          {!applied ?
            <Button type="default" className="BtnAction" onClick={apply} loading={loadingApply}>Başvur</Button>
            :
            <Button type="default" className="BtnAction disabled" disabled>Başvuruldu</Button>
          }
          <Link to={`/jobs/detail/${job.id}`}>
            <Button type="default" className="BtnAction">Görüntüle</Button>
          </Link>
        </div>
        <div className="Info">
          <div className="Id">İlan No: {get(job, 'advertisementNo', '-')}</div>
          <div className="Hashtag">{get(job, 'sector.sector', '-')}</div>
          <div className="text-xl fw-bold text-red-pink">
          <img src="/static/icons/multiple-user.svg" alt="Bölgesel İstihdam Ofisleri" className="mr-5" />
            {get(job, 'applicationCount', 0)}
          </div>
          <div className="text-xl fw-bold text-red-pink mb-5">
          <EyeOutlined className="mr-5"/>
            {get(job, 'viewCount', 0)}
          </div>
          <div className="text-md ls-half mb-5">Yayınlanma Tarihi: {moment(get(job, 'publishedAt', null)).format('DD.MM.YYYY')}</div>
          <div className="text-md fw-bold ls-half">Son Başvuru Tarihi: {moment(get(job, 'lastApplicationDate', null)).format('DD.MM.YYYY')}</div>
        </div>
      </Col>
      <Divider className="Divider grey" />
      <Modal footer={null} className="modal-info listingModal" width={630} open={visible} closable={false} onCancel={() => setVisible(false)} closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri" />}>
        <img src={logo} alet="" width={240}/>
        <p className="text-center text-4xl mb-15 mt-15 subText">Henüz bir hesabın yok mu hemen Bölgesel İstihdam Ofislerine Kaydını oluştur.</p>
        <Button className="BtnUserType pink" onClick={() => history.push( '/register-citizen' )}>Kayıt Ol</Button>
        <p className="subText">Zaten bir hesabın varsa buradan <Link style={{ fontWeight:'bold' }} to="/login-citizen">Giriş Yap</Link></p>
        <p className="subText">ya da İstanbul Senin hesabınlada hızla giriş yapabilirsin.</p>
        <IstanbulSeninButton modal={true}/>
      </Modal>
    </Row>
  );
}

export default Job;
