import { useEffect, useState } from "react";
import { Row, Col } from 'antd';
import { get } from "lodash";
import moment from 'moment';
import useGet from "hooks/useGet";
import { idToNames } from "helpers/methods";
import ReactHtmlParser from "react-html-parser";
import { useSelector } from "react-redux";


const JobPreview = ({ data }) => {

  const [stateSalaryMin, setStateSalaryMin] = useState(null);
  const [stateSalaryMax, setStateSalaryMax] = useState(null);
  const [stateCounties, setStateCounties] = useState(null);
  //const [stateGender, setStateGender] = useState(null);
  const [stateSectors, setStateSectors] = useState(null);
  const [stateWorkingTypes, setStateWorkingTypes] = useState(null);
  const [stateWorkingPlaces, setStateWorkingPlaces] = useState(null);
  const [stateApplicantRights, setStateApplicantRights] = useState(null);
  const [stateOffDays, setStateOffDays] = useState(null);
  //const [stateMilitaryStatuses, setStateMilitaryStatuses] = useState(null);
  const [stateProfession, setStateProfession] = useState(null);
  const [stateSmokingOption, setStateSmokingOption] = useState(null);
  const [stateEducationLevels, setStateEducationLevels] = useState(null);
  const [stateDisabilityStatuses, setStateDisabilityStatuses] = useState(null);
  const [stateForeignLanguages, setStateForeignLanguages] = useState(null);


  //const { data: genders } = useGet({ url: data && data.genderType && '/types/gender', key: 'genders' });
  const { data: sectors } = useGet({ url: data && data.sectorId && '/sectors', key: 'sectors' });
  const myCompany = useSelector(state => state.data.companyResult.result);
  
  const { data: workingTypes } = useGet({ url: data && data.workingTypes && `/working-types`, key: 'workingTypes' });
  const { data: workingPlaces } = useGet({ url: data && data.workingPlaces && `/types/working-place`, key: 'workingPlaces' });
  const { data: applicantRights } = useGet({ url: data && data.applicantRightsId && '/jobs/applicant-rights', key: 'applicantRights' });
  const { data: weekDays } = useGet({ url: data && data.weeklyOffDays && '/types/weekday', key: 'weekDays' });
  const { data: counties } = useGet({ url: data && data.countyIds && `/public/counties`, key: 'counties' });
  //const { data: militaryStatuses } = useGet({ url: '/types/military-status', key: 'militaryStatus' });
  const { data: professions } = useGet({ url: '/professions', key: 'professions' });
  const { data: salaryRanges } = useGet({ url: '/salary-ranges', key: 'salaryRanges' });
  const { data: smokingOptions } = useGet({ url: '/types/smoking-option', key: 'smokingOptions' });
  const { data: educationLevels } = useGet({ url: '/types/education-levels', key: 'educationLevels' });
  const { data: disabilities } = useGet({ url: '/disabilities', key: 'disabilities' });
  const { data: disabilityTypes } = useGet({ url: '/disabilities/types', key: 'disabilityTypes' });
  const { data: foreignLanguages } = useGet({ url: '/languages', key: 'foreignLanguages' });

  useEffect(() => {
    counties && setStateCounties(idToNames(data.countyIds, counties, 'value', 'id'));
  }, [data, counties]);

  // useEffect(() => {
  //   genders && setStateGender(genders.find(x => x.name === data.genderType).label);
  //   // eslint-disable-next-line
  // }, [data, genders]);

  useEffect(() => {
    sectors && setStateSectors(sectors.find(x => x.sectorId === data.sectorId).sectorName);
    // eslint-disable-next-line
  }, [data, sectors]);

  useEffect(() => {
    data.workingTypes && workingTypes && setStateWorkingTypes(idToNames(data.workingTypes, workingTypes, 'label', 'name'));
    // eslint-disable-next-line
  }, [data, workingTypes]);

  useEffect(() => {
    data.workingPlaces && workingPlaces && setStateWorkingPlaces(idToNames(data.workingPlaces, workingPlaces, 'label', 'name'));
    // eslint-disable-next-line
  }, [data, workingPlaces]);


  useEffect(() => {
    data.applicantRightsId && applicantRights && setStateApplicantRights(idToNames(data.applicantRightsId, applicantRights, "title", "id"));
    // eslint-disable-next-line
  }, [data, applicantRights]);

  useEffect(() => {
    data.weeklyOffDays && weekDays && setStateOffDays(idToNames(data.weeklyOffDays, weekDays, "label", "name"));
    // eslint-disable-next-line
  }, [data, weekDays]);

  // useEffect(() => {
  //   data.militaryStatuses && militaryStatuses && setStateMilitaryStatuses(idToNames(data.militaryStatuses, militaryStatuses, "label", "name"));
  // }, [militaryStatuses, data]);

  useEffect(() => {
    data.professionId && professions && setStateProfession(professions.find(x => x.id === data.professionId).profession);
  }, [professions, data]);

  useEffect(() => {
    if (salaryRanges && data.salaryRangeId) {
      const salaryRange = salaryRanges.find(x => x.hashId === data.salaryRangeId);
      setStateSalaryMin(salaryRange.min);
      setStateSalaryMax(salaryRange.max);
    }
  }, [salaryRanges, data]);

  useEffect(() => {
    data.smokingOption && smokingOptions && setStateSmokingOption(smokingOptions.find(x => x.name === data.smokingOption).label);
  }, [smokingOptions, data]);

  useEffect(() => {
    data.educationLevels && educationLevels && setStateEducationLevels(idToNames(data.educationLevels, educationLevels, "label", "name"));
  }, [educationLevels, data]);

  useEffect(() => {
    if (data.foreignLanguages && foreignLanguages) {
      setStateForeignLanguages(data.foreignLanguages.map(x => {
        const name = foreignLanguages.find(y => y.id === x.languageId).name;
        return {
          name,
          readingLevel: x.readingLevel,
          speakingLevel: x.speakingLevel,
          translatingLevel: x.translatingLevel,
          writingLevel: x.writingLevel
        };
      }));
    }
  }, [foreignLanguages, data]);

  useEffect(() => {
    if (disabilities) {
      const disabilityStatuses = [];

      for (var [key, value] of data.disabilities) {
        const name = disabilities.find(x => x.id === key).disability;
        const percentage = disabilityTypes.find(x => x.name === value).label;

        disabilityStatuses.push({ name, percentage });
      }

      setStateDisabilityStatuses(disabilityStatuses);
    }
  }, [disabilities, data]);

  return (
    <div className="Job pt-70 pl-10 pr-10 content md">
      <Row justify="space-between" className="mb-20">
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <h1 className="Title">{get(data, 'positionTitle', '-')}</h1>
          {stateSectors && <h2 className="Hashtag">{stateSectors}</h2>}
          <p className="CompanyName">{data.hiddenCompany ? 'Şirket bilgisi gizli' : get(myCompany, 'companyName', '-')}</p>
        </Col>
      </Row>
      <Row gutter={[10, 10]} className="mb-20">
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <img src="/static/icons/pin-pink.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
          {stateCounties && <span className="Info">{stateCounties.join(', ')}</span>}
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <img src="/static/icons/calendar.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
          <span className="Info">Yayınlanma Tarihi: {moment().format('DD.MM.YYYY')}</span>
        </Col>
        <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <img src="/static/icons/calendar.svg" className="InfoIcon mr-10" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
          <span className="Info">Son Başvuru Tarihi: {moment().add(1, 'M').format('DD.MM.YYYY')}</span>
        </Col>
      </Row>
      <Row>
      <Col xs={24} sm={24} md={24} lg={24} xl={24}>
          <div className="Conditions">
            <div className="Condition">
              <img src="/static/icons/notification.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
              <div className="ConditionTitle">
                <div className="Text">Çalışma Şekli</div>
                <div className="Value">
                  {stateWorkingTypes && stateWorkingTypes.join(', ')}
                </div>
              </div>
            </div>
            {/*<div className="Condition">*/}
            {/*  <img src="/static/icons/gender.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri iş ilanı" />*/}
            {/*  <div className="ConditionTitle">*/}
            {/*    <div className="Text">Cinsiyet</div>*/}
            {/*    <div className="Value">{stateGender || '-'}</div>*/}
            {/*  </div>*/}
            {/*</div>*/}
            <div className="Condition">
              <img src="/static/icons/wall-clock.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
              <div className="ConditionTitle">
                <div className="Text">Mesai Saatleri</div>
                <div className="Value">{moment(get(data, 'workStartAt')).format('HH:mm') || '-'} / {moment(get(data, 'workEndAt')).format('HH:mm') || '-'}</div>
              </div>
            </div>
            <div className="Condition">
              <img src="/static/icons/megaphone.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
              <div className="ConditionTitle">
                <div className="Text">Yan Haklar</div>
                <div className="Value">{stateApplicantRights && stateApplicantRights.map(x => <p>{x}</p>)}</div>
              </div>
            </div>
            <div className="Condition">
              <img src="/static/icons/calendar-white.svg" className="ConditionIcon" alt="Bölgesel İstihdam Ofisleri iş ilanı" />
              <div className="ConditionTitle">
                <div className="Text">İzin Günleri</div>
                {stateOffDays && stateOffDays.map(x =>
                  <p className="text-white text-md mb-5">{x}</p>
                )}
              </div>
            </div>
          </div>
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col  >
          <div className="Section">
            <h1 className="Header mb-10">GENEL NİTELİKLER VE İŞ TANIMI</h1>
            <div className="Requirement">
              <div className="Dot Red v-hidden" />
              <p className="Description-preview">
                 {ReactHtmlParser(data.qualifications)}
                 {/* {data.qualifications.replace(/<\/p><p>/g, '').replace(/<\/?p>/g, '').replace(/<\/strong><strong>/g, ', ').replace(/<\/?strong>/g, '').replace(/&nbsp;/g, ' ').replace(/<\/br><br>/g, ', ').replace(/<\/?br>/g, '').replaceAll('?','')}  */}
              </p>
            </div>
          </div>
          <div className="Section">
            <h1 className="Header mb-10">ADAY KRİTERLERİ</h1>
            <p className="Criteria">Aktif araç kullanımı: <span>{get(data, 'activeDriving') ? 'Gerekli' : 'Gerekli Değil'}</span></p>
            <p className="Criteria">Yaş Aralığı: <span>{get(data, 'ageMin', '-')} - {get(data, 'ageMax', '-')}</span></p>
            {stateDisabilityStatuses && <p className="Criteria">Engellilik Durumu: {<span>{stateDisabilityStatuses.map(x => `${x.name}(${x.percentage})`).join(",")}</span>}</p>}
            {stateEducationLevels && <p className="Criteria">Eğitim Seviyesi: <span>{stateEducationLevels.join(",")}</span></p>}
            <p className="Criteria">Ehliyet Tipi: <span>{get(data, 'drivingLicenceTypes', []).join(",")}</span></p>
            {stateForeignLanguages && <p className="Criteria">
              Yabancı Dil:
              <br />
              {stateForeignLanguages.map(x =>
                <span className="ml-0 mt-10">
                  {x.name}(Okuma: {x.readingLevel}, Yazma: {x.writingLevel}, Tercüme: {x.translatingLevel}, Konuşma: {x.speakingLevel})
                  <br />
                </span>)
              }
            </p>}
            {
              get(data, 'workingExperienceType') !== 'F' &&
              <p className="Criteria">
                Minimum Tecrübe Yılı:
                    <span>
                  {get(data, 'minExperienceYear', '-')}
                </span>
              </p>
            }
            {stateProfession && <p className="Criteria">Meslek: <span>{stateProfession}</span></p>}
            {get(data, 'showSalaryToCandidate', false) &&
              <p className="Criteria">
                Maaş Aralığı(₺):<span>{stateSalaryMin} - {stateSalaryMax}</span>
              </p>
            }
            {stateSmokingOption && <p className="Criteria">Sigara Opsiyonu: <span>{stateSmokingOption}</span></p>}
            {data && data.weeklyWorkingHours && <p className="Criteria">Haftalık Çalışma Saati: <span>{data.weeklyWorkingHours}</span></p>}
            {data && data.workingExperienceType && <p className="Criteria">Tecrübe Tipi: <span>{data.workingExperienceType === 'T' ? 'Tecrübeli' : 'Tecrübesiz'}</span></p>}
            {stateWorkingTypes && <p className="Criteria">Çalışma Şekli: <span>{stateWorkingTypes.join(",")}</span></p>}
            {stateWorkingPlaces && <p className="Criteria">Çalışma Yeri: <span>{stateWorkingPlaces.join(",")}</span></p>}
            <p className="Criteria">Ön Yazı Talebi: <span>{get(data, 'coverLetterRequested', '-') ? 'Gerekli' : 'Gerekli Değil'}</span></p>
          </div>
        </Col>
      
      </Row>
    </div>
  );
}

export default JobPreview;
