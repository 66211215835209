import Banner from "components/UIComponents/Banner"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { updateState } from "redux/services/request";
import { getData } from "redux/services/request";
import {Helmet} from "react-helmet";

export default () => {

  const aboutUsData = useSelector(state => state.aboutUs);

  useEffect(() => {
    async function fetchData() {
      updateState({ aboutUs: { data: null, loading: true } });
      const aboutUs = await getData({ url: '/public/about-us' });
      updateState({ aboutUs: { data: aboutUs, loading: false } });
    }

    fetchData();
  }, []);

  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | Gizlilik Sözleşmesi</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Gizlilik Sözleşmesi" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        <article className="Article">
          <div class="blog-single">

            <p><b><span>GİZLİLİK PRENSİPLERİ</span></b><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı (Portal), tüm bireysel ve kurumsal üyelerinin üye bilgilerini, güvenlik ve gizlilik prensipleri doğrultusunda koruyan interaktif bir portal olmayı hedeflemektedir. Bölgesel İstihdam Ofisleri Portalı; üyenin portalı aşağıdaki Gizlilik Prensipleri'ne veya yasalara aykırı olarak kullandığını tespit ederse veya yetkili mercilerce bu konuda bir tespit veya inceleme talebi gelirse, üye ile ilgili bilgileri yetkili makamlara bildirmek hakkına sahiptir. Ayrıca benzer bir durumda, Bölgesel İstihdam Ofisleri Portalı Gizlilik Prensipleri'nde öngörülen diğer tedbirleri alma hakkına da sahiptir. Bölgesel İstihdam Ofisleri Portalı, vermiş olduğunuz bilgilerin güvenliğini sağlamaya yönelik olarak gelişen teknolojiye uygun teknik önlemlerle desteklenmektedir. Bölgesel İstihdam Ofisleri Portalı işveren değildir, sadece aday ile işveren arasında aracılık yapmaktadır.</span><span></span></p>
            <p><b><span>1.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BİREYSEL ÜYELİK (VATANDAŞ)</span></b><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı, bireysel üyelik formunu dolduran tüm vatandaşlarımızın eğitim durumu, mesleği ve doğum tarihi gibi bilgileri, portal üzerinde sunulan hizmetlerin yelpazesini genişletmek, ihtiyaç ve beklentileri belirleyebilmek amacıyla kullanılmaktadır. Üyelik işlemi sırasında alınan e-posta adresleri, gsm ve diğer iletişim bilgileri; yapılan yenilikleri, haber bültenlerini ve üyelerimiz ile iletişimi devam ettirebilmek açısından önem taşımaktadır. Bölgesel İstihdam Ofisleri Portalı üyeleri, kişisel bilgilerindeki değişiklikleri, üyelik bilgilerini güncelleyerek bildirebilmektedir.</span><span></span></p>
            <p><b><span>Özgeçmiş</span></b><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı bireysel üyelerine, internet üzerinde özgeçmiş hazırlama, kaydetme ve iş başvurusu yapma olanağı tanımaktadır. Üyelerin kendi hazırladıkları özgeçmişleri, bu olanak kapsamında, işverenler tarafından görüntülenebilmektedir. Hazırlanan özgeçmişler üyenin kendi seçimine göre aktif ya da pasif konumda Bölgesel İstihdam Ofisleri Portalı’nda saklanmaktadır. Üye, Üyelik Formu'nda vermiş olduğu tüm bilgilerin doğru, geçerli ve güncel olduğunu kabul ve taahhüt eder. Özgeçmiş bilgilerinin gerçeğe aykırı, eksik veya aldatıcı/yanıltıcı olması; ayrımcılığa yol açması, 3. kişileri suça yönlendirmesi ya da herhangi bir etnik grup, köken, dil, siyasi görüş ve din gibi konularda propaganda içermesi kesinlikle yasaktır. Bu durum nedeniyle Bölgesel İstihdam Ofisleri Portalı ve/veya 3. şahısların herhangi bir zarara uğraması halinde tüm zararları aynen ve derhal tazmin edeceklerini kabul ve taahhüt eder.</span><span></span></p>
            <p><span>Aktif konumda olan üyelerin özgeçmişleri, işveren firmaların Bölgesel İstihdam Ofisleri Portalı üzerinde yaptıkları özgeçmiş bankası aday aramalarında görüntülenebilmektedir. Üyelerin özgeçmişlerinde iletişim bilgilerini paylaşmaları, 3. kişilerin bu bilgileri görebilmesi anlamına gelir. Söz konusu durum Bölgesel İstihdam Ofisleri Portalı sorumluluğunda bulunmamaktadır. Üyelerin özgeçmiş bilgileri, üyelerin rızaları devam ettiği müddetçe ve yasaların izin verdiği ölçüde Bölgesel İstihdam Ofisleri Portalı tarafından yayımlanabilecektir. Bu bilgilendirme, adayların üyeliğinin aktif durumda bulunduğu tüm durumlar için geçerli olup, her defasında bu duruma ilişkin bilgilendirme yapılmayacaktır.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı’nda kişisel bilgilerin temin edilmesi, bunların Bölgesel İstihdam Ofisleri Portalı tarafından belirlenecek/uygun bulunacak şekilde kullanımına muvafakat edildiği anlamına gelir. Portala kaydedilen bilgilerin diğer üyeler ve/veya 3. kişiler eliyle kullanılmış olması ve/veya bilgiler dolayısıyla Fikir ve Sanat Eserleri Kanunu'nda düzenlenen manevi hakların ihlal edilmesi ve/veya her ne şekilde olursa olsun herhangi bir zarara uğranılmasında Bölgesel İstihdam Ofisleri Portalı’nın hiçbir sorumluluğu bulunmamaktadır.</span><span></span></p>
            <p><span>Pasif konumda olan üyelerin özgeçmişleri, işveren firmalarca, Bölgesel İstihdam Ofisleri Portalı özgeçmiş bankası aramalarında hiçbir şekilde görüntülenememektedir. Özgeçmişlerin aktif ya da pasif olma durumları özgeçmiş güncelleme sırasında değiştirilebilmektedir. Doğrudan firmaya gönderilen (örneğin: e-posta, sms, faks, vb. yollarla) özgeçmişler adayın sorumluluğundadır.</span><span></span></p>
            <p><b><span>Değerlendirme Sonuçları</span></b><span></span></p>
            <p><span>İşveren firmalar, iş ilanlarına başvuruda bulunan iş arayanlar ile 'Başvurularım' bölümünde yer alan 'Mesajlar' bölümü aracılığıyla iletişim kurabilmektedir. Ayrıca, Bölgesel İstihdam Ofisleri İstihdam Portalı, firmaların adaylara sistem içinden ilettiği mesajlar için e-posta aracılığıyla bilgilendirme yapmaktadır. İşveren firmaların, başvuruda bulunan adaylara bilgi vermesi, yalnızca kendi inisiyatiflerindedir.</span><span></span></p>
            <p><b><span>2.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;KURUMSAL ÜYELİK</span></b><span></span></p>
            <p><span>Üyelik talep eden firmalar kariyer danışmanları ya da İstihdam portalı üzerinden ulaşabilecekleri “Firma Üyelik Formu”nu doldurmak suretiyle üyelik hakkı kazanırlar. Firmaların üyelik formuyla paylaşılan bilgileri, hem ilanlarda başvuru bilgisi olarak hem de iletişim ve portal üzerinden alınan hizmetlerde kullanılmaktadır. Üye olan firmalar, iş ilanı yayımlama, aday havuzundan arama yapma ve portalın diğer özelliklerini sadece kendileri için kullanabilirler.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı iş ilanı yayımlama, aday havuzundan arama, başvuruları görüntüleme ve diğer özelliklerde görünen veya görünmeyen kısıtlamalar, limitler uygulayabilir. Firmalar sadece personel arama amacı ile portalı kullanabilir. Personel arama dışında kullanıldığı tespit edildiğinde, firmaların üyelikleri iptal edilir. Firmalar bilgisayar programları veya benzeri yöntemler ile ilan, özgeçmiş, başvuru, firma bilgisi veya herhangi bir sayfayı/içeriği toplu halde veya tek tek kaydedemez. Bilgisayar programları veya benzeri yöntemler ile toplu halde veya tek tek kaydedildiği tespit edildiğinde, bu durum kötüye kullanım olarak değerlendirilir ve firmaların üyelikleri iptal edilir. Oluşan/oluşabilecek tüm zararlar üye firma tarafından karşılanır.</span><span></span></p>
            <p><b><span>İş Kanunu</span></b><span></span></p>
            <p><span>Üye Firmalar, yeni istihdam ettiği vatandaşa Eşit İstihdam Fırsatı İlkesine bağlı olarak din, dil, ırk, mezhep, cinsiyet, köken, kültürel farklılık, medeni hal, engellilik,<b>&nbsp;</b>yaş gibi alanlarda ayrımcılık yapmamak konusunda tüm kanun, uluslararası sözleşme ve kurallara uyacağını taahhüt eder.</span><span></span></p>
            <p><span>Üye firmalar, iş ilanlarında belirttikleri kriterlere bağlı kalacaklarını ve istihdam ettikleri vatandaş için İş Kanununun hükümlerini tatbik edeceklerini kabul ederler.</span><span></span></p>
            <p><b><span>İş İlanları</span></b><span></span></p>
            <p><span>Firmaların Bölgesel İstihdam Ofisleri Portalı üzerinde ilan verebilmesi ve portalı kullanabilmesi için, Bölgesel İstihdam Ofisleri Portalı’na üye olması gerekmektedir. Eklenen ilanlar, uygunluğu kontrol edildikten sonra yayımlanmaktadır. Yayımlanan ilanlarda firma iletişim bilgileri herkese açık şekilde görüntülenir. İlanlar aksi belirtilmedikçe en fazla 30 gün yayında kalır. Yayında olan ilan, mükerrer şekilde yayımlanamaz.</span><span></span></p>
            <p><span>Eklenen ilanlar firma girişi yapılarak istenildiği zaman yayından kaldırılabilir.</span><span></span></p>
            <p><span>İlanlarda&nbsp;<b>‘Pozisyon Adı’</b>&nbsp;ve&nbsp;<b>‘İşin Tanımı’</b>&nbsp;detaylı olarak yazılmalıdır.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı’nda sadece personel alım ilanları yayımlanabilir. Ortaklık, girişimcilik, bayilik, özel ders, vb. ilanları kabul edilmemektedir.</span><span></span></p>
            <p><span>Portala eklenen hiç bir ilandan Bölgesel İstihdam Ofisleri Portalı sorumlu değildir. Eklenen tüm ilanların sorumluluğu ekleyen firmaya aittir.</span><span></span></p>
            <p><span>Reklam amaçlı ilan eklenemez. Bu tür ilanlar kabul edilmez.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı eklenen her ilanın yayınlama zorunluluğu yoktur. Uygun görülmeyen ilanlar yayımlanmayabilir.</span><span></span></p>
            <p><span>Her firma bir üyelik oluşturma hakkına sahiptir. Daha önceden oluşturulmuş üyeliklerin olması durumunda, herhangi bir olumsuzluk ile karşılaşılmaması için kariyer danışmanı ile iletişime geçilmelidir.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı firma veya adaylar tarafından gelen şikâyetleri üç iş günü içerisinde değerlendirebilir ve sonuçlandırabilir. Aday veya firmanın üyeliğini iptal edebilir. Bölgesel İstihdam Ofisleri Portalı firma ve adayların portal üzerinden gönderdiği tüm mesaj, mektup, yorum, vb. bilgileri okuyabilir/kontrol edebilir.</span><span></span></p>
            <p><b><span>Kullanıcı İstekleri / Geri Bildirimler</span></b><span></span></p>
            <p><span>Üye ve ziyaretçilerinin Bölgesel İstihdam Ofisleri Portalı ile ilgili her türlü istek, öneri, yorum ve sorularını içeren bilgiler portal performansını ölçmek açısından saklanmaktadır. Bu bilgiler kişilerin kendilerine yanıt vermek ve portal etkinliğini artırmaya yönelik araştırmalarda kullanılmak dışında başka bir amaç için kullanılmamaktadır.</span><span></span></p>
            <p><b><span>İçerik Kullanımı</span></b><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı’nda yayımlanan yazı, grafik ve istatistikler (materyaller) üçüncü kişiler tarafından izinsiz kullanılamaz. Portalda yer alan içerik materyalleri ya da fonksiyonları Bölgesel İstihdam Ofisleri Portalı’ndan izni alınmadan başka mecralarda yayınlanamaz.</span><span></span></p>
            <p><b><span>Kullanıcı Adı ve Şifre</span></b><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı’ndaki kurumsal ve bireysel üyelere ait tüm bilgilere, ‘Kullanıcı Adı’ ve ‘Şifre’ ile erişim sağlanmaktadır. Üyenin portaldan yararlanma hakkı sadece kendisine aittir, başkalarına devredilemez. ‘Kullanıcı Adı’ ve ‘Şifre’nin gizliliği üyenin sorumluluğundadır, üçüncü kişi ya da kuruluşlara kullandırılamaz. ‘Kullanıcı Adı’ ve ‘Şifre’nin izinli veya izinsiz olarak üçüncü kişiler tarafından kullanımından doğabilecek durumlardan Bölgesel İstihdam Ofisleri Portalı sorumluluk taşımamaktadır. Doğabilecek her türlü hukukî ve cezâî yükümlülük üyeye aittir. Bu maddeye aykırı hareket edilmesi, üyeye verilen hizmetlerin herhangi bir bildirimde bulunmaksızın tek taraflı olarak durdurulmasına yol açabilir. Şifrenin izinsiz kullanımının tespit edilmesi durumunda Bölgesel İstihdam Ofisleri Portalı bilgilendirilmelidir.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı belirlediği güvenlik/ gizlilik prensiplerine uymayan ve bunları dikkate almayan kurumsal ve bireysel üyelerin, güvenlik/gizlilik prensiplerinin aksi yönünde bir durumla karşılaşması halinde Bölgesel İstihdam Ofisleri Portalı sorumluluk taşımamaktadır. Bu tür olumsuz bir durumla karşılaşıldığı takdirde Kariyer Danışmanı ile irtibata geçilmesi gerekmektedir.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı Gizlilik Prensipleri, üyeler hakkında edinilen bilgilerin kullanım amacı ve portalın kullanım şeklini içeren bir dokümandır. Üye, Bölgesel İstihdam Ofisleri Portalı üyesi olarak işbu Gizlilik Prensipleri'nde yer alan maddelerin tümünü okuduğunu, anladığını, kabul ettiğini, kendisiyle ilgili olarak verdiği bilgilerin doğruluğunu onayladığını ve Gizlilik Prensipleri'nin kendisi hakkında hüküm ifade edeceğini kabul ve taahhüt eder.</span><span></span></p>
            <p><span>Bölgesel İstihdam Ofisleri Portalı, Gizlilik Prensipleri üzerinde değişiklik yapma hakkına sahiptir.</span><span></span></p>
          </div>
        </article>
      </div>
    </div>
  );
}