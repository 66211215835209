import { Row, Col } from 'antd';
import Banner from 'components/UIComponents/Banner';
import { useState } from 'react';
import Filter from './Filter';
import Results from './Results';
import {Helmet} from "react-helmet";

const JobList = () => {
  const [search, setSearch] = useState(null);

  const replySearch = (_search) => {
    setSearch(_search);
  };

  return (
    <div className="Jobs" style={{ paddingBottom: 200 }}>
      <Helmet>
        <title>İş Ara İş Bul | İş İlanları</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="İş İlanları" />
      <div className="content md">
        <Row gutter={{ sm: 0, md: 60 }}>
          <Col sm={{ span: 24 }} md={{ span: 8 }}>
            <Filter replySearch={replySearch} />
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 16 }}>
            <Results searchProp={search} />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default JobList;
