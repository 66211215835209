/*
  ----<UseLayout/>----
  created as dashboard template. it renders all users and employers template.
  This layout component takes 8 props to fill all data section.

  * title : string, it renders text for html title through react helmet.
  * bannerText : string, it renders for banner text similar as title.
  * wrapperClass : string, this props for Layout class name you can design in your layout with custom style.
  * children : React.Node, this props automatically render in component tags between <UseLayout>{children}</UseLayout>.
  * company : boolean, company when true company name, location and image will appear on page. default value is false.
  * companyName : string, if company is true you can write company name here.
  * cityName : string, if company is true you can write company city name here.
  * countyName : string, if company is true you can write company county name here.
*/
import Helmet from "react-helmet";
import Banner from "../../../components/UIComponents/Banner";
import {Layout, Typography} from "antd";
import React from "react";
import {Icon} from "../../../components/UIComponents";

const UserLayout = ({ title, bannerText, wrapperClass, children, company= false, companyName, cityName, countyName }) => {
  return (
    <>
      <Helmet>
        <title>{ title }</title>
      </Helmet>
      <Banner text={ bannerText }/>
      <Layout className={ wrapperClass }>
        {
          company && (
            <div>
              <Typography.Title strong={ true } level={ 3 }>
                { companyName }
              </Typography.Title>
              <Typography.Text style={{ fontSize:'16px' }}>
                <Icon size={20} style={{ marginRight: '0.2rem' }} name="pin"/>
                { cityName } / { countyName }
              </Typography.Text>
            </div>
          )
        }
        { children }
      </Layout>
    </>
  )
}

export default UserLayout;
