import { Icon } from "components/UIComponents";
import ComputerSkill from "./ComputerSkill";
import {Form, Button} from "antd";

export default ({computerSkills, add, remove,setDisabledButton}) => {
  
  return (
    <div className="ComputerSkills">
      { computerSkills.map((x, i) => (
          <Form.List name={x.id}>{fields =>
              <ComputerSkill key={x.id} item={x} index={i} remove={remove} computerSkills={computerSkills} setDisabledButton={setDisabledButton}/>
          }</Form.List>
      ))}

      <Button className="btn btn-pink-filled radius-lg p-20 text-md" type="primary" icon={<Icon name="plus" size={26} className="mr-10" />} onClick={() => add()}>
           Ekle
      </Button>
    </div>
  );
};
