const Banner = ({ text, className }) => {
  return (
    <div className={`Banner ${className}`}>
      <div className="pl-55">
        <h1 className="Title">
          {text}
        </h1>
      </div>
    </div>
  );
};

export default Banner;
