import { Row, Col } from 'antd';
import { get } from 'lodash';

const Info = ({ title, text }) => {
  return (
    <Row className="mb-10">
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }}>
        <p className="text-xl fw-semibold ls-half mb-0">{title}</p>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }}>
        <p className="text-xl fw-bold ls-half mb-0">{text}</p>
      </Col>
    </Row>
  );
};

const Resume = ({ data }) => {
  return (
    <div className="Communication full-width">
      <p className="text-xl fw-bold">İLETİŞİM BİLGİLERİ:</p>
      <Info title="E-posta" text={get(data, 'email', '-')} />
      <Info title="Cep Telefonu" text={get(data, 'phone', '-')} />
      <Info title="Ev Adresi" text={get(data, 'address', '-')} />
      { /** Backend' de böyle bir field yok */}
      {/* <Info title="Deneyim" text={get(data, '', '-')} /> */}
      <Info title="Çalışma Durumu" text={get(data, 'workingStatus.description', '-')} />
      <Info title="Emeklilik Durumu" text={get(data, 'retirementStatus.description', '-')} />
      <Info title="Uyruk" text={get(data, 'nationality', '-')} />
    </div>
  );
};

export default Resume;
