import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import "./cv_tab.less";
import { useCallback, useEffect, useRef, useState } from "react";

const CvTabs = ({ cvTabData, activeTab, setActiveTab }) => {
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [isDragging, setIsDragging] = useState(false);
  const tabsContainerRef = useRef(null);

  const handleRightArrow = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleLeftArrow = () => {
    if (tabsContainerRef.current) {
      tabsContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const handleScroll = useCallback(() => {
    if (tabsContainerRef.current) {
      const container = tabsContainerRef.current;
      const scrollLeft = container.scrollLeft;
      const scrollWidth = container.scrollWidth;
      const clientWidth = container.clientWidth;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    }
  }, []);

  useEffect(() => {
    const container = tabsContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);
      return () => container.removeEventListener("scroll", handleScroll);
    }
  }, [handleScroll]);

  // Dragging functionality
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setStartX(e.pageX - tabsContainerRef.current.offsetLeft);
    setScrollLeft(tabsContainerRef.current.scrollLeft);
    e.preventDefault(); // Prevent default action to avoid text selection or other default behaviors
  };

  const handleMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.pageX - tabsContainerRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Adjust scrolling speed here
    tabsContainerRef.current.scrollLeft = scrollLeft - walk;
    e.preventDefault(); // Prevent default action to avoid text selection or other default behaviors
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  useEffect(() => {
    const container = tabsContainerRef.current;
    if (container) {
      container.addEventListener("mousedown", handleMouseDown);
      container.addEventListener("mousemove", handleMouseMove);
      container.addEventListener("mouseup", handleMouseUp);
      container.addEventListener("mouseleave", handleMouseUp); // To handle mouse leaving container during drag
      return () => {
        container.removeEventListener("mousedown", handleMouseDown);
        container.removeEventListener("mousemove", handleMouseMove);
        container.removeEventListener("mouseup", handleMouseUp);
        container.removeEventListener("mouseleave", handleMouseUp);
      };
    }
  }, [isDragging, startX, scrollLeft]);

  // Prevent clicks on buttons while dragging
  const handleClick = (e) => {
    if (isDragging) {
      e.preventDefault();
      e.stopPropagation();
    }
  };

  return (
    <div className="scrollable_tabs_container">
      {showLeftArrow && (
        <div className="left_arrow" onClick={handleLeftArrow}>
          <LeftOutlined />
        </div>
      )}
      <ul ref={tabsContainerRef}>
        {cvTabData.map((item) => {
          if (item.page !== 11) {
            return (
              <li key={item.page}>
                <button
                  className={`${item.page === activeTab.page ? "active" : ""}`}
                  onClick={(e) => {
                    if (!isDragging) {
                      setActiveTab(item);
                    }
                    handleClick(e);
                  }}
                >
                  {item.tab}
                </button>
              </li>
            );
          }
        })}
      </ul>
      {showRightArrow && (
        <div className="right_arrow" onClick={handleRightArrow}>
          <RightOutlined />
        </div>
      )}
    </div>
  );
};

export default CvTabs;
