import { Button, Form, Input, message, Modal, Select } from "antd";
import AuthLayoutInput from "../../../../Auth/Components/AuthLayoutInput";
import React, { useState } from "react";
import { postData2 } from "../../../../../redux/services/request";
import { Icon } from "components/UIComponents";
import useGet from "hooks/useGet";

export default ({ visible, setVisible, history, setStatus }) => {
  const [loading, setLoading] = useState(false);
  const [idNo, setIdNo] = useState("");
  const [form] = Form.useForm();

  const { data: unvanData } = useGet({
    url: "/public/responsible-title",
    key: "title",
  });

  async function handleSubmit(data) {
    setLoading(true);
    setStatus(true);

    try {
      const response = await postData2({ url: "/companies/staff", data });

      if (response.code !== 200) {
        setLoading(false);
        setStatus(false);
        setVisible(false);
        setIdNo("");
        message.error(response.message);
        return;
      }

      message.success("Çalışan başarıyla eklendi.");
      setLoading(false);
      setStatus(false);
      setIdNo("");
      history.go([0]);
    } catch (e) {
      message.error("Upps bir şeyler ters gitti.");
    }
  }

  const handleCancel = () => {
    setVisible(false);
  };

  return (
    <Modal
      title="Çalışan Ekle"
      visible={visible}
      onCancel={handleCancel}
      footer={null}
    >
      <Form
        form={form}
        name="addStaff"
        id="addStaff"
        onFinish={handleSubmit}
        initialValues={{
          idNo,
        }}
        autoComplete="off"
      >
        <AuthLayoutInput
          label="T.C. Kimlik No:"
          name="idNo"
          input={<Input placeholder="T.C. Kimlik No" value={idNo} />}
        />
        <AuthLayoutInput
          label="Ünvan:"
          name="responsibleTitle"
          input={
            <Select
              defaultValue="seciniz"
              suffixIcon={<Icon name="arrow-down" size={18} />}
              className="auth-form-input"
              minLength={2}
            >
              <Select.Option>Seçiniz</Select.Option>
              {unvanData?.map((item, index) => (
                <Select.Option value={item?.hashId} key={index}>
                  {item?.title}
                </Select.Option>
              ))}
            </Select>
          }
        />

        <Button
          loading={loading}
          type="primary"
          htmlType="submit"
          form="addStaff"
        >
          Ekle
        </Button>
      </Form>
    </Modal>
  );
};
