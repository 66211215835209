import { Row, Col, Form, Input, DatePicker, Button, Divider, Pagination, Empty } from 'antd';
import { Icon } from 'components/UIComponents';
import { get } from 'lodash';

import Job from "../Job";

const Favorites = ({ data, onChangeFilter, onChangePage, clearFilters, pageRender }) => {

  const [form] = Form.useForm();

  const clear = () => {
    form.resetFields();
    clearFilters('favorites');
  }

  return (
    <div className="Applications">
      <div className="d-flex j-center">
        <Form form={form} onFinish={data => onChangeFilter('favorites', data)} className="CandidateJobFilter d-flex j-between full-width">
          <Form.Item name="advertisementNo" className="f-1">
            <Input className="form-input" placeholder="İlan Numarası" />
          </Form.Item>
          <Form.Item name="companyName" className="f-1">
            <Input className="form-input" placeholder="Firma Adı" />
          </Form.Item>
          <Form.Item name="positionTitle" className="f-1">
            <Input className="form-input" placeholder="Pozisyon" />
          </Form.Item>
          <Form.Item name="releaseDate" className="f-1">
            <DatePicker className="form-input full-width" placeholder="Tarih" suffixIcon={<Icon name="double-arrow" size={30} />} format="DD/MM/YYYY" />
          </Form.Item>
          <Button type="default" className="BtnFilter Blue" onClick={clear}>Temizle</Button>
          <Button type="primary" className="BtnFilter Pink" htmlType="submit">ARA</Button>
        </Form>
      </div>
      <Divider className="mt-40" />
      { get(data, 'results.length', 0) > 0 ? get(data, 'results', []).map(x => (
        <Job key={x.id} job={x} isApplied={false} />
      ))
        :
        <Empty description="Veri bulunamadı" />
      }
      {get(data, 'results.length', 0) > 0 &&
        <Row>
          <Col span={24} className="d-flex j-center">
            <Pagination
              current={get(data, 'page')}
              onChange={(page, pageSize) => onChangeFilter('favorites', page)}
              total={get(data, 'total')}
              itemRender={pageRender} />
          </Col>
        </Row>
      }
    </div>
  );
};

export default Favorites;