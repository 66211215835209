import { get, isEmpty } from "lodash";
import { Row, Col } from 'antd';
import moment from 'moment';

const Education = ({ data }) => {
  return (
    <>
      <p className="text-xl fw-bold ls-half mb-10">EĞİTİM BİLGİLERİ:</p>
      { !isEmpty(data) &&
        data.map(info => (
          <>
            { (info.level.id === 'S2' ||
              info.level.id === 'S3' ||
              info.level.id === 'S4' ||
              info.level.id === 'S5')
              &&
              <HighSchool info={info} />
            }
            {
              (info.level.id === 'S6' ||
                info.level.id === 'S7' ||
                info.level.id === 'S8' ||
                info.level.id === 'S9')
              &&
              <University info={info} />
            }
            {
              (info.level.id === 'S0' ||
                info.level.id === 'S1' ||
                info.level.id === 'S10' ||
                info.level.id === 'S11')
              &&
              <Normal info={info} />
            }
          </>
        ))
      }
    </>
  );
};

const HighSchool = ({ info }) => (
  <Row className="EducationInfo full-width b-bottom pb-10 mb-10" key={info}>
    <Col span={9}>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'level.description', '-')}</p>
      <p className="text-xl fw-semibold ls-half mb-0">{moment(get(info, 'startDate', '-'), 'YYYY.MM.DD').format('DD.MM.YYYY')} | {moment(get(info, 'endDate', '-'), 'YYYY.MM.DD').format('DD.MM.YYYY')}</p>
    </Col>
    <Col span={11}>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'instituteName', '-')}</p>
    </Col>
  </Row>
);

const University = ({ info }) => (
  <Row className="EducationInfo full-width b-bottom pb-10 mb-10" key={info}>
    <Col span={9}>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'level.description', '-')}</p>
      <p className="text-xl fw-semibold ls-half mb-0">{moment(get(info, 'startDate', '-'), 'YYYY.MM.DD').format('DD.MM.YYYY')} | {moment(get(info, 'endDate', '-'), 'YYYY.MM.DD').format('DD.MM.YYYY')}</p>
    </Col>
    <Col span={11}>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'instituteName', '-')}</p>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'facultyName', '-')}</p>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'departmentName', '-')}</p>
    </Col>
  </Row>
);

const Normal = ({ info }) => (
  <Row className="EducationInfo full-width b-bottom pb-10 mb-10" key={info}>
    <Col span={9}>
      <p className="text-xl fw-semibold ls-half mb-0">{get(info, 'level.description', '-')}</p>
    </Col>
    <Col span={11}>
      <p className="text-xl fw-bold ls-half mb-0">{get(info, 'text', '-')}</p>
    </Col>
  </Row>
);



export default Education;
