import { Button, Col, DatePicker, Form, Input, Row } from "antd";
import { Icon } from "components/UIComponents";
import React from "react";

const EiiCertificate = ({
  data,
  deleteCertificate,
  deleteCertificateLoading,
}) => {
  return (
    <Row>
      <Col>
        <Row>
          <Col span={24} className="d-flex a-center mb-20 full-width">
            <Form.Item name={"course"} className="full-width">
              <Input
                placeholder={data?.course?.name}
                className="form-input full-width ant-input"
                disabled
                value={data?.course?.name}
              />
            </Form.Item>
            <Button
              className="BtnDeleteItem btn btn-blue-filled full-width text-md mb-10"
              onClick={() => deleteCertificate(data?.id)}
              loading={
                data.id === deleteCertificateLoading.id &&
                deleteCertificateLoading.status
              }
              disabled={
                data.id === deleteCertificateLoading.id &&
                deleteCertificateLoading.status
              }
            >
              {data.id === deleteCertificateLoading.id &&
              deleteCertificateLoading.status
                ? ""
                : "Sil"}
            </Button>
          </Col>
          <Col span={24}>
            <Form.Item name={"certType"}>
              <Input
                placeholder={data?.certType?.name}
                className="form-input mb-20"
                disabled
                value={data?.certType?.name}
              />
            </Form.Item>
          </Col>
          {/* <Col span={24}>
            <Form.Item name={"durationHours"}>
              <InputNumber
                className="form-input full-width mb-20"
                placeholder={
                  data?.durationHours ? data?.durationHours + " saat" : ""
                }
                disabled
                value={data?.durationHours}
              />
            </Form.Item>
          </Col> */}
          <Col span={24} className="d-flex a-center mb-20">
            <Icon
              name="calendar"
              size={42}
              style={{ transform: "translateY(-8px)" }}
            />
            <Form.Item name={"startedAt"}>
              <DatePicker
                placeholder={data?.startedAt}
                className="form-input"
                suffixIcon={null}
                format="DD/MM/YYYY"
                value={data?.startedAt}
                disabled
                style={{ margin: "0 10px" }}
              />
            </Form.Item>
            <Form.Item name={"endedAt"}>
              <DatePicker
                placeholder={data?.endedAt}
                className="form-input"
                suffixIcon={null}
                format="DD/MM/YYYY"
                value={data?.endedAt}
                disabled
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default EiiCertificate;
