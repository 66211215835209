import { Col, DatePicker, Input, message, Row, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getDataNew } from "../../../../../../redux/services/request";
import axios from "axios";
import config from "../../../../../../plugins/ibb/config";
import { PlusOutlined } from "@ant-design/icons";
import biohead from "../../../../../../assets/biohead.png";
import UploadPhotoModal from "../UploadPhotoModal";
import { useHistory } from "react-router-dom";
import { changeUrl, getBase64 } from "../../../../../../helpers/methods";
import { useSelector } from "react-redux";

const PersonalInfo = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [isModalUploadPhotoOpen, setIsModalUploadPhotoOpen] = useState(false);

  const showModalUploadPhoto = () => {
    setIsModalUploadPhotoOpen(true);
  };

  const resume = useSelector((state) => state.data.resumeData);
  const resumeData = resume?.result;
  const pageLoading = useSelector((state) => state.data.pageLoading);

  useEffect(() => {
    if (resumeData?.photoId) {
      (async () => {
        //`${config.BASE_API_URL}/candidates/resumes/profile-photos/${resumeData.photoId}`
        await axios
          .get(
            changeUrl(
              `candidates/resumes/profile-photos/${resumeData.photoId}`
            ),
            {
              responseType: "blob",
              headers: {
                "Content-Type": "image/jpg, image/png",
              },
            }
          )
          .then((response) => {
            getBase64(response.data, (img) => {
              setImageUrl(img);
            });
          })
          .catch((err) => {
            message.error(err);
          });
      })();
    }
  }, [resumeData]);

  return (
    <div className="General">
      <Spin spinning={pageLoading}>
        <Row style={{ marginBottom: "20px" }}>
          <div
            style={{
              width: 200,
              height: 200,
              borderRadius: "50%",
              position: "relative",
              backgroundImage: `url(${imageUrl || biohead})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              backgroundColor: "rgba(85,41,150,0.8)",
            }}
          >
            <PlusOutlined
              style={{
                position: "absolute",
                bottom: 0,
                right: 20,
                fontSize: 36,
              }}
              onClick={showModalUploadPhoto}
            />
          </div>
          <UploadPhotoModal
            isModalOpen={isModalUploadPhotoOpen}
            setIsModalOpen={setIsModalUploadPhotoOpen}
          />
        </Row>
        <Row justify="space-between">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }} lg={14}>
            <div className="full-width">
              <Input
                name="citizenNo"
                className="form-input mb-15"
                value={resumeData?.idNo}
                disabled
              />

              <Input
                className="form-input mb-15"
                name="firstName"
                value={resumeData?.firstName}
                disabled
                style={{ textTransform: "capitalize" }}
              />

              <Input
                className="form-input mb-15"
                name="lastName"
                value={resumeData?.lastName}
                disabled
                style={{ textTransform: "uppercase" }}
              />

              <Input
                className="form-input mb-15"
                name="genderType"
                value={resumeData?.genderOption?.description}
                disabled
                style={{ textTransform: "uppercase" }}
              />

              <DatePicker
                name="birthDate"
                className="form-input full-width mb-15"
                placeholder={resumeData?.birthDate}
                format="DD/MM/YYYY"
                disabled
              />
            </div>
          </Col>
        </Row>
      </Spin>
    </div>
  );
};

export default PersonalInfo;
