import { useHistory, Link } from "react-router-dom";
import { Drawer, Menu } from "antd";
import biohead from '../../../assets/logo-logo.svg';
import logo from '../../../assets/static/img/logo.png';
import seninbanner from '../../../assets/bioseninbanner.png';

export default ({ visible, setVisible, candidateName, companyName }) => {
  const history = useHistory();

  const logout = async () => {
    localStorage.clear();
    window.location.replace("/");
  }

  return (
    <Drawer
      visible={visible}
      closable = {false}
      onClose={() => setVisible(false)}>
      <img src={logo} width={160}/>
      <Menu mode="inline" className="mt-30 b-none">
        <Menu.Item onClick={() => {
          history.push('/')
          setVisible( false )
        }}>Anasayfa</Menu.Item>
        <Menu.Item onClick={() =>{
          history.push('/about-us')
          setVisible(false)
        }}>Hakkımızda</Menu.Item>
        <Menu.Item onClick={() => {
          history.push('/offices')
          setVisible(false)
        }}>Ofislerimiz</Menu.Item>
        <Menu.Item onClick={() => {
          history.push('/jobs/list')
          setVisible( false )
        }}>İş İlanları</Menu.Item>
        <Menu.Item onClick={() => {
          history.push('/news/list')
          setVisible(false)
        }}>Haberler</Menu.Item>
        <Menu.SubMenu title="İletişim">
          <Menu.Item onClick={() => {
            history.push('/communication/contact-us')
            setVisible(false)
          }}>Bize Ulaşın</Menu.Item>
          <Menu.Item onClick={() => {
            history.push('/communication/faq')
            setVisible(false)
          } }>Sıkça Sorulan Sorular</Menu.Item>
        </Menu.SubMenu>
        {!localStorage.getItem('accessToken') &&
          <Menu.Item className="subtitle">
            <Link to="/login-citizen"/>
             <img src={biohead} width={22} className="mr-5"/> Üye Girişi
          </Menu.Item>
        }
        {
          !localStorage.getItem('accessToken') &&
          <Menu.Item className="subtitle">
            <Link to="/login-corporate"/>
            <img src={biohead} width={22} className="mr-5"/> İşveren Girişi
          </Menu.Item>
        }
        {
          localStorage.getItem('type') === 'CITIZEN' &&
          <Menu.SubMenu title={candidateName} className="subtitle">
            <Menu.Item>
              <Link to="/account/candidate/account">
                Hesap Profili
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/account/candidate/cv">
                Özgeçmiş
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/account/candidate/profile">
                İlan İşlemleri
              </Link>
            </Menu.Item>
            <Menu.Item onClick={logout}>
              Çıkış Yap
            </Menu.Item>
          </Menu.SubMenu>
        }
        {
          localStorage.getItem('type') === 'CORPORATE' &&
          <Menu.SubMenu title={companyName} className="subtitle">
            <Menu.Item>
              <Link to="/account/employer/profile">
                Firma Profili
              </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/account/employer/jobs/list">
                Firma İlan Listesi
                </Link>
            </Menu.Item>
            <Menu.Item>
              <Link to="/account/employer/jobs/create">
                İlan Oluştur
                </Link>
            </Menu.Item>
            <Menu.Item onClick={logout}>
              Çıkış Yap
              </Menu.Item>
          </Menu.SubMenu>
        }
      </Menu>
      <img src={seninbanner} onClick={() => window.open('https://app.adjust.com/pjt7vbi?deeplink=https%3A%2F%2Fpaylas.istanbulsenin.istanbul%2Fkobilservice%3FsID%3Da4813ca6-207c-4779-8866-7cb719f34e72')} width={'100%'} style={{ position:"absolute", bottom:'0' }}/>
    </Drawer>
  );
};
