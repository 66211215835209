import React from "react"; // import dependency
import { codes } from "./constants";
import jwt from "jwt-decode";
import { Button, message, Tooltip } from "antd";
import {
  CheckCircleOutlined,
  DeleteOutlined,
  FieldTimeOutlined,
  StopOutlined,
  UserSwitchOutlined,
  UserOutlined,
} from "@ant-design/icons";
import config from "../plugins/ibb/config";
import axios from "axios";
import { updateState } from "../redux/services/request";

export const generateRecaptcha = () => {
  let code = "";
  for (let i = 0; i < 5; i++) {
    code += codes[Math.floor(Math.random() * codes.length).toString()];
  }
  return code;
};

export const getConsents = (accessToken) => {
  const token = accessToken ?? localStorage.getItem("accessToken");

  if (!token) return { required: false };

  try {
    const claims = jwt(token);

    return {
      required:
        claims.kvkkExplicitConsent !== true ||
        claims.kvkkClarificationConsent !== true ||
        (claims.smsConsent !== true && claims.emailConsent !== true),
      kvkkExplicit: claims.kvkkExplicitConsent,
      kvkkClarification: claims.kvkkClarificationConsent,
      kvkkPlatformUsageAgreement: claims.kvkkClarificationConsent,
      sms: claims.smsConsent,
      email: claims.emailConsent,
    };
  } catch (error) {
    return { required: false };
  }
};

export const idToNames = (ids, objects, labelKey, valueKey) => {
  let arr = [];
  objects.forEach((obj) => {
    ids.forEach((id) => {
      if (id === obj[valueKey]) {
        arr.push(obj[labelKey]);
      }
    });
  });
  return arr;
};

export const dataLayerPush = (args) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(args);
};

export const handleFormErrors = (errors) =>
  errors.errorFields
    .map((entry) => entry.errors)
    .forEach((error) => message.error(error));

// to manage user transitions we are using this function ( update, delete, approve, reject, passive, active ) on Dashboard firm staffList
export const manageUserTransition = (status, id, setUser, loading) => {
  switch (status) {
    case "Onayda":
      return <FieldTimeOutlined style={{ color: "gold" }} />;

    case "Aktif":
      return (
        <>
          <Tooltip title={"Kullanıcıyı pasif yap"}>
            <Button
              loading={loading}
              onClick={(e) => setUser(e, id, "passive", status)}
            >
              <StopOutlined style={{ color: "crimson" }} />
            </Button>
          </Tooltip>
          <Tooltip title={"Kullanıcıyı sil"}>
            <Button
              loading={loading}
              onClick={(e) => setUser(e, id, "delete", status)}
              style={{ marginLeft: 5 }}
            >
              <DeleteOutlined style={{ color: "crimson" }} />
            </Button>
          </Tooltip>
        </>
      );

    case "Pasif":
      return (
        <>
          <Tooltip title={"Kullanıcıyı aktif yap"}>
            <Button
              loading={loading}
              onClick={(e) => setUser(e, id, "active", status)}
            >
              <CheckCircleOutlined style={{ color: "green" }} />
            </Button>
          </Tooltip>
          <Tooltip title={"Kullanıcıyı sil"}>
            <Button
              loading={loading}
              onClick={(e) => setUser(e, id, "delete", status)}
              style={{ marginLeft: 5 }}
            >
              <DeleteOutlined style={{ color: "crimson" }} />
            </Button>
          </Tooltip>
        </>
      );

    case "Silindi":
      return (
        <>
          <Tooltip title={"Aktif yap"}>
            <Button
              loading={loading}
              onClick={(e) => setUser(e, id, "active", status)}
            >
              <CheckCircleOutlined style={{ color: "green" }} />
            </Button>
          </Tooltip>{" "}
        </>
      );
    default:
  }
};

export const handleSetMasterButton = (
  mainMaster,
  master,
  status,
  setUser,
  id
) => {
  if (status === "WAITING") {
    return;
  } else if (!master) {
    return (
      mainMaster && (
        <Tooltip title={"Kullanıcıyı yetkili yap"}>
          <UserSwitchOutlined
            style={{ fontSize: "20px", color: "orange", cursor: "pointer" }}
            onClick={(e) => setUser(e, id, "master")}
          />
        </Tooltip>
      )
    );
  }

  return <UserOutlined style={{ fontSize: "20px", color: "green" }} />;
};

export const confirmMessage = (message) => {
  return (
    <div
      style={{
        height: 500,
        width: "100%",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#E95B29",
      }}
    >
      <p style={{ fontSize: 24, color: "white" }}>{message}</p>
    </div>
  );
};

export const checkMaster = (currentUserName, masterName) => {
  if (masterName && currentUserName) {
    return masterName.toLowerCase().includes(currentUserName.toLowerCase());
  }
};

export const selectRoute = (routeTo, stateId, idNo, nextUrl) => {
  let route;

  switch (routeTo) {
    case "ADVERTISEMENT":
      route = "/jobs/list";
      break;
    case "CV":
      route = "/account/candidate/cv";
      break;
    case "PROFILE_UPDATE":
      route = { pathname: "/personal-info", state: { stateId, idNo, nextUrl } };
      break;
    default:
      route = "/account/employer/jobs/list";
  }

  return route;
};

/**/
export const getGoogleRoute = (office) => {
  console.log("ofis: " + office?.county);

  switch (office?.county?.county) {
    case "Şişli":
      return "https://www.google.com/maps/place/B%C3%B6lgesel+%C4%B0stihdam+Ofisi-%C5%9Ei%C5%9Fli/@41.0677066,28.9846106,17z/data=!3m1!4b1!4m5!3m4!1s0x14cab7a85c4ea9ab:0x50933c70f0be6ad0!8m2!3d41.0677066!4d28.9868046";
      break;
    case "Tuzla":
      return "https://www.google.com/maps/place/%C4%B0BB+Tuzla+Bolgesel+Istihdam+Ofisi/@40.8297638,29.3069509,17z/data=!3m1!4b1!4m5!3m4!1s0x14cadd4b4d1f6c5d:0xad24dea273fda261!8m2!3d40.8297638!4d29.3091449";
      break;
    case "Büyükçekmece":
      return "https://www.google.com/maps/place/B%C3%BCy%C3%BCk%C3%A7ekmece+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.023252,28.5833906,17z/data=!3m1!4b1!4m5!3m4!1s0x14b55f6cbf0aa27b:0xa8a3f822aca201e9!8m2!3d41.0232521!4d28.5878699";
      break;
    case "Küçükçekmece":
      return "https://www.google.com/maps/place/K%C3%BC%C3%A7%C3%BCk%C3%A7ekmece+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@40.9882575,28.7882794,17z/data=!3m1!4b1!4m5!3m4!1s0x14caa341dd30e16f:0xc80cc786308aebb1!8m2!3d40.9882575!4d28.7904734";
      break;
    case "Bahçelievler":
      return "https://www.google.com/maps/place/Bah%C3%A7elievler+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0078,28.8418106,17z/data=!3m1!4b1!4m5!3m4!1s0x14caa5b7e511bd4b:0x627e04d7b82329f2!8m2!3d41.0078067!4d28.8440048";
      break;
    case "Bağcılar":
      return "https://www.google.com/maps/place/%C4%B0BB+Ba%C4%9Fc%C4%B1lar+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0336694,28.8675205,17z/data=!3m1!4b1!4m5!3m4!1s0x14cabb71e83c4cf7:0xc8bcfc014f5b7d07!8m2!3d41.0336694!4d28.8697145";
      break;
    case "Sultangazi":
      return "https://www.google.com/maps/place/%C4%B0BB+Sultangazi+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0938952,28.9028991,17z/data=!3m1!4b1!4m5!3m4!1s0x14cab1072687552d:0x4a4b5d732c75e423!8m2!3d41.0938952!4d28.9050931";
      break;
    case "Ümraniye":
      return "https://www.google.com/maps/place/%C4%B0BB+%C3%9Cmraniye+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0171622,29.0356439,13z/data=!4m14!1m6!3m5!1s0x14cac9adf1bcae69:0xe5fc481619006241!2zxLBCQiDDnG1yYW5peWUgQsO2bGdlc2VsIMSwc3RpaGRhbSBPZmlzaQ!8m2!3d41.0171622!4d29.1056817!3m6!1s0x14cac9adf1bcae69:0xe5fc481619006241!8m2!3d41.0171622!4d29.1056817!15sCiJiw7ZsZ2VzZWwgaXN0aWhkYW0gb2Zpc2kgw7xtcmFuaXllkgEUY2l0eV9kaXN0cmljdF9vZmZpY2XgAQA!16s%2Fg%2F11tg2szsqq";
      break;
    case "Kartal":
      return "https://yandex.com.tr/harita/org/ibb_kartal_bolgesel_istihdam_ofisi/180793324106/?from=tabbar&ll=29.196450%2C40.886461&mode=search&sctx=ZAAAAAgBEAAaKAoSCZ7uPPGcAT1AESS05VyKhURAEhIJFAg7xapBsD8Raww6IXTQsT8iBgABAgMEBSgKOABA78kGSAFiFnJlbGV2X2hlYXZ5X3RvcF9zaXplPTViNHJlYXJyPXNjaGVtZV9Mb2NhbC9HZW91cHBlci9IZWF2eVJhbmtpbmdGb3JGdWxsVG9wPTBqAnRynQHNzEw9oAEAqAEAvQGAv0tTwgEGxvvYo5wC6gEA8gEA%2BAEAggIvYXJuYXZ1dGvDtnkgYsO2bGdlc2VsIGlzdGloZGFtIG9maXNpIGJvxJ9hemvDtnmKAgCSAgYxMDc3NTmaAgxkZXNrdG9wLW1hcHM%3D&sll=29.196450%2C40.886461&source=serp_navig&sspn=0.012113%2C0.006433&text=arnavutk%C3%B6y%20b%C3%B6lgesel%20istihdam%20ofisi%20bo%C4%9Fazk%C3%B6y&z=16.76";
      break;
    case "Sancaktepe":
      return "https://yandex.com.tr/profile/242621660804?no-distribution=1&source=wizbiz_new_map_single";
      break;
    case "Üsküdar":
      const map =
        office?.id?.id === "zNn9dmbqBQIbomvNJdmzfm5Z"
          ? "https://www.google.com/maps/place/%C4%B0BB+B%C3%96LGESEL+%C4%B0ST%C4%B0HDAM+OF%C4%B0S%C4%B0+%C3%9CSK%C3%9CDAR+%C5%9EUBES%C4%B0/@41.0458536,28.8326109,12z/data=!4m13!1m6!3m5!1s0x14cab95469216cad:0x299ec78b9fdb87f6!2zxLBCQiBCw5ZMR0VTRUwgxLBTVMSwSERBTSBPRsSwU8SwIMOcU0vDnERBUiDFnlVCRVPEsA!8m2!3d41.0206835!4d29.0151823!3m5!1s0x14cab95469216cad:0x299ec78b9fdb87f6!8m2!3d41.0206835!4d29.0151823!15sCiLDvHNrw7xkYXIgYsO2bGdlc2VsIGlzdGloZGFtIG9maXNpkgEUY2l0eV9kaXN0cmljdF9vZmZpY2XgAQA"
          : "https://www.google.com/maps/place/K%C4%B1s%C4%B1kl%C4%B1,+Bosna+Blv+No:39,+34692+%C3%9Csk%C3%BCdar%2F%C4%B0stanbul/@41.0292083,29.0800669,17z/data=!3m1!4b1!4m6!3m5!1s0x14cac845266b2067:0x225079cb8312bd38!8m2!3d41.0292083!4d29.0800669!16s%2Fg%2F11c5m7pg0x";
      return map;
      break;
    case "Silivri":
      return "https://www.google.com/maps/place/Piri+Mehmet+Pa%C5%9Fa,+Mumhane+Sk.+No:17,+34570+Silivri%2F%C4%B0stanbul/@41.0731311,28.2428525,17z/data=!3m1!4b1!4m5!3m4!1s0x14b515837bce47c5:0x9372ca5e29db032a!8m2!3d41.0731312!4d28.2473318";
      break;
    case "Bayrampaşa":
      return "https://www.google.com/maps/place/Bayrampa%C5%9Fa+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0458749,28.9006389,17z/data=!3m1!4b1!4m5!3m4!1s0x14caba86e4fcdf7b:0x82a3a22d129e64e2!8m2!3d41.0458749!4d28.9028329";
      break;
    case "Arnavutköy":
      return "https://yandex.com.tr/harita/org/ibb_arnavutkoy_bolgesel_istihdam_ofisi/4077187878/?from=tabbar&ll=28.900480%2C41.098180&mode=search&sll=28.900480%2C41.097588&source=serp_navig&text=arnavutk%C3%B6y%20b%C3%B6lgesel%20istihdam%20ofisi%20bo%C4%9Fazk%C3%B6y&z=10";
      break;
    case "Sarıyer":
      return "https://yandex.com.tr/profile/10953147150?no-distribution=1&source=wizbiz_new_map_single";
      break;
    case "Başakşehir":
      return "https://yandex.com.tr/harita/org/ibb_basaksehir_bolgesel_istihdam_ofisi/76310527430/?from=tabbar&ll=28.719921%2C41.205274&mode=search&sctx=ZAAAAAgBEAAaKAoSCZ7uPPGcAT1AESS05VyKhURAEhIJFAg7xapBsD8Raww6IXTQsT8iBgABAgMEBSgKOABAsKoGSAFiFnJlbGV2X2hlYXZ5X3RvcF9zaXplPTViNHJlYXJyPXNjaGVtZV9Mb2NhbC9HZW91cHBlci9IZWF2eVJhbmtpbmdGb3JGdWxsVG9wPTBqAnRynQHNzEw9oAEAqAEAvQGAv0tTwgEFqv%2FWjiLqAQDyAQD4AQCCAi9hcm5hdnV0a8O2eSBiw7ZsZ2VzZWwgaXN0aWhkYW0gb2Zpc2kgYm%2FEn2F6a8O2eYoCAJICBjEwNzc1OZoCDGRlc2t0b3AtbWFwcw%3D%3D&sll=28.719921%2C41.205274&source=serp_navig&sspn=0.012113%2C0.006402&text=arnavutk%C3%B6y%20b%C3%B6lgesel%20istihdam%20ofisi%20bo%C4%9Fazk%C3%B6y&z=16.76";
    case "Eyüpsultan":
      return "https://www.google.com/maps/place/%C4%B0gda%C5%9F+Park%C4%B1/@41.0719361,28.9414061,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab0d0e1959839:0x305a680cd71f52f6!8m2!3d41.0719361!4d28.943981!16s%2Fg%2F113dpmrch";
    case "Gaziosmanpaşa":
      return "https://www.google.com/maps/place/Barbaros+Hayrettin+Pa%C5%9Fa+Enstit%C3%BC+%C4%B0stanbul+%C4%B0SMEK/@41.0767512,28.8968784,17z/data=!3m1!4b1!4m6!3m5!1s0x14cab041e238d457:0x8b82935cbd54e634!8m2!3d41.0767512!4d28.8990671!16s%2Fg%2F11c615lpsc";
    case "Adalar":
      return "https://yandex.com.tr/harita/org/ibb_adalar_bolgesel_istihdam_ofisi/12486753788/?ll=29.128789%2C40.874038&z=16";
    case "Şile":
      return "https://www.google.com/maps/place/%C4%B0BB+%C5%9Eile+K%C3%BClt%C3%BCr+Merkezi/@41.1725701,29.6134887,17z/data=!3m1!4b1!4m6!3m5!1s0x409e325ec3ba0851:0x9008dfb6cc022a10!8m2!3d41.1725701!4d29.6156774!16s%2Fg%2F11bxfrxzxw";
    case "Zeytinburnu":
      return "https://www.google.com/maps/place/G%C3%B6kalp,+34.+Sk.+No:27,+34020+Zeytinburnu%2F%C4%B0stanbul/@40.9930811,28.9102535,20.75z/data=!4m5!3m4!1s0x14cabb9f130106e1:0xa82ed587867e5391!8m2!3d40.9931027!4d28.9101706";
    case "Çatalca":
      return "https://www.google.com/maps/place/Ferhatpa%C5%9Fa,+M%C3%BCnif+Sevi+Cd+No:14,+34888+%C3%87atalca%2F%C4%B0stanbul/@41.1445428,28.4618598,20.75z/data=!4m6!3m5!1s0x14b54517eea56c4f:0xab6235e95edc0289!8m2!3d41.1446128!4d28.4619654!16s%2Fg%2F11cphcq9dz";
    case "Antakya":
      return "https://www.google.com/maps/dir//Sarayc%C4%B1k+Mahallesi+%C3%87evre+Yolu+CD.+%C4%B0BB+Hatay+Afet+Koordinasyon+Merkezi+Antakya%2FHatay/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x1525c25f666079e9:0x64a42c671fb9a?sa=X&ved=2ahUKEwingM72y9b-AhWYQfEDHdykAGcQ9Rd6BAhrEAM";
    case "Pendik":
      return "https://www.google.com.tr/maps/place/%C4%B0BB+Pendik+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@40.8804247,29.2488546,17z/data=!3m1!4b1!4m14!1m7!3m6!1s0x14cadd52c185be75:0x2580737420cb3767!2s%C4%B0BB+Pendik+B%C3%B6lgesel+%C4%B0stihdam+Ofisi!8m2!3d40.8804247!4d29.2514295!16s%2Fg%2F11vzy4yhjr!3m5!1s0x14cadd52c185be75:0x2580737420cb3767!8m2!3d40.8804247!4d29.2514295!16s%2Fg%2F11vzy4yhjr?hl=tr&entry=ttu";
    default:
      return "https://www.google.com/maps/place/Bayrampa%C5%9Fa+B%C3%B6lgesel+%C4%B0stihdam+Ofisi/@41.0458749,28.9006389,17z/data=!3m1!4b1!4m5!3m4!1s0x14caba86e4fcdf7b:0x82a3a22d129e64e2!8m2!3d41.0458749!4d28.9028329";
  }
};

// nonce is a 10 characters of string for istanbul senin url
export const generateNonce = (length) => {
  let result = "";
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

// ist senin logout button link
export const logoutIstSenin = () =>
  window.open(
    `https://ibb01.api.ibb-test.2ndfloor.aws-dev.kobil.com/auth/realms/ibb01/protocol/openid-connect/logout?redirect_uri=${config.IST_SENIN_REDIRECT_URL}`,
    "_self"
  );

// ist senin login system token to token api send code
export const fetchToken = async (code, setLoading, history) => {
  setLoading(true);

  try {
    const data = {
      code,
    };

    updateState({ loginInit: { data: null, loading: true } });
    const res = await axios.post(changeUrl(`istsenin/auth/login`), data);

    if (res.status !== 200) {
      message.error("Istanbul senin bağlantı hatası lütfen tekrar deneyiniz.");
      logoutIstSenin();
      return;
    }

    if (res.data.code !== 200) {
      message.error("Istanbul senin bağlantı hatası lütfen tekrar deneyiniz.");
      logoutIstSenin();
      return;
    }
    const route = selectRoute(
      res?.data.result.routeTo,
      res?.data?.result?.stateId,
      res?.data?.result?.uid,
      "/login-citizen"
    );

    // local pushes
    localStorage.setItem("accessToken", res?.data?.result?.accessToken);
    localStorage.setItem("type", res?.data?.result?.userType);
    localStorage.setItem("fullName", res?.data?.result?.fullName);

    // bearer token
    axios.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${localStorage.getItem("accessToken")}`;
    localStorage.getItem("type") &&
      (axios.defaults.headers.common["userType"] =
        localStorage.getItem("type"));

    // datalayer
    dataLayerPush({
      event: "uyeGiris",
      result: "uyeGirisBasarili",
    });

    if (res?.data?.result?.routeTo !== "PROFILE_UPDATE") {
      message.success(
        `İstanbul senin ile giriş işlemi başarılı teşekkür ederiz ${res?.data?.result?.fullName}.`
      );
    }
    updateState({ loginInit: { data: res.data, loading: false } });
    setLoading(false);
    history.push(route);
  } catch {
    alert("Code didn" / "t send back");
  }
};

export function levelIndicator(val) {
  let arr = [];

  if (val) {
    for (let i = 1; i <= val; i++) {
      const el = <span className="level-circle full"></span>;
      arr.push(el);
    }
  }

  for (let i = 1; i <= 5 - val; i++) {
    const el = <span className="level-circle"></span>;
    arr.push(el);
  }

  return arr;
}

export function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result));
  reader.readAsDataURL(img);
}

export const printFunction = (name) => {
  document.title = `${name}_bio_cv`;
  window.print();
};

export const changeUrl = (url) => {
  const baseUrl = config.BASE_API_URL;
  if (baseUrl.split("/")[baseUrl.split("/").length - 1].length === 0) {
    const newUrl = `${baseUrl}${url}`;
    return newUrl;
  } else {
    const newUrl = `${baseUrl}/${url}`;
    return newUrl;
  }
};
