import AuthLayoutForm from "./Components/AuthLayoutForm";
import { Input, Col, Row, Checkbox, DatePicker, message } from "antd";
import MaskedInput from "react-maskedinput";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import {useState} from "react";
import {postNewLogin, updateState} from "../../redux/services/request";
import {useHistory} from "react-router-dom";
import Policy from '../../assets/policy/BİO_KVKAydınlatmaMetni v.18.5.23.pdf'

export default function RegisterCitizen() {
  const [checked,setChecked] = useState(false);
  const [disabled,setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const handleSubmit =  async(data) => {
    setLoading(true);

    const newData = {
      ...data,
      birthDate: data?.birthDate?.format('YYYY-MM-DD'),
      phone: "0" + data?.phone?.replace(/[()\s]/g,''),
      userType: 'CITIZEN',
      taxNoOrIdNo: null,
      companyAlias: null,
      clarificationConfirmation: checked
    }

    try {
      updateState({ registerInit : {data: null, loading: true }});
      const response = await postNewLogin({ url: "/auth/register/init", data: newData });

      if( response.code !== 200 ) {
        message.error( response.message );
        setLoading(false);
        return;
      }

      message.success('Telefon doğrulamadan sonra işleminiz tamamlanacaktır ilgili sayfaya yönlendiriliyorsunuz');
      setLoading( false );
      updateState( { registerInit : { data: response, loading: false }});
      history.push( '/verification' );

    } catch {
      setLoading(false);
    }
  }

  const initialValues = {
    idNo: "",
    firstName: "",
    lastName: "",
    birthDate: '',
    email: "",
    phone: "",
    password: "",
    taxNoOrIdNo: null,
    companyAlias: null,
    userType: "CITIZEN",
    clarificationConfirmation: false
  };

  return (
    <>
      <AuthLayoutForm
        initialValues={ initialValues }
        formTitle="Kayıt Ol"
        formName="register-citizen"
        button="KAYIT OL"
        onFinish={ handleSubmit }
        disabled={ disabled }
        loading={ loading }
        seoDescription="Bölgesel İstihdam Ofisi Aday Kayıt Ol"
        seoTitle="Bölgesel İstihdam Ofisi Aday Kayıt"
        seoKeywords="Aday, Aday Kayıt Sayfası, Üye Kayıt, Vatandaş, Vatandaş Kayıt Ol"
      >
        <Row>
          <Col lg={ 12 } md={ 24 } xs={ 24 } style={{ paddingRight: "25px" }}>
            <AuthLayoutInput
              name="idNo"
              label="T.C. Kimlik No"
              input={<Input className="auth-form-input" maxLength={ 11 } />}
            />
            <AuthLayoutInput
              name="firstName"
              label="Ad"
              input={<Input className="auth-form-input" minLength={ 2 } />}
            />
             <AuthLayoutInput
              name="lastName"
              label="Soyad"
              input={<Input className="auth-form-input" minLength={2} />}
            />
            <AuthLayoutInput
              name="birthDate"
              label="Doğum Tarihi"
              hasFeedback
              input={
                <DatePicker
                  className="auth-form-input"
                  format="DD-MM-YYYY"/>
              }
            />
          </Col>
          <Col lg={12} md={24} xs={24} className="auth-form-register">

            <AuthLayoutInput
              name="email"
              label="E-posta"
              input={<Input className="auth-form-input" />}
            />
            <AuthLayoutInput
              name="phone"
              label="Cep Telefonu"
              input={
                <MaskedInput
                  className="masked-input"
                  mask="(111) 111 11 11"
                  name="phone"
                  maxLength={15}
                  minLength={15}
                  placeholder="Telefon"
                />
              }
            />
            <AuthLayoutInput
              name="password"
              label="Şifre"
              hasFeedback
              input={<Input.Password className="auth-form-input" />}
            />
          </Col>
          <Col lg={24} md={24} xs={24}>
            <AuthLayoutInput
              name="clarificationConfirmation"
              input={
                  <Checkbox name="clarificationConfirmation" onChange={(e) => {
                    setChecked(e.target.checked)
                    setDisabled(checked) }}>
                    <a target="_blank"  href={Policy}>Aydınlatma metnini</a> okudum onayladım.
                  </Checkbox>
              }
            />
          </Col>
        </Row>
      </AuthLayoutForm>
    </>
  );
}
