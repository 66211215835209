import { useEffect, useState } from 'react';
import { Tabs } from 'antd';
import Banner from 'components/UIComponents/Banner';

import Applications from './Tabs/Applications';
import Favorites from './Tabs/Favorites';
import Recommended from './Tabs/Recommended';
import Searches from './Tabs/Searches';
import useGet from 'hooks/useGet';
import { get } from 'lodash';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import {Helmet} from "react-helmet";


const Announcements = () => {
  const history = useHistory();
  const [tab, setTab] = useState(queryString.parse(history.location.search).tab);

  const [paramsFavorites, setParamsFavorites] = useState(null);
  const [paramsUserJobs, setParamsUserJobs] = useState(null);
  const [paramsRecommended, setParamsRecommended] = useState(null);
  const [paramsSavedFilters, setParamsSavedFilters] = useState(null);

  const { data: userJobs, loading: loadingUserJobs } = useGet({ url: '/applications/query', key: 'userJobs', isArray: false, params: paramsUserJobs });
  const { data: favorites, loading: loadingFavorites, refresh } = useGet({ url: '/jobs/favorites', key: 'favoriteJobs', isArray: false, params: paramsFavorites });
  const { data: recommended, loading: loadingRecommended } = useGet({ url: '/candidates/jobs/suggests', key: 'suggestedJobs', isArray: false });
  const { data: savedFilters, loading: loadingSavedFilters, refresh: refreshSearches } =
      useGet({ url: localStorage.getItem('accessToken') ? '/filters/jobs': undefined, key: 'savedFilters', isArray: false, params: paramsSavedFilters });


  const onChangeFilter = (key, data) => {
    key === 'applications' && setParamsUserJobs(data);
    key === 'favorites' && setParamsFavorites(data);
    key === 'searches' && setParamsSavedFilters(data);
    key === 'recommended' && setParamsRecommended(data);
  }

  const clearFilters = (key) => {
    key === 'applications' && setParamsUserJobs(undefined);
    key === 'favorites' && setParamsFavorites(undefined);
    key === 'recommended' && setParamsRecommended(undefined);
  }

  const onChangePage = (tab, page) => {
    tab === 'applications' && setParamsUserJobs({ ...paramsUserJobs, page });
    tab === 'favorites' && setParamsFavorites({ ...paramsFavorites, page });
    tab === 'recommended' && setParamsRecommended({ ...paramsRecommended, page });
    tab === 'searches' && setParamsSavedFilters({ ...paramsSavedFilters, page });
  }

  const pageRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />
    else
      return originalElement;
  }

  useEffect(() => {
    setTab(queryString.parse(history.location.search).tab);
  }, [history.location.search]);

  return (
    <div className="Announcements">
      <Banner text="Aday İlan İşlemleri" className="mb-0" />
      <Helmet>
        <title>İş Ara İş Bul | Aday İlan İşlemleri</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <div>
        <div className="content md content-detail">
          <Tabs activeKey={tab} defaultActiveKey="applications" className="AnnouncementTabs" onChange={setTab}>
            <Tabs.TabPane key="applications" tab={<div>Başvurular {<span className="ml-10">{get(userJobs, 'total', 0)}</span>}</div>}>
              <Applications loading={loadingUserJobs} data={userJobs} pageRender={pageRender} onChangeFilter={onChangeFilter} clearFilters={clearFilters} refreshFavorites={refresh} onChangePage={onChangePage} />
            </Tabs.TabPane>
            <Tabs.TabPane key="favorites" tab={<div>Favori İlanlar {<span className="ml-10">{get(favorites, 'total', 0)}</span>}</div>}>
              <Favorites loading={loadingFavorites} data={favorites} pageRender={pageRender} onChangeFilter={onChangeFilter} clearFilters={clearFilters} onChangePage={onChangePage} />
            </Tabs.TabPane>
            <Tabs.TabPane key="recommended" tab={<div>Önerilen İlanlar {<span className="ml-5">{get(recommended, 'total', 0)}</span>}</div>}>
              <Recommended loading={loadingRecommended} data={recommended} pageRender={pageRender} onChangeFilter={onChangeFilter} clearFilters={clearFilters} onChangePage={onChangePage} />
            </Tabs.TabPane>
            <Tabs.TabPane key="savedFilters" tab={<div>Kayıtlı Aramalar {<span className="ml-5">{get(savedFilters, 'total', 0)}</span>}</div>}>
              <Searches loading={loadingSavedFilters} data={savedFilters} pageRender={pageRender} onChangeFilter={onChangeFilter} clearFilters={clearFilters} refresh={refreshSearches} onChangePage={onChangePage} />
            </Tabs.TabPane>
          </Tabs>
        </div>
      </div>
    </div >
  );
};

export default Announcements;
