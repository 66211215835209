import { Form, Row, Col, Modal, Input, Button, message, Collapse, Descriptions } from 'antd';
import moment from 'moment';
import { LookupSelect } from 'components/UIComponents/FormElements';
import { get } from 'lodash';
import { useEffect, useState } from 'react';
import { putData } from 'redux/services/request';
import { Icon } from 'components/UIComponents';


const InterviewResult = ({ data, visible, setVisible, interviews, refresh }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentInterview, setCurrentInterview] = useState(null);

  const onSubmit = async (model) => {
    setLoading(true);
    try {
      await putData({ url: `/interviews/${currentInterview?.interviewId || ''}`, data: model });
      message.success('Mülakat sonucu girildi');
      setVisible(false);
      refresh();
    } catch (err) {
      message.error('Mülakat sonucu girilirken bir hata meydana geldi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (interviews) {
      const ongoingInterview = interviews.find(x => x.resultStatus === 'CONTINUES');
      setCurrentInterview(ongoingInterview);
    }
  }, [interviews]);

  useEffect(() => {
    if (visible) {
      form.resetFields();
    }
  }, [visible, form]);
  return (
    <Modal className="InviteModal" width={720} footer={null} open={visible} onCancel={() => setVisible(false)}>
      <Form form={form} onFinish={onSubmit}>
        <Row>
          <Col span={24}>
            <h1 className="fw-bold mb-20">
              {data?.firstName || '-'} {data?.lastName || '-'} isimli adayın Mülakat Sonucunu Gir
            </h1>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="interviewStatus" rules={[{ required: true, message: 'Mülakat Durumu zorunludur!' }]}>
              <LookupSelect url="/interviews/types/statuses" labelKey="label" valueKey="name" value={interviews[0]?.interviewStatus} placeholder="Mülakat Durumu" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="resultStatus" rules={[{ required: true, message: 'Mülakat Sonucu zorunludur!' }]}>
              <LookupSelect url="/interviews/types/results" labelKey="label" valueKey="name" value={interviews[0]?.resultStatus} placeholder="Mülakat Sonucu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="resultReport">
              <Input.TextArea className="form-input" placeholder="Sonuç raporu" rows={6} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button htmlType="submit" className="InviteBtn" loading={loading}>Sonucu Gir</Button>
          </Col>
        </Row>
      </Form>
      <Collapse accordion expandIconPosition="end" className="mt-30">
        <Collapse.Panel header={<p className="fw-bold mb-0">Geçmiş Mülakatlar</p>}>
          {interviews?.map(interview => (
            <Descriptions className="mb-30" bordered>
              <Descriptions.Item label="Tarih" span={4}>{interview?.interviewAt && moment(interview.interviewAt).format('DD.MM.YYYY HH:mm')}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Durumu" span={4}>{interview?.interviewStatus || '-'}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Tipi" span={4}>{interview?.interviewType || '-'}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Notu" span={4}>{interview?.invitationNote || '-'}</Descriptions.Item>
            </Descriptions>
          ))}
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};

export default InterviewResult;