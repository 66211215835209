import { useEffect } from "react";
import { Button, Form, Select, message, Modal } from "antd";
import axios from "axios";
import config from "plugins/ibb/config";
import { useHistory } from "react-router-dom";
import { Icon } from "components/UIComponents";
import { useState } from "react";
import { useSelector } from "react-redux";
import { changeUrl } from "helpers/methods";

const CompanyWorkFlows = ({ visible, setVisible, companyData }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const countLookup = useSelector((state) => state.data.companyWorkForce);

  const [disabled, setDisabled] = useState("false");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleSubmit = async (data) => {
    setSubmitLoading(true);
    try {
      await axios.put(changeUrl(`companies/${companyData?.id?.id}`), data);
      message.success(`Çalışan sayısı başarıyla değiştirildi.`);
      setSubmitLoading(false);
      history.go(0);
    } catch (e) {
      message.error(e);
      setSubmitLoading(false);
    }
  };

  const handleDisabled = () => {
    const formValues = form.getFieldsValue(true);
    formValues.companyWorkforce34Id !== "Seçiniz" &&
    formValues.companyWorkforceTrId !== "Seçiniz"
      ? setDisabled(false)
      : setDisabled(true);
  };
  return (
    <Modal
      wrapClassName="commercial-modal"
      bodyStyle={{ overflowY: "auto" }}
      centered
      style={{ border: "#E95B29 3px solid" }}
      open={visible}
      closable={false}
      footer={false}
      className={"commercial-modal-body"}
      title="Aşağıdaki bilgileri giriniz.."
      maskStyle={{ backgroundColor: "rgb(0 0 0 / 75%)" }}
      okButtonProps={{
        href: "/",
        target: "_blank",
        className: "ant-modal-footer-button",
      }}
    >
      <Form
        form={form}
        initialValues={{
          companyWorkforce34Id: "Seçiniz",
          companyWorkforceTrId: "Seçiniz",
        }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexWrap: "wrap",
        }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={"companyWorkforce34Id"}
          label={"İstanbul İçi Çalışan Sayısı: "}
          style={{ width: "100%", maxWidth: 440 }}
          rules={[{ required: true, message: "Please select an option" }]}
        >
          <Select
            onChange={handleDisabled}
            suffixIcon={
              countLookup?.countLoad ? undefined : (
                <Icon name="arrow-down" size={18} />
              )
            }
            className="auth-form-input"
            minLength={2}
          >
            <Select.Option value="Seçiniz">Seçiniz</Select.Option>
            {countLookup?.data?.results?.map((amount, index) => (
              <Select.Option value={amount.id.id} key={index}>
                {amount.workforce}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name={"companyWorkforceTrId"}
          label={"İstanbul Dışı Çalışan Sayısı: "}
          style={{ width: "100%", maxWidth: 440 }}
          rules={[{ required: true, message: "Please select an option" }]}
        >
          <Select
            onChange={handleDisabled}
            suffixIcon={
              countLookup?.countLoad ? undefined : (
                <Icon name="arrow-down" size={18} />
              )
            }
            className="auth-form-input"
            minLength={2}
          >
            <Select.Option value="Seçiniz">Seçiniz</Select.Option>
            {countLookup?.data?.results?.map((amount, index) => (
              <Select.Option value={amount.id.id} key={index}>
                {amount.workforce}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Button
          loading={submitLoading}
          type="primary"
          className="but"
          htmlType="submit"
          disabled={disabled}
          block
        >
          Güncelle
        </Button>
      </Form>
    </Modal>
  );
};
export default CompanyWorkFlows;
