import { Modal } from "antd";
import { get } from "lodash";
import ReactQuill from "react-quill";

const CoverLetter = ({ data, visible, setVisible }) => {

  return (
    <Modal className="CoverLetterModal" visible={visible} width={720} onCancel={() => setVisible(false)} footer={null} closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri" />}>
      <h1 className="text-blue text-4xl fw-bold mb-10">{get(data, 'firstName')} {get(data, 'lastName')}</h1>
      <p className="text-red-pink text-4xl fw-semibold">Adayın Önyazısı</p>
      <ReactQuill value={get(data, 'coverLetter')} modules={{ toolbar: false }} />
    </Modal>
  );
};

export default CoverLetter;
