import consider from '../../../../assets/static/icons/danisman-inceleme.svg';
import find from '../../../../assets/static/icons/üye-ol.svg';
import deal from '../../../../assets/static/icons/deal.svg';


const Portal = () => {
  return (
    <div className="Portal pt-40">
      <div className="content lg d-flex fd-column a-center">
        <h1 className="section-header mb-10">Portal Nasıl İşliyor?</h1>
        <p className="section-info">Bölgesel İstihdam Ofisleri Portalı: İstanbullu iş arayan ile işverenin buluşma noktası.</p>
        <div className="PortalItemContainer d-flex j-between">
          <div className="PortalItem" href={'/auth/register-employer'}>
            <img src={find} alt="Bölgesel İstihdam Ofisleri" className="mb-20" />
            <h1 className="fw-extrabold text-3xl text-center">Ücretsiz Üye Olun</h1>
            <p className="text-md text-center">Ücretsiz üye olun ve özgeçmişinizi/ilanınızı kolayca oluşturun. Özgeçmişiniz/firma üyeliğiniz onaylandıktan sonra hesabınıza erişin.</p>
          </div>
          <div className="PortalItem">
            <img src={consider} alt="Bölgesel İstihdam Ofisleri" className="mb-20" style={{width:'95px'}} />
            <h1 className="fw-extrabold text-3xl text-center">Kariyer Danışmanınıza Başvurun</h1>
            <p className="text-md text-center">Kariyer Danışmanlarımız ile iletişime geçerek iş/personel bulma süreçlerinizde destek alın.</p>
          </div>
          <div className="PortalItem">
            <img src={deal} alt="Bölgesel İstihdam Ofisleri" className="mb-20" />
            <h1 className="fw-extrabold text-3xl text-center">İşinize/Personelinize Ulaşın</h1>
            <p className="text-md text-center">Bölgesel İstihdam Ofisleri Portalı ile doğru iş/doğru personel bulma fırsatını yakalayın. Hem siz kazanın hem İstanbullu kazansın.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Portal;
