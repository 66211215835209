import { List, Spin } from 'antd';
import { Banner } from 'components/UIComponents';
import useGet from 'hooks/useGet';
import NewsItem from './NewsItem';
import {Helmet} from "react-helmet";

const NewsList = () => {
  const { data, loading } = useGet({ url: '/public/news', key: 'news' });

  return (
    <div className="NewsList mb-50">
      <Helmet>
        <title>İş Ara İş Bul |  Haberler</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Haberler" />
      <div className="content md">
        <Spin spinning={loading}>
          <List
            itemLayout="vertical"
            size="large"
            dataSource={data || []}
            renderItem={ item => item && <NewsItem item={item} />}
          />
        </Spin>
      </div>
    </div>
  );
}

export default NewsList;
