import { Button, Modal, Form, Input, message } from "antd";
import { useState } from "react";
import axios from "axios";
import config from "../../../../../plugins/ibb/config";
import { changeUrl } from "helpers/methods";

const UploadPhotoModal = ({ setIsModalOpen, isModalOpen }) => {
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  function handleSubmit() {
    setLoading(true);
    const formData = new FormData();
    formData.append("image", file);
    //`${config.BASE_API_URL}/candidates/resumes/profile-photo`
    axios
      .post(changeUrl("candidates/resumes/profile-photo"), formData, {
        headers: {
          "Content-Type": "image/jpeg",
        },
      })
      .then((response) => {
        if (response.data.code !== 200) {
          message.error(response.data.message);
          setLoading(false);
          setIsModalOpen(false);
          return;
        }

        message.success("profil resmi başarıyla yüklendi.");
        setLoading(false);
        setIsModalOpen(false);
        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        setIsModalOpen(false);
      });
  }

  return (
    <>
      <Modal
        title="CV Profil Resmi Ekleme"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer=""
      >
        <Form onFinish={handleSubmit}>
          <Form.Item>
            <Input type="file" onChange={handleFileChange} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading}>
              Yükle
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default UploadPhotoModal;
