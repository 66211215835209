import { useEffect, useState } from "react";
import "./free_pass.less";
import { getData } from "redux/services/request";
import {
  Alert,
  Spin,
  Checkbox,
  message,
  Upload,
  Row,
  Col,
  Popconfirm,
} from "antd";
import {
  InboxOutlined,
  CheckOutlined,
  CloseOutlined,
  DownloadOutlined,
  EyeOutlined,
} from "@ant-design/icons";
import ImgIcon from "../../../../../../../assets/img.svg";
import PdfIcon from "../../../../../../../assets/pdf.svg";
import Api from "api/Api";
import { changeUrl } from "helpers/methods";
import fileDownload from "js-file-download";
import KvkkModal from "./KvkkModal";
import IlluminationText from "../../../../../../../assets/policy/toplu_ulasim_destegi_aydinlatma_metni.pdf";
import useGet from "hooks/useGet";
import { useSelector } from "react-redux";

const confirmStatus = {
  Onayda: "warning",
  Onaylandı: "success",
  Reddedildi: "danger",
};

const FreePass = ({ activeTab }) => {
  const [loading, setLoading] = useState(false);
  const [cvProgress, setCvProgress] = useState("");
  const [cvBirthdate, setCvBirthdate] = useState("");
  const [documentToUpload, setDocumentToUpload] = useState("");
  const [fileInputKey, setFileInputKey] = useState(0);
  const [myUploadedDocuments, setMyUploadedDocuments] = useState([]);
  const [kvkkModal, setKvkkModal] = useState(false);

  const [freePassKvkkConsent, setFreePassKvkkConsent] = useState({
    kvkkClarificationConsent: false,
    kvkkExplicitConsent: false,
    kvkkEmailConsent: false,
    kvkkSmsConsent: false,
  });

  const allConsentsGiven = Object.values(freePassKvkkConsent).every(
    (value) => value
  );

  const candidatesMe = useSelector(
    (state) => state?.data?.candidatesMe?.data?.result
  );

  useEffect(() => {
    if (allConsentsGiven) {
      setKvkkModal(false);
    } else {
      if (
        (cvProgress &&
          cvProgress > 70 &&
          calculateAgeFromDate(cvBirthdate) < 16) ||
        calculateAgeFromDate(cvBirthdate) < 65
      ) {
        setKvkkModal(true);
      }
    }
  }, [freePassKvkkConsent, allConsentsGiven, cvBirthdate, cvProgress]);

  useEffect(() => {
    if (activeTab?.page === 11) {
      setLoading(true);
      getData({ url: "candidates/resumes/S1", key: "resumeS1" })
        .then((data) => {
          setCvProgress(data?.progress);
          setCvBirthdate(data?.detail?.birthDate ?? candidatesMe?.birthDate);
          setLoading(false);
        })
        .catch((err) => {
          message.error("Hata Oluştu.");
          setLoading(false);
        });
    }
  }, [activeTab, candidatesMe?.birthDate]);

  useEffect(() => {
    setLoading(true);
    new Api()
      .getData({
        url: changeUrl(`candidates/me`),
      })
      .then((res) => {
        if (res?.code === 200) {
          setFreePassKvkkConsent({
            kvkkExplicitConsent: res?.result?.kvkkExplicitConsent,
            kvkkClarificationConsent: res?.result?.kvkkClarificationConsent,
            kvkkEmailConsent: res?.result?.kvkkEmailConsent,
            kvkkSmsConsent: res?.result?.kvkkSmsConsent,
          });
        } else {
          message.error(res?.message);
        }
      })
      .catch((err) => message.error("Hata Oluştu"))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    new Api()
      .getData({ url: changeUrl(`candidate/free-pass`) })
      .then((res) => {
        if (res?.code === 200) {
          if (res?.result?.results?.length > 0) {
            setMyUploadedDocuments(res?.result?.results);
          }
          setLoading(false);
        }
      })
      .catch((err) => message.error("Hata Oluştu."));
  }, []);

  const handleChangeDocumentFile = (e) => {
    const file = e.target.files[0];
    if (
      file.type === "application/pdf" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "image/jpeg"
    ) {
      setDocumentToUpload(file);
    } else {
      message.error("Geçersiz dosya formatı");
    }
  };

  const handleCancelDocumentUpload = () => {
    setDocumentToUpload("");
    setFileInputKey((prev) => prev + 1);
  };

  const handleClickUploadFile = () => {
    if (allConsentsGiven && documentToUpload) {
      const formData = new FormData();
      formData.append("file", documentToUpload);
      setLoading(true);
      new Api()
        .postData({
          url: changeUrl(`candidate/free-pass`),
          _data: formData,
        })
        .then((res) => {
          if (res.code === 200) {
            message.success("Sosyal Güvenlik Belgesi Eklendi.");
            setDocumentToUpload("");
            new Api()
              .getData({ url: changeUrl(`candidate/free-pass`) })
              .then((res) => {
                if (res.code === 200) {
                  if (res?.result?.results?.length > 0) {
                    setMyUploadedDocuments(res?.result?.results);
                  }
                  setLoading(false);
                }
              })
              .catch((err) => message.error("Hata Oluştu."));
          } else {
            message.error(res.message);
            setLoading(false);
            setDocumentToUpload("");
          }
        })
        .catch((err) => {
          setLoading(false);
          setDocumentToUpload("");
          message.error("Yükleme Sırasında Hata Oluştu.");
        });
    }
  };

  const handleDownloadFile = async (uuid, fileName) => {
    if (uuid) {
      message.loading("Dosya İndiriliyor...");
      const file = await new Api().getFile({
        url: `candidate/free-pass/download/${uuid}`,
      });
      fileDownload(file.data, fileName);
      message.success("Dosya İndirildi.");
    }
  };

  const handleNewTabFile = async (uuid) => {
    if (uuid) {
      message.loading("Dosya Yeni Sekmede Açılıyor...");
      try {
        const response = await new Api().getFile({
          url: `candidate/free-pass/download/${uuid}`,
          responseType: "blob",
        });

        displayBlobInNewTab(response.data);
      } catch (error) {
        console.error("Dosya alınırken bir hata oluştu:", error);
      }
    }
  };

  const displayBlobInNewTab = (blob) => {
    const url = URL.createObjectURL(blob);
    const newTab = window.open(url, "_blank");

    if (newTab) {
      newTab.onload = () => {
        URL.revokeObjectURL(url);
        message.success("Belge Görüntülendi.");
      };
    } else {
      message.error("Yeni sekme açılamadı.");
    }
  };

  const handleDeleteFile = (uuid) => {
    if (uuid) {
      setLoading(true);
      new Api()
        .deleteData({
          url: changeUrl(`candidate/free-pass/${uuid}`),
        })
        .then((res) => {
          if (res?.code === 200) {
            message.success("Dosya Silindi.");
            new Api()
              .getData({ url: changeUrl(`candidate/free-pass`) })
              .then((res) => {
                if (res.code === 200) {
                  if (res?.result?.results?.length > 0) {
                    setMyUploadedDocuments(res?.result?.results);
                  } else {
                    setMyUploadedDocuments([]);
                  }
                  setLoading(false);
                }
              })
              .catch((err) => message.error("Hata Oluştu."));
          } else {
            message.error(res?.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          setLoading(false);
          message.error("Dosya Silinirken Hata Oluştu.");
        });
    }
  };

  const handleChangeKvkkConsent = (kvkkConsent) => {
    if (kvkkConsent) {
      setLoading(true);
      new Api()
        .postData({
          url: changeUrl(`auth/consent`),
          _data: kvkkConsent,
        })
        .then((res) => {
          if (res?.code === 200) {
            message.success("Kvkk İzinleri Değiştirildi.");
            setLoading(true);
            new Api()
              .getData({
                url: changeUrl(`candidates/me`),
              })
              .then((res) => {
                if (res?.code) {
                  setFreePassKvkkConsent({
                    kvkkExplicitConsent: res?.result?.kvkkExplicitConsent,
                    kvkkClarificationConsent:
                      res?.result?.kvkkClarificationConsent,
                    kvkkEmailConsent: res?.result?.kvkkEmailConsent,
                    kvkkSmsConsent: res?.result?.kvkkSmsConsent,
                  });
                } else {
                  message.error(res?.message);
                }
              })
              .catch((err) => err && message.error("Hata Oluştu."))
              .finally(() => setLoading(false));
          } else {
            message.error(res?.message);
          }
        })
        .catch((err) => err && message.error("Hata Oluştu."))
        .finally(() => setLoading(false));
    }
  };

  if (loading) {
    return <Spin />;
  }

  console.log("cvProgress", cvProgress);
  console.log("cvProgressType", typeof cvProgress);
  console.log("cvBirthdate", cvBirthdate);
  console.log("cvBirthdateType", typeof cvBirthdate);

  return (
    <>
      <div className="full-width">
        <Spin spinning={loading}>
          <h1 className="fw-bold ls-full text-4xl text-black mb-30">
            İBB Ulaşım Desteği
          </h1>

          {!loading && myUploadedDocuments?.length === 0 && cvProgress < 70 && (
            <div className="free_pass_alert warning">
              Ulaşım desteği için Cv'nizin %70 dolu olması gereklidir.
            </div>
          )}
          {!loading &&
            myUploadedDocuments?.length === 0 &&
            (calculateAgeFromDate(cvBirthdate) < 15 ||
              calculateAgeFromDate(cvBirthdate) > 65) && (
              <div className="free_pass_alert warning">
                <span className="fw-bold">
                  {calculateAgeFromDate(cvBirthdate)}&nbsp;yaşındasınız.
                </span>
                &nbsp;Ulaşım desteği için yaşınız 16 ile 65 yaşları arasında
                olması gerekmektedir.
              </div>
            )}
          {!loading &&
            cvProgress >= 70 &&
            calculateAgeFromDate(cvBirthdate) > 15 &&
            calculateAgeFromDate(cvBirthdate) < 65 &&
            myUploadedDocuments?.length === 0 && (
              <div className="free_pass_alert info">
                <ul>
                  <li
                    style={{ color: `${cvProgress >= 70 ? "green" : "red"}` }}
                  >
                    Cv'nizin %70 dolu olması gerekmektedir.
                    {cvProgress >= 70 ? <CheckOutlined /> : <CloseOutlined />}
                  </li>
                  <li
                    style={{ color: `${allConsentsGiven ? "green" : "red"}` }}
                  >
                    <span
                      onClick={() => setKvkkModal(true)}
                      style={{
                        color: "#ed4a23",
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      Kvkk İzinleri
                    </span>
                    'ni Onaylayınız&nbsp;
                    {allConsentsGiven ? (
                      <CheckOutlined style={{ color: "green" }} />
                    ) : (
                      <CloseOutlined style={{ color: "red" }} />
                    )}
                  </li>
                  <li
                    style={{
                      color: `${
                        allConsentsGiven && documentToUpload ? "green" : "red"
                      }`,
                    }}
                  >
                    Sosyal Güvenlik Kayıt Belgesi Yükleyin&nbsp;
                    {allConsentsGiven && documentToUpload ? (
                      <CheckOutlined />
                    ) : (
                      <CloseOutlined />
                    )}
                  </li>
                </ul>
              </div>
            )}
          {!loading &&
            myUploadedDocuments?.length > 0 &&
            myUploadedDocuments?.find(
              (el) => el?.unemploymentDocumentStatus === "Onayda"
            ) && (
              <div className="free_pass_alert info">
                <ul>
                  <li style={{ color: "#333" }}>
                    <a
                      href="https://bio.ibb.istanbul/offices"
                      target="_blank"
                      style={{ textDecoration: "underline" }}
                    >
                      En Yakın Bölgesel İstihdam Ofisine
                    </a>{" "}
                    giderek belgenizi onaylatmanız gerekmektedir.
                  </li>
                </ul>
              </div>
            )}
          {!loading &&
            cvProgress >= 70 &&
            calculateAgeFromDate(cvBirthdate) > 15 &&
            calculateAgeFromDate(cvBirthdate) < 65 &&
            allConsentsGiven && (
              <>
                <label
                  className="free_pass_file_uploader"
                  htmlFor="free_pass_file_uploader"
                >
                  <input
                    key={fileInputKey}
                    type="file"
                    accept="*"
                    id="free_pass_file_uploader"
                    onChange={handleChangeDocumentFile}
                  />
                  <InboxOutlined />
                  <span className="file_uploader_text">
                    Dosya yüklemek için bu alana tıklayın.
                  </span>
                </label>
                <h2 className="illumination_title">
                  *Dosya Yükleyerek Kişisel Verilerin İşlenmesi Hakkında{" "}
                  <a
                    href={IlluminationText}
                    target="_blank"
                    style={{ color: "#E95B29" }}
                  >
                    Aydınlatma Metni
                  </a>
                  ’ni okuduğunuzu beyan ediyorsunuz.
                </h2>
              </>
            )}

          {allConsentsGiven && documentToUpload && (
            <Row
              className="full-width"
              style={{ marginTop: "20px", marginBottom: "20px" }}
            >
              <Col
                xxl={{ span: 20 }}
                xl={{ span: 20 }}
                lg={{ span: 20 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <div className="uploaded_file">
                  <img
                    src={
                      documentToUpload.type === "application/pdf"
                        ? PdfIcon
                        : ImgIcon
                    }
                    alt=""
                  />
                  <div className="uploaded_file_info">
                    <h5 className="uploaded_file_name">
                      {documentToUpload?.name}
                    </h5>
                    <p className="uploaded_file_size">
                      {convertBytes(documentToUpload?.size)}
                    </p>
                  </div>
                  <button
                    className="uploaded_file_cancel"
                    onClick={handleCancelDocumentUpload}
                  >
                    <CloseOutlined />
                  </button>
                </div>
              </Col>
              <Col
                xxl={{ span: 4 }}
                xl={{ span: 4 }}
                lg={{ span: 4 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                xs={{ span: 24 }}
              >
                <button className="upload_btn" onClick={handleClickUploadFile}>
                  Dosyayı Yükle
                </button>
              </Col>
            </Row>
          )}
          {myUploadedDocuments?.length > 0 && (
            <div>
              <h3 className="fw-bold ls-full text-3xl text-black mb-20">
                Sosyal Güvenlik Kayıt Belgelerim
              </h3>
              {myUploadedDocuments?.map((item, key) => (
                <Row className="uploaded_file_row" key={key}>
                  <Col lg={{ span: 16 }} className="uploaded_file">
                    <img
                      src={item.extension === "pdf" ? PdfIcon : ImgIcon}
                      alt=""
                    />
                    <div className="uploaded_file_info">
                      <h5 className="uploaded_file_name">
                        {item?.originalName}
                      </h5>
                      <p className="uploaded_file_size">
                        {convertCreatedAt(item?.createdAt)}
                      </p>
                    </div>
                    <div
                      className={`upload_badge ${
                        confirmStatus[item?.unemploymentDocumentStatus]
                      }`}
                    >
                      {item?.unemploymentDocumentStatus}
                    </div>
                  </Col>
                  <Col lg={{ span: 8 }} className="uploaded_file">
                    <button
                      className="uploaded_file_btn"
                      style={{ background: "green" }}
                      onClick={() => handleNewTabFile(item?.uuid)}
                    >
                      <EyeOutlined />
                    </button>
                    <button
                      className="uploaded_file_btn"
                      onClick={() =>
                        handleDownloadFile(item?.uuid, item?.originalName)
                      }
                    >
                      <DownloadOutlined />
                    </button>
                    <Popconfirm
                      title="Belgeyi silmek istediğinize emin misiniz?"
                      onConfirm={() => handleDeleteFile(item?.uuid)}
                      okText="Sil"
                      cancelText="İptal"
                    >
                      {item?.unemploymentDocumentStatus !== "Onaylandı" && (
                        <button className="uploaded_file_btn uploaded_file_cancel">
                          <CloseOutlined />
                        </button>
                      )}
                    </Popconfirm>
                  </Col>
                </Row>
              ))}
            </div>
          )}
        </Spin>
      </div>
      {kvkkModal && (
        <KvkkModal
          kvkkModal={kvkkModal}
          setKvkkModal={setKvkkModal}
          freePassKvkkConsent={freePassKvkkConsent}
          setFreePassKvkkConsent={setFreePassKvkkConsent}
          setDocumentToUpload={setDocumentToUpload}
          handleChangeKvkkConsent={handleChangeKvkkConsent}
        />
      )}
    </>
  );
};

export default FreePass;

const calculateAgeFromDate = (birthDateString) => {
  const birthDateObj = new Date(birthDateString);
  const today = new Date();

  let age = today.getFullYear() - birthDateObj.getFullYear();
  const monthDiff = today.getMonth() - birthDateObj.getMonth();

  // Check if the current month is before the birth month or
  // if it's the same month but the current day is before the birth day
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDateObj.getDate())
  ) {
    age--;
  }

  return age;
};

const convertBytes = (bytes) => {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
};

const convertCreatedAt = (createdAt) => {
  if (createdAt) {
    const day = createdAt.split("T")[0].split("-")[2];
    const month = createdAt.split("T")[0].split("-")[1];
    const year = createdAt.split("T")[0].split("-")[0];

    return `${day}-${month}-${year}`;
  }
};
