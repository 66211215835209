import { useState } from "react";
import { Col, Row } from "antd";
import "./style.css";
// import CookiePolicy from "../../../../assets/policy/Bio.ibb.istanbul Çerez Politikası ve Çerez Aydınlatma Metni.pdf";
import CookiePolicy from "../../../../assets/policy/Bio.ibb.istanbul Çerez Politikası ve Çerez Aydınlatma Metni_yeni.pdf";
import CookieManagement from "./CookieManagement";

const Cookie = () => {
  const [open, setOpen] = useState(false);
  const acceptAll = () => {
    localStorage.setItem("cookieAllowed", true);
    localStorage.removeItem("cookieRejected");
    window.location.reload();
  };
  const rejectAll = () => {
    localStorage.setItem("cookieRejected", true);
    localStorage.removeItem("cookieAllowed");
    window.location.reload();
  };
  return (
    <>
      <div className="cookie_wrapper">
        <div className="cookie_container">
          <Row>
            <Col lg={{ span: 14 }} md={{ span: 24 }}>
              <p className="cookie_text">
                İnternet sitemizde analitik faaliyetleri yürütebilmek için
                çerezler kullanıyoruz. Kesinlikle Gerekli Çerezler dışındaki
                çerezlerin kullanımına ve bu çerezler aracılığıyla elde edilen
                kişisel verilerinizin yurt dışına aktarılmasına onay vermek için
                “Tümünü Kabul Et” butonuna; çerezler aracılığıyla elde edilen
                kişisel verilerinizin işlenmesine ilişkin tercihlerinizi
                yönetmek için ise “Çerezleri Yönet” butonuna tıklayabilirsiniz.
                Çerezler ile kişisel verilerinizin işlenmesine dair detaylı
                bilgi almak için{" "}
                <a
                  target="_blank"
                  style={{ textDecoration: "underline" }}
                  href={CookiePolicy}
                  rel="noreferrer"
                >
                  Çerez Politikası Ve Aydınlanma Metni
                </a>
                'ni ziyaret edebilirsiniz.
              </p>
            </Col>
            <Col lg={{ span: 10 }} md={{ span: 24 }}>
              <div className="btn_group">
                <button className="cookie_btn" onClick={acceptAll}>
                  Tümünü Kabul Et
                </button>
                <button className="cookie_btn" onClick={rejectAll}>
                  Tümünü Reddet
                </button>
                <button className="cookie_btn" onClick={() => setOpen(true)}>
                  Çerezleri Yönet
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      {open && <CookieManagement open={open} setOpen={setOpen} />}
    </>
  );
};

export default Cookie;
