import axios from 'axios';
import qs from 'qs'
import { notification } from "antd";
import config from '../plugins/ibb/config'

class Api {
  constructor() {
    this.axios = axios.create({
      baseURL: config.BASE_API_URL,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'comma' });
      },
    });
  }

  async getData({ url, params }) {
    try {
      const { data } = await this.axios.get(url, { params });
      return data;
    } catch (error) {
      throw error.response;
    }
  }

  async getFile({ url, params }) {
    try {
      const res = await this.axios.get(url, { params, responseType: 'blob' });
      return res;
    } catch (error) {
      throw error.response;
    }
  }

  async postMultipart({ url, formData }) {
    try {
      const { data } = await this.axios.post(url, formData, {
        timeout: 60000,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return data;
    } catch (error) {
      notification.error({ message: 'İstek gerçekleşirken bir hata oluştu. Lütfen daha sonra tekrar deneyin' })
      throw error.response;
    }
  }

  async postData({ url, _data, params }) {
    try {
      const { data } = await this.axios.post(url, _data, { params });
      return data;
    } catch (error) {
      throw error.response;
    }
  }

  async putData({ url, _data, params }) {
    try {
      const { data } = await this.axios.put(url, _data, { params });
      return data;
    } catch (error) {
      throw error.response;
    }
  }

  async deleteData({ url, id, params }) {
    try {
      const { data } = await this.axios.delete(`${url}`, { params });
      return data;
    } catch (error) {
      throw error.response;
    }
  }
}

export default Api;
