import { get, isEmpty } from 'lodash';
import { Row, Col } from 'antd';

const Experience = ({ data }) => {
  return (
    <>
      <p className="text-xl fw-bold ls-half mb-10">DENEYİM BİLGİLERİ:</p>
      { !isEmpty(data) &&
        data.map(x => (
          <Row className="EducationInfo full-width mb-20">
            <Col span={9}>
              <p className="text-xl fw-semibold ls-half mb-0">{get(x, 'startDate', '-')} - {(data && !(data.onGoing)) ? get(x, 'endDate', '-') : 'Devam Ediyor'}</p>
              <p className="text-xl fw-semibold ls-half mb-0">({get(x, 'time', '-')})</p>
            </Col>
            <Col span={11}>
              <p className="text-xl fw-bold ls-half mb-0">{get(x, 'companyTitle', '-')}</p>
              <p className="text-xl fw-bold ls-half mb-0">{get(x, 'description', '-')}</p>
            </Col>
            <Col span={4}>
              <p className="text-xl fw-bold ls-half mb-0">{get(x, 'location', '-')}</p>
            </Col>
          </Row>
        ))
      }
    </>
  );
};

export default Experience;
