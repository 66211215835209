import { Modal } from "antd";
import { get } from "lodash";
import ReactQuill from "react-quill";

const CoverLetter = ({ candidate, visible, setVisible }) => {
  return (
    <Modal className="CoverLetterModal" open={visible} width={720} onCancel={() => setVisible(false)} footer={null} closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri" />}>
      <h1 className="text-blue text-4xl fw-bold mb-10">{get(candidate, 'firstName')} {get(candidate, 'lastName')}</h1>
      <p className="text-red-pink text-4xl fw-semibold">Adayın Önyazısı</p>
      <ReactQuill value={get(candidate, 'coverLetter')} modules={{ toolbar: false }} className="CoverLetterModal" rows={20} />
    </Modal>
  );
};

export default CoverLetter;
