import { Collapse, Spin } from 'antd';
import Banner from "components/UIComponents/Banner"
import { Icon } from 'components/UIComponents';
import useGet from 'hooks/useGet';
import {Helmet} from "react-helmet";

export default () => {
  const { data, loading } = useGet({ url: '/public/faq', key: 'faq' });

  return (
    <div className="Faq">
      <Helmet>
        <title>İş Ara İş Bul | Sıkça Sorulan Sorular</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Sıkça Sorulan Sorular" className="mb-0" />
      <div className="content md content-detail pl-100 pr-100 pb-80 pt-80">
        <Spin spinning={loading} size="large">
          <Collapse accordion expandIconPosition="right" expandIcon={() => <Icon name="arrow-down" size={32} style={{marginTop:'13px'}} />}>
            {data && data.map((x, i) => (
              <Collapse.Panel key={i} header={x.question}>
                {x.answer}
              </Collapse.Panel>
            ))}
          </Collapse>
        </Spin>
      </div>
    </div>
  );
}