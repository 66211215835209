import React, { useEffect, useState } from "react";
import { Button, Row, Col, Divider } from "antd";
import UserLayout from "../../userLayout";
import useGetNew from "../../../../../hooks/useGetNew";
import { confirmMessage } from "../../../../../helpers/methods";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement,
} from "chart.js";
import { Pie, Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyData } from "redux/actions/companyActions";

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  RadialLinearScale,
  CategoryScale,
  LinearScale,
  BarElement
);

export default () => {
  const dispatch = useDispatch();
  const [existData, setExistData]= useState(false); 
  useEffect(() => {
      dispatch(loadCompanyData());
  },[])

  const me = useSelector(state => state.data.companiesMe);

  const report = useGetNew({ url: "/companies/report" });
  const activeAdvertisment =   report?.data?.result?.activeAdvertisment;
  const passiveAdvertisment = report?.data?.result?.passiveAdvertisment;
  const totalAds = activeAdvertisment +  passiveAdvertisment;
 
  useEffect(()=>{  
   if (activeAdvertisment > 0 || passiveAdvertisment > 0) {
       setExistData(true)
   } 
   
  },[report]); 
  
  var databar = {
    labels: ["Aktif İlan", "Pasif İLan", "Toplam İlan"],
    datasets: [
      {
        label: "Aktif İlan",
        data: [
           activeAdvertisment,  passiveAdvertisment, totalAds,
        ],
        backgroundColor: ["rgb(91,58,41)", "rgb(255,165,0)", "rgb(139,0,0)"],
        hoverOffset: 4,
      },
    ],
  };

  var pieChartApplicationByAgeData = {
    labels: report?.data?.result?.applicationByAge.map((data) => data.label),
    datasets: [
      {
        label: "# of Votes",
        data: report?.data?.result?.applicationByAge.map((data) => data.value),
        backgroundColor: [
          "rgba(101, 49, 135, 0.7)",
          "rgba(54, 162, 235, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 159, 64, 0.7)",
        ],
        borderColor: [
          "rgba(101, 49, 135, 1)",
          "rgba(54, 162, 235, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var pieChartApplicationByCountyData = {
    labels: report?.data?.result?.applicationByCounty.map((data) => data.label),

    datasets: [
      {
        label: "# of Votes",
        data: report?.data?.result?.applicationByCounty.map(
          (data) => data.value
        ),
        backgroundColor: [
          "rgba(101, 49, 135, 0.7)",
          "rgba(233,91,41, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(122, 159, 64, 0.7)",
          "rgba(169, 122, 64, 0.7)",
        ],
        borderColor: [
          "rgba(101, 49, 135, 1)",
          "rgba(233,91,41, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(122, 159, 64, 1)",
          "rgba(169, 122, 64, 1)",
        ],
        borderWidth: 1,
      },
    ],
  };

  var pieChartApplicationByGenderData = {
    labels: report?.data?.result?.applicationByGender.map((data) => data.label),

    datasets: [
      {
        label: "# of Votes",
        data: report?.data?.result?.applicationByGender.map(
          (data) => data.value
        ),
        backgroundColor: [
          "rgba(54, 162, 235, 0.7)",
          "rgba(101, 49, 135, 0.7)",
          "rgba(255, 206, 86, 0.7)",
          "rgba(75, 192, 192, 0.7)",
          "rgba(153, 102, 255, 0.7)",
          "rgba(255, 159, 64, 0.7)",
        ],
        borderColor: [
          "rgba(54, 162, 235, 1)",
          "rgba(101, 49, 135, 1)",
          "rgba(255, 206, 86, 1)",
          "rgba(75, 192, 192, 1)",
          "rgba(153, 102, 255, 1)",
          "rgba(255, 159, 64, 1)",
        ],
        borderWidth: 1,
        hoverOffset: 4,
      },
    ],
  };

  var options = {
    maintainAspectRatio: true,
    scales: {},
    legend: {
      labels: {
        fontSize: 25,
      },
    },
  };

  return me?.data && me.data.code !== 200 ? (
    confirmMessage(me?.data.message)
  ) : (
    <UserLayout
      wrapperClass="container"
      title="Firma Raporları"
      companyName={me?.data?.result.companyName}
      bannerText="Firma Raporları"
      cityName={me?.data?.result?.county?.city}
      countyName={me?.data?.result?.county?.county}
      company={true}
      style={{ paddingTop: "35px", paddingLeft: "25px" }}
    >
      <Divider style={{ paddingTop: "10px" }} /> 
       {!existData ? (<Row>
          <Col>
            <h1>İlanlarınıza Ait Herhangi Bir Rapor Yok.</h1>      
          </Col>
        </Row>) :

        ( <Row align="center">
        <Col  
          lg={12}
          md={24}
          xs={24}
          style={ {display: existData ? '' : 'none', paddingTop: "35px", paddingLeft: "25px" }}
        >
          <h1 class="ant-typography auth-form-title">İlan Sayısı</h1>

          <h2 style={{ paddingTop: "50px" }}>
            Aktif İlan Sayısı : {report?.data?.result?.activeAdvertisment}
          </h2>
          <h2>
            Pasif İlan Sayısı : {report?.data?.result?.passiveAdvertisment}
          </h2>
          <h2>
            Toplam İlan Sayısı :{" "}
            {report?.data?.result?.activeAdvertisment +
              report?.data?.result?.passiveAdvertisment}
          </h2>
          <Bar data={databar} options={options} />
        </Col>

        <Col
          lg={12}
          md={24}
          xs={24}
          style={{display: existData ? '' : 'none', paddingTop: "35px", paddingLeft: "25px" }}
          gab
        >
          <h1 class="ant-typography auth-form-title">Yaş Grubu</h1>
          <Pie data={pieChartApplicationByAgeData} options={options} />
        </Col>
        <Divider style={{ paddingTop: "10px" }} />
        <Col lg={12} md={24} xs={24} style={{display: existData ? '' : 'none', paddingTop: "35px" }}>
          <h1
            class="ant-typography auth-form-title"
            style={{ padding: "25px", paddingBottom: "25px" }}
          >
            İlçe
          </h1>
          <Pie data={pieChartApplicationByCountyData} options={options} />
        </Col>

        <Col
          lg={12}
          md={24}
          xs={24}
          style={{display: existData ? '' : 'none', paddingTop: "35px", paddingBottom: "25px" }}
        >
          <h1
            class="ant-typography auth-form-title"
            style={{ padding: "25px" }}
          >
            Cinsiyet
          </h1>
          <Pie data={pieChartApplicationByGenderData} options={options} />
        </Col>
      </Row>)
       }
      
    </UserLayout>
  );
};
