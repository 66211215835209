import React, { useEffect, useState } from "react";
import { Button, Form, message, Spin } from "antd";
import { Icon } from "components/UIComponents";
import Row from "./Row";
import { useSelector } from "react-redux";
import Api from "../../../../../../../api/Api";
import { getData } from "../../../../../../../redux/services/request";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [foreignLanguages, setForeignLanguages] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS7 ? state.data?.resumeS7.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S7", key: "resumeS7" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail && resume.detail.languages) {
      const _foreignLanguages = [];
      const formValues = {};
      resume.detail.languages.forEach((item) => {
        const id = Math.random();
        const values = {
          id: id,
          languageId: item.languageId.languageId,
          reading: item.reading ? parseInt(item.reading.substr(1, 1)) + 1 : 1,
          writing: item.writing ? parseInt(item.writing.substr(1, 1)) + 1 : 1,
          speaking: item.speaking
            ? parseInt(item.speaking.substr(1, 1)) + 1
            : 1,
          translation: item.translation
            ? parseInt(item.translation.substr(1, 1)) + 1
            : 1,
        };
        formValues[id] = values;
        _foreignLanguages.push(values);
      });

      setForeignLanguages(_foreignLanguages);
      form.setFieldsValue(formValues);
    }
  }, [resume, form]);

  const add = () => {
    const language = {
      id: Math.random(),
      reading: 1,
      writing: 1,
      speaking: 1,
      translation: 1,
    };
    setForeignLanguages([...foreignLanguages, language]);
  };

  const remove = (index) => {
    const languages = [...foreignLanguages];
    languages.splice(index, 1);
    setForeignLanguages(languages);
  };

  const onFinish = () => {
    setLoading(true);

    const request = Object.values(form.getFieldsValue());
    request.forEach((item) => {
      item.reading = item.reading && "L" + (item.reading - 1);
      item.writing = item.reading && "L" + (item.writing - 1);
      item.speaking = item.reading && "L" + (item.speaking - 1);
      item.translation = item.reading && "L" + (item.translation - 1);
    });

    new Api()
      .putData({
        url: "/candidates/resumes/languages",
        _data: { languages: request },
      })
      .then(() => {
        message.success("Dil bilgileri güncellendi");
        setPageLoading(true);
        getData({ url: "candidates/resumes/S7", key: "resumeS7" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            setPageLoading(false);
          });
        refresh();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch(() => {
        message.error("Dil bilgileri güncellenirken bir hata meydana geldi");
        setLoading(false);
      });
  };

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  return (
    <div className="ForeignLanguage">
      <Spin spinning={pageLoading}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <h1 className="text-md fw-bold ls-full mb-20 mt-30">YABANCI DİL</h1>
          <div className="p-20">
            {foreignLanguages.map((x, i) => (
              <Form.List name={x.id}>
                {(fields) => (
                  <Row
                    item={x}
                    key={x.id}
                    remove={remove}
                    index={i}
                    foreignLanguages={foreignLanguages}
                  />
                )}
              </Form.List>
            ))}
            <Button
              className="btn btn-pink-filled radius-lg p-20 text-md"
              type="primary"
              icon={<Icon name="plus" size={26} className="mr-10" />}
              onClick={() => add()}
            >
              Ekle
            </Button>
          </div>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};
