import { useState } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import { Row, Col, Button, Divider, message } from 'antd';
import moment from 'moment';
import { postData, putData } from 'redux/services/request';
import {EyeOutlined} from '@ant-design/icons';

const Job = ({ job, refreshFavorites }) => {
  const [applied, setApplied] = useState(get(job, 'applied'));
  const [favorite, setFavorite] = useState(get(job, 'favorite'));
  const [loadingApply, setLoadingApply] = useState(false);

  const favoriteOperation = async () => {
    try {
      await putData({ url: `/jobs/${job.id}/favorites/${job.favorite ? 'unmark' : 'mark'}` });
      setFavorite(!favorite);
      refreshFavorites && refreshFavorites();
    } catch (e) {
      message.error('İş ilanı favori işlemlerinde bir hata meydana geldi');
    }
  }

  const apply = async () => {
    try {
      const data = { jobId: job.id };
      setLoadingApply(true);
      const res = await postData({ url: `/applications`, data });
      if (res && res.code && res.code === 190) {
        throw new Error(res);
      } else {
        message.success('İş ilanına başvuruldu');
        setApplied(!applied);
        setLoadingApply(false);
      }
    } catch (e) {
      message.error(e.data.message);
      setLoadingApply(false);
    }
  }

  return (
    <Row className="Post pl-35" justify="space-between">
      <img src={`/static/icons/${favorite ? 'star-filled' : 'star'}.svg`} className="Star" alt="add-favorite" style={{ width: 30 }} onClick={favoriteOperation} />
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }} className="pl-35">
        <h1 className="Title mb-50">{get(job, 'positionTitle')}</h1>
        <p className="text-md">{get(job, 'companyName')}</p>
        <p className="Location d-flex a-center mb-0">
          <img src="/static/icons/pin-pink.svg" className="mr-10" alt="Bölgesel İstihdam Ofisleri" />
          <span className="text-lg text-black">{get(job, 'county')} - {get(job, 'city')}</span>
        </p>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} className="text-right fd-column">
        <div className="Actions mb-10">
          {
            localStorage.getItem('type') !== 'CORPORATE' &&
            <>
              {get(job, 'applied') ?
                <Button type="default" className="BtnAction disabled" disabled>Başvuruldu</Button>
                :
                <Button type="default" className="BtnAction" loading={loadingApply} onClick={apply}>Başvur</Button>
              }
            </>
          }
          <Link to={`/jobs/detail/${get(job, 'id')}`}>
            <Button type="default" className="BtnAction">Görüntüle</Button>
          </Link>
        </div>
        <div className="JobInfo">
          <div className="Id">İlan No: {get(job, 'advertisementNo')}</div>
          <div className="Hashtag mb-30">{get(job, 'sector.sector')}</div>
          <div className="text-md fw-bold text-red-pink d-flex a-center j-end mb-20">
            <img src="/static/icons/multiple-user.svg" alt="Bölgesel İstihdam Ofisleri" className="mr-5" />
            <span className="mr-10">{get(job, 'applicationCount')}</span>
            <EyeOutlined className="mr-5"/>
            <span>{get(job, 'applicationCount')}</span>
          </div>
          <div className="text-md ls-half mb-5">Yayınlanma Tarihi: {moment(get(job, 'publishedAt')).format('DD.MM.YYYY')}</div>
          <div className="text-md fw-bold ls-half">Son Başvuru Tarihi: {moment(get(job, 'lastApplicationDate')).format('DD.MM.YYYY')}</div>
        </div>
      </Col>
      <Divider className="Divider grey" />
    </Row>
  );
}

export default Job;
