import { Row, Col, Divider, Button, message } from 'antd';
import { advertisementStatuses } from 'helpers/constants';
import { get } from 'lodash';
import moment from 'moment';
import { useState } from 'react';
import { withRouter } from 'react-router-dom';
import { putData } from 'redux/services/request';

const ListItem = ({ job, history, refresh }) => {

  const [loadingPassive, setLoadingPassive] = useState(false);

  const copyLink = () => {
    navigator.clipboard.writeText(`${window.location.origin}/jobs/detail/${job.id}`).then(() => {
      message.success('İlan linki kopyalandı');
    });
  }

  const makePassive = async () => {
    setLoadingPassive(true);
    try {
      await putData({ url: `/jobs/${job.id}/passive` });
      setLoadingPassive(false);
      refresh && refresh();
    } catch (err) {
      message.error('İş ilanı pasife çekilirken bir hata meydana geldi');
      setLoadingPassive(false);
    }
  }

  return (
    <Row className="JobListItem">
      <Row className="full-width">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }} className="JobStatus pl-30">
          <span className="fw-bold text-md mr-20 d-iflex">{get(job, 'advertisementNo', '-')}</span>
          {job && job.status && job.status.id &&
            <span className={`fw-bold text-md text-uppercase ${advertisementStatuses[get(job, 'status.id')].color}`}>
              {advertisementStatuses[get(job, 'status.id')].text}
            </span>
          }
        </Col>
        <Col xs={{ offset: 24 }} sm={{ offset: 24 }} md={{ offset: 6 }}></Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
          <Divider className="Divider grey mt-10" />
        </Col>
      </Row>
      <Row className="full-width">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 7 }} className="pl-30 pr-10">
          <p className="fw-bold text-md ls-half mb-5">POZİSYON</p>
          <p className="text-md ls-half mb-30">{get(job, 'positionTitle', '-')}</p>
          <p className="text-md ls-half fw-bold mb-5">YÖNLENDİRİLEN ADAY SAYISI</p>
          <p className="text-md ls-half mb-5">{get(job, 'directedCandidateCount', '-')}</p>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4, offset: 1 }}>
          <p className="fw-bold text-md ls-half mb-5">İLÇE</p>
          <p className="text-md ls-half mb-30 full-width">{get(job, 'counties', []).join(', ')}</p>
          <p className="text-md ls-half fw-bold mb-5">İLAN TARİHİ</p>
          <p className="text-md ls-half mb-5">{moment(get(job, 'createdAt', '-')).format('DD.MM.YYYY')}</p>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4, offset: 1 }}>
          <p className="fw-bold text-md ls-half mb-5">BAŞVURU SAYISI</p>
          <p className="text-md ls-half mb-50 full-width">{get(job, 'applicationCount', '-')}</p>
          <p className="text-md ls-half fw-bold mb-5">İSTİHDAM OFİSİ</p>
          <p className="text-md ls-half mb-5">{(get(job, 'offices', []) || []).join(', ')}</p>
        </Col>
        <Col className="Actions" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5, offset: 2 }}>
          <Button className="btn-account-operation blue" onClick={() => history.push(`/jobs/detail/${job.id}`)}>Ön İzleme</Button>
          <Button className="btn-account-operation blue" onClick={copyLink}>Linki Kopyala</Button>
          {
            get(job, 'status.id') === 'PASSIVE' ?
              <Button className="btn-account-operation blue" disabled>İlanı Düzenle</Button>
              :
              <Button className="btn-account-operation blue" onClick={() => history.push(`/account/employer/jobs/edit/${get(job, 'id')}`)} disabled={get(job, 'status.id') === 'ACTIVE'}>İlanı Düzenle</Button>
          }
          <Button className={`btn-account-operation blue ${get(job, 'status.id') === 'WAITING' ? 'disabled' : ''}`} onClick={() => history.push(`/account/employer/jobs/${get(job, 'id')}/suitable-candidates/list`)} disabled={get(job, 'status.id') === 'WAITING'}>Uygun Aday Listesi</Button>
          <Button className={`btn-account-operation blue ${get(job, 'status.id') === 'WAITING' ? 'disabled' : ''}`} onClick={() => history.push(`/account/employer/jobs/${get(job, 'id')}/application-pool`)} disabled={get(job, 'status.id') === 'WAITING'}>İlan Başvuru Havuzu</Button>
          {
            get(job, 'status.id') === 'PASSIVE' ?
              <Button type="success" className="btn-account-operation mb-0" disabled>Yayından kaldırıldı</Button>
              :
              <Button type="danger" className="btn-account-operation red mb-0" onClick={makePassive} loading={loadingPassive}>Yayından Kaldır</Button>
          }
        </Col>
      </Row>
    </Row>
  );
};

export default withRouter(ListItem);
