import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Select, Pagination, Divider, Spin, Empty, message } from 'antd';
import Banner from 'components/UIComponents/Banner';
import ListItem from './ListItem';
import useGet from 'hooks/useGet';
import { get } from 'lodash';
import Filter from './Filter';
import { putData } from 'redux/services/request';
import {Helmet} from "react-helmet";

const SuitableCandidates = () => {
  const [id] = useState(useParams().id);
  const [checks, setChecks] = useState([]);
  const [operation, setOperation] = useState(null);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState(null);
  const [loadingIneligible, setLoadingIneligible] = useState(false);
  const { data, loading, refresh } = useGet({ url: `/jobs/${id}/candidates/suitable`, isArray: false, params });
  const { data: advertisement, loading: loadingAdvertisement } = useGet({ url: id && `/jobs/${id}`, isArray: false });


  const itemRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />
    else
      return originalElement;
  }

  const onCheckChange = (checked, applicationId) => {
    if (checked) {
      setChecks([...checks, applicationId]);
    } else {
      setChecks(checks.filter(x => x !== applicationId));
    }
  }

  const onChangeOperation = async (val) => {
    setOperation(val);
  }

  const onChangeFilter = (filter) => {
    setParams({
      ...params,
      ...filter,
      page: 1
    });
  };

  useEffect(() => {
    setParams({
      ...params,
      page
    });
    // eslint-disable-next-line
  }, [page]);

  useEffect(() => {
    async function ineligibleBulk() {
      setLoadingIneligible(true);
      try {
        await putData({ url: '/applications/ineligible/bulk', data: { ids: checks } });
        message.success('Seçili adayların durumu "UYGUN DEĞİL" olarak güncellendi');
      } catch (err) {
        message.error('Aday bilgisi güncellenirken bir hata meydana geldi');
      } finally {
        setLoadingIneligible(false);
        setOperation(null);
      }
    }
    operation === 'ineligible-bulk' && ineligibleBulk();
    // eslint-disable-next-line
  }, [operation]);

  const suggestedFirstOrdering = () => {
    const suggestedFiltered = get(data, 'results', []).filter( x => x.applicationStatus.id === 'SUGGESTED' );
    const NotSuggestedFiltered = get(data, 'results', []).filter( x => x.applicationStatus.id !== 'SUGGESTED' );
    return [...suggestedFiltered, ...NotSuggestedFiltered ];
  }

  return (
    <div>
      <Banner text="Uygun Aday Listesi" className="mb-0" />
      <div className="content md content-detail">
        <Helmet>
          <title>İş Ara İş Bul | Firma Uygun Aday Listesi</title>
          <meta
            name="description"
            content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
          <meta
            name="keywords"
            content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
        </Helmet>
        <Row
          align="top"
          justify="space-between"
          gutter={15}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}>
            <Spin spinning={loadingAdvertisement}>
              <p className="fw-bold text-4xl">
                {get(advertisement, 'advertisementNo')} - {get(advertisement, 'positionTitle')}
              </p>
            </Spin>
          </Col>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 6 }}>
            <Spin spinning={loadingIneligible}>
              <Select
                value={operation}
                className="form-select full-width"
                suffixIcon={<img src="/static/icons/select-arrow.svg" alt="Bölgesel İstihdam Ofisleri" />}
                placeholder="İşlemler"
                onChange={onChangeOperation}>
                <Select.Option value="ineligible-bulk">
                  Uygun Değil
                </Select.Option>
              </Select>
            </Spin>
          </Col>
        </Row>
        <Filter onChange={onChangeFilter} />
        <Row className="List">
          <Spin spinning={loading}>
            {get(data, 'total', 0) !== 0 ?

              suggestedFirstOrdering().map( (x,key) =>
               <ListItem disabled={checks.length} candidate={x} onCheckChange={onCheckChange} refresh={refresh}  key={key}/>
              )
              :
              <Empty description="İlan havuzunda herhangi bir aday yok" />
            }
          </Spin>
        </Row>
        <Divider className="Divider grey" />
        <div className="text-center">
          <Pagination
            current={page}
            onChange={(page, pageSize) => setPage(page)}
            total={get(data, 'total', 0 )}
            itemRender={itemRender} />
        </div>
      </div>
    </div>
  );
};

export default SuitableCandidates;
