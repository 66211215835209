import { useState, useEffect } from 'react';
import { getData } from 'redux/services/request';

export default ({ url, key, params, loadOnce, isArray = true, dataContainerKey = undefined }) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);


  const get = async () => {
    setLoading(true);
    try {
      const _data = await getData({ url, key, params });
      dataContainerKey ? setData(_data[dataContainerKey]) : (isArray ? setData(_data.results) : setData(_data))
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  }

  useEffect(() => {
    url && get();
    // eslint-disable-next-line
  }, [url, params]);

  useEffect(() => {
    data && setLoading(false);
  }, [data]);

  return { data, loading, refresh: get };
}
