import { Button, message } from "antd";
import Modal from "antd/lib/modal/Modal";
import "./eiiModal.css";
import { useState } from "react";
import Api from "api/Api";
import { changeUrl } from "helpers/methods";
import { getData } from "redux/services/request";

const EiiModal = ({
  eiiModal,
  setEiiModal,
  onCancel,
  newOrDeletedCertificates,
  selectedNewOrDeletedCertificates,
  setSelectedNewOrDeletedCertificates,
  newOrDeletedCertificatesDataRefresh,
}) => {
  const [addCertificatesLoading, setAddCertificatesLoading] = useState(false);
  const addCertificates = () => {
    if (selectedNewOrDeletedCertificates.length > 0) {
      setAddCertificatesLoading(true);
      new Api()
        .postData({
          url: changeUrl(`candidates/resumes/ismek/certs`),
          _data: { certNos: selectedNewOrDeletedCertificates },
        })
        .then((res) => {
          if (res.code === 200) {
            setAddCertificatesLoading(false);
            message.success("Sertifikalar özgeçmişe eklendi");
            setSelectedNewOrDeletedCertificates([]);
            getData({ url: "candidates/resumes/S5", key: "resumeS5" });
            newOrDeletedCertificatesDataRefresh();
            setEiiModal(false);
          }
        })
        .catch((err) => {
          setAddCertificatesLoading(false);
          message.error("Sertifika silme sırasında bir hata oluştu");
        });
    }
  };

  const handleChangeCheckbox = (certNo) => {
    const findItem = selectedNewOrDeletedCertificates.find(
      (el) => el === certNo
    );
    if (findItem) {
      setSelectedNewOrDeletedCertificates(
        selectedNewOrDeletedCertificates.filter((el) => el !== certNo)
      );
    } else {
      setSelectedNewOrDeletedCertificates([
        ...selectedNewOrDeletedCertificates,
        certNo,
      ]);
    }
  };

  return (
    <Modal
      title="Eİİ Sertifikalarım"
      visible={eiiModal}
      onCancel={onCancel}
      footer={null}
    >
      <div className="eiiModalBody">
        {newOrDeletedCertificates && newOrDeletedCertificates?.length > 0
          ? newOrDeletedCertificates.map((item, key) => (
              <label htmlFor={item?.course} key={key} className="eiiModalItem">
                <input
                  type="checkbox"
                  id={item?.course}
                  onChange={() => handleChangeCheckbox(item?.certNo)}
                  checked={selectedNewOrDeletedCertificates.some(
                    (el) => el === item?.certNo
                  )}
                />
                &nbsp;&nbsp;
                {item?.course}
              </label>
            ))
          : "Özgeçmişinize ekleyebileceğiniz sertifika bulunmamaktadır."}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          paddingTop: "15px",
        }}
      >
        <Button
          type="primary"
          htmlType="submit"
          className="modalBtn"
          onClick={addCertificates}
          loading={addCertificatesLoading}
          disabled={
            selectedNewOrDeletedCertificates.length === 0 ||
            newOrDeletedCertificates.length === 0 ||
            addCertificatesLoading
          }
        >
          Kaydet
        </Button>
      </div>
    </Modal>
  );
};

export default EiiModal;
