import {useEffect, useState} from "react";
import {Col, Form, Radio, Row} from 'antd';
import {Icon} from "components/UIComponents";
import StartDate from "../components/StartDate";
import EndDate from "../components/EndDate";

export default ({data}) => {
  const [ongoing, setOngoing] = useState()

  useEffect(() => {
    if (data) {
      setOngoing(data.status)
    }
  }, [data])

  return (
    <Row>
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Row className="full-width">
          <Col span={24} className="d-flex a-center mb-20">
            <Icon name="calendar" size={42}/>
            <StartDate/>
            {ongoing !== 'ONGOING' && <EndDate/>}
          </Col>
          <Col span={24}>
            <h1 className="text-xl fw-bold ls-full mb-20 mt-30">Devam Durumu <span className="text-orange-bio">(*)</span></h1>
            <Form.Item name="status" rules={[{required: true, message:"Devam Durumu"}]}>
              <Radio.Group className="form-radio mb-50" onChange={e => setOngoing(e.target.value)}>
                <Radio value="ONGOING">Devam Ediyor</Radio>
                <Radio value="GRADUATED">Mezun</Radio>
                <Radio value="ABANDON">Terk</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
