import { Form, Row, Col, Modal, Input, Button, message, Collapse, Descriptions } from 'antd';
import { Icon } from 'components/UIComponents';
import { LookupSelect } from 'components/UIComponents/FormElements';
import { get } from 'lodash';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { putData } from 'redux/services/request';

const InterviewResult = ({ data, visible, setVisible, interviews }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [interview, setInterview] = useState(false);

  const onSubmit = async (model) => {
    try {
      await putData({ url: `/interviews/${get(interview, 'interviewId', null)}`, data: model });
      message.success('Mulakat sonucu başarılı şekilde kaydedildi');
      setVisible(false);
    } catch (err) {
      message.error('Mülakat sonucu kaydedilirken bir hata meydana geldi');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (interviews) {
      const interview = interviews.filter(x => x.resultStatus === 'CONTINUES')[0];
      setInterview(interview);
    }
  }, [interviews, form]);

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  return (
    <Modal className="InviteModal" width={720} footer={null} open={visible} onCancel={() => setVisible(false)}>
      <Form form={form} onFinish={onSubmit}>
        <Row>
          <Col span={24}>
            <h1 className="fw-bold mb-20">
              <span className="text-blue mr-10">{get(data, 'firstName', '-')} {get(data, 'lastName', '-')}</span>isimli adayın Mülakat Sonucunu Gir
            </h1>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="interviewStatus">
              <LookupSelect url="/interviews/types/statuses" labelKey="label" valueKey="name" placeholder="Mülakat Durumu" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="resultStatus">
              <LookupSelect url="/interviews/types/results" labelKey="label" valueKey="name" placeholder="Mülakat Sonucu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="resultReport">
              <Input.TextArea className="form-input" placeholder="Sonuç raporu" rows={6} />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Button htmlType="submit" className="InviteBtn" loading={loading}>Sonucu Gir</Button>
          </Col>
        </Row>
      </Form>
      <Collapse accordion expandIconPosition="right" expandIcon={() => <Icon name="arrow-down" size={23} />} className="mt-30">
        <Collapse.Panel header={<p className="fw-bold mb-0">Geçmiş Mülakatlar</p>}>
          {interviews && interviews.map(x => (
            <Descriptions className="mb-30" bordered>
              <Descriptions.Item label="Tarih" span={4}>{x.interviewAt && moment(x.interviewAt).format('DD.MM.YYYY HH:mm')}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Durumu" span={4}>{get(x, 'interviewStatus', '-')}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Tipi" span={4}>{get(x, 'interviewType', '-')}</Descriptions.Item>
              <Descriptions.Item label="Görüşme Notu" span={4}>{get(x, 'invitationNote', '-')}</Descriptions.Item>
            </Descriptions>
          ))}
        </Collapse.Panel>
      </Collapse>
    </Modal>
  );
};

export default InterviewResult;
