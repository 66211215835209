import { useEffect, useState } from 'react';
import { Icon } from 'components/UIComponents';
import { languages } from 'helpers/constants';

import Row from './Row';


export default (props) => {

  const [foreignLanguages,setForeignLanguages] = useState([]);
  

  useEffect(() => {
    props.onChange && props.onChange(foreignLanguages.map(x => ({
      languageId: x.languageId,
      readingLevel: languages[x.readingLevel],
      writingLevel: languages[x.writingLevel],
      speakingLevel: languages[x.speakingLevel],
      translatingLevel: languages[x.translatingLevel]
    })));
    // eslint-disable-next-line
  }, [foreignLanguages]);

  const add = () => {
    const language = {
      languageId: '',
      readingLevel: 0,
      writingLevel: 0,
      speakingLevel: 0,
      translatingLevel: 0
    };
    setForeignLanguages([...foreignLanguages, language]);
  };

  const onChange = (i, language) => {
    const languages = [...foreignLanguages];
    languages[i] = language;
    setForeignLanguages(languages);
  }

  const remove = (languageId) => {
    setForeignLanguages(foreignLanguages.filter(x => x.languageId !== languageId));
  };

  return (
    <div className="ForeignLanguage">
      <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">YABANCI DİL</h1>
      <div className="bg-softgrey2 pb-10">
        {foreignLanguages.map((x, i) => <Row key={i} onChange={onChange} remove={remove} index={i} setLanguageDisabledBtn={props.setLanguageDisabledBtn}/>)}
        <div className="c-pointer" onClick={() => add()}>
          <Icon name="add" size={32} className="ml-95 pt-20" style={{ position: 'relative', left: -15 }} />
        </div>
      </div>
    </div>
  );
}