import { Row, Col, Button, Input, DatePicker, InputNumber, Form } from "antd";
import { Icon } from "components/UIComponents";

const formRules = {
  instituteName: [
    {
      required: true,
      message: "Bir kurum seçimi yapmalısınız",
    },
    {
      whitespace: true,
      message: "Bir kurum seçimi yapmalısınız",
    },
  ],
  subject: [
    {
      required: true,
      message: "Bir konu belirtiniz.",
    },
    {
      whitespace: true,
      message: "Bir konu belirtiniz.",
    },
  ],
  start: [
    {
      required: true,
      message: "Bir başlangıç tarihi belirtiniz",
    },
  ],
  end: {
    required: true,
    message: "Bir bitiş tarihi belirtiniz",
  },
  durationInHours: {
    required: true,
    message: "Bir süre belirtiniz",
  },
  description: [
    {
      required: true,
      message: "Açıklama giriniz",
    },
    {
      whitespace: true,
      message: "Bir Açıklama Giriniz.",
    },
  ],
};

export default ({ index, remove, data }) => {
  return (
    <Row>
      <Col span={24}>
        <Row>
          <Col span={24} className="d-flex a-center mb-20">
            <Form.Item name={"instituteName"} rules={formRules.instituteName}>
              <Input placeholder="Kurum Adı" className="form-input" />
            </Form.Item>
            <Button
              className="BtnDeleteItem btn btn-blue-filled full-width text-md mb-10"
              onClick={() => remove(index)}
            >
              Sil
            </Button>
          </Col>
          <Col span={24}>
            <Form.Item name={"subject"} rules={formRules.subject}>
              <Input placeholder="Konusu" className="form-input mb-20" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name={"durationInHours"}
              rules={[formRules.durationInHours]}
            >
              <InputNumber
                className="form-input full-width mb-20"
                placeholder="Süre"
              />
            </Form.Item>
          </Col>
          <Col span={24} className="d-flex a-center mb-20">
            <Icon
              name="calendar"
              size={42}
              style={{ transform: "translateY(-8px)" }}
            />
            <Form.Item name={"start"} rules={formRules.start}>
              <DatePicker
                placeholder="Başlangıç Tarihi"
                className="form-input text-center f-1 ml-10 suffix-none"
                suffixIcon={null}
                format="DD/MM/YYYY"
              />
            </Form.Item>
            <Form.Item name={"end"} rules={[formRules.end]}>
              <DatePicker
                placeholder="Bitiş Tarihi"
                className="form-input text-center f-1 ml-10 suffix-none"
                suffixIcon={null}
                format="DD/MM/YYYY"
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" rules={[formRules.description]}>
              <Input.TextArea
                className="form-input mb-20"
                placeholder="Açıklama"
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};
