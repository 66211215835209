import icons from 'assets/icons';

const Icon = ({ name, className, size, style }) => {

  return (
    <i className={`ibb-icon ${className}`} style={{ fontSize: size, ...style }}>
      {icons[name]}
    </i>
  )
};

export default Icon;
