import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import PlatformPolicy from "../../../../../../../assets/policy/EK-C Platform Kullanım Sözleşmesi.pdf";
import AcikRizaPolicy from "../../../../../../../assets/policy/EK-D İSPER_BİO_Özgeçmiş Paylaşımı Açık Rıza Metni (1).pdf";
import EmailPolicy from "../../../../../../../assets/policy/EK-E Açık Rıza Metni_Ticari Elektronik İleti_e-posta.pdf";
import SmsPolicy from "../../../../../../../assets/policy/EK-F Açık Rıza Metni_Ticari Elektronik İleti_SMS.pdf";
import { CheckOutlined } from "@ant-design/icons";

const KvkkModal = ({
  kvkkModal,
  setKvkkModal,
  freePassKvkkConsent,
  setFreePassKvkkConsent,
  setDocumentToUpload,
  handleChangeKvkkConsent,
}) => {
  const [freePassKvkkConsentModal, setFreePassKvkkConsentModal] = useState({
    kvkkExplicitConsent: false,
    kvkkClarificationConsent: false,
    kvkkEmailConsent: false,
    kvkkSmsConsent: false,
  });

  console.log("freePassKvkkConsent", freePassKvkkConsent);

  useEffect(() => {
    if (Object.entries(freePassKvkkConsent)?.length > 0) {
      setFreePassKvkkConsentModal(freePassKvkkConsent);
    }
  }, [freePassKvkkConsent]);

  const handleConfirmModal = () => {
    if (Object.entries(freePassKvkkConsentModal)?.length > 0) {
      setFreePassKvkkConsent(freePassKvkkConsentModal);
      setDocumentToUpload("");
      handleChangeKvkkConsent({
        kvkkExplicit: freePassKvkkConsentModal?.kvkkExplicitConsent,
        kvkkClarification: freePassKvkkConsentModal?.kvkkClarificationConsent,
        kvkkPlatformUsageAgreement:
          freePassKvkkConsentModal?.kvkkClarificationConsent,
        sms: freePassKvkkConsentModal?.kvkkSmsConsent,
        email: freePassKvkkConsentModal?.kvkkEmailConsent,
      });
      setKvkkModal(false);
    }
  };

  return (
    <Modal
      title="İBB Geçiş Desteği Kvkk İzinleri"
      open={kvkkModal}
      onOk={handleConfirmModal}
      onCancel={() => {
        setDocumentToUpload("");
        setKvkkModal(false);
      }}
      width={1000}
    >
      <>
        <div className="free_pass_alert info">
          <h5>
            İBB Geçiş Desteği İçin Aşağıdaki Kvkk İzinlerini Onaylamanız
            Gerekmektedir.
          </h5>
          <ul>
            {!freePassKvkkConsent?.kvkkClarificationConsent && (
              <li
                style={{
                  color: `${
                    freePassKvkkConsentModal?.kvkkClarificationConsent
                      ? "green"
                      : ""
                  }`,
                }}
              >
                Platform Kullanım Sözleşmesi&nbsp;
                {freePassKvkkConsentModal?.kvkkClarificationConsent && (
                  <CheckOutlined />
                )}
              </li>
            )}
            {!freePassKvkkConsent?.kvkkExplicitConsent && (
              <li
                style={{
                  color: `${
                    freePassKvkkConsentModal?.kvkkExplicitConsent ? "green" : ""
                  }`,
                }}
              >
                Açık Rıza Metni&nbsp;
                {freePassKvkkConsentModal?.kvkkExplicitConsent && (
                  <CheckOutlined />
                )}
              </li>
            )}
            {!freePassKvkkConsent?.kvkkEmailConsent && (
              <li
                style={{
                  color: `${
                    freePassKvkkConsentModal?.kvkkEmailConsent ? "green" : ""
                  }`,
                }}
              >
                E-Posta yoluyla ticari elektronik ileti izni&nbsp;
                {freePassKvkkConsentModal?.kvkkEmailConsent && (
                  <CheckOutlined />
                )}
              </li>
            )}
            <li
              style={{
                color: `${
                  freePassKvkkConsentModal?.kvkkSmsConsent ? "green" : ""
                }`,
              }}
            >
              SMS yoluyla ticari elektronik ileti izni&nbsp;
              {freePassKvkkConsentModal?.kvkkSmsConsent && <CheckOutlined />}
            </li>
          </ul>
        </div>
        {/*Platform Kullanım Sözleşmesi*/}
        {!freePassKvkkConsent?.kvkkClarificationConsent && (
          <label
            htmlFor="kvkkClarificationConsent"
            className="free_pass_checkbox_label"
          >
            <input
              type="checkbox"
              id="kvkkClarificationConsent"
              onChange={(e) =>
                setFreePassKvkkConsentModal({
                  ...freePassKvkkConsentModal,
                  kvkkClarificationConsent: e.target.checked,
                })
              }
              checked={freePassKvkkConsentModal?.kvkkClarificationConsent}
            />
            <span>
              <a target="_blank" href={PlatformPolicy}>
                Platform Kullanım Sözleşmesi
              </a>
              'nde geçen koşulları kabul ediyorum.
            </span>
          </label>
        )}
        {/*Explicit Policy Checkbox*/}
        {!freePassKvkkConsent?.kvkkExplicitConsent && (
          <label
            htmlFor="kvkkExplicitConsent"
            className="free_pass_checkbox_label"
          >
            <input
              type="checkbox"
              id="kvkkExplicitConsent"
              onChange={(e) =>
                setFreePassKvkkConsentModal({
                  ...freePassKvkkConsentModal,
                  kvkkExplicitConsent: e.target.checked,
                })
              }
              checked={freePassKvkkConsentModal?.kvkkExplicitConsent}
            />
            <span>
              <a target="_blank" href={AcikRizaPolicy}>
                Açık Rıza Metni
              </a>
              'nde belirtilen amaçlar dahilinde özgeçmiş bilgilerimin iş
              başvurusu yapmadığım işverenlere aktarılmasını onaylıyorum.
            </span>
          </label>
        )}
        {/*Email Policy Checkbox*/}
        {!freePassKvkkConsent?.kvkkEmailConsent && (
          <label
            htmlFor="kvkkEmailConsent"
            className="free_pass_checkbox_label"
          >
            <input
              type="checkbox"
              id="kvkkEmailConsent"
              onChange={(e) =>
                setFreePassKvkkConsentModal({
                  ...freePassKvkkConsentModal,
                  kvkkEmailConsent: e.target.checked,
                })
              }
              checked={freePassKvkkConsentModal?.kvkkEmailConsent}
            />
            <span>
              <a target="_blank" href={EmailPolicy}>
                Açık Rıza Metni
              </a>{" "}
              uyarınca e-posta yoluyla ticari elektronik ileti almak istiyorum.
            </span>
          </label>
        )}
        {/*Sms Policy Checkbox*/}
        <label htmlFor="kvkkSmsConsent" className="free_pass_checkbox_label">
          <input
            type="checkbox"
            id="kvkkSmsConsent"
            onChange={(e) =>
              setFreePassKvkkConsentModal({
                ...freePassKvkkConsentModal,
                kvkkSmsConsent: e.target.checked,
              })
            }
            checked={freePassKvkkConsentModal?.kvkkSmsConsent}
          />
          <span>
            <a target="_blank" href={SmsPolicy}>
              Açık Rıza Metni
            </a>{" "}
            uyarınca SMS yoluyla ticari elektronik ileti almak istiyorum.
          </span>
        </label>
      </>
    </Modal>
  );
};

export default KvkkModal;
