import { useState,useRef,useEffect} from 'react';
import useGetNew from "../../../hooks/useGetNew";
import Api from "../../../api/Api";
import { List } from 'antd';
import { useHistory } from "react-router-dom";
import icon from "../../../assets/bio-svg-notification.svg"

const Notifications = () => { 
    const [visible,setVisible]=useState()
    const notificationCardRef = useRef(null);
    const history = useHistory();
    const [isReadLen ,setIsReadLen]=useState(null)

    const candidateId=localStorage.userId;
    const { data: notificationData } = useGetNew({url: '/candidate/view/tracking?candidateId=' + candidateId, isArray: false, key:'candidate'});
    
    const handleNotifications= ()=>{
        setVisible(!visible);
        const idler = notificationData?.result?.results.map(item => item.id);
        new Api().putData({ url: `/candidate/view/tracking/isRead`, _data:{ids:idler} });
    }


    useEffect(() => {
        const handleClick = (event) => {
          
          if ( notificationCardRef.current && !notificationCardRef.current.contains(event.target)) {
            setVisible(false);
            setIsReadLen(null)
          }
        };
        document.addEventListener("click", handleClick);
        return () => {
          document.removeEventListener("click", handleClick);
        };
      }, []);

    useEffect(()=>{
      let data=notificationData?.result?.results.filter(item => item.isRead ===false)
        if(data?.length    ) {
          setIsReadLen(data)
      }

    },[notificationData ])


    return  (  
    <>
   {<div className='notifications'  ref={notificationCardRef} onClick={() => handleNotifications()} style={{position: 'relative', margin: '0 5px 2% 10px' }}>
      {isReadLen && <div className='notificationIcon'>
            <span className='notificationCount'>{isReadLen?.length}</span>
        </div>}
        <img width="35" height="35" src={icon} alt="appointment-reminders" />   
        
     
        {visible && <><List
         ref={notificationCardRef}
        bordered='true'
        colorBorder='#fff'
        className='notificationCard'
        style={{display: "block"}}
            itemLayout="horizontal"
            dataSource={notificationData?.result?.results}
            renderItem={(item, index) => (
            item.jobStatus===true ? 
           <List.Item className='notificationItem' style={{cursor:'pointer'}}>
                <List.Item.Meta className='notificationItem'
                title={item.value}
                onClick={()=> history.push(`/jobs/detail/${item.jobId}`)}
                />
                   <div>  <div>{item.timePassedSinceViewing}</div><div>...</div></div>
            </List.Item>:
            <List.Item className='notificationList' style={{cursor: 'unset'}}>
                <List.Item.Meta className='notificationItem '  title={item.value} />
                
                <div>  <div>{item.timePassedSinceViewing}</div><div>...</div></div>
            </List.Item>
            )}
        />
       
       </> }
    </div>
 }
    </>
  )
};

export default Notifications;
  
       /* <div class="notificationCard more" style={{display: "block"}}>
            <ul class="notificationList" style={{display: "block"}}>
            {notificationData?.result?.results.map(item=>{
                return <li class="notificationItem" key={item.id}>
                  <p> {item.value.toString()}</p>
                </li>
            })}
            </ul>
        </div>*/