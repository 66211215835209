import {Form, Row, Col, Input, Button,Select} from 'antd';
import {Icon} from 'components/UIComponents';
import {LookupSelect, CityCountyLookupSelect} from 'components/UIComponents/FormElements';
import React, {useState} from 'react';

const Filter = ({onChange}) => {
  const [form] = Form.useForm();
  const [items, setItems] = useState([]);

  const clearFilters = () => {
    form.resetFields();
    onChange && onChange(form.getFieldsValue());
  }

  const handleItemSelection = (items) => {
    setItems(items)
  }

  return (
      <Form form={form} onFinish={onChange}>
        <Row className="mt-70" gutter={15}>

          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
            <Form.Item name="firstName">
              <Input placeholder="Ad" className="form-input"/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
            <Form.Item name="lastName">
              <Input placeholder="Soyad" className="form-input"/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
            <Form.Item name="counties">
              <CityCountyLookupSelect
                  onChangeObject={handleItemSelection}
                  url="/public/counties" stateKey="counties" labelKey="value" valueKey="id" valueType="type"
                  placeholder="İlçe"
                  mode="multiple"/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="retirementStatus">
            <Select className="form-select" placeholder="Emeklilik Durumu" suffixIcon={<Icon name="arrow-down" size={23}/>}>
              <Select.Option value="">Tümü</Select.Option>
              <Select.Option value="Y">Emekli</Select.Option>
              <Select.Option value="N">Emekli Değil</Select.Option>
              <Select.Option value="U">Belirtilmemiş</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      
        </Row>

        <Row className="mt-20" gutter={15}>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}}>
            <Form.Item name="preferredPositions">
              <LookupSelect url="/professions" labelKey="profession" valueKey="id"
                            placeholder="İlgilendiği Pozisyonlar"/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 12}}>
            <Form.Item name="workingPositions">
              <LookupSelect url="/professions" labelKey="profession" valueKey="id" placeholder="Çalıştığı Pozisyonlar"/>
            </Form.Item>
          </Col>
          <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="isViewed">
            <Select className="form-select" placeholder="CV Görüntüleme Durumu" suffixIcon={<Icon name="arrow-down" size={23}/>}>
              <Select.Option value="">Tümü</Select.Option>
              <Select.Option value="true">Görüntülendi</Select.Option>
              <Select.Option value="false">Görüntülenmedi</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        </Row>
        <Row justify="center" className="mt-30 mb-40">
          <Button className="ant-btn btn-filter btn-blue text-4xl mr-20" onClick={clearFilters}>
            Temizle
          </Button>
          <Button type="primary" className="btn btn-filter btn-pink-filled text-4xl" htmlType="submit">
            ARA
          </Button>
        </Row>
        
      </Form>
  );
}

export default Filter;
