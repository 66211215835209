import { useEffect, useState } from "react";
import { Layout, Space } from "antd";
import { Route, Switch, withRouter } from "react-router-dom";
import AppHeader from "components/Layout/Header";
import Footer from "components/Layout/Footer";
import ScrollTop from "plugins/routes/ScrollTop";
import { getConsents } from "./helpers/methods";
import Kvkk from "./views/Dashboard/Home/Kvkk";
import { useDispatch, useSelector } from "react-redux";
import ModalAuthCommercial from "./views/Auth/Modals/ModalAuthCommercial";
import CompanyWorkFlows from "components/Modals/WorkForceModal";
import { loadUserData } from "redux/actions/userActions";
import { loadCompanyData } from "redux/actions/companyActions";
import { loadCompanyWorkForceData } from "redux/actions/companyWorkForceActions";
import Cookie from "views/Dashboard/Home/Cookie";

// without layout(header,footer) paths..
const paths = [
  "/login-corporate",
  "/register-corporate",
  "/login-citizen",
  "/register-citizen",
  "/reset-password",
  "/forgot-password",
  "/printcv",
  "/verification",
  "/rejected",
  "/personal-info",
  "/account/candidate/account/print-cv",
];

function App({ history }) {
  const accessToken = useSelector((state) => state.data);
  const status = useSelector((state) => state.loginInit);
  const fullName = status?.data?.result?.fullName;
  const companyName = status?.data?.result?.selectedCompany?.name;
  const [kvkkModal, setKvkkModal] = useState(false);
  const [visibleModalCommercial, setVisibleModalCommercial] = useState(false);
  const [workForceModal, setWorkForceModal] = useState(false);

  const data = useSelector((state) => state.data.companyResult);

  const result = data && data.result;

  const dispatch = useDispatch();

  useEffect(() => {
    // çalışmama durumunda accessToke ile condition eklenerek kontrol edilecek.
    if (localStorage.getItem("type") === "CITIZEN") {
      dispatch(loadUserData());
    } else if (localStorage.getItem("type") === "CORPORATE") {
      dispatch(loadCompanyData());
      dispatch(loadCompanyWorkForceData());
    }
  }, [dispatch]);

  useEffect(() => {
    if (
      result &&
      localStorage.getItem("type") === "CORPORATE" &&
      (result.companyWorkforce34 === null || result.companyWorkforceTr === null)
    ) {
      setWorkForceModal(true);
    }
  }, [data]);

  history.listen(() => {
    setKvkkModal(false);
    setKvkkModal(getConsents().required);
  });

  useEffect(() => {
    if (accessToken) {
      setKvkkModal(getConsents().required);
    }
  }, [accessToken]);

  useEffect(() => {
    if (accessToken) {
      setKvkkModal(getConsents().required);
    }
  }, [accessToken]);

  useEffect(() => {
    const ibbToken = accessToken
      ? JSON.stringify(accessToken)
      : JSON.stringify(localStorage.getItem("accessToken"));
    localStorage.setItem("ibb-bio_at", ibbToken);
  }, []);

  return (
    <>
      {/*
      <Switch>
        <Route path="/" component={require('UnderConstructionPage').default} />
      </Switch>
    */}
      <div>
        <Layout className="homeLayout">
          {!paths.includes(history.location.pathname) && (
            <AppHeader
              candidateName={fullName || localStorage.getItem("fullName")}
              companyName={
                companyName ? companyName : localStorage.getItem("companyName")
              }
            />
          )}
          <Layout.Content>
            <ScrollTop />
            <Switch>
              {/* <PublicRoutes /> */}
              {kvkkModal && <Kvkk close={() => setKvkkModal(false)} />}

              <Route
                exact
                path="/"
                component={require("views/Dashboard").default}
              ></Route>
              <Route
                path="/login-corporate"
                component={require("views/Auth/LoginCorporate").default}
              ></Route>
              <Route
                path="/register-citizen"
                component={require("views/Auth/RegisterCitizen").default}
              ></Route>
              <Route
                path="/forgot-password"
                component={require("views/Auth/ForgotPassword").default}
              ></Route>
              <Route
                path="/reset-password"
                component={require("views/Auth/ResetPassword").default}
              ></Route>
              <Route
                path="/verification"
                component={require("views/Auth/Verification").default}
              ></Route>
              <Route
                path="/login-citizen"
                component={require("views/Auth/LoginCitizen").default}
              ></Route>
              <Route
                path="/register-corporate"
                component={require("views/Auth/RegisterCorporate").default}
              ></Route>
              <Route
                path="/about-us"
                component={require("views/Dashboard/AboutUs").default}
              ></Route>
              <Route
                path="/news/list"
                component={require("views/Dashboard/News/List").default}
              ></Route>
              <Route
                path="/news/detail/:id"
                component={require("views/Dashboard/News/Detail").default}
              ></Route>
              <Route
                path="/jobs/list"
                component={require("views/Dashboard/Jobs/List").default}
              ></Route>
              <Route
                path="/jobs/detail/:id"
                component={require("views/Dashboard/Jobs/Detail").default}
              ></Route>
              <Route
                path="/communication/faq"
                component={require("views/Dashboard/Communication/Faq").default}
              ></Route>
              <Route
                path="/communication/contact-us"
                component={
                  require("views/Dashboard/Communication/ContactUs").default
                }
              ></Route>
              <Route
                path="/contracts/employer-information-text"
                component={
                  require("views/Dashboard/Contracts/EmployerInformationText")
                    .default
                }
              ></Route>
              <Route
                path="/contracts/informative-text"
                component={
                  require("views/Dashboard/Contracts/InformativeText").default
                }
              ></Route>
              <Route
                path="/contracts/general-conditions"
                component={
                  require("views/Dashboard/Contracts/GeneralConditions").default
                }
              ></Route>
              <Route
                path="/contracts/portal-membership-agreement"
                component={
                  require("views/Dashboard/Contracts/PortalMembershipAgreement")
                    .default
                }
              ></Route>
              <Route
                path="/contracts/privacy-principles"
                component={
                  require("views/Dashboard/Contracts/PrivacyPrinciples").default
                }
              ></Route>
              <Route
                path="/mobile-vehicle"
                component={require("views/Dashboard/Mobile").default}
              ></Route>
              <Route
                path="/offices"
                component={require("views/Dashboard/Offices").default}
              ></Route>
              <Route
                path="/account/candidate/account/print-cv"
                component={
                  require("views/Dashboard/User/Candidate/Account/PrintCV/index")
                    .default
                }
              ></Route>
              {status && status.code && status.code === 3030 ? (
                <>
                  <Route
                    path="/account"
                    component={
                      require("views/Dashboard/User/Employer/FirmConfirmation")
                        .default
                    }
                  />
                </>
              ) : (
                localStorage.getItem("type") &&
                localStorage.getItem("type") !== null &&
                (localStorage.getItem("type") === "CORPORATE" ? (
                  <>
                    <Route
                      path="/printcv"
                      component={
                        require("views/Dashboard/User/Employer/PrintCV").default
                      }
                    />
                    <Route
                      path="/account/employer/profile"
                      component={
                        require("views/Dashboard/User/Employer/Profile").default
                      }
                    />
                    <Route
                      path="/account/employer/waiting"
                      component={
                        require("views/Dashboard/User/Employer/FirmConfirmation")
                          .default
                      }
                    />
                    <Route
                      path="/account/employer/firm-employer"
                      component={
                        require("views/Dashboard/User/Employer/FirmStaffs")
                          .default
                      }
                    />
                    <Route
                      path="/account/employer/firm-reports"
                      component={
                        require("views/Dashboard/User/Employer/FirmReports")
                          .default
                      }
                    />
                    <Route
                      path="/account/employer/notes/create"
                      component={
                        require("views/Dashboard/User/Employer/Notes/Create")
                          .default
                      }
                    />
                    <Route
                      path="/account/employer/jobs/list"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/List")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/employer/jobs/create"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/Create")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/employer/jobs/edit/:id"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/Edit")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/employer/jobs/:id/application-pool"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/Detail/ApplicationPool")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/employer/jobs/:id/suitable-candidates/list"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/Detail/SuitableCandidates/List")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/employer/jobs/:applicationId/candidates/:candidateId"
                      component={
                        require("views/Dashboard/User/Employer/Jobs/Detail/SuitableCandidates/Detail")
                          .default
                      }
                    ></Route>
                  </>
                ) : (
                  <>
                    <Route
                      path="/account/oauth/callback"
                      component={require("views/Auth/Callback").default}
                    ></Route>
                    <Route
                      path="/account/candidate/profile"
                      component={
                        require("views/Dashboard/User/Candidate/Announcements")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/account/candidate/cv"
                      component={
                        require("views/Dashboard/User/Candidate/CV").default
                      }
                    ></Route>
                    <Route
                      path="/account/candidate/account"
                      component={
                        require("views/Dashboard/User/Candidate/Account")
                          .default
                      }
                    ></Route>
                    <Route
                      path="/personal-info"
                      component={require("views/Auth/PersonalInfo").default}
                    ></Route>
                  </>
                ))
              )}
            </Switch>
          </Layout.Content>
          {!paths.includes(history.location.pathname) && (
            <Layout.Footer>
              <Footer />
            </Layout.Footer>
          )}
          {!paths.includes(history.location.pathname) && (
            <ModalAuthCommercial
              visible={visibleModalCommercial}
              setVisible={setVisibleModalCommercial}
              style={{ display: "none" }}
            />
          )}

          <CompanyWorkFlows
            visible={workForceModal}
            setVisible={setWorkForceModal}
            companyData={result}
          ></CompanyWorkFlows>
          {localStorage?.getItem("cookieAllowed") === null &&
            !localStorage.getItem("cookieRejected") && <Cookie />}
        </Layout>
      </div>
    </>
  );
}

export default withRouter(App);
