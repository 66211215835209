import {Button, Col, Form, Input, Row, Select} from 'antd';
import {Icon} from 'components/UIComponents';
import {CityCountyLookupSelect, LookupSelect} from 'components/UIComponents/FormElements';
import React from 'react';

const Filter = ({onChange}) => {
  const [form] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    onChange && onChange(form.getFieldsValue());
  }

  return (
    <Form className="FilterForm" form={form} onFinish={onChange}>
      <Row className="mt-70" gutter={15}>

        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="firstName">
            <Input placeholder="Ad" className="form-input"/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="lastName">
            <Input placeholder="Soyad" className="form-input"/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="counties">
            <CityCountyLookupSelect
              url="/public/counties"
              stateKey="counties" labelKey="value" valueKey="id" valueType="type" placeholder="İlçe"
              mode="multiple"/>
          </Form.Item>
        </Col>
      </Row>
      <Row className="mt-20" gutter={{xs: 0, sm: 0, md: 15}}>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 9}}>
          <Form.Item name="preferredPositions">
            <LookupSelect url="/professions" labelKey="profession" valueKey="id" placeholder="İlgilendiği Pozisyonlar"
                          mode="multiple"/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 9}}>
          <Form.Item name="workingPositions">
            <LookupSelect url="/professions" labelKey="profession" valueKey="id" placeholder="Çalıştığı Pozisyonlar"
                          mode="multiple"/>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="status">
            <Select className="form-select" placeholder="Durum" suffixIcon={<Icon name="arrow-down" size={23}/>}>
              <Select.Option value="APPLIED">Başvurmuş</Select.Option>
              <Select.Option value="SUGGESTED">Önerilen Aday</Select.Option>
              <Select.Option value="ELIGIBLE">Uygun Aday</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="retirementStatus">
            <Select className="form-select" placeholder="Emeklilik Durumu" suffixIcon={<Icon name="arrow-down" size={23}/>}>
              <Select.Option value="">Tümü</Select.Option>
              <Select.Option value="Y">Emekli</Select.Option>
              <Select.Option value="N">Emekli Değil</Select.Option>
              <Select.Option value="U">Belirtilmemiş</Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 6}}>
          <Form.Item name="isViewed">
            <Select className="form-select" placeholder="CV Görüntülenme Durumu" suffixIcon={<Icon name="arrow-down" size={23}/>}>
              <Select.Option value="">Tümü</Select.Option>
              <Select.Option value="true">Görüntülendi</Select.Option>
              <Select.Option value="false">Görüntülenmedi</Select.Option>
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row justify="center" className="mt-30 mb-40">
        <Button className="ant-btn btn-filter btn-blue text-4xl mr-20" onClick={clearFilters}>
          Temizle
        </Button>
        <Button type="primary" className="btn btn-filter btn-pink-filled text-4xl" htmlType="submit">
          ARA
        </Button>
      </Row>
    </Form>
  );
}

export default Filter;
