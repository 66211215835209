import { Row, Col, Divider, Image } from "antd";
import { links } from "helpers/constants";
import { withRouter, Link } from "react-router-dom";
import logofooter from "assets/static/img/logo-footer.png";
import footerIbblogo from "assets/static/img/istanbul-buyuksehir-belediyesi-logo_white.svg";
import iskur from "assets/static/img/iskur.png";
import instagram from "assets/static/icons/instagram.svg";
import twitter from "assets/static/icons/twitter.svg";
import linkedin from "assets/static/icons/linkedin.svg";
import facebook from "assets/static/icons/facebook.svg";
import FooterLogo from "components/UIComponents/FooterLogo";
import seninImage from "../../../assets/ilan.png";

const Footer = ({ history }) => {
  return (
    <div className="Footer text-white">
      <div className="content">
        <Row className="full-width pt-50">
          <Col
            sm={{ span: 24 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}
            className="logos"
          >
            <Link
              href="https://www.ibb.istanbul/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                className="ml-auto rightline ibb-logo"
                src={footerIbblogo}
                alt="Bölgesel İstihdam Ofisleri"
                preview={false}
              />
            </Link>
            <Link
              href="https://www.ibb.istanbul/"
              target="_blank"
              rel="noreferrer"
            >
              <Image
                className="ml-10 ibb-bio-logo"
                src={logofooter}
                alt="Bölgesel İstihdam Ofisleri"
                preview={false}
              />
            </Link>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
            className="footer-padding-top"
          >
            <p className="FooterTitle">KURUMSAL</p>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 7 }}
            lg={{ span: 7 }}
            className="footer-padding-top footer-padding"
          >
            <p className="FooterTitle">SÖZLEŞMELER</p>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 5 }}
            lg={{ span: 5 }}
            className="footer-padding-top"
          >
            <p className="FooterTitle">İLETİŞİM</p>
          </Col>
          <Divider className="Divider white" />
          <Col sm={{ span: 24 }} md={{ span: 8 }} lg={{ span: 8 }}>
            <p className="email">bio@ibb.istanbul</p>
            <div className="d-flex mb-25">
              <a href={links.instagram} target="_blank" rel="noreferrer">
                <Image
                  className="social-icon"
                  src={instagram}
                  alt="Bölgesel İstihdam Ofisleri Instagram"
                  preview={false}
                />
              </a>
              <a href={links.twitter} target="_blank" rel="noreferrer">
                <Image
                  className="social-icon"
                  src={twitter}
                  alt="Bölgesel İstihdam Ofisleri Twitter"
                  preview={false}
                />
              </a>
              <a href={links.linkedin} target="_blank" rel="noreferrer">
                <Image
                  className="social-icon"
                  src={linkedin}
                  alt="Bölgesel İstihdam Ofisleri Linkedin"
                  preview={false}
                />
              </a>
              <a href={links.facebook} target="_blank" rel="noreferrer">
                <Image
                  className="social-icon"
                  src={facebook}
                  alt="Bölgesel İstihdam Ofisleri Facebook"
                  preview={false}
                />
              </a>
            </div>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 4 }}>
            <p
              onClick={() => history.push("/about-us")}
              className="FooterMenuItem"
            >
              Hakkımızda
            </p>
            <p
              onClick={() => history.push("/offices")}
              className="FooterMenuItem"
            >
              Bölgesel İstihdam Ofisleri
            </p>
          </Col>
          <Col
            sm={{ span: 24 }}
            md={{ span: 7 }}
            lg={{ span: 7 }}
            className="footer-padding"
          >
            <p
              onClick={() => history.push("/contracts/informative-text")}
              className="FooterMenuItem"
            >
              Bilgilendirme Metni
            </p>
            <p
              onClick={() => history.push("/contracts/general-conditions")}
              className="FooterMenuItem"
            >
              Genel Koşullar
            </p>
            <p
              onClick={() => history.push("/contracts/privacy-principles")}
              className="FooterMenuItem"
            >
              Gizlilik Prensipleri
            </p>
            <p
              onClick={() =>
                history.push("/contracts/portal-membership-agreement")
              }
              className="FooterMenuItem"
            >
              Portal Üyelik Sözleşmesi
            </p>
            <p
              onClick={() =>
                history.push("/contracts/employer-information-text")
              }
              className="FooterMenuItem"
            >
              İşveren Bilgilendirme Metni
            </p>
          </Col>
          <Col sm={{ span: 24 }} md={{ span: 5 }} lg={{ span: 5 }}>
            <p
              className="FooterMenuItem"
              onClick={() => history.push("/communication/contact-us")}
            >
              Bize Ulaşın
            </p>
            <p
              className="FooterMenuItem"
              onClick={() => history.push("/communication/faq")}
            >
              Sıkça Sorulan Sorular
            </p>
          </Col>
        </Row>
        <Row style={{ width: "100%" }}>
          <a
            style={{ margin: "0 auto" }}
            href="https://app.adjust.com/pjt7vbi?deeplink=https%3A%2F%2Fpaylas.istanbulsenin.istanbul%2Fkobilservice%3FsID%3Da4813ca6-207c-4779-8866-7cb719f34e72"
          >
            <img src={seninImage} alt="alt" width="100%" />
          </a>
        </Row>
        <Divider className="Divider black" />
        <Row className="IskurContainer full-width">
          <Col sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 3 }}>
            <Image
              src={iskur}
              alt="Bölgesel İstihdam Ofisleri İşkur"
              preview={false}
              className="iskurlogo"
            />
          </Col>
          <Col sm={{ span: 12 }} md={{ span: 20 }} lg={{ span: 21 }}>
            <p className="IskurText">
              {/* İSPER İstanbul Personel Yönetim A.Ş. Özel İstihdam Bürosu olarak
              27.05.2020-26.05.2023 tarihleri arasında faaliyette bulunmak
              üzere, Türkiye İş Kurumu tarafından 21.05.2020 tarih ve 1170
              numaralı izin belgesi ile faaliyet göstermektedir. 4904 sayılı
              kanun uyarınca iş arayanlardan ücret alınmayacak ve menfaat temin
              edilmeyecektir. Şikayetleriniz için aşağıdaki telefon numaralarına
              başvurabilirsiniz. Türkiye İş Kurumu İstanbul İl Müdürlüğü: 0212
              249 29 87 Türkiye İş Kurumu İstanbul Çalışma ve İş Kurumu
              Bayrampaşa Hizmet Merkezi: 0212 480 05 20 */}
              İSPER İstanbul Personel Yönetim A.Ş. Özel İstihdam Bürosu olarak
              faaliyette bulunmak üzere, Türkiye İş Kurumu tarafından 26.05.2026
              tarihine kadar yetkilendirilmiştir. 4904 Sayılı Kanun uyarınca iş
              arayanlardan ücret alınmayacak ve menfaat temin edilmeyecektir.
              Şikayetleriniz için aşağıdaki telefon numaralarına
              başvurabilirsiniz. Türkiye İş Kurumu İstanbul İl Müdürlüğü: 0212
              249 29 87 Türkiye İş Kurumu İstanbul Çalışma ve İş Kurumu
              Bayrampaşa Hizmet Merkezi: 0212 480 05 20
            </p>
          </Col>
        </Row>
      </div>
      <div>
        <Row className="full-width">
          <Col span={24} className="text-center">
            <FooterLogo />
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default withRouter(Footer);
