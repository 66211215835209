import React from 'react'
import "./style.css"
import login7 from "../../../../assets/login7.svg"
import loginfooter1 from "../../../../assets/loginfooter1.svg";
import loginfooter2 from "../../../../assets/loginfooter2.svg"
import loginfooter3 from "../../../../assets/loginfooter3.svg"

const EmployerScreen= () => {
    return (
       <div className='container employer'>
            <div className='big-title'>
                BÖLGESEL İSTİHDAM OFİSİ’NDE <br/>
                <span className='bolder'>
                    SİZİ NELER BEKLİYOR?
                </span>
            </div>
            <div className='desc'>
                <div>
                    <div className='desc-item'>
                        <img src={login7} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <div>Ücretsiz Ve Sınırsız İlan Yayınlama Fırsatı</div> 
                    </div>
                    <div className='desc-item'>
                        <img src={login7} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <div>Her Gün Yenilenen Geniş Aday Havuzu</div>
                    </div>
                    <div className='desc-item'>
                        <img src={login7} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <div>Ofislerimizde Toplu Mülakat ve Birebir Görüşme Hizmetis</div>
                    </div>
                </div>
                <div className='banner-footer'>
                    <div className='left-side'>
                        <img src={loginfooter1} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                        <img src={loginfooter2} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                    </div>
                    <div className='right-footer'>
                        <img src={loginfooter3} alt="Bölgesel İstihdam Ofisleri" class="mb-10 mr-10"/>
                    </div>
                </div>
            </div>
           
       </div>
    )
}

export default EmployerScreen