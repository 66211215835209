import {Row, Col, Form, Input, DatePicker, Button, Pagination, Divider, Spin, InputNumber, Select} from 'antd';
import Banner from 'components/UIComponents/Banner';
import {CityCountyLookupSelect} from 'components/UIComponents/FormElements';
import useGetNew from 'hooks/useGetNew';
import {get} from 'lodash';
import React, {useEffect, useState} from 'react';
import ListItem from './ListItem';
import {Helmet} from "react-helmet";
import {confirmMessage} from "../../../../../../helpers/methods";

const List = () => {
  const [item, setItem] = useState(null);
  const [form] = Form.useForm();
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10)
  const [params, setParams] = useState(undefined);
  const {data, loading, refresh} = useGetNew({url: '/jobs/companies', isArray: false, params});


  useEffect(() => {
    data && setPage(data?.result?.page);
  }, [data]);

  useEffect(() => {
    page && setParams({...params, page});
    // size && setParams({...params, size});
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [page, size]);

  const filterJobs = (params) => {
    if (params.releaseDate) {
      params.startDate = params.releaseDate[0].format('YYYY-MM-DD');
      params.endDate = params.releaseDate[1].format('YYYY-MM-DD');
    }

    delete params.releaseDate;
    setParams(params);
  };

  const clearFilters = () => {
    form.resetFields();
    setParams(undefined);
  }

  const handleItemSelection = (item) => {
    setItem(item)
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisi"/>
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisi"/>
    else
      return originalElement;
  }

  return (
    data && data.code !== 200 ? (
      confirmMessage( data?.message )
    ) : (
      <div className="JobList">
        <Helmet>
          <title>İş Ara İş Bul | Firma İlan Listesi</title>
          <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
          <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
        </Helmet>
        <Banner text="Firma İlan Listesi" className="mb-0"/>
        <div className="content md content-detail">
          <Form form={form} onFinish={filterJobs}>
            <Row className="full-width" gutter={{xs: 0, sm: 0, md: 30}}>
              <Col xs={{span: 24}} sm={{span: 24}} md={{span: 20}}>
                <Row gutter={{xs: 0, sm: 0, md: 30}}>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="advertisementNo">
                      <InputNumber type="number" className="form-input full-width f-1 mr-10" placeholder="İlan Numarası"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="positionTitle">
                      <Input className="form-input f-1 mr-10" placeholder="Pozisyon"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="status">
                      <Select className="form-select"
                              suffixIcon={<img src="/static/icons/select-arrow.svg" alt="Bölgesel İstihdam Ofisi"/>}
                              placeholder="İlan Durumu">
                        <Select.Option value="WAITING">Onayda</Select.Option>
                        <Select.Option value="REJECTED">Reddedildi</Select.Option>
                        <Select.Option value="ACTIVE">Aktif</Select.Option>
                        <Select.Option value="PASSIVE">Pasif</Select.Option>
                        <Select.Option value="NEED_EDIT">Düzenleme İhtiyacı</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 8}}>
                    <Form.Item name="countyId">
                      <CityCountyLookupSelect
                          url="/public/counties" stateKey="counties"  labelKey="value" valueKey="id" valueType="type"
                          onChangeObject={handleItemSelection}
                          placeholder="İlçe"/>
                    </Form.Item>
                  </Col>
                  <Col xs={{span: 24}} sm={{span: 24}} md={{span: 16}}>
                    <Form.Item name="releaseDate">
                      <DatePicker.RangePicker className="form-input full-width" placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]} format="DD.MM.YYYY"/>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col xs={{span: 24}} sm={{span: 24}} md={{span: 4}}>
                <Row className="full-width">
                  <Col span={24}>
                    <Button type="primary" className="btn btn-filter btn-pink-filled text-md full-width"
                            htmlType="submit">ARA</Button>
                  </Col>
                  <Col span={24}>
                    <Button className="btn-filter btn-blue text-md full-width" onClick={clearFilters}>TEMİZLE</Button>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
          <div className="Jobs">
            <Spin spinning={loading}>
              { data && data.result.results.map(x => (
                  <ListItem key={x.id} job={x} refresh={refresh}/>
              ))}
            </Spin>
          </div>
          <Row>
            <Col span={16}>
              <Divider className="Divider grey"/>
            </Col>
          </Row>
          <div className="text-center">
            <Pagination
                current={get(data, 'result.page', 1) || 1}
                onChange={(page, pageSize) => {
                  setPage(page)
                  setSize(pageSize)
                }}
                total={(data && data.result.total) ? data.result.total : 0}
                itemRender={itemRender}/>
          </div>
        </div>
      </div>
    )
  );
};

export default List;
