import {useEffect, useState} from "react";
import {DatePicker, Input, message} from "antd";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import { useHistory } from "react-router-dom";
import {postNewLogin} from "../../redux/services/request";
import {useSelector} from "react-redux";

export default function PersonalInfo(props) {
  const [loading, setLoading] = useState(false);
  const stateId = props?.location?.state?.stateId;
  const idNo = props?.location?.state?.idNo;
  const nextUrl = props?.location?.state?.nextUrl;
  const status = useSelector(state => state.loginInit );
  const history = useHistory();

  useEffect( () => {
    message.warn('Doğum tarihinizi doğru girerek profilinizi güncellemeniz gerekmektedir')
  },[status,stateId,idNo])

  const handleSubmit = async (data) => {
    setLoading(true);

    const newData = {
      idNo: idNo ?? '',
      birthDate: data.birthDate?.format( 'YYYY-MM-DD' ),
      stateId: stateId ?? ''
    };

    try {
      const response = await postNewLogin({ url: "/auth/update-profile", data: newData });

      if( response?.code !== 200 ) {
        message.error( response?.message );
        setLoading(false );
        return;
      }

      setLoading( false );
      message.success('Tebrikler profiliniz başarılı bir şekilde güncellendi. Tekrar giriş yaparak kullanıma devam edebilirsiniz.')
      localStorage.clear();
      history.push(nextUrl);

    } catch {
      setLoading(false);
    }
  };

  const initialValues = {
    idNo: idNo ?? '',
    birthDate: '',
    stateId: ""
  };

  return (
    <>
      <AuthLayoutForm
        formTitle="Doğum Tarihi Güncelleme"
        button="Güncelle"
        onFinish={ handleSubmit }
        initialValues={initialValues}
        validateFirst={false}
        formName="reset-password"
        loading={loading}
        backButton={false}
      >
        <AuthLayoutInput
          name="birthDate"
          label="Doğum Tarihi"
          hasFeedback
          input={
            <DatePicker
              className="auth-form-input"
              format="DD-MM-YYYY"/>
          }/>
        <AuthLayoutInput
          name="idNo"
          label="T.C. Kimlik Numarası:"
          input={<Input value={idNo}/>}
        />
      </AuthLayoutForm>
    </>
  );
}
