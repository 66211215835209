import React, { useEffect, useState } from "react";
import { Button, Col, Form, message, Row, Spin } from "antd";
import { Icon } from "components/UIComponents";
import Certificate from "./Certificate";
import { useSelector } from "react-redux";
import { getData } from "../../../../../../../redux/services/request";
import moment from "moment";
import Api from "../../../../../../../api/Api";
import { get } from "lodash";
import EiiCertificate from "./EiiCertificate";
import EiiModal from "./EiiModal";
import useGet from "hooks/useGet";
import { changeUrl } from "helpers/methods";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [certificates, setCertificates] = useState([]);
  const [eiiCertificates, setEiiCertificates] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS5 ? state.data?.resumeS5.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  const [eiiModal, setEiiModal] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S5", key: "resumeS5" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail && resume.detail.certificationAndSeminars) {
      const _items = [];
      const formValues = {};
      resume.detail.certificationAndSeminars.forEach((item) => {
        const id = Math.random();
        const values = {
          ...item,
          start: item.start ? moment(item.start) : undefined,
          end: item.end ? moment(item.end) : undefined,
          id: id,
        };
        formValues[id] = values;
        _items.push(values);
      });

      setCertificates(_items);
      form.setFieldsValue(formValues);
    }
  }, [resume, form]);

  useEffect(() => {
    if (resume && resume?.detail && resume?.detail?.ismekCertifications) {
      setEiiCertificates(resume?.detail?.ismekCertifications);
    }
  }, [resume]);

  const add = () => setCertificates([...certificates, { id: Math.random() }]);

  const {
    data: newOrDeletedCertificatesData,
    refresh: newOrDeletedCertificatesDataRefresh,
  } = useGet({
    url: "/candidates/resumes/ismek/certs",
    key: "certs",
  });

  const [newOrDeletedCertificates, setNewOrDeletedCertificates] = useState([]);

  useEffect(() => {
    if (newOrDeletedCertificatesData?.length > 0) {
      setNewOrDeletedCertificates(newOrDeletedCertificatesData);
    }
  }, [newOrDeletedCertificatesData]);

  const [
    selectedNewOrDeletedCertificates,
    setSelectedNewOrDeletedCertificates,
  ] = useState([]);

  console.log("newOrDeletedCertificates", newOrDeletedCertificates);

  const [deleteCertificateLoading, setDeleteCertificateLoading] = useState({
    status: false,
    id: "",
  });

  const addEii = () => {
    if (newOrDeletedCertificatesData) {
      setNewOrDeletedCertificates(newOrDeletedCertificatesData);
      setEiiModal(true);
    }
  };

  const deleteCertificate = async (id) => {
    if (id) {
      setDeleteCertificateLoading({ status: true, id });
      new Api()
        .deleteData({
          url: changeUrl(`candidates/resumes/ismek/certs/${id}`),
        })
        .then((res) => {
          if (res.code === 200) {
            message.success("Sertifika Silindi");
            setDeleteCertificateLoading((prev) => ({ ...prev, status: false }));
            getData({ url: "candidates/resumes/S5", key: "resumeS5" });
            newOrDeletedCertificatesDataRefresh();
          }
        })
        .catch((err) => {
          setDeleteCertificateLoading((prev) => ({ ...prev, status: false }));
          message.error("Sertifika silme sırasında bir hata oluştu");
        });
    }
  };

  const onCancel = () => {
    setEiiModal(false);
  };

  const remove = (i) => {
    const _certificates = [...certificates];
    _certificates.splice(i, 1);
    setCertificates(_certificates);
  };

  const onClickNext = () => {
    setLoading(true);
    const request = form.getFieldsValue();

    new Api()
      .putData({
        url: "/candidates/resumes/certifications",
        _data: { certificationAndSeminars: Object.values(request) },
      })
      .then(() => {
        setPageLoading(true);
        getData({ url: "candidates/resumes/S5", key: "resumeS5" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            message.success("Adım 5 verileri alınırken bir hata meydana geldi");
            setPageLoading(false);
          });
        refresh();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch(() => {
        message.error("Sertifikalar güncellenirken bir hata meydana geldi");
        setLoading(false);
      });
  };
  const onFinishFailed = (err) => {
    const errors = get(err, "errorFields", []).map((x) => {
      return x.errors.map((errItem) => {
        return errItem;
      });
    });
    message.error("Form bilgileri eksik ya da hatalı. " + errors);
  };
  return (
    <>
      <div className="Certificates">
        <Spin spinning={pageLoading}>
          <Row justify="space-between" gutter={50}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <Form
                form={form}
                onFinish={onClickNext}
                onFinishFailed={onFinishFailed}
              >
                <h1 className="fw-bold ls-full text-4xl text-black mb-30">
                  Seminerler ve Sertifikalar
                </h1>
                {certificates.map((x, i) => (
                  <Form.List name={x.id}>
                    {(fields) => (
                      <Certificate
                        data={x}
                        key={x.id}
                        index={i}
                        remove={remove}
                      />
                    )}
                  </Form.List>
                ))}
                <Button
                  className="btn btn-pink-filled radius-lg p-20 text-md mb-20"
                  type="primary"
                  icon={<Icon name="plus" size={26} className="mr-10" />}
                  onClick={add}
                >
                  Ekle
                </Button>
              </Form>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <h1 className="fw-bold ls-full text-4xl text-black mb-30">
                İsmek Sertifikaları
              </h1>
              {!pageLoading && eiiCertificates && eiiCertificates?.length > 0
                ? eiiCertificates.map((x, i) => (
                    <Form.List name={x.id} className="full-width">
                      {(fields) => (
                        <EiiCertificate
                          data={x}
                          key={x.id}
                          index={i}
                          deleteCertificate={deleteCertificate}
                          deleteCertificateLoading={deleteCertificateLoading}
                        />
                      )}
                    </Form.List>
                  ))
                : !pageLoading &&
                  newOrDeletedCertificates?.length === 0 && (
                    <p>
                      İSMEK sertifikalarınızı eklemek için, bilgilerinizi
                      paylaşmak amacıyla onay vermeniz gerekmektedir. Bu işlemi
                      gerçekleştirmek için&nbsp;
                      <a
                        href="https://enstitu.ibb.istanbul/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        https://enstitu.ibb.istanbul/
                      </a>
                      &nbsp; adresini ziyaret edebilirsiniz.
                    </p>
                  )}
              <Button
                className="btn btn-purple-filled radius-lg p-20 text-md"
                style={{ background: "#8e3faf", borderColor: "#8e3faf" }}
                type="primary"
                icon={<Icon name="plus" size={26} className="mr-10" />}
                onClick={addEii}
              >
                Eİİ Sertifikalarımı Getir
              </Button>
            </Col>
            <div className="d-flex mt-50 pt-30 full-width">
              {loading ? (
                <Spin />
              ) : (
                <Button
                  type="primary"
                  htmlType="submit"
                  className="BtnNextTab ml-auto"
                  onClick={onClickNext}
                >
                  Kaydet ve İlerle
                </Button>
              )}
            </div>
          </Row>
        </Spin>
      </div>
      <EiiModal
        eiiModal={eiiModal}
        setEiiModal={setEiiModal}
        onCancel={onCancel}
        newOrDeletedCertificates={newOrDeletedCertificates}
        setNewOrDeletedCertificates={setNewOrDeletedCertificates}
        setSelectedNewOrDeletedCertificates={
          setSelectedNewOrDeletedCertificates
        }
        selectedNewOrDeletedCertificates={selectedNewOrDeletedCertificates}
        newOrDeletedCertificatesDataRefresh={
          newOrDeletedCertificatesDataRefresh
        }
      />
    </>
  );
};
