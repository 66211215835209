import {
  Button,
  Col,
  DatePicker,
  Form,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Select,
  Checkbox,
  Upload,
} from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { LookupSelect } from "components/UIComponents/FormElements";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../../../../redux/services/request";
import moment from "moment";
import Api from "../../../../../../api/Api";
import { Icon } from "../../../../../../components/UIComponents";
import DrivingLicense from "./General/DrivingLicense";
import Disability from "./General/Disability";
import Conviction from "./General/Conviction";
import { get } from "lodash";
import useGetNew from "../../../../../../hooks/useGetNew";
import AcikRiza from "../../../../../../assets/policy/EK-H İSPER_BİO_Sabıka Kaydı Açık Rıza Metni.pdf";
import EngelliAcikRiza from "../../../../../../assets/policy/EK-G İSPER_BİO_Engelilik Durumu Açık Rıza.pdf";

const General = ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [hasConviction, setHasConviction] = useState(false);
  const [hasDisability, setHasDisability] = useState(null);
  const [hasTreatment, setHasTreatment] = useState(null);
  const [drivingLicenses, setDrivingLicenses] = useState([]);
  const [disabilities, setDisabilities] = useState([]);
  const [convictions, setConvictions] = useState([]);
  const [gender, setGender] = useState("Kadın");
  const [militaryStatus, setMilitaryStatus] = useState(null);
  const [city, setCity] = useState(null);
  const [county, setCounty] = useState(null);
  const [loading, setLoading] = useState(false);
  const [workingStatusesState, setWorkingStatusesState] = useState("");
  const [pageLoading, setPageLoading] = useState(false);
  const { data: meData, loading: meLoading } = useGetNew({
    url: "/candidates/me",
    isArray: false,
    key: "me",
  });
  // const results = (state, key) => state[key] && state[key].data ? ('results' in state[key].data ? state[key].data.results : state[key].data) : []

  const results = (state, key) => {
    // Eğer state[key] tanımlı ve state[key].data varsa
    if (state.data[key] && state.data[key].data) {
      // Eğer state[key].data içinde 'results' özelliği varsa
      if ("results" in state.data[key].data) {
        // 'results' özelliği varsa, bu özelliği döndür
        return state.data[key].data.results;
      } else {
        // 'results' özelliği yoksa, state[key].data'yı döndür
        return state.data[key].data;
      }
    } else {
      // Eğer state[key] tanımlı değil veya state[key].data yoksa, boş bir dizi döndür
      return [];
    }
  };
  const workingStatuses = useSelector((state) =>
    results(state, "workingStatuses")
  );
  const maritalStatuses = useSelector((state) =>
    results(state, "maritalStatuses")
  );
  const retirementStatus = useSelector((state) =>
    results(state, "retirementStatus")
  );
  const militaryStatuses = useSelector((state) =>
    results(state, "militaryStatuses")
  );
  const smokingOptions = useSelector((state) =>
    results(state, "smokingOptions")
  );
  const resume = useSelector((state) =>
    state.data?.resumeS1 ? state.data.resumeS1.data : state.data?.resume?.data
  );

  useEffect(() => {
    form.setFieldsValue({
      citizenNo: localStorage.getItem("uid"),
      firstName: localStorage.getItem("firstName"),
      lastName: localStorage.getItem("lastName"),
    });
    setPageLoading(true);
    Promise.all(
      getData({ url: "types/smoking-option", key: "smokingOptions" }),
      getData({ url: "types/military-status", key: "militaryStatuses" }),
      getData({ url: "types/working-status", key: "workingStatuses" }),
      getData({ url: "types/marital-status", key: "maritalStatuses" }),
      getData({ url: "types/retirement-statuses", key: "retirementStatus" })
    )
      .then(() => {
        setPageLoading(false);
      })
      .catch(() => {
        setPageLoading(false);
      });
    reload();
  }, [form]);

  const reload = () => {
    setPageLoading(true);
    refresh();
    getData({ url: "candidates/resumes/S1", key: "resumeS1" })
      .then(() => {
        setPageLoading(false);
      })
      .catch(() => {
        setPageLoading(false);
      });
  };

  useEffect(() => {
    if (meData) {
      const { result: data } = meData;
      setGender(data?.genderOption?.description);
    }
  }, [meData]);

  useEffect(() => {
    if (resume) {
      onProgressChange(resume?.progress, resume?.status);
    }
    if (resume && resume.detail) {
      setHasDisability(
        resume.detail.disabilityConditions &&
          resume.detail.disabilityConditions.length > 0
      );
      setHasConviction(
        resume.detail.convictionStatus &&
          resume.detail.convictionStatus.length > 0
      );
      setMilitaryStatus(resume.detail.militaryStatus);

      setWorkingStatusesState(
        workingStatuses?.find(
          (el) => el?.name === resume?.detail?.workingStatus
        )?.label
      );

      form.setFieldsValue({
        ...resume.detail,
        birthDate: meData
          ? moment(meData?.result?.birthDate, "YYYY-MM-DD")
          : null,
      });

      if (resume && resume.detail.county) {
        setCity(resume.detail.county.cityId);
        form.setFieldsValue({
          countyId: resume.detail.county.id,
        });
      }

      if (resume && resume.detail.county) {
        form.setFieldsValue({
          militaryStatus:
            resume.detail.militaryStatus === "U"
              ? null
              : resume.detail.militaryStatus,
        });
      }

      if (
        resume.detail.disabilityConditions &&
        resume.detail.disabilityConditions.length > 0
      ) {
        const _disabilities = [];
        const _disabilityFormValues = {};
        resume.detail.disabilityConditions.forEach((disability) => {
          const id = Math.random();
          disability = {
            disabilityType: disability.disabilityId.disabilityId,
            disabilityPercentage: disability.percentage,
            disabilityDescription: disability.description,
          };
          _disabilityFormValues[`disabilities_${id}`] = disability;
          _disabilities.push({ id, ...disability });
        });

        setHasDisability(true);
        setDisabilities(_disabilities);
        form.setFieldsValue(_disabilityFormValues);
      }

      if (
        resume.detail.convictionStatus &&
        resume.detail.convictionStatus.length > 0
      ) {
        const _convictions = [];
        const _convictionFormValues = {};
        resume.detail.convictionStatus.forEach((conviction) => {
          const id = Math.random();
          _convictionFormValues[`convictions_${id}`] = conviction;
          _convictions.push({ id, ...conviction });
        });

        setHasConviction(true);
        setConvictions(_convictions);
        form.setFieldsValue(_convictionFormValues);
      }

      if (
        resume.detail.drivingLicenses &&
        resume.detail.drivingLicenses.length > 0
      ) {
        const _drivingLicenses = [];
        const _drivingLicenseFormValues = {};
        resume.detail.drivingLicenses.forEach((drivingLicense) => {
          const id = Math.random();
          _drivingLicenseFormValues[`drivingLicenses_${id}`] = drivingLicense;
          _drivingLicenses.push({ id, ...drivingLicense });
        });

        setDrivingLicenses(_drivingLicenses);
        form.setFieldsValue(_drivingLicenseFormValues);
      }

      if (resume.detail.sustainReqTreatment) {
        form.setFieldsValue({ sustainReqTreatment: "Y" });
        form.setFieldsValue({
          sustainReqTreatmentDescription:
            resume.detail.sustainReqTreatmentDescription,
        });
        setHasTreatment("Y");
      } else {
        form.setFieldsValue({ sustainReqTreatment: "N" });
        setHasTreatment("N");
      }
    }
  }, [resume, form]);

  // const handleFinish = () => {
  //   setLoading(true)
  //   const request = form.getFieldsValue()
  //   delete request.birthDate;
  //   delete request.genderType;
  //   const formKeys = Object.keys(request)
  //   request.drivingLicenses = []
  //   request.disabilityConditions = []
  //   request.convictionStatus = []
  //   request.militaryStatus = militaryStatus;

  //   formKeys.forEach(key => {
  //     if (key.startsWith('drivingLicenses_')) {
  //       request.drivingLicenses.push(request[key])
  //     }

  //     if (key.startsWith('disabilities_')) {
  //       request.disabilityConditions.push({
  //         ...request[key],
  //         percentage: request[key].disabilityPercentage,
  //         disabilityId: request[key].disabilityType,
  //         description: request[key].disabilityDescription,
  //       })
  //     }
  //     if (key.startsWith('convictions_')) {
  //       request.convictionStatus.push(request[key])
  //     }
  //   })

  //   if(request.birthCityId === undefined){
  //     request.birthCityId = null
  //   }

  //   if(request.nationality === undefined){
  //     request.nationality = null
  //   }

  //   if(request.smokingOption === undefined){
  //     request.smokingOption = null
  //   }

  //   if(request.address === undefined){
  //     request.address = null
  //   }

  //   let apiCall = () => new Api().postData({ url: '/candidates/resumes/new', _data: request })
  //   if (resume && resume.detail && parseInt(resume.progress) > 0) {
  //     apiCall = () => new Api().putData({ url: '/candidates/resumes/personal', _data: request })
  //   }

  //   apiCall().then(() => {
  //     reload()
  //     setActive((parseInt(active) + 1).toString())
  //     onProgressChange(resume)
  //     message.success('Özgeçmiş kaydedildi');
  //     setLoading(false)
  //   }).catch(() => {
  //     message.error('Özgeçmiş kaydedilirken bir hata meydana geldi');
  //     setLoading(false)
  //   })
  // }

  const handleFinish = () => {
    setLoading(true);
    const request = form.getFieldsValue();

    // Kullanılmayan özellikleri çıkar
    const { birthDate, genderType, ...rest } = request;

    // Yeni özellik dizileri oluştur
    const drivingLicenses = [];
    const disabilityConditions = [];
    const convictionStatus = [];

    // Alanları dolaş ve uygun dizilere ekle
    Object.entries(rest).forEach(([key, value]) => {
      if (key.startsWith("drivingLicenses_")) {
        drivingLicenses.push(value);
      } else if (key.startsWith("disabilities_")) {
        disabilityConditions.push({
          ...value,
          percentage: value.disabilityPercentage,
          disabilityId: value.disabilityType,
          description: value.disabilityDescription,
        });
      } else if (key.startsWith("convictions_")) {
        convictionStatus.push(value);
      }
    });

    // API çağrısını hazırla
    const apiMethod =
      resume && resume.detail && parseInt(resume.progress) > 0
        ? "putData"
        : "postData";
    const apiEndpoint =
      apiMethod === "postData"
        ? "/candidates/resumes/new"
        : "/candidates/resumes/personal";

    // Sadece tanımlı olan alanları gönder
    const apiRequest = {
      ...rest,
      drivingLicenses,
      disabilityConditions,
      convictionStatus,
      militaryStatus,
      birthCityId: rest.birthCityId || null,
      address: rest.address || null,
    };

    // smokingOption ve nationality tanımlıysa ekle
    if (rest.smokingOption !== undefined) {
      apiRequest.smokingOption = rest.smokingOption;
    }
    if (rest.nationality !== undefined) {
      apiRequest.nationality = rest.nationality;
    }

    new Api()
      [apiMethod]({ url: apiEndpoint, _data: apiRequest })
      .then(() => {
        reload();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        onProgressChange(resume?.progress, resume?.status);
        message.success("Özgeçmiş kaydedildi");
      })
      .catch(() => {
        message.error("Özgeçmiş kaydedilirken bir hata meydana geldi");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (err) => {
    const errors = get(err, "errorFields", []).map((x) => {
      return x.errors.map((errItem) => {
        return errItem;
      });
    });
    message.error("Form bilgileri eksik ya da hatalı." + errors);
  };

  const addDrivingLicense = () =>
    setDrivingLicenses([...drivingLicenses, { id: Math.random() }]);

  const removeDrivingLicense = (id) => {
    setDrivingLicenses(drivingLicenses.filter((x) => x.id !== id));
  };

  const addDisability = () =>
    setDisabilities([...disabilities, { id: Math.random() }]);

  const removeDisability = (id) => {
    if (disabilities.length === 1) setHasDisability(false);
    setDisabilities(disabilities.filter((x) => x.id !== id));
  };

  const handleSetDisability = (value) => {
    setHasDisability(value);

    if (!value) {
      setDisabilities([]);
      return;
    }

    setDisabilities([{ id: Math.random() }]);
  };

  const addConviction = () =>
    setConvictions([...convictions, { id: Math.random() }]);
  const removeConviction = (id) => {
    if (convictions.length === 1) setHasConviction(false);
    setConvictions(convictions.filter((x) => x.id !== id));
  };
  const onChangeCity = (cityId) => {
    setCity(cityId);
  };

  return (
    <div className="General">
      <Spin spinning={pageLoading}>
        <Form
          form={form}
          onFinish={handleFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row justify="space-between">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <div className="First full-width">
                <h1 className="fw-bold ls-full text-4xl text-black mb-30">
                  Kişisel Bilgiler
                </h1>
                <Form.Item name="citizenNo">
                  <Input
                    className="form-input"
                    placeholder="T.C. Kimlik Numarası"
                    value={localStorage.getItem("uid")}
                    disabled
                  />
                </Form.Item>
                <Form.Item name="firstName">
                  <Input
                    className="form-input"
                    placeholder="Adı"
                    value={localStorage.getItem("firstName")}
                    disabled
                    style={{ textTransform: "capitalize" }}
                  />
                </Form.Item>
                <Form.Item name="lastName">
                  <Input
                    className="form-input"
                    placeholder="Soyadı"
                    value={localStorage.getItem("lastName")}
                    disabled
                    style={{ textTransform: "uppercase" }}
                  />
                </Form.Item>
                <Form.Item name="nationality" className={"mt-20"}>
                  <LookupSelect
                    url="nationalities"
                    placeholder="Uyruk"
                    key={"id"}
                    labelKey={"name"}
                  />
                </Form.Item>
                <Form.Item
                  name="interestedInPositionIds"
                  className="mb-20"
                  rules={[
                    {
                      required: true,
                      message:
                        "Lütfen ilgili olduğunuz pozisyonları belirtiniz",
                    },
                  ]}
                >
                  <LookupSelect
                    url="/professions"
                    labelKey="profession"
                    valueKey="id"
                    mode="multiple"
                    placeholder="İlgilendiğiniz pozisyonlar (Zorunlu)"
                  />
                </Form.Item>
                <Form.Item
                  name="interestedInSectorIds"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen tercih edilen sektörleri belirtiniz",
                    },
                  ]}
                >
                  <LookupSelect
                    url="/sectors"
                    labelKey="sectorName"
                    valueKey="sectorId"
                    placeholder="Terchi Edilen Sektör  (Zorunlu)"
                    mode="multiple"
                  />
                </Form.Item>

                <h1 className="text-xl text-black ls-half fw-bold mt-60 mb-20">
                  İş Durumu <span className="text-orange-bio">(*)</span>
                </h1>
                <Form.Item
                  name="workingStatus"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: "Lütfen çalışma durumunu belirtiniz",
                    },
                  ]}
                >
                  <Radio.Group className="form-radio full-width">
                    <Row className="full-width">
                      {workingStatuses.map((item) => (
                        <Col span={12}>
                          <Radio
                            value={item.name}
                            onChange={() =>
                              setWorkingStatusesState(item?.label)
                            }
                          >
                            {item.label}
                          </Radio>
                        </Col>
                      ))}
                    </Row>
                  </Radio.Group>
                </Form.Item>

                {/* {workingStatusesState &&
                  workingStatusesState === "Çalışmıyor" && (
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      style={{ marginTop: "15px" }}
                    >
                      <Form.Item
                        name="file"
                        rules={[
                          { required: true, message: "Dosya Yükleyiniz" },
                        ]}
                      >
                        <Upload.Dragger
                          beforeUpload={false}
                          height={130}
                          className="mb-20"
                        >
                          <p className="ant-upload-drag-icon">
                            <InboxOutlined max={1} />
                          </p>
                          <p className="ant-upload-text">
                            Dosyalarınızı seçmek için tıklayın ya da buraya
                            sürükleyin
                          </p>
                        </Upload.Dragger>
                      </Form.Item>
                    </Col>
                  )} */}

                <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                  Cinsiyeti <span className="text-orange-bio">(*)</span>
                </h1>
                <Input className="form-input" value={gender} disabled />
              </div>

              <Form.Item
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: "Lütfen engellilik durumunu belirtiniz",
                  },
                ]}
              >
                <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                  Engellilik Durumu <span className="text-orange-bio">(*)</span>
                </h1>

                <Checkbox
                  value={hasDisability}
                  onChange={(e) => handleSetDisability(e.target.checked)}
                >
                  {" "}
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    Engelli statüsünde iş başvurumu yapmak istiyorum ve bu
                    kapsamda kişisel verilerimin işlenmesine{" "}
                    <a target="_blank" href={EngelliAcikRiza}>
                      {" "}
                      Açık Rıza
                    </a>{" "}
                    veriyorum.{" "}
                  </span>
                </Checkbox>
              </Form.Item>
              {hasDisability && (
                <div className="p-20 mt-10 bg-softgrey">
                  {disabilities.map((x, i) => (
                    <Form.List name={"disabilities_" + x.id}>
                      {(fields) => (
                        <Disability
                          key={x.id}
                          item={x}
                          index={i}
                          remove={() => removeDisability(x.id)}
                          disabilities={disabilities}
                        />
                      )}
                    </Form.List>
                  ))}
                  <Button
                    className="BtnAddDisability btn btn-pink-filled radius-lg"
                    type="primary"
                    icon={<Icon name="plus" size={26} className="mr-15" />}
                    onClick={addDisability}
                  >
                    Engellilik Durumu Ekle
                  </Button>
                </div>
              )}

              <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                Sigara kullanımınız var mı?
              </h1>
              <Form.Item name="smokingOption" className="mb-0">
                <Radio.Group className="form-radio full-width">
                  <Row className="full-width">
                    {smokingOptions.map((item) => {
                      return (
                        <Col span={12} key={item.name}>
                          <Radio value={item.name}>{item.label}</Radio>
                        </Col>
                      );
                    })}
                  </Row>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 9 }}>
              <h1 className="fw-bold ls-full text-4xl text-black mb-30 v-hidden">
                Kişisel Bilgiler
              </h1>
              <Form.Item name="birthCityId">
                <LookupSelect
                  url="/locations/cities"
                  valueKey="id"
                  labelKey="city"
                  placeholder="Doğum Yerini"
                />
              </Form.Item>
              <Form.Item name="birthDate" className="mb-10">
                <DatePicker
                  className="form-input full-width"
                  placeholder={
                    meData && moment(meData?.result?.birthDate, "YYYY-MM-DD")._i
                  }
                  disabled
                />
              </Form.Item>
              <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                Askerlik Durumu <span className="text-orange-bio">(*)</span>
              </h1>
              <Form.Item
                name="militaryStatus"
                className="mb-0"
                rules={[
                  {
                    required: gender === "M",
                    message: "Erkek adaylar için askerlik bilgisi zorunludur.",
                  },
                ]}
              >
                <Radio.Group
                  className="form-radio full-width"
                  onChange={(e) => setMilitaryStatus(e.target.value)}
                  value={militaryStatus}
                >
                  <Row>
                    {militaryStatuses.map((item) => (
                      <>
                        {item.name !== "U" && (
                          <Col span={12}>
                            <Radio value={item.name}>{item.label}</Radio>
                          </Col>
                        )}
                      </>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
              <h1 className="text-xl text-black ls-half fw-bold mb-20 mt-40">
                Medeni Durum <span className="text-orange-bio">(*)</span>
              </h1>
              <Form.Item
                name="maritalStatus"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: "Lütfen medeni halinizi belirtiniz",
                  },
                ]}
              >
                <Radio.Group className="form-radio">
                  <Row>
                    {maritalStatuses.map((item) => (
                      <Col span={8}>
                        <Radio value={item.name}>{item.label}</Radio>
                      </Col>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
              <h1 className="text-xl text-black ls-half fw-bold mb-20 mt-40">
                Emeklilik Durumu <span className="text-orange-bio">(*)</span>
              </h1>
              <Form.Item
                name="retirementStatus"
                className="mb-0"
                rules={[
                  { required: true, message: "Lütfen durumunuzu belirtiniz" },
                ]}
              >
                <Radio.Group className="form-radio">
                  <Row>
                    {retirementStatus.map((item) => (
                      <>
                        {item.name !== "U" && (
                          <Col span={12}>
                            <Radio value={item.name}>{item.label}</Radio>
                          </Col>
                        )}
                      </>
                    ))}
                  </Row>
                </Radio.Group>
              </Form.Item>
              <div className={"mt-40 p-20"}>
                {drivingLicenses.map((item) => (
                  <Form.List name={"drivingLicenses_".concat(item.id)}>
                    {(fields) => (
                      <DrivingLicense
                        fields={fields}
                        item={item}
                        drivingLicenses={drivingLicenses}
                        remove={() => removeDrivingLicense(item.id)}
                      />
                    )}
                  </Form.List>
                ))}
                <Button
                  className="BtnAddEducation btn btn-pink-filled radius-lg"
                  type="primary"
                  icon={<Icon name="plus" size={26} className="mr-10" />}
                  onClick={addDrivingLicense}
                >
                  <span style={{ fontSize: "14px" }}>Sürücü Belgesi Ekle</span>
                </Button>
              </div>

              <h1 className="text-md text-black ls-half fw-bold mt-40 mb-20">
                Sabıka Kaydı <span className="text-orange-bio">(*)</span>
              </h1>
              <div className={"mt-40 conviction"}>
                <Checkbox
                  value={hasConviction}
                  onChange={(e) => setHasConviction(e.target.checked)}
                  checked={hasConviction}
                >
                  {" "}
                  <span style={{ marginLeft: "10px" }}>
                    {" "}
                    Sabıka kayıtlı statüsünde iş başvurumu yapmak istiyorum ve
                    bu kapsamda kişisel verilerimin işlenmesine{" "}
                    <a target="_blank" href={AcikRiza}>
                      {" "}
                      Açık Rıza
                    </a>{" "}
                    veriyorum.{" "}
                  </span>
                </Checkbox>
              </div>
              {hasConviction && (
                <div
                  className="p-20 mt-10 bg-softgrey"
                  style={{
                    display: "flex",
                    flexDirection: " column",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  {convictions.map((x, i) => (
                    <Form.List name={"convictions_" + x.id}>
                      {(fields) => (
                        <Conviction
                          key={x.id}
                          data={x}
                          index={i}
                          remove={() => removeConviction(x.id)}
                        />
                      )}
                    </Form.List>
                  ))}
                  <Button
                    className="BtnAddDisability btn btn-pink-filled radius-lg"
                    type="primary"
                    icon={<Icon name="plus" size={24} className="mr-10" />}
                    onClick={addConviction}
                  >
                    Ekle
                  </Button>
                </div>
              )}
              <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                İkamet Bilgileri<span className="text-orange-bio">(*)</span>
              </h1>
              <Col>
                <div className="full-width">
                  <LookupSelect
                    url="/locations/cities"
                    labelKey="city"
                    valueKey="id"
                    placeholder="İl"
                    className="mb-20"
                    onChange={onChangeCity}
                    value={city}
                    stateKey="cities"
                  />
                  <Form.Item
                    name="countyId"
                    rules={[{ required: true, message: "İlçe" }]}
                  >
                    {city && (
                      <LookupSelect
                        url={`locations/cities/${city}`}
                        labelKey="county"
                        valueKey="id"
                        placeholder="İlçe"
                        value={county}
                        dataContainerKey="counties"
                      />
                    )}
                  </Form.Item>
                  <Form.Item name="address">
                    <Input.TextArea
                      placeholder="Adres Bilgisi Giriniz"
                      rows={10}
                      maxLength={4000}
                      showCount
                    />
                  </Form.Item>
                </div>
              </Col>

              {/* <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">Süreklilik arz eden ve takip
                  gerektiren bir tedaviniz var mı?</h1>
                <Form.Item name="sustainReqTreatment" className="mb-0" rules={[{required: true}]}>
                  <Radio.Group className="form-radio full-width" onChange={e => setHasTreatment(e.target.value)}>
                    <Row className="full-width">
                      <Col span={12}>
                        <Radio value="Y">Evet</Radio>
                      </Col>
                      <Col span={12}>
                        <Radio value="N">Hayır</Radio>
                      </Col>
                    </Row>
                  </Radio.Group>
                </Form.Item>

                {hasTreatment === "Y" &&
                <Form.Item name="sustainReqTreatmentDescription" rules={[{required: true}]}>
                  <Input.TextArea placeholder="Tedavi açıklamasını giriniz" rows={10} maxLength={4000} showCount/>
                </Form.Item>
                } */}
            </Col>
          </Row>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};

export default General;
