
const Experience = ({ company, title, era, duration, description }) => {
  return (
    <>
      <div className="experience-wrapper">
        <div className="title-container">
          <h4 className="company-title">{company}</h4>
          <p className="candidate-title">{title}</p>
        </div>
        <div className="time-container">
          <p className="date">{ era }</p>
          <p className="experience-time">{ duration }</p>
        </div>
      </div>
      <div className="experience-description">
        { description }
      </div>
    </>
  );
};

export default Experience;
