import { useEffect } from "react";
import { Row, Col, Form, Input, Button, message, Spin } from "antd";
import { Banner } from "components/UIComponents";
import { Helmet } from "react-helmet";
import useGet from "hooks/useGet";
import { postData2 } from "redux/services/request";
import MaskedInput from "react-maskedinput";

export default () => {
  const [form] = Form.useForm();

  const fullName = localStorage.getItem("fullName");

  const { data: myCompany, loading: loadingMyCompany } = useGet({
    url: "/companies/me",
    key: "companiesMe",
    isArray: false,
  });

  const { data: staff } = useGet({
    url: "/companies/staff",
    key: "companiesStaff",
    isArray: false,
  });

  useEffect(() => {
    if (myCompany) {
      form.setFieldsValue({
        companyName: myCompany?.companyName,
        companyCity: myCompany?.county?.city,
        companyDistrict: myCompany?.county?.county,
      });
    }
  }, [form, myCompany]);

  const onFinish = async (values) => {
    try {
      if (myCompany && fullName && staff?.results) {
        const formData = {
          message: values?.message,
          phoneNumber: values?.phoneNumber,
          companyId: myCompany?.id?.id,
          companyCandidateId: staff?.results?.find(
            (user) => user.firstName + " " + user.lastName === fullName
          ).id,
        };
        if (Object.values(formData).includes(undefined)) {
          message.error("Beklenmeyen bir hata oluştu");
        } else {
          const response = await postData2({
            url: "company/notes",
            data: formData,
          });
          if (response.code === 200 && response?.result?.id) {
            message.success("Mesaj Gönderildi.");
          } else {
            //message.error(response?.result?.message);
            message.error("Beklenmeyen Bir Hata Oluştu.");
          }
          form.setFieldsValue({
            message: "",
            phoneNumber: "",
          });
        }
      }
    } catch (error) {
      // message.error(error.data.message);
    }
  };

  return (
    <div className="JobCreate">
      <Helmet>
        <title>İş Ara İş Bul | Firma Not Oluştur</title>
        <meta
          name="description"
          content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
        />
        <meta
          name="keywords"
          content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
        />
      </Helmet>
      <Banner text="Not Oluştur" className="mb-0" />
      <div className="content md content-detail pl-100">
        <h1 className="text-4xl fw-bold ls-full mb-30">FİRMA BİLGİLERİ</h1>
        <Row className="full-width">
          <Form className="full-width" form={form} onFinish={onFinish}>
            <Spin spinning={loadingMyCompany}>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item name="companyName">
                  <Input className="form-input mb-20" disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item name="companyCity">
                  <Input className="form-input mb-20" disabled />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
                <Form.Item name="companyDistrict">
                  <Input className="form-input mb-20" disabled />
                </Form.Item>
              </Col>
            </Spin>
            <h1 className="text-4xl fw-bold ls-full mb-30">NOT BİLGİLERİ</h1>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
              <Form.Item
                name="phoneNumber"
                rules={[{ required: true, message: "telefon boş bırakılamaz" }]}
              >
                <MaskedInput
                  type="tel"
                  className="form-input mb-20"
                  placeholder="Telefon"
                  mask="01111111111"
                  size="11"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
              <Form.Item
                name="message"
                rules={[
                  { required: true, message: "mesaj boş bırakılamaz" },
                  { whitespace: true, message: "lütfen bir değer girin." },
                ]}
              >
                <Input.TextArea
                  rows={6}
                  className="form-input mb-20"
                  placeholder="Mesaj"
                />
              </Form.Item>
            </Col>
            <div className="d-flex j-center mt-50">
              <Button className="BtnSave" htmlType="submit">
                Yayınla
              </Button>
            </div>
          </Form>
        </Row>
      </div>
    </div>
  );
};
