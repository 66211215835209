import AuthLayout from './AuthLayout';
import { Form, Button, Typography } from "antd";
import { SubLinkGenerate } from './utility';
import '../../../styles/layouts/authLayout.less';
import Callback from "../Callback";
import BrowserHelmet from "../../../components/Layout/Helmet";
import IstanbulSeninButton from "./IstanbulSenin";

const {Title} = Typography;

const AuthLayoutForm = ({ formTitle, formName, button, children, onFinish, initialValues, redirect, redirectUrl, redirectQuestion, redirectButton, disabled, loading, buttonStyle, backButton, istanbulSenin = false, seoDescription, seoTitle, seoKeywords  }) => {
  const [form] = Form.useForm();

  return (
    <>
      <BrowserHelmet description={seoDescription} title={seoTitle} keywords={seoKeywords} />
      <AuthLayout backButton={backButton} >
        <Title className="auth-form-title">{ formTitle }</Title>
        <Form
          form={ form }
          name={ formName }
          onFinish={ onFinish }
          initialValues={ initialValues }
          className="auth-form"
        >
          { loading ? <Callback/> : children }
          <Button type="primary" htmlType="submit" disabled={ disabled } loading={ loading } className="auth-form-button" style={ buttonStyle }>{button}</Button>
          { istanbulSenin && <IstanbulSeninButton/> }
          { redirect && SubLinkGenerate( redirectQuestion, redirectButton, redirectUrl )}
        </Form>
      </AuthLayout>
    </>
  );
};

export default AuthLayoutForm;
