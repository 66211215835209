import {Col, Form, InputNumber, Radio, Row} from 'antd';
import {LookupSelect} from "components/UIComponents/FormElements";
import {Icon} from "components/UIComponents";
import React, {useEffect, useState} from "react";
import StartDate from "../components/StartDate";
import EndDate from "../components/EndDate";

export default ({data}) => {
  const [university, setUniversity] = useState()
  const [faculty, setFaculty] = useState()
  const [department, setDepartment] = useState()
  const [ongoing, setOngoing] = useState()
  const [gradingType, setGradingType] = useState()

  useEffect(() => {
    if (data) {
      setUniversity(data.instituteId)
      setFaculty(data.facultyCode)
      setDepartment(data.departmentId)
      setOngoing(data.status)
      setGradingType(data.gradingType)
    }
  }, [data])

  return (
    <Row justify="space-between">
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Form.Item name="instituteId" rules={[{required: true, message:"Üniversite"}]}>
          <LookupSelect url="/educations/universities" labelKey="name" valueKey="unitHashId"
                        placeholder="Üniversite" className="mb-20" onChange={setUniversity}/>
        </Form.Item>
      </Col>
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Form.Item name="facultyCode" rules={[{required: true, message:"Fakülte"}]}>
          <LookupSelect url={university && `/educations/universities/${university}/faculties`} labelKey="facultyName"
                        valueKey="facultyCode"
                        placeholder="Fakülte" className="mb-20" onChange={setFaculty} value={faculty}/>
        </Form.Item>
      </Col>
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Form.Item name="departmentId" rules={[{required: true, message:"Bölüm"}]}>
          <LookupSelect
            url={university && faculty && `/educations/universities/${university}/faculties/${faculty}/departments`}
            labelKey="departmentName" valueKey="departmentCode"
            placeholder="Bölüm" className="mb-20" value={department} onChange={setDepartment}/>
        </Form.Item>
      </Col>
      {/* <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        {ongoing === 'GRADUATED' && <Form.Item name="gradingType">
          <LookupSelect url={`/educations/grading-types`}
                        labelKey="label" valueKey="name"
                        value={gradingType}
                        onChange={setGradingType}
                        placeholder="Not Sistemi" className="mb-20"/>
        </Form.Item>}
      </Col> */}
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}} className="d-flex a-center mb-20">
        <Icon name="calendar" size={42}/>
        <StartDate/>
        {ongoing !== 'ONGOING' && <EndDate/>}
      </Col>
      {/* <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        {ongoing === 'GRADUATED' && <Form.Item name="grade">
          <InputNumber className="form-input half-width" placeholder="Diploma Notu"/>
        </Form.Item>}
      </Col> */}
      <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
        <Row className="full-width">
          <Col span={24}>
            <h1 className="text-xl fw-bold ls-full mb-20 mt-30">Devam Durumu <span className="text-orange-bio">(*)</span></h1>
            <Form.Item name="status" rules={[{required: true, message:"Devam Durumu Belirtiniz"}]}>
              <Radio.Group className="form-radio mb-50" value={ongoing} onChange={e => setOngoing(e.target.value)}>
                <Radio value="ONGOING">Devam Ediyor</Radio>
                <Radio value="GRADUATED">Mezun</Radio>
                <Radio value="ABANDON">Terk</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
