import { Row, Col, Form, Input, DatePicker, Button, Divider, Pagination, Spin, Empty } from 'antd';
import { get } from 'lodash';
import { Icon } from 'components/UIComponents';
import Job from "../Job";

const Applications = ({ data, loading, onChangeFilter, clearFilters, onChangePage, pageRender, refreshFavorites }) => {
  const [form] = Form.useForm();

  const clear = () => {
    form.resetFields();
    clearFilters('applications');
  }

  return (
    <div className="Applications">
      <div className="d-flex j-center ">
        <Form form={form} onFinish={data => onChangeFilter('applications', data)} className="CandidateJobFilter d-flex j-between full-width">
          <Form.Item name="advertisementNo" className="f-1">
            <Input className="form-input full-width" placeholder="İlan Numarası" />
          </Form.Item>
          <Form.Item name="companyName" className="f-1">
            <Input className="form-input" placeholder="Firma Adı" />
          </Form.Item>
          <Form.Item name="positionTitle" className="f-1">
            <Input className="form-input" placeholder="Pozisyon" />
          </Form.Item>
          <Form.Item name="releaseDate" className="f-1">
            <DatePicker className="form-input full-width" placeholder="Tarih" suffixIcon={<Icon name="double-arrow" size={30} />} format="DD/MM/YYYY" />
          </Form.Item>
          <Form.Item className='button-padding'>
              <Button type="default" className="BtnFilter Blue" onClick={() => clear()}>Temizle</Button>
          </Form.Item>
          <Form.Item  className='button-padding'>
             <Button type="primary" className="BtnFilter Pink" htmlType="submit">ARA</Button>
          </Form.Item>
          
        </Form>
      </div>
      <Divider className="mt-40" />
      <Spin spinning={loading}>
        {
          get(data, 'results.length', 0) !== 0 ? get(data, 'results').map(x =>
            <Job job={x} refreshFavorites={refreshFavorites} />
          )
            :
            <Empty description="Veri bulunamadı" />
        }
      </Spin>
      { get(data, 'results.length', 0) !== 0 &&
        <Row>
          <Col span={24} className="d-flex j-center">
            <Pagination
              current={get(data, 'page')}
              onChange={(page, pageSize) => onChangePage('applications', page)}
              total={get(data, 'total')}
              itemRender={pageRender} />
          </Col>
        </Row>}
    </div>
  );
};

export default Applications;