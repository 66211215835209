
import { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Button, Modal, message } from 'antd';
import { get } from 'lodash';
import { postData } from 'redux/services/request';

const Email = ({ visible, setVisible, data, candidateId }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onSubmit = async (model) => {
    setLoading(true);
    try {
      const data = {
        ids: [candidateId],
        ...model
      }
      await postData({ url: `/candidates/direct-mail`, data: data });
      message.success('E-Posta Gönderildi');
    } catch (err) {
      message.error('E-Posta gönderilirken bir hata meydana geldi');
    } finally {
      setLoading(false);
      setVisible(false);
    }
  }

  useEffect(() => {
    form.resetFields();
  }, [visible, form]);

  return (
    <Modal className="InviteModal" visible={visible} width={800} onCancel={() => setVisible(false)} footer={null} closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri" />}>
      <Form form={form} onFinish={onSubmit}>
        <Row className="full-width">
          <Col span={24}>
            <h1 className="fw-bold mb-20">
              <span className="text-blue mr-10">{get(data, 'firstName', '-')} {get(data, 'lastName', '-')}</span>isimli adaya E-Posta Gönder
          </h1>
          </Col>
          <Col span={13}>
            <Form.Item name="to">
              <Input className="form-input" placeholder="Alıcı" />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item name="cc">
              <Input className="form-input" placeholder="İletilenler" />
            </Form.Item>
          </Col>
          <Col span={13}>
            <Form.Item name="subject">
              <Input className="form-input" placeholder="Konu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="body">
              <Input.TextArea rows={6} placeholder="Metin" />
            </Form.Item>
          </Col>
          <Button htmlType="submit" className="InviteBtn" loading={loading}>E-Posta Gönder</Button>
        </Row>
      </Form>
    </Modal>
  );
};

export default Email;