import { Row, Col, Rate } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { LookupSelect } from "components/UIComponents/FormElements";
import { useEffect, useState } from "react";

export default ({ data, onChange, remove, index,setLanguageDisabledBtn }) => {

  const [languageId, setLanguageId] = useState(null);
  const [readingLevel, setReadingLevel] = useState(0);
  const [writingLevel, setWritingLevel] = useState(0);
  const [speakingLevel, setSpeakingLevel] = useState(0);
  const [translatingLevel, setTranslatingLevel] = useState(0);

  const [languageErrorMessage,setLanguageErrorMessage] = useState('');

  useEffect(() => {
    if(languageId && (!readingLevel || !writingLevel || !speakingLevel || !translatingLevel)){
      setLanguageDisabledBtn(true);
      setLanguageErrorMessage('Boş bırakılamaz');
    }else{
      setLanguageDisabledBtn(false);
      setLanguageErrorMessage('');
    }
  },[languageId,readingLevel,writingLevel,speakingLevel,translatingLevel,setLanguageDisabledBtn]);

  useEffect(() => {
    if (data) {
      setLanguageId(data.languageId);
      setReadingLevel(data.readingLevel);
      setWritingLevel(data.writingLevel);
      setSpeakingLevel(data.speakingLevel);
      setTranslatingLevel(data.translatingLevel);
    }
  }, [data]);

  return (
    <div className="ForeignLanguage">
      <Row className="full-width" gutter={30} justify="space-between" align="middle">
        <Col style={{ width: 20 }}>
          <DeleteOutlined onClick={() => remove(languageId)} />
        </Col>
        <Col>
          <LookupSelect
            url="/languages"
            valueKey="id"
            labelKey="name"
            className="form-select w-200"
            placeholder="Dil"
            value={languageId}
            onChange={languageId => onChange(index, {
              languageId,
              readingLevel,
              writingLevel,
              speakingLevel,
              translatingLevel
            })} />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Okuma</p>
          <Rate
            className="text-4xl l-1"
            value={readingLevel}
            onChange={x => {
              setReadingLevel(x);
              onChange(index, {
                languageId,
                readingLevel: x,
                writingLevel,
                speakingLevel,
                translatingLevel
              })
            }}
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>}
          />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Yazma</p>
          <Rate
            value={writingLevel}
            onChange={x => {
              setWritingLevel(x);
              onChange(index, {
                languageId,
                readingLevel,
                writingLevel: x,
                speakingLevel,
                translatingLevel
              })
            }}
            className="text-4xl l-1" character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>} />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Konuşma</p>
          <Rate
            value={speakingLevel}
            onChange={x => {
              setSpeakingLevel(x);
              onChange(index, {
                languageId,
                readingLevel,
                writingLevel,
                speakingLevel: x,
                translatingLevel
              })
            }}
            className="text-4xl l-1"
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="none" fillRule="evenodd">
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>} />
        </Col>
        <Col>
          <p className={`text-xl mb-10 ${languageErrorMessage ? 'text-red':''}`}>Tercüme</p>
          <Rate
            value={translatingLevel}
            onChange={x => {
              setTranslatingLevel(x);
              onChange(index, {
                languageId,
                readingLevel,
                writingLevel,
                speakingLevel,
                translatingLevel: x
              })
            }}
            className="text-4xl l-1"
            character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
              <g fill="red" fillRule="evenodd" >
                <g fill="currentColor">
                  <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                </g>
              </g>
            </svg>} />
        </Col>
      </Row>
            {languageErrorMessage && <span className="text-lg text-red">{languageErrorMessage}</span>}
    </div>
  );
}
