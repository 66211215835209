import { logoutIstSenin } from "helpers/methods";
import { getDataNew } from "../services/request";
import {  message } from "antd";

export const loadCompanyData = () => async (dispatch) => {
  dispatch({ type: 'COMPANY_DATA_START' });
  const logout = async () => {
      localStorage.clear();
      logoutIstSenin();
      try {
        window.location.replace("/");
      } catch (e) {
        message.error('Çıkış işleminde bir hata oluştu');
      }
  }
  try {
    const data = await getDataNew({ url: '/companies/me', key: 'companiesMe' });
    (data.code!==100 && data.code!==101) ? dispatch({ type: 'COMPANY_DATA_SUCCESS', payload: data }): logout()
  } catch (error) {
    dispatch({ type: 'COMPANY_DATA_ERROR', error });
  }
};