import { useEffect, useState } from 'react';
import queryString from 'query-string';
import { get } from 'lodash';
import { Row, Col, Select, Divider, Pagination, Spin } from 'antd';
import useGet from 'hooks/useGet';
import Job from './Job';
import { useHistory } from 'react-router-dom';

const Results = ({ searchProp }) => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const [infoText, setInfoText] = useState('');
  const [url, setUrl] = useState(localStorage.getItem('accessToken') ? '/jobs' : '/public/jobs');
  const [params, setParams] = useState(queryString.parse(history.location.search));

  const { data, loading } = useGet({ url, key: 'jobs', isArray: false, params });

  useEffect(() => {
    setPage(get(data, 'page', 1));
  }, [data]);

  useEffect(() => {
    setParams(queryString.parse(history.location.search));
  }, [history.location.search]);

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />

    return originalElement;
  }

  const setSort = (sort) => {
    if (sort === 'favorite') {
      setUrl('/jobs/favorites');
      setInfoText('Favori İlanlar Listeleniyor');
      setParams(null);
    } else {
      setInfoText('İlanlar Listeleniyor');
      setParams({ ...params, sort });
    }
  }

  useEffect(() => {
    setParams({ ...params, page });
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, [page]);

  return (
    <div className="Results pt-50 mb-100">
      <Row className="Header d-flex j-between a-center pl-35 full-width">
        <Col sm={{ span: 24 }} md={{ span: 12 }} className="full-width">
          <p className="InfoText text-xl mb-0">{infoText}</p>
        </Col>
        <Col sm={{ span: 24 }} md={{ span: 8 }} className="full-width">
          <Select
            placeholder="Seçiniz"
            className="form-select full-width"
            suffixIcon={<img src="/static/icons/select-arrow.svg" alt="arrow" />}
            defaultActiveFirstOption={true}
            onChange={x => setSort(x)}
          >
            {localStorage.getItem('accessToken') && <Select.Option value="favorite">Favori İlanlar</Select.Option>}
            <Select.Option value="ASC">Eskiden Yeniye</Select.Option>
            <Select.Option value="DESC">Yeniden Eskiye</Select.Option>
          </Select>
        </Col>
      </Row>
      <Row gutter={{ md: 0, lg: 70 }} justify="center">
        <Col span={24}>
          {data &&
            <p className="Info pl-35">
              <span className="TotalJobs">{get(data, 'total', 0)}</span>
              Açık Pozisyon Bulundu.
            </p>
          }
          {!data &&
            <p className="Info pl-35">
              İlanlar Yükleniyor...
            </p>
          }
          <Divider className="Divider grey" />
          <Spin spinning={loading}>
            {data && data.results.map(x => (
              <Job key={x.id} job={x} />
            ))}
          </Spin>
        </Col>
        <Col span={24} className="d-flex j-center">
          <Pagination
            current={page}
            onChange={(page, pageSize) => setPage(page)}
            pageSize={get(data, 'size', 10)}
            total={get(data, 'total', 0)}
            itemRender={itemRender} />
        </Col>
      </Row>
    </div>
  );
}

export default Results;
