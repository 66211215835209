import {Select} from "antd";
import Icon from "components/UIComponents/Icon";
import useGet from "hooks/useGet";
import {useEffect, useState} from "react";


export default ({url, mode, placeholder, labelKey = 'label', valueKey = 'name', valueType, onChange, className, stateKey, suffixIcon, children, value, staticLoading, labelSeperator, disabled, onChangeObject, dataContainerKey, queryByApi}) => {
  const [keyword, setKeyword] = useState('');
  let {data, loading} = useGet({
    url: keyword ? `${url}?q=${keyword}` : url,
    key: stateKey ? `lookups.${stateKey}` : '',
    dataContainerKey: dataContainerKey,
    loadOnce: true
  });
  const [, setVal] = useState(undefined);
  const [labelKeys, setLabelKeys] = useState(null );

  useEffect(() => {
    setVal(value);
  }, [value]);

  useEffect(() => {
    Array.isArray(labelKey) && setLabelKeys(labelKey)
  }, [labelKey]);

  const onChangeValue = (_value) => {
    setVal(_value);
    onChange && onChange(_value);
    if (onChangeObject) {
      let items = []
      if (Array.isArray(_value)) {
        _value.forEach(_val => {
          const i = data.find(x => x[valueKey] === _val);
          items.push(i)
        })
      } else {
        const item = data.find(x => x[valueKey] === _value);
        items.push(item)
      }

      onChangeObject(items);
    }
  }

  const filterOption = (input, option) => {
    return option && option.children && option.children.replace(/İ/gim, "i").replace(/I/gim, "ı").replace(/Ğ/gim, "ğ").replace(/Ç/gim, "ç").replace(/Ş/gim, "ş").replace(/Ü/gim, "ü").replace(/Ö/gim, "ö")
        .toString().toLowerCase().includes(input.replace(/İ/gim, "i").replace(/I/gim, "ı").replace(/Ğ/gim, "ğ").replace(/Ç/gim, "ç").replace(/Ş/gim, "ş").replace(/Ü/gim, "ü").replace(/Ö/gim, "ö").toLowerCase());
  };

  return (
      <Select
          showSearch
          showArrow
          allowClear
          mode={mode}
          value={value}
          disabled={disabled}
          open={loading ? false : undefined}
          loading={staticLoading ? staticLoading : loading}
          onChange={onChangeValue}
          placeholder={placeholder}
          filterOption={filterOption}
          onKeyUp={event => {
            queryByApi && setKeyword(event.target.value)
          }}
          className={`form-select full-width ${className ? className : ''}`}
          suffixIcon={loading ? undefined : (suffixIcon ? suffixIcon : <Icon name="arrow-down" size={23}/>)}
      >
        <Select.OptGroup label="İstanbul İlçeleri">
          {data && data.filter(v => v[valueType] === 'COUNTY').map((x, i) => (
              <Select.Option key={i} value={x[valueKey]}>
                {labelKeys ?
                    labelKeys.map(label => x[label]).join(` ${labelSeperator} `)
                    :
                    x[labelKey]
                }
              </Select.Option>
          ))}
          {children}
        </Select.OptGroup>
        <Select.OptGroup label="Marmara Bölgesi İlleri">
          {data && data.filter(v => v[valueType] === 'CITY').map((x, i) => (
              <Select.Option key={i} value={x[valueKey]}>
                {labelKeys ?
                    labelKeys.map(label => x[label]).join(` ${labelSeperator} `)
                    :
                    x[labelKey]
                }
              </Select.Option>
          ))}
          {children}
        </Select.OptGroup>

      </Select>
  );
}
