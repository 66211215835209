import {get} from "lodash";

const InfoItem = ({ label, text }) => {
  return (
    <div className="info-item">
      <h3 className="info-item-label">{label}</h3>
      <p className="info-item-text">{text}</p>
    </div>
  );
};

const PersonalInfo = ({ candidate }) => {
  return (
    <div className="personal-info">
      <InfoItem text={ get(candidate, 'phone', '-') } label="İletişim Numarası:"/>
      <InfoItem text={ get(candidate, 'email', '-') } label="E-Posta:"/>
      <InfoItem text={ get(candidate, 'birthDate', '-') } label="Doğum Tarihi:"/>
      <InfoItem text={ candidate?.drivingLicenses?.map((item, index) => <p>{item.licenseType} Sınıfı - {item.issuedAt}</p> ) } label="Ehliyet:"/>
      <InfoItem text={ candidate?.residenceCounty?.city } label="İl"/>
      <InfoItem text={ candidate?.residenceCounty?.county } label="İlçe"/>
      { candidate?.genderType?.id === 'M' && <InfoItem text={ candidate?.militaryStatus?.description } label="Askerlik durumu"/>}
    </div>
  );
}

export default PersonalInfo;

