import { Button, Col, Form, message, Row, Spin } from "antd";
import ComputerSkills from "./ComputerSkills";
import ExamInfo from "./ExamInfo";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getData } from "../../../../../../../redux/services/request";
import moment from "moment";
import Api from "../../../../../../../api/Api";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [examForm] = Form.useForm();
  const [computerSkillsForm] = Form.useForm();
  const [computerSkills, setComputerSkills] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);
  const [exams, setExams] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS6 ? state.data?.resumeS6.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S6", key: "resumeS6" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail) {
      if (resume.detail.computerSkills) {
        const _computerSkills = [];
        const formValues = {};
        resume.detail.computerSkills.forEach((item) => {
          const id = Math.random();
          const values = {
            skillId: item.skillId.skillId,
            val: item.val,
            id: id,
          };
          formValues[id] = values;
          _computerSkills.push(values);
        });

        setComputerSkills(_computerSkills);
        computerSkillsForm.setFieldsValue(formValues);
      }

      if (resume.detail.exams) {
        const _exams = [];
        const formValues = {};

        resume.detail.exams.forEach((item) => {
          const id = Math.random();
          const values = {
            ...item,
            examId: item.examId,
            date: moment(item.date),
            id: id,
          };
          formValues[id] = values;
          _exams.push(values);
        });

        setExams(_exams);
        examForm.setFieldsValue(formValues);
      }
    }
  }, [resume, examForm, computerSkillsForm]);

  const addExam = () => setExams([...exams, { id: Math.random() }]);
  const addComputerSkill = () =>
    setComputerSkills([...computerSkills, { id: Math.random() }]);

  const removeExam = (index) => {
    const _exams = [...exams];
    _exams.splice(index, 1);
    setExams(_exams);
  };

  const removeComputerSkill = (index) => {
    const _computerSkills = [...computerSkills];
    _computerSkills.splice(index, 1);
    setComputerSkills(_computerSkills);
  };
  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  const onClickNext = () => {
    setLoading(true);

    const request = {
      exams: Object.values(examForm.getFieldsValue()) || [],
      computerSkills: Object.values(computerSkillsForm.getFieldsValue()) || [],
    };

    // if(request?.computerSkills?.indexOf(undefined) > -1) {
    // message.error('Form bilgileri eksik ya da hatalı.');
    //  setLoading(false)
    // }else{
    new Api()
      .putData({
        url: "/candidates/resumes/competencies",
        _data: request,
      })
      .then(() => {
        message.success("Yetenek bilgileri güncellendi");
        setPageLoading(true);
        getData({ url: "candidates/resumes/S7", key: "resumeS7" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            setPageLoading(false);
          });
        refresh();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch((response) => {
        message.error(response.data.message);
        setLoading(false);
      });
    //}
  };

  return (
    <div className="Skills">
      <Spin spinning={pageLoading}>
        <Row justify="space-between">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }}>
            <h1 className="text-black text-2xl fw-bold ls-full">
              Bilgisayar Bilgisi
            </h1>
            <Form form={computerSkillsForm} onFinishFailed={onFinishFailed}>
              <ComputerSkills
                computerSkills={computerSkills}
                add={addComputerSkill}
                remove={removeComputerSkill}
                setDisabledButton={setDisabledButton}
              />
            </Form>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }}>
            <h1 className="text-black text-2xl fw-bold ls-full">
              Sınav Bilgisi
            </h1>
            <Form form={examForm} onFinishFailed={onFinishFailed}>
              <ExamInfo exams={exams} add={addExam} remove={removeExam} />
            </Form>
          </Col>
        </Row>
        <div className="d-flex mt-50 pt-30">
          {loading ? (
            <Spin />
          ) : (
            <Button
              type="primary"
              htmlType="submit"
              className="BtnNextTab ml-auto"
              disabled={disabledButton}
              onClick={onClickNext}
            >
              Kaydet ve İlerle
            </Button>
          )}
        </div>
      </Spin>
    </div>
  );
};
