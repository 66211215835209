import Banner from "components/UIComponents/Banner"
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | İşveren Bilgilendirme Metni</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="İşveren Bilgilendirme Metni" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        <article className="Article">
          <div class="blog-single">
            <p className="text-xl fw-bold text-red-pink">6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”) UYARINCA KİŞİSEL VERİLERİN KORUNMASI KAPSAMINDA GERÇEK KİŞİ İŞVEREN /TÜZEL KİŞİ İŞVERENİN GERÇEK KİŞİ TEMSİLCİSİ BİLGİLENDİRME METNİ</p>
            <p  >Türkiye’de Bölgesel İstihdam Ofisleri olarak; veri sorumlusu sıfatıyla, duruma göre aşağıda belirtilen şekillerde elde ettiğimiz kişisel verilerinizin, sizlerle olan iş ilişkimiz dahilinde, işlenmelerini gerektiren amaç çerçevesinde ve bu amaç ile bağlantılı, sınırlı ve ölçülü şekilde, tarafımıza bildirdiğiniz veya bildirildiği şekliyle kişisel verilerin doğruluğunu ve en güncel halini koruyarak, kaydedileceğini, depolanacağını, muhafaza edileceğini, yeniden düzenleneceğini, kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacağını ve KVKK’nın öngördüğü şartlarda, yurtiçinde veya yurtdışında üçüncü kişilere aktarılacağını, devredileceğini, sınıflandırılabileceğini ve KVKK’da sayılan diğer şekillerde işlenebileceğini bildiririz.</p>
            <p  >GERÇEK KİŞİ İŞVEREN/TÜZEL KİŞİ İŞVERENİN GERÇEK KİŞİ TEMSİLCİSİ VERİLERİNE İLİŞKİN</p>
            <p  >Gerçek kişi işveren veya tüzel kişi işverenin gerçek kişi temsilcisi olarak tarafımızca işlemeye konu olabilecek kişisel verileriniz aşağıdaki gibidir:</p>
            <p  >Kimlik Verisi</p>
            <p  >Adı, soyadı, TCKN, doğum tarihi, medeni durumu</p>
            <p  >&nbsp;</p>
            <p  >İletişim Verisi</p>
            <p  >Telefonu, e-postası, işyeri adresi</p>
            <p  >&nbsp;</p>
            <p  >Çalışma Verisi</p>
            <p  >Vergi No, Çalıştığı firma, departmanı, çalışma şekli, mesleği, unvanı, imza sirküleri</p>
            <p  >&nbsp;</p>
            <p  >Görsel ve İşitsel Veri</p>
            <p  >Gerçek kişiye ait fotoğraf, ses kayıtları</p>
            <p  >Websitesi/Uygulama Kullanım Verileri</p>
            <p  >Kullanıcının site içi aktiviteleri, kullanıcının Mouse hareketleri</p>
            <p  >&nbsp;</p>
            <p  >GERÇEK KİŞİ İŞVEREN /TÜZEL KİŞİ İŞVERENİN GERÇEK KİŞİ TEMSİLCİSİNE AİT KİŞİSEL VERİLERİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ</p>
            <p  >Bölgesel İstihdam Ofisleri olarak hukuki yükümlülüklerimizi yerine getirmek, firmanızla ve/veya sizinle aramızdaki istihdam zeminini sağlamak ve istihdam yönetimi hizmetine ilişkin sözleşmenin ifası ve Bölgesel İstihdam Ofisleri’nin meşru menfaatini temin etmek için sizden ve/veya firmanızı temsilen diğer temsilcilerden/çalışanlardan talep ettiğimiz kişisel verilerinizi, sizin ve/veya firmanızın diğer temsilcileri/çalışanları tarafından bu bilgilerin bize fiziksel veya elektronik ortamda iletilmesi, portalımıza bilgi girişi yapılması ve internet ortamında 3. kişilerin ulaşımına açık bilgilerin kaydedilmesi suretiyle topluyoruz.</p>
            <p  >GERÇEK KİŞİ İŞVEREN/TÜZEL KİŞİ İŞVERENİN GERÇEK KİŞİ TEMSİLCİSİ KİŞİSEL VERİLERİNİN İŞLEME AMAÇLARI</p>
            <p  >Bölgesel İstihdam Ofisleri İstihdam Portalı tarafından kişisel verileriniz aşağıda belirtilen amaçlar ve hukuki gerekçeler doğrultusunda işlenecektir.</p>
            <p  >Ayrıca bu verileriniz, ilgili mevzuatta bildirilen şekilde, işlendikleri amaç için gerekli olduğu belirtilen süre kadar muhafaza edilecektir.</p>
            <p  >Yukarıda belirttiğimiz kişisel verileriniz, Hizmet Sözleşmemizin ifası için aşağıdaki amaçlarla işlenmektedir:</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Hizmet Sözleşmesi kapsamında işverene destek verme ve İşveren ile iletişime geçilmesi.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sözleşme oluşturulması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Teklif gönderilmesi.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; İşverenin talebi halinde sözleşmesinin, verilerinin ve haklarının kontrolü ve işverenin bilgilendirmesi.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; İşverenin kaydının açılması, kullanıcı adı oluşturulması, güncellenmesi, pasif hale getirilmesi.&nbsp;&nbsp;&nbsp;
                        </p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Raporlama yapılması.&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p>
            <p  >Size verdiğimiz hizmeti en iyi şekilde yerine getirebilmek için sürekli gelişmeye ve ilerlemeye gayret ediyoruz. Bunun için kişisel verilerinizi aşağıdaki şekilde işleyebilmekteyiz:</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; İşveren şikâyetlerinde haklı/haksız ayrımının yapılması, işveren memnuniyetinin arttırılması, ihtiyacının anlaşılması ve işveren ile ilişkili süreçlerin iyileştirilmesinin sağlanması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kalite kontrolü yapılması ve hizmet kalitesinin artırılması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sözleşme süresi dolan işveren&nbsp; ile yeni bir sözleşme durumunun sağlanması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; İşveren memnuniyeti analizinin yapılması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Gelen çağrıları karşılama ve destek süreçlerinin belirlenmesi.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Toplu elektronik ileti yönetiminin sağlanması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Yönetimsel analizlerin yapılması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Site kullanımının yorumlanması.&nbsp;&nbsp;&nbsp; </p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; İşveren veri tabanının sağlıklı çalışması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Araştırma ve geliştirme çalışmalarının sürdürülmesinin sağlanması.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Mevcut ve Ar-Ge aşamasındaki uygulamalarımızın geliştirilmesi ve yönetilmesi.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bölgesel İstihdam Ofisleri içerisinde kullanılan yazılımların iç ve dış kaynak kullanılarak geliştirilmesi.</p>
            <p  >&nbsp;</p>
            <p  >GERÇEK KİŞİ İŞVEREN/ TÜZEL KİŞİ İŞVERENİN GERÇEK KİŞİ TEMSİLCİSİ KİŞİSEL VERİLERİNİN ÜÇÜNCÜ KİŞİLERLE PAYLAŞILMASI</p>
            <p  >Aşağıdaki durumlarda kişisel verilerinizi yurt içindeki ve yurt dışındaki üçüncü kişilerle paylaşabilmekteyiz:</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kişisel verileriniz, size verdiğimiz istihdam sağlama ve istihdam yönetimi hizmetlerine ilişkin olan Hizmet Sözleşmemizin ifası için Portalımız üzerinden iş başvurusu yapmak isteyen çalışan adayları tarafından bu amaçla işlenebilmektedir.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Size verdiğimiz hizmeti en iyi şekilde yerine getirebilmek için sürekli gelişmeye ve ilerlemeye gayret ediyoruz. Bunun için kişisel verilerinizi aşağıdaki şekilde üçüncü kişilere aktarabilmekteyiz.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Sizin hizmetlerimizden memnun kalıp kalmadığınızı anlamak, sizi nasıl daha memnun edeceğimizi keşfedebilmek amacıyla müşteri memnuniyeti analizi yapan firmalara gerekli kişisel verilerinizi aktarılabiliriz.</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Ayrıca savunma hakkımızı kullanabilmemiz için avukatlarımıza ve hukuka ve usule uygun olması koşuluyla mahkeme kararı veya delil talebi gibi hukuki talepleri yerine getirme yükümlülüğümüz çerçevesinde ilgili kurumlarla paylaşabiliriz.</p>
            <p  >&nbsp;</p>
            <p  >HAKLARINIZ</p>
            <p  >&nbsp;</p>
            <p  >KVKK’nın 11. maddesi gereği bize şahsen, kimliğinizi ispat etmeniz kaydıyla, kişisel verileriniz ile ilgili;</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Bölgesel İstihdam Ofisleri’nin hakkınızda kişisel veri işleyip işlemediğini öğrenmek, eğer işlemişse, buna ilişkin bilgi talep etmek,</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığı öğrenmek,</p>
            <p  >•&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Kişisel verilerin yurtiçi veya yurtdışına aktarılıp aktarılmadığı ve kimlere aktarıldığını öğrenmek hakkına sahipsiniz.</p>
            <p  >Ayrıca, Bölgesel İstihdam Ofisleri’nden yanlış ve eksik kişisel verilerinizin düzeltilmesini ve verilerinin aktarıldığı veya aktarılmış olabileceği alıcıların bilgilendirilmesini talep etme hakkınız vardır.</p>
            <p  >Kişisel verilerinizin KVKK madde 7’de öngörülen şartlar çerçevesinde imha edilmesini (silinmesini, yok edilmesini veya anonim hale getirilmesini) Bölgesel İstihdam Ofisleri’nden talep edebilirsiniz. Aynı zamanda verilerinizin aktarıldığı veya aktarılabileceği 3. kişilerin söz konusu imha talebiniz ile ilgili bilgilendirilmesini talep edebilirsiniz. Ancak imha talebinizi değerlendirerek hangi yöntemin uygun olduğu somut olayın koşullarına göre tarafımızca değerlendirilecektir. Bu bağlamda seçtiğimiz imha yöntemini neden seçtiğimiz ile ilgili bizden her zaman bilgi talep edebilirsiniz.</p>
            <p  >Münhasıran bir otomatik sistem kullanılarak oluşturulmuş kişisel veri analizinizin sonuçlarına bu sonuçlar çıkarlarınıza aykırıysa itiraz edebilirsiniz.</p>
            <p  >Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep edebilirsiniz.</p>
            <p  >Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin Bölgesel İstihdam Ofisleri için ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından, Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de belirlenen tarifedeki ücret alınabilir.</p>
          </div>
        </article>
      </div>
    </div>
  );
}