import { Button, Form, message, Spin } from "antd";
import { Icon } from "components/UIComponents";
import React, { useEffect, useState } from "react";
import ExperienceItem from "./ExperienceItem";
import { useSelector } from "react-redux";
import { getData } from "../../../../../../../redux/services/request";
import moment from "moment";
import Api from "../../../../../../../api/Api";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [experiences, setExperiences] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS4 ? state.data?.resumeS4.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S4", key: "resumeS4" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail && resume.detail.experiences) {
      const _experiences = [];
      const formValues = {};

      resume.detail.experiences.forEach((item) => {
        const id = Math.random();
        const values = {
          ...item,
          isOngoing: !!item.isOngoing,
          professionId: item.professionId
            ? item.professionId.professionId
            : undefined,
          workingType: item.workingType
            ? item.workingType.workingType
            : undefined,
          instituteId: item.instituteId
            ? item.instituteId.instituteId
            : undefined,
          startDate: item.startDate ? moment(item.startDate) : undefined,
          endDate: item.endDate ? moment(item.endDate) : undefined,
          id: id,
        };
        formValues[id] = values;
        _experiences.push(values);
      });
      setExperiences(_experiences);
      form.setFieldsValue(formValues);
    }
  }, [form, resume]);

  const addExperience = () =>
    setExperiences([...experiences, { id: Math.random() }]);

  const remove = (index) => {
    const _experiences = [...experiences];
    _experiences.splice(index, 1);
    setExperiences(_experiences);
  };

  const onClickNext = () => {
    setLoading(true);

    const request = {
      experiences: Object.values(form.getFieldsValue()).map((experience) => ({
        ...experience,
        isOngoing:
          experience.endDate === undefined || experience.endDate === null,
      })),
    };

    new Api()
      .putData({
        url: "/candidates/resumes/experiences",
        _data: request,
      })
      .then(() => {
        message.success("Deneyim bilgileri güncellendi");
        setPageLoading(true);
        refresh();
        getData({ url: "candidates/resumes/S4", key: "resumeS4" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            setPageLoading(false);
          });
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch(() => {
        message.error(
          "Deneyim bilgileri güncellenirken bir hata meydana geldi"
        );
        setLoading(false);
      });
  };

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  return (
    <div className="WorkingExperience">
      <Spin spinning={pageLoading}>
        <h1 className="fw-bold ls-full text-4xl text-black mb-30">
          Deneyim Bilgileri
        </h1>
        <Form
          form={form}
          onFinish={onClickNext}
          onFinishFailed={onFinishFailed}
        >
          {experiences.map((x, i) => (
            <Form.List name={x.id}>
              {(fields) => (
                <ExperienceItem key={x.id} data={x} index={i} remove={remove} />
              )}
            </Form.List>
          ))}
          <Button
            className="BtnAddEducation btn btn-pink-filled radius-lg"
            type="primary"
            icon={<Icon name="plus" size={26} className="mr-10" />}
            onClick={() => addExperience()}
          >
            Ekle
          </Button>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};
