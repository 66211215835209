import Banner from "components/UIComponents/Banner"
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | Mobil Araç Nerede</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Mobil Araç Nerede" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        <div className="Image mb-20">GÖRSEL</div>
        <article className="Article">
          Integer et metus eros. Pellentesque imperdiet, risus sit amet ultrices molestie, nulla nunc hendrerit risus, vitae cursus magna quam id ipsum. Morbi libero turpis, rutrum ut maximus ac, dapibus id augue. Sed mollis quam id lacinia bibendum. Nunc risus augue, pretium at risus quis, pulvinar auctor nisl. Vivamus pretium, nibh sed dignissim lacinia, risus enim rutrum ligula, sit amet condimentum ipsum nunc eget odio. Cras odio velit, cursus ac sodales ut, congue non lacus. Aenean ac dignissim turpis, nec sollicitudin erat. Integer placerat velit sit amet libero semper molestie. Vivamus scelerisque mauris lorem. Suspendisse luctus erat in mi tristique, nec fermentum quam consequat. Quisque quis magna ex. Quisque pharetra neque sit amet nisi consequat eleifend. Curabitur feugiat tellus et erat rhoncus, et interdum massa maximus.
          <br /><br />
          Pellentesque eu risus ultrices, suscipit elit in, posuere lectus. Suspendisse malesuada, diam id volutpat faucibus, quam quam eleifend nulla, eu dictum nunc magna vel libero. Aenean cursus sed lorem nec egestas. Quisque varius ac lacus nec dictum. Suspendisse neque ipsum, interdum eu maximus a, auctor at erat. In tristique felis erat, sit amet ultricies ipsum volutpat quis. Duis ullamcorper lorem lacinia ante ornare, vitae fermentum mauris tristique. Proin congue, orci at interdum fini Pellentesque eu risus ultrices, suscipit elit in, posuere lectus. Suspendisse malesuada, diam id volutpat faucibus, quam quam eleifend nulla, eu dictum nunc magna vel libero. Aenean cursus sed lorem nec egestas. Quisque varius ac lacus nec dictum. Suspendisse neque ipsum, interdum eu maximus a, auctor at erat. In tristique felis erat, sit amet ultricies ipsum volutpat quis. Duis ullamcorper lorem lacinia ante ornare, vitae fermentum mauris tristique. Proin congue, orci at interdum fini
        </article>
      </div>
    </div>
  );
}