import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react";
import AOS from "aos";
import { Input, Button, Form, Row, Col, Modal, Spin, message } from "antd";
import { CityCountyLookupSelect } from "../../../../components/UIComponents/FormElements";
import { SearchOutlined } from "@ant-design/icons";
import leftWorkers from "../../../../assets/left-workers.png";
import rightWorkers from "../../../../assets/bio-workers-right.png";
import logo from "../../../../assets/login/ibb-main-logo-2x.png";
import pathOrange from "../../../../assets/orange-workers-path.svg";
import pathYellow from "../../../../assets/yellow-workers-path.svg";
import isaraisbul from "../../../../assets/is-ara-is-bul.svg";
import PopupBanner from "./PopupBanner";

import yazi from "../../../../assets/hero/yazi.png";
import group1 from "../../../../assets/hero/group_1.svg";
import orangeBanner from "../../../../assets/hero/orangeBanner.jpg";
import blueBanner from "../../../../assets/hero/blueBanner.jpg";
//import moiBanner from "../../../../assets/hero/moi_banner.jpg";
//import moiBanner2 from "../../../../assets/hero/moi_banner2.jpg";
import moiBanner3 from "../../../../assets/hero/moi_banner3.png";
import bioİsmekBanner from "../../../../assets/hero/bio_ismek.jpg";
import bioİsmekBanner2 from "../../../../assets/hero/bio_ismek_2.jpg";

import SwiperCore, { Pagination, Navigation, Autoplay, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import useGet from "hooks/useGet";

SwiperCore.use([Pagination, Autoplay, A11y, Navigation]);

const Hero = () => {
  const [item, setItem] = useState([]);
  const history = useHistory();
  const [showPopup, setShowPopup] = useState(false);
  const [firmType] = useState(localStorage.getItem("type") ?? null);

  useEffect(() => {
    AOS.init({
      duration: 3000,
      debounceDelay: 500,
    });
    AOS.refresh();
  }, []);

  const { data: bannerList, loading: bannerLoading } = useGet({
    url: "/public/banner",
    key: "bannerList",
  });

  console.log(
    "bannerList",
    bannerList &&
      bannerList?.length > 0 &&
      bannerList[1]?.redirectUrl?.includes("tab=ibb-ulasim-destegi")
  );

  const search = (values) => {
    const queryParams = [];
    if (item.length > 0) {
      if (item[0].type === "COUNTY") {
        queryParams.push(`&countyOptions=${item[0].id}`);
      } else if (item[0].type === "CITY") {
        queryParams.push(`&cityOptions=${item[0].id}`);
      }
    }

    values.query && queryParams.push(`&keyword=${values.query}`);
    history.push(
      "/jobs/list".concat(
        queryParams.length > 0 ? "?" + queryParams.join("&") : ""
      )
    );
  };

  const handleItemSelection = (item) => {
    setItem(item);
  };

  const additionalClass =
    localStorage.getItem("type") === "CORPORATE"
      ? "heroCorporateBackground"
      : "";

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <Spin spinning={bannerLoading}>
      <div
        className={`hero_section ${
          firmType === "CORPORATE" ? "corporate" : ""
        }`}
      >
        {/*showPopup && <PopupBanner />*/}
        <div className="hero_section_circle"></div>
        <div className="hero_container">
          {firmType !== "CORPORATE" && (
            <div className="hero_top">
              <Row>
                <Col xl={8}>
                  <Row>
                    <Col md={12} className="hero_top_left">
                      <img src={yazi} alt="" className="hero_top_img" />
                    </Col>
                    <Col md={12} className="hero_top_right">
                      <img src={group1} alt="" className="hero_top_img" />
                    </Col>
                  </Row>
                </Col>
                <Col
                  xl={16}
                  xs={24}
                  className="content_form full-height"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <Form
                    onFinish={search}
                    style={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Row
                      style={{ width: "100%", height: "100%" }}
                      gutter={{ xs: 5, sm: 10, md: 15 }}
                    >
                      <Col xl={10} xs={24}>
                        <Form.Item name="query" className="mb-0">
                          <Input
                            style={{ borderRadius: "20px" }}
                            placeholder="İş Tanımı, Anahtar Kelimeler ya da Faaliyet Konusu (Sektör)..."
                            suffix={
                              <img
                                src="/static/icons/search.svg"
                                alt="Bölgesel İstihdam Ofisleri Search"
                                width="17px"
                              />
                            }
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={10} xs={24} className="customMargin">
                        <Form.Item
                          name="county"
                          className="full-height mb-0 form-padding-top"
                        >
                          <CityCountyLookupSelect
                            stateKey="counties"
                            className="HeroSelect full-height round"
                            url="/public/counties"
                            suffixIcon={
                              <img
                                src="/static/icons/pin.svg"
                                alt="Bölgesel İstihdam Ofisleri"
                              />
                            }
                            labelKey="value"
                            valueType="type"
                            valueKey="id"
                            placeholder="İlçe"
                            onChangeObject={handleItemSelection}
                          />
                        </Form.Item>
                      </Col>
                      <Col xl={4} xs={24}>
                        <Button
                          htmlType="submit"
                          className="BtnFindJob p-10 full-width round"
                          type="primary"
                        >
                          <SearchOutlined />
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </div>
          )}
          <div className="hero_slider">
            <Swiper
              initialSlide={1}
              autoplay={{
                enabled: true,
                delay: 5000,
              }}
              speed={1000}
              loop={true}
              navigation
              pagination={{ clickable: true }}
            >
              {/* <SwiperSlide>
              <img src={blueBanner} alt="" className="slider-img" />
            </SwiperSlide>
            <SwiperSlide>
              <img src={moiBanner3} alt="" className="slider-img" />
            </SwiperSlide>
            <SwiperSlide>
              <a
                href="https://bio.ibb.istanbul/jobs/detail/G80zAYwEXgu9Z0odY39Af8g191yqaacGnDN3aPryUnyd1Kz"
                target="_blank"
                rel="noreferrer"
              >
                <img src={bioİsmekBanner} alt="" className="slider-img" />
              </a>
            </SwiperSlide>
            <SwiperSlide>
              <a
                href="https://bio.ibb.istanbul/jobs/detail/4pP13OJN9kub0ekvdAbnS8ga0aYbVVcwV5XgY9xaFVr1Qpq "
                target="_blank"
                rel="noreferrer"
              >
                <img src={bioİsmekBanner2} alt="" className="slider-img" />
              </a>
            </SwiperSlide> */}
              {bannerList?.length > 0 &&
                bannerList?.map((banner, key) => (
                  <SwiperSlide key={key}>
                    {banner?.redirectUrl ? (
                      <a
                        href={banner?.redirectUrl}
                        target={`${banner?.redirectUrl ? "_blank" : ""}`}
                        rel="noreferrer"
                      >
                        <img
                          src={`data:image/jpg;base64,${banner?.image}`}
                          alt={banner?.redirectUrl}
                          className="slider-img"
                        />
                      </a>
                    ) : (
                      <img
                        src={`data:image/jpg;base64,${banner?.image}`}
                        alt={banner?.redirectUrl}
                        className="slider-img"
                      />
                    )}
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>
      </div>
    </Spin>
  );

  // return (
  //   <div className="heroContainer">
  //     { showPopup && <PopupBanner/>}
  //     <div className="wrapper">
  //       <div className="worker-left-container">
  //       <div>
  //         <img
  //           src={leftWorkers}
  //           alt="Bölgesel İstihdam Ofisleri Çalışanlar"

  //         /> </div>
  //       </div>
  //       <div className="worker-path-container-orange">
  //       <div>
  //       <img
  //           src={pathOrange}
  //           alt="Bölgesel İstihdam Ofisleri Çalışanlar"
  //         />
  //       </div>
  //       </div>
  //       <div className="content-search">
  //           <div>
  //             <h1 className="pt-30">
  //             <span  className="hero-text-bold">
  //               {`${
  //               localStorage.getItem("type") !== "CORPORATE" ? "ÖZEL SEKTÖRDE BİNLERCE İLAN ARASINDA" : "BAŞVURU YAPAN BİNLERCE ADAY ARASINDA"
  //             }`}
  //               </span>

  //             </h1>
  //           </div>

  //         <div>
  //           <a href="https://app.adjust.com/pjt7vbi?deeplink=https%3A%2F%2Fpaylas.istanbulsenin.istanbul%2Fkobilservice%3FsID%3Da4813ca6-207c-4779-8866-7cb719f34e72" target="_blank">
  //              <img src={isaraisbul} alt="Bölgesel İstihdam Ofisleri Logo" className="hero-logo" />
  //           </a>
  //         </div>

  //         <div>
  //           <div className="content-form">
  //           <Form
  //             className={`heroForm ${
  //               localStorage.getItem("type") === "CORPORATE" ? "v-hidden" : ""
  //             }`}
  //             onFinish={search}
  //           >
  //             <Row className="full-width pl-30 pr-30 pb-15" gutter={{ xs: 5, sm: 10, md: 6 }} >
  //               <Col xs={{ span: 24 }} sm={{ span: 14 }} lg={{ span: 12 }}>
  //                 <Form.Item name="query" className="mb-0">
  //                   <Input style={{borderRadius: '20px'}}
  //                     placeholder="İş Tanımı, Anahtar Kelimeler ya da Faaliyet Konusu (Sektör)..."
  //                     suffix={
  //                       <img
  //                         src="/static/icons/search.svg"
  //                         alt="Bölgesel İstihdam Ofisleri Search"
  //                         width="17px"
  //                       />
  //                     }
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col xs={{ span: 24 }} sm={{ span: 6 }} lg={{ span: 9 }}>
  //                 <Form.Item name="county" className="full-height mb-0 form-padding-top">
  //                   <CityCountyLookupSelect
  //                     stateKey="counties"
  //                     className="HeroSelect full-height round"
  //                     url="/public/counties"
  //                     suffixIcon={
  //                       <img src="/static/icons/pin.svg" alt="Bölgesel İstihdam Ofisleri" />
  //                     }
  //                     labelKey="value"
  //                     valueType="type"
  //                     valueKey="id"
  //                     placeholder="İlçe"
  //                     onChangeObject={handleItemSelection}
  //                   />
  //                 </Form.Item>
  //               </Col>
  //               <Col xs={{ span: 24 }} sm={{ span: 4 }} lg={{ span: 3 }}>
  //                 <Button
  //                   htmlType="submit"
  //                   className="BtnFindJob p-10 full-width round"
  //                   type="primary"
  //                 >
  //                   <SearchOutlined />
  //                 </Button>
  //               </Col>
  //             </Row>
  //           </Form>
  //           <p
  //             style={{ fontSize: "12px" }}
  //             className={`InfoText text-white text-lg mt-15 ${
  //               localStorage.getItem("type") === "CORPORATE" && "v-hidden"
  //             }`}
  //           >
  //             * Bölgesel İstihdam Ofisleri Portalı <br /> İstanbullu iş arayan
  //             vatandaşlarımız ile personel arayan işverenlerimize hizmet
  //             vermektedir.
  //           </p>
  //           <img src={logo} width="84" alt="İstanbul Büyükşehir Belediyesi"/>
  //           </div>
  //        </div>

  //       </div>
  //       <div className="worker-right-container">
  //       <div>
  //         <img
  //           src={rightWorkers}
  //           alt="Bölgesel İstihdam Ofisleri Çalışanlar"

  //         />
  //         </div>
  //       </div>
  //       <div className="worker-path-container-yellow">
  //       <div

  //       >
  //         <img
  //           src={pathYellow}
  //           alt="Bölgesel İstihdam Ofisleri Çalışanlar"
  //         />
  //       </div>

  //       </div>
  //     </div>
  //   </div>
  //);
};

export default Hero;
