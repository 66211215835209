import {levelIndicator} from "../../../../../../helpers/methods";

const ComputerSkills = ({data}) => {

  return(
    <div className="computer-container">
      <div className="skill-title">
        Bilgisayar Bilgisi
      </div>
      <div>
        {
          data && data.map(( skill, index ) => {
            return(
              <>
                <div className="computer-skill-container" key={index}>
                  <h4 className="program-title">{ skill.competency }</h4>
                  <div className="level-container">
                    {
                      levelIndicator( skill.val )
                    }
                  </div>
                </div>
              </>
            )
          },[])
        }
      </div>
    </div>
  )
}

export default ComputerSkills;
