import { Link } from "react-router-dom";
import "../../../styles/layouts/authLayout.less";
import { Layout, Image } from "antd";
import { LeftOutlined } from "@ant-design/icons";
import Logo from "../../../assets/login/bolgesel-isdihtam-ofisi-logo-2x.png";
import IbbMainLogo from "../../../assets/login/ibb-main-logo-2x.png";
import AdayNewLogo from "../../../assets/login/PortalAday.png";
import FirmaNewLogo from "../../../assets/login/Portalİşveren.png";
import Footer16MilyonLogo from "../../../assets/login/footer-16-milyon-logo.svg";
import CandidateScreen from "./EntryScreen/CandidateScreen";
import EmployerScreen from "./EntryScreen/EmployerScreen";

const { Header, Footer, Sider, Content } = Layout;

export default function AuthLayout({ children, backButton = true }) {
  return (
    <Layout className="auth-layout">
      <Sider className="auth-sider">
        {window.innerWidth < 1028 ? (
          <Image src={IbbMainLogo} preview="false" />
        ) : document.location.pathname.indexOf("login-citizen") > -1 ? (
          <CandidateScreen />
        ) : (
          <EmployerScreen />
        )}
      </Sider>
      <Layout className="auth-second-layout">
        <Header className="auth-header">
          <Image className="auth-logo" src={Logo} preview={false} />
          {backButton && (
            <Link className="auth-back-link" to="/">
              <LeftOutlined
                style={{ fontSize: "14px", padding: "3px", color: "#eb530d" }}
              />
              Giriş Sayfasına Geri Dön
            </Link>
          )}
        </Header>
        <Content className="auth-content">{children}</Content>
        <Footer className="auth-footer">
          <div className="footer-line">
            <p className="footer-corporate-text">
              İstanbul Büyükşehir Belediyesi Bilgi İşlem Dairesi Başkanlığı
              tarafından ❤️ ile tasarlanmıştır.
            </p>
            <img
              className="footer-16-milyon-logo"
              src={Footer16MilyonLogo}
              alt="İstanbul Büyükşehir Belediyesi Bilgi İşlem Dairesi Başkanlığı"
            />
          </div>
        </Footer>
      </Layout>
    </Layout>
  );
}
