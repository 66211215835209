import React, { useEffect, useState } from 'react';
import { Row, Col, Rate, Button, Form, Select } from "antd"; 
import useGet from 'hooks/useGet';

export default ({ index, remove,item,computerSkills,setDisabledButton }) => {
    const [rateValue, setRateValue] = useState(0);// Burada başlangıç değeri olarak 0 ayarladık.

    const { data } = useGet({ url: "/competencies/computers", key: "computerSkills", isArray: false });
    const [filteredComputerSkills,setFilteredComputerSkills] = useState([]);
    const [selectedComputerSkill,setSelectedComputerSkill] = useState('');
    const [rateValueMessage,setRateValueMessage] = useState('');
    const [skillMessage,setSkillMessage] = useState('');


    useEffect(() => {
        if(item && Object.entries(item).length === 1){
            if(selectedComputerSkill && rateValue === 0){
                setRateValueMessage('Bu alan boş bırakılamaz');
                setSkillMessage('');
                setDisabledButton(true);
            }else if(!selectedComputerSkill){
                setSkillMessage('Bu alan boş bırakılamaz');
                setDisabledButton(true);
            }
            else{
                setRateValueMessage('');
                setSkillMessage('');
                setDisabledButton(false);
            }
            setFilteredComputerSkills(data?.results.filter(el => !computerSkills.some((skill) => skill.skillId === el.id && el.id !== selectedComputerSkill)));
        }else{
            setFilteredComputerSkills(data?.results);
        }
    },[item,computerSkills,selectedComputerSkill,data,rateValue,setRateValueMessage,setDisabledButton]);


    return (
        <Row className="ComputerSkill mb-20" align="middle">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }}>
                <Form.Item name={"skillId"} rules={[{ required: true,message:'layla' }]}>
                    {/* <LookupSelect url="/competencies/computers" placeholder={"Seçiniz"} valueKey="id" labelKey="description" /> */}
                    <Select
            className="form-select full-width"
            style={{border: `${skillMessage ? '0.5px solid red':''}`}}
            onChange={(value) => setSelectedComputerSkill(value)}
            placeholder={"Seçiniz"}
          >
            {filteredComputerSkills?.map((item, idx) => (
              <Select.Option value={item.id} key={idx}>
                {item.description}
              </Select.Option>
            ))}    
            
          </Select>
                </Form.Item>
                {skillMessage && <h4 style={{color:'red',fontSize:'18px',fontWeight:'bold'}}>{skillMessage}</h4>}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
                <p className="text-xl mb-10 ml-20">Yetkinlik</p>
                <Form.Item name={"val"} label={rateValue} rules={[{ type:"integer",required: true, min: 0, max: 5 }]}>
                    <Rate
                        className="text-4xl l-1 ml-20"
                        value={rateValue} // Burası eklenen kısım
                        onChange={(value) => setRateValue(value)} // Burası eklenen kısım
                        character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                            <g fill="none" fillRule="evenodd">
                                <g fill="currentColor">
                                    <path stroke={`${rateValueMessage ? 'red':'#004da1'}`} strokeWidth="2px" d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                                </g>
                            </g>
                        </svg>} 
                    /> 
                </Form.Item>
                    {rateValueMessage && <h4 style={{color:'red',fontSize:'18px',fontWeight:'bold'}}>{rateValueMessage}</h4>}
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }}>
                <Button className="btn btn-blue-filled full-width text-md" onClick={() => remove(index)}>Sil</Button>
            </Col>
        </Row>
    );
};
