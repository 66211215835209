import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Form, Input, Checkbox, Button, DatePicker, Radio, Spin, message, Select } from 'antd';
import {FilterOutlined} from '@ant-design/icons';
import queryString from 'query-string';
import { CityCountyLookupSelect, LookupSelect } from 'components/UIComponents/FormElements';
import { postData } from 'redux/services/request';
import useGet from 'hooks/useGet';
import { Icon } from 'components/UIComponents';
import { zip } from 'lodash';

const { RangePicker } = DatePicker;

const Filter = ({ replySearch }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const [saved, setSaved] = useState(false);
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);
  const [keywords, setKeywords] = useState(null);
  const { data: savedFilters, loading: loadingSavedFilters, refresh: refreshSearches } =
      useGet({ url: localStorage.getItem('accessToken') ? '/filters/jobs': undefined, key: 'savedFilters' });

  useEffect(() => {
    const search = window.location.search;
    if (search) {
      const params = queryString.parse(search.replace('cityOptions', 'countyOptions'))
      form.setFieldsValue(params)
    }
  }, [form]);

  useEffect(() => {
    if (keywords) {
      history.push(`/jobs/list?${keywords}`);
    }
  }, [keywords]);


  const onFinish = async (values) => {
    if (values.releaseDate && values.releaseDate.length > 0) {
      if (values.releaseDate[0]) {
        values.startDate = values.releaseDate[0].format('YYYY-MM-DD')
      }

      if (values.releaseDate[1]) {
        values.endDate = values.releaseDate[1].format('YYYY-MM-DD')
      }
    }

    delete values.releaseDate

    if (saved) {
      const data = { title: values.keyword, query: JSON.stringify(values) };
      setLoading(true);
      try {
        await postData({ url: '/filters/jobs', data });
        message.success('Arama başarıyla kaydedildi');
        setLoading(false);
      } catch (e) {
        message.warn('Arama kaydedilirken bir hata oluştu');
        setLoading(false);
      }
    }

    if (items.length > 0) {
      delete values.countyOptions

      let cityParamList = []
      let countyParamList = []

      items.forEach(item => {
        if (item.type === 'CITY') {
          cityParamList.push(item.id)
        } else if (item.type === 'COUNTY') {
          countyParamList.push(item.id)
        }
      })

      if (cityParamList.length > 0) {
        values.cityOptions = cityParamList.join(",")
      }

      if (countyParamList.length > 0) {
        values.countyOptions = countyParamList.join(",")
      }
    }

    const params = queryString.stringify(values);
    refreshSearches();
    history.push(`/jobs/list?${params}`);
  };

  const handleItemSelection = (items) => {
    setItems(items)
  };

  const onChange = (keywords) => {
    setKeywords(queryString.stringify(JSON.parse(keywords)));
  }

  const url = localStorage.getItem('accessToken') ? "/working-types" : "public/working-types"; 
  return (
    <Spin spinning={loading}>
      <Form className="Filter" form={form} onFinish={onFinish}>

        <Form.Item name="keyword">
          <Input
            className="form-input input-base"
            suffix={<img src="/static/icons/search.svg" alt="ibb-search" />}
            placeholder="Anahtar Kelime"
          />
        </Form.Item>
        <Form.Item name="searchOnTitle">
          <Radio.Group className="form-radio pink pl-30">
            <Radio className="text-white mb-20" value={false}>Tüm İlanlarda Ara</Radio>
            <Radio className="text-white" value={true}>Sadece İlan Başlığında</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item name="sectors">
          <LookupSelect
            url="/public/sectors"
            valueKey="sectorId"
            labelKey="sectorName"
            stateKey="sectors"
            placeholder="Sektör"
            mode="multiple"
          />
        </Form.Item>
        <Form.Item name="countyOptions">
          <CityCountyLookupSelect
            valueKey="id"
            valueType="type"
            labelKey="value"
            stateKey="counties"
            url="/public/counties"
            mode="multiple"
            placeholder="İlçe"
            onChangeObject={handleItemSelection}
          />
        </Form.Item>
        <Form.Item name="releaseDate">
          <RangePicker
            placeholder={['Başlangıç Tarihi', 'Bitiş Tarihi']}
            className="form-select input-base full-width"
            suffixIcon={<img src="/static/icons/calendar.svg" alt="Bölgesel İstihdam Ofisleri" width={18}  />}
            format="DD/MM/YYYY"
          />
          {/* <DatePicker
        /> */}
        </Form.Item>
        <Form.Item name="workingTypes">
          <LookupSelect
            url={url}
            labelKey="description"
            mode="multiple"
            valueKey="id"
            stateKey="workingTypes"
            placeholder="Çalışma Şekli"
          />
        </Form.Item>
        <Form.Item name="experienceType">
          <LookupSelect
            url="/types/working-experiences"
            labelKey="label"
            valueKey="name"
            stateKey="workingExperiences"
            placeholder="Tecrübe"
          />
        </Form.Item>
        <Form.Item name="educationLevelOptions">
          <LookupSelect
            labelKey="label"
            valueKey="name"
            stateKey="educationLevels"
            url="/types/education-levels"
            mode="multiple"
            placeholder="Eğitim Seviyesi"
          />
        </Form.Item>
        <Form.Item name="onlyDisabilities" valuePropName="checked">
          <Checkbox className="form-check text-white pl-30">
            Sadece Engelli İlanlarını Göster
          </Checkbox>
        </Form.Item>
        {localStorage.getItem('accessToken') &&
          <Checkbox className="form-check text-white pl-30" onChange={e => setSaved(e.target.checked)}>
            Bu aramayı kaydet
          </Checkbox>
        }
        {localStorage.getItem('accessToken') && savedFilters &&
          <Form.Item name="onlyDisabilities">
            <Select suffixIcon={loadingSavedFilters ? undefined : <Icon name="arrow-down" size={23} />} className="form-select full-width mt-30" placeholder="Kayıtlı arama seç" onChange={onChange}>
              {savedFilters.map(x => (
                <Select.Option value={JSON.stringify(x.keyword)}>{x.keyword.keyword}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        }
        <Button
          className="btn btn-base mt-25 m-auto d-flex align-center justify-content-center filterButton"
          htmlType="submit">
          Filtrele <FilterOutlined />
        </Button>
      </Form>
    </Spin>
  );
}

export default Filter;
