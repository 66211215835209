import {useEffect, useState} from "react";
import {Button, DatePicker, Input, Modal, Form, message} from "antd";
import { postNewLogin} from "../../../../../../redux/services/request";
import {validations} from "../../../../../Auth/Components/utility";
import {  useSelector } from "react-redux";


const Edit = ({visible, handleOk, handleCancel}) => {
  
  const [ loading, setLoading ] = useState( false );
  const [ form ] = Form.useForm();
  const resume = useSelector(state => state.data.resumeData);
  const resumeData= resume?.result
  const pageLoading = useSelector(state => state.data.pageLoading);

  const updateProfile = async (values) => {
    setLoading(true)
    
    const data = {
      birthDate: values?.birthDate?.format('YYYY-MM-DD'),
    }

    try {
      const response = await postNewLogin({url: '/auth/update-birth-date', data });

      if( response.code !== 200 ) {
        message.error(response.message)
        setLoading(false)
        return;
      }

      setLoading(false)
      message.success('Kimlik Bilgileriniz Başarıyla Güncellendi.')
      handleCancel()

    } catch {
      setLoading(false)

    }
  }

  return(
    <>
      <Modal
        open={visible}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled: true,
        }}
        cancelButtonProps={{
          disabled: true,
        }}
        footer={null}
      >
        <Form
        form={form}
          onFinish = { updateProfile }
          initialValues={{
            birthDate: '' ,
            citizenNo: ''
          }}
          className="form-profile-candidate"
         >            
          <label>T.C. Kimlik No:</label>
          <Form.Item name="citizenNo"  >
            <Input
              placeholder={ pageLoading ? '...' : resumeData?.idNo }
              className="form-input mb-15"
              disabled />
          </Form.Item>
          <label>Doğum Tarihi:</label>
          <Form.Item   name="birthDate" required >
            <DatePicker
              placeholder={ pageLoading ? '...' : resumeData?.birthDate }
              className="form-input full-width mb-15"
              required
              format="DD/MM/YYYY"/>
          </Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            size="large"
            >
            Güncelle ve Kaydet
          </Button>
        </Form>
      </Modal>
    </>
  )
}

export default Edit;
