export const languages = {
  1: 'A1',
  2: 'A2',
  3: 'B2',
  4: 'C1',
  5: 'C2'
};

export const _languages = {
  'A1': 1,
  'A2': 2,
  'B2': 3,
  'C1': 4,
  'C2': 5
};

export const codes = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

export const links = {
  facebook: 'https://www.facebook.com/bolgeselistihdamofisi/',
  twitter: 'https://twitter.com/bio_istanbul?lang=tr',
  linkedin: 'https://www.linkedin.com/company/bolgeselistihdamofisi',
  instagram: 'https://www.instagram.com/bolgeselistihdamofisi/?hl=en'
};

export const advertisementStatuses = {
  WAITING: {
    text: 'Onayda Bekleyen',
    color: 'text-warning'
  },
  ACTIVE: {
    text: 'Aktif',
    color: 'text-red-pink'
  },
  PASSIVE: {
    text: 'Pasif',
    color: 'red'
  },
  REJECTED: {
    text: 'Reddedildi',
    color: 'text-red'
  },
  P_CLOSED : {
    text: 'Olumlu Kapatıldı',
    color: 'text-blue'
  },
  N_CLOSED : {
    text: 'Olumsuz Kapatıldı',
    color: 'text-red'
  },
  NEED_EDIT : {
    text: 'Düzenleme İhtiyacı',
    color: 'text-warning'
  },
}