import Banner from "components/UIComponents/Banner";
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div className="FirmConfirmation">
      <Helmet>
        <title>İş Ara İş Bul | Sistem Erişimi</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text=""/>
      <div className="content md content-detail d-flex fd-column a-center pt-60 pb-100">
        <img className="mb-30" src="/static/img/email.svg" alt="Bölgesel İstihdam Ofisleri" />
        <p className="text-black text-center text-4xl fw-semibold ls-full">Sisteme erişebilmeniz için Bölgesel İstihdam Ofisi tarafından onaylanmanız bekleniyor.</p>
        <p className="text-red-pink text-4xl mt-30">Üyeliğiniz en kısa sürede onaylanacaktır.</p>
      </div>
    </div>
  );
};
