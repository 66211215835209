import React, { useState, useEffect } from "react";
import { Button, Col, Form, InputNumber, Row, Select } from "antd";
import moment from "moment";
import useGet from "hooks/useGet";

const currentYear = moment().year();

const formRules = {
  licenseType: { required: true, message: "Belge" },
  issuedAt: {
    required: true,
    type: "number",
    min: 1900,
    max: currentYear,
    message: "Geçersiz ya da hatalı yıl."
  }
};

export default ({ remove, drivingLicenses, item }) => {
  const { data } = useGet({ url: "/types/driving-license", key: "drivingLicence", isArray: false });
  const [filteredLicense, setFilteredLicense] = useState([]);
  const [selectedLicense, setSelectedLicense] = useState("");

  useEffect(() => {
    if (item && Object.entries(item).length === 1) {
      setFilteredLicense(data?.results.filter((nameObj) => !drivingLicenses.some((license) => license.licenseType === nameObj.name && nameObj.name !== selectedLicense)));
    } else {
      setFilteredLicense(data?.results);
    }
  }, [item, data, selectedLicense]);

  return (
    <Row className="DrivingLicense mb-20" align="middle">
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
        <Form.Item name={"licenseType"} rules={[formRules.licenseType]}>
          <Select
            className="form-select full-width"
            onChange={(value) => setSelectedLicense(value)}
          >
            {filteredLicense?.map((item, idx) => (
              <Select.Option value={item.name} key={idx}>
                {item.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 11 }}>
        <Form.Item name="issuedAt" rules={[formRules.issuedAt]}>
          <InputNumber
            type={"number"}
            className="form-input full-width"
            placeholder={currentYear}
          />
        </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
        <Button
          className="btn btn-blue-filled full-width text-md ml-15 mb-20"
          onClick={remove}
        >
          Sil
        </Button>
      </Col>
    </Row>
  );
};
