export const getEmbedMapIstanbul = (office) => {
  console.log(office);
  switch (office?.county?.county) {
    case "Üsküdar":
      const map =
        office?.id?.id === "N7qBd8kmLaCb4mPxYvmqIrM"
          ? "https://harita.istanbul/2d/embed/0001?@=29.01519,41.02066,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=dde55331372d993ee313e67d62b0f064&dwk="
          : "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=4e3e7c23b93362e62ce2461d54347f39&dwk=";
      return map;
    case "Arnavutköy":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=0ccc111fd5690af38314545b84653996&dwk=";
    case "Beykoz":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=b4c4eb33e13db62be505b18a7b4c3d7e&dwk=";
    case "Büyükçekmece":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=925001bbcceefcc42ee884a89cee3110&dwk=";
    case "Sancaktepe":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=78f8a005d31619866887b2dfac3072d4&dwk=";
    case "Beylikdüzü":
      return "https://harita.istanbul/2d/embed/0001?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=f2eec7c0a098e39c4680f0b608848ad1&dwk=";
    case "Avcılar":
      return "https://harita.istanbul/2d/embed/0001?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=dd37d6fe74590ff4e6995c7a1b4f10c1&dwk=";
    case "Küçükçekmece":
      return "https://harita.istanbul/2d/embed/0001?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=21d403da6594555661ca71bea7a2e6fd&dwk=";
    case "Şile":
      return "https://harita.istanbul/2d/embed/0001?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=3d74ba7e902d94dd805c8ab1de1042c6&dwk=";
    case "Şişli":
      return "https://harita.istanbul/2d/embed/0001?@=28.98674,41.06772,18.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=c5758f7231a9e84c02fe6ebd03f60622&dwk=";
    case "Çatalca":
      return "https://harita.istanbul/2d/embed/0001?@=29.61555,41.17236,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=2631c1de55aaf0eda3bb067c15c0384b&dwk=";
    case "Sarıyer":
      return "https://harita.istanbul/2d/embed/0001?@=29.61555,41.17236,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=c95ab84fc8d3a165208fae3a690b1472&dwk=";
    case "Sultangazi":
      return "https://harita.istanbul/2d/embed/0001?@=28.90520,41.09387,18.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=d65b8261611772ef94bffdc480f6a137&dwk=";
    case "Bayrampaşa":
      return "https://harita.istanbul/2d/embed/0001?@=28.90239,41.09755,17.88832&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=e938698487832ee99da47e47bb2f189a&dwk=";
    case "Silivri":
      return "https://harita.istanbul/2d/embed/0001?@=29.61505,41.17236,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=f454353aa871eac7b2cdd271ec88b3cc&dwk=";
    case "Beyoğlu":
      return "https://harita.istanbul/2d/embed/0001?@=28.97281,41.02787,18.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=22cc39376fb3d1beaea71dbf7074cd45&dwk=";
    case "Bahçelievler":
      return "https://harita.istanbul/2d/embed/0001?@=28.97231,41.02784,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=bbda318c8c62e01340c66a2f7ab07408&dwk=";
    case "Ümraniye":
      return "https://harita.istanbul/2d/embed/0001?@=28.84402,41.00780,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=381e7dfa500d6180e96ce5a9dee25e90&dwk=";
    case "Tuzla":
      return "https://harita.istanbul/2d/embed/0001?@=29.10599,41.01702,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=d456ac337ffcc1385633b2d91ef46a32&dwk=";
    case "Zeytinburnu":
      return "https://harita.istanbul/2d/embed/0001?@=29.10599,41.01702,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=6af7a4f1e74fb2278d430fb9e02d316e&dwk=";
    case "Esenyurt":
      return "https://harita.istanbul/2d/embed/0001?@=28.91009,40.99312,18.89661&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=fbbfc1a9a67f63978c5f9ee708f8c288&dwk=";
    case "Adalar":
      return "https://harita.istanbul/2d/embed/0001?@=29.10599,41.01702,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=89bca9823efa16321f32e509ce023693&dwk=";
    case "Bağcılar":
      return "https://harita.istanbul/2d/embed/0001?@=29.12874,40.87403,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=c9fbcc08e065b2e7caca132009fb7d09&dwk=";
    case "Başakşehir":
      return "https://harita.istanbul/2d/embed/0001?@=28.87003,41.03354,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=dd5dd242bb5a47044ae844ccb74a472b&dwk=";
    case "Eyüpsultan":
      return "https://harita.istanbul/2d/embed/0001?@=28.75300,41.06811,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=2303d954b4b844df25549c71cc75ed09&dwk=";
    case "Pendik":
      return "https://harita.istanbul/2d/embed/0001?@=28.94409,41.07047,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=11babf9220bc41f924efeef34d4fb4aa&dwk=";
    case "Kartal":
      return "https://harita.istanbul/2d/embed/0001?@=29.25104,40.88048,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=7005c67e55e0b0cbcab42649a0c8ef9c&dwk=";
    case "Gaziosmanpaşa":
      return "https://harita.istanbul/2d/embed/0001?@=28.89879,41.07648,18.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=b850010bf86a0aae928ba27bd817e118&dwk=";
    case "Kadıköy":
      return "https://harita.istanbul/2d/embed/0001?@=29.25104,40.88048,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=7005c67e55e0b0cbcab42649a0c8ef9c&dwk=";
    case "Maltepe":
      return "https://harita.istanbul/2d/embed/0001?@=29.25104,40.88048,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=7005c67e55e0b0cbcab42649a0c8ef9c&dwk=";
    case "Bakırköy":
      return "https://harita.istanbul/2d/embed/0001?@=29.10599,41.01702,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=6af7a4f1e74fb2278d430fb9e02d316e&dwk=";
    case "Güngören":
      return "https://harita.istanbul/2d/embed/0001?@=28.97231,41.02784,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=bbda318c8c62e01340c66a2f7ab07408&dwk=";
    case "Çekmeköy":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=78f8a005d31619866887b2dfac3072d4&dwk=";
    case "Sultanbeyli":
      return "https://harita.istanbul/2d/embed/0001?@=28.95358,41.01431,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=78f8a005d31619866887b2dfac3072d4&dwk=";
    case "Esenler":
      return "https://harita.istanbul/2d/embed/0001?@=29.12874,40.87403,17.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=c9fbcc08e065b2e7caca132009fb7d09&dwk=";
    case "Fatih":
      return "https://harita.istanbul/2d/embed/0001?@=28.90239,41.09755,17.88832&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=e938698487832ee99da47e47bb2f189a&dwk=";
    default:
      return "https://harita.istanbul/2d/embed/0001?@=28.98674,41.06772,18.00000&p=45.00000&b=0.00000&suk=&ruk=!&ms=!b281!c&o=!o1&ct=0&duk=c5758f7231a9e84c02fe6ebd03f60622&dwk=";
  }
};

export const getDirectionsMapIstanbul = (office) => {
  switch (office?.county?.county) {
    case "Üsküdar":
      const map =
        office?.id?.id === "N7qBd8kmLaCb4mPxYvmqIrM"
          ? "https://harita.istanbul/2d/route/0123?@=29.01519,41.02066,17.00000&p=45.00000&b=0.00000&suk=&ruk=!dde55331372d993ee313e67d62b0f064&ms=!b281!c&o=!o1&ct=0&duk=&dwk="
          : "https://harita.istanbul/2d/route/0123?@=29.080083493,41.029162272,17.00000&p=45.00000&b=0.00000&suk=&ruk=!4e3e7c23b93362e62ce2461d54347f39&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
      return map;
    case "Arnavutköy":
      return "https://harita.istanbul/2d/route/0123?@=28.76484,41.18607,17.00000&p=45.00000&b=0.00000&suk=&ruk=!0ccc111fd5690af38314545b84653996&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Beykoz":
      return "https://harita.istanbul/2d/route/0123?@=29.09097,41.09195,17.00000&p=45.00000&b=0.00000&suk=&ruk=!b4c4eb33e13db62be505b18a7b4c3d7e&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Büyükçekmece":
      return "https://harita.istanbul/2d/route/0123?@=28.58779,41.02320,17.00000&p=45.00000&b=0.00000&suk=&ruk=!925001bbcceefcc42ee884a89cee3110&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Sancaktepe":
      return "https://harita.istanbul/2d/route/0123?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!78f8a005d31619866887b2dfac3072d4&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Beylikdüzü":
      return "https://harita.istanbul/2d/route/0123?@=28.66130,41.00750,17.00000&p=45.00000&b=0.00000&suk=&ruk=!f2eec7c0a098e39c4680f0b608848ad1&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Avcılar":
      return "https://harita.istanbul/2d/route/0123?@=28.72172,40.97910,17.00000&p=45.00000&b=0.00000&suk=&ruk=!dd37d6fe74590ff4e6995c7a1b4f10c1&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Küçükçekmece":
      return "https://harita.istanbul/2d/route/0123?@=28.79045,40.98821,17.00000&p=45.00000&b=0.00000&suk=&ruk=!21d403da6594555661ca71bea7a2e6fd&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Şile":
      return "https://harita.istanbul/2d/route/0123?@=29.61555,41.17236,17.00000&p=45.00000&b=0.00000&suk=&ruk=!3d74ba7e902d94dd805c8ab1de1042c6&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Şişli":
      return "https://harita.istanbul/2d/route/0123?@=28.98674,41.06772,18.00000&p=45.00000&b=0.00000&suk=&ruk=!c5758f7231a9e84c02fe6ebd03f60622&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Çatalca":
      return "https://harita.istanbul/2d/route/0123?@=28.46192,41.14465,18.00000&p=45.00000&b=0.00000&suk=&ruk=!2631c1de55aaf0eda3bb067c15c0384b&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Sarıyer":
      return "https://harita.istanbul/2d/route/0123?@=29.00119,41.11685,18.00000&p=45.00000&b=0.00000&suk=&ruk=!c95ab84fc8d3a165208fae3a690b1472&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Sultangazi":
      return "https://harita.istanbul/2d/route/0123?@=28.90520,41.09387,18.00000&p=45.00000&b=0.00000&suk=&ruk=!d65b8261611772ef94bffdc480f6a137&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Bayrampaşa":
      return "https://harita.istanbul/2d/route/0123?@=28.902697145,41.042356683,17.00000&p=45.00000&b=0.00000&suk=&ruk=!e938698487832ee99da47e47bb2f189a&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Silivri":
      return "https://harita.istanbul/2d/route/0123?@=28.24706,41.07328,17.00000&p=45.00000&b=0.00000&suk=&ruk=!f454353aa871eac7b2cdd271ec88b3cc&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Beyoğlu":
      return "https://harita.istanbul/2d/route/0123?@=28.97281,41.02787,18.00000&p=45.00000&b=0.00000&suk=&ruk=!22cc39376fb3d1beaea71dbf7074cd45&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Bahçelievler":
      return "https://harita.istanbul/2d/route/0123?@=28.84402,41.00780,17.00000&p=45.00000&b=0.00000&suk=&ruk=!bbda318c8c62e01340c66a2f7ab07408&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Ümraniye":
      return "https://harita.istanbul/2d/route/0123?@=29.10599,41.01702,17.00000&p=45.00000&b=0.00000&suk=&ruk=!381e7dfa500d6180e96ce5a9dee25e90&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Tuzla":
      return "https://harita.istanbul/2d/route/0123?@=29.30915,40.82978,17.00000&p=45.00000&b=0.00000&suk=&ruk=!d456ac337ffcc1385633b2d91ef46a32&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Zeytinburnu":
      return "https://harita.istanbul/2d/route/0123?@=28.91009,40.99312,17.00000&p=45.00000&b=0.00000&suk=&ruk=!6af7a4f1e74fb2278d430fb9e02d316e&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Esenyurt":
      return "https://harita.istanbul/2d/route/0123?@=28.68273,41.03355,17.00000&p=45.00000&b=0.00000&suk=&ruk=!fbbfc1a9a67f63978c5f9ee708f8c288&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Adalar":
      return "https://harita.istanbul/2d/route/0123?@=29.12874,40.87403,17.00000&p=45.00000&b=0.00000&suk=&ruk=!89bca9823efa16321f32e509ce023693&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Bağcılar":
      return "https://harita.istanbul/2d/route/0123?@=28.87074,41.03363,17.00000&p=45.00000&b=0.00000&suk=&ruk=!c9fbcc08e065b2e7caca132009fb7d09&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Başakşehir":
      return "https://harita.istanbul/2d/route/0123?@=28.75300,41.06811,17.00000&p=45.00000&b=0.00000&suk=&ruk=!dd5dd242bb5a47044ae844ccb74a472b&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Eyüpsultan":
      return "https://harita.istanbul/2d/route/0123?@=28.94409,41.07047,17.00000&p=45.00000&b=0.00000&suk=&ruk=!2303d954b4b844df25549c71cc75ed09&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Pendik":
      return "https://harita.istanbul/2d/route/0123?@=29.25105,40.88048,18.00000&p=45.00000&b=0.00000&suk=&ruk=!11babf9220bc41f924efeef34d4fb4aa&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Kartal":
      return "https://harita.istanbul/2d/route/0123?@=29.196185206,40.886435729,17.00000&p=45.00000&b=0.00000&suk=&ruk=!7005c67e55e0b0cbcab42649a0c8ef9c&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Gaziosmanpaşa":
      return "https://harita.istanbul/2d/route/0123?@=28.89879,41.07648,18.00000&p=45.00000&b=0.00000&suk=&ruk=!b850010bf86a0aae928ba27bd817e118&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Kadıköy":
      return "https://harita.istanbul/2d/route/0123?@=29.196185206,40.886435729,17.00000&p=45.00000&b=0.00000&suk=&ruk=!7005c67e55e0b0cbcab42649a0c8ef9c&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Maltepe":
      return "https://harita.istanbul/2d/route/0123?@=29.196185206,40.886435729,17.00000&p=45.00000&b=0.00000&suk=&ruk=!7005c67e55e0b0cbcab42649a0c8ef9c&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Bakırköy":
      return "https://harita.istanbul/2d/route/0123?@=28.91009,40.99312,17.00000&p=45.00000&b=0.00000&suk=&ruk=!6af7a4f1e74fb2278d430fb9e02d316e&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Güngören":
      return "https://harita.istanbul/2d/route/0123?@=28.84402,41.00780,17.00000&p=45.00000&b=0.00000&suk=&ruk=!bbda318c8c62e01340c66a2f7ab07408&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Çekmeköy":
      return "https://harita.istanbul/2d/route/0123?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!78f8a005d31619866887b2dfac3072d4&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Sultanbeyli":
      return "https://harita.istanbul/2d/route/0123?@=29.23246,40.97857,17.00000&p=45.00000&b=0.00000&suk=&ruk=!78f8a005d31619866887b2dfac3072d4&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Esenler":
      return "https://harita.istanbul/2d/route/0123?@=28.87074,41.03363,17.00000&p=45.00000&b=0.00000&suk=&ruk=!c9fbcc08e065b2e7caca132009fb7d09&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    case "Fatih":
      return "https://harita.istanbul/2d/route/0123?@=28.902697145,41.042356683,17.00000&p=45.00000&b=0.00000&suk=&ruk=!e938698487832ee99da47e47bb2f189a&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
    default:
      return "https://harita.istanbul/2d/route/0123?@=28.98674,41.06772,18.00000&p=45.00000&b=0.00000&suk=&ruk=!c5758f7231a9e84c02fe6ebd03f60622&ms=!b281!c&o=!o1&ct=0&duk=&dwk=";
  }
};
