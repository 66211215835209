import { getCompanyWorkForceData } from "../services/request";

export const loadCompanyWorkForceData = () => async (dispatch) => {
  dispatch({ type: 'COMPANY_WORK_FORCE_START' });

  try {
    const data = await getCompanyWorkForceData({ url: '/public/companyWorkforce', key: 'companyWorkForce' });
    dispatch({ type: 'COMPANY_WORK_FORCE_SUCCESS', payload: data.result });
  } catch (error) {
    dispatch({ type: 'COMPANY_WORK_FORCE_ERROR', error });
  }
};