import { useEffect, useState } from "react";
import { Row, Col, Divider, Form, Button } from 'antd';
import { LookupSelect } from "components/UIComponents/FormElements";
import Highschool from "./Highschool";
import PrimarySchool from "./PrimarySchool";
import University from "./University";

export default ({ data, index, remove }) => {
  const [selectedEducation, setSelectedEducation] = useState(null);

  useEffect(() => {
    if (data) {
      setSelectedEducation(data.level)
    }
  }, [data])
  return (
    <div className="EducationLevelItem full-width">
      <div className="d-flex a-center full-width mb-20">
        <Row className="full-width">
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} className="d-flex a-center full-width">
            <Row className="full-width">
              <Col span={16}>
                <Form.Item name="level" rules={[{ required: true, message:"Eğitim Seviyesi" }]}>
                  <LookupSelect
                    onChange={setSelectedEducation}
                    url="/types/education-levels"
                    valueKey="name"
                    labelKey="label"
                    placeholder="Eğitim Seviyesi"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Button className="BtnDeleteItem btn btn-blue-filled full-width text-md" onClick={() => remove(index)}>Sil</Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {
        (
          selectedEducation === 'S0' ||
          selectedEducation === 'S1'
        ) &&
        <>
          <PrimarySchool data={data}/>
          <Divider className="Divider grey" />
        </>
      }
      {(
        selectedEducation === 'S2' ||
        selectedEducation === 'S3' ||
        selectedEducation === 'S4' ||
        selectedEducation === 'S5'
      ) &&
        <>
          <Highschool data={data}/>
          <Divider className="Divider grey" />
        </>
      }
      {
        (
          selectedEducation === 'S6' ||
          selectedEducation === 'S7' ||
          selectedEducation === 'S8' ||
          selectedEducation === 'S9'
        ) &&
        <>
          <University data={data} />
          <Divider className="Divider grey" />
        </>
      }
    </div>
  );
};
