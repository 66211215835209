import React, { useEffect, useState } from "react";
import useGet from "../../../hooks/useGet";
import { Spin, Row, Col, Button, Select } from "antd";
import { Helmet } from "react-helmet";
import { Banner, Icon, Map } from "components/UIComponents";
import { get } from "lodash";
import { getGoogleRoute } from "../../../helpers/methods";
import HaritaIstanbul from "./HaritaIstanbul";
import { getDirectionsMapIstanbul } from "./HaritaIstanbulRoute";

const Offices = () => {
  const { data, loading } = useGet({
    url: "/public/offices?page=1&size=40",
    key: "offices",
  });
  const [active, setActive] = useState({ index: 0, data: null });
  const [options, setOptions] = useState([]);

  useEffect(() => {
    data && setActive({ ...active, data: data[0] });

    data &&
      setOptions(() =>
        data.map((option, index) => {
          return { value: index, label: option.county.county };
        })
      );
  }, [data]);
  console.log("data: " + JSON.stringify(data));

  console.log(active);

  return (
    <div className="Offices">
      <Helmet>
        <title>İş Ara İş Bul | Ofislerimiz</title>
        <meta
          name="description"
          content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
        />
        <meta
          name="keywords"
          content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
        />
      </Helmet>
      <Banner text="Bölgesel İstihdam Ofisleri" className="mb-0" />
      <div className="content md content-detail">
        <Spin spinning={loading}>
          <Row
            className="BtnOfficeContainer-Mobile"
            gutter={{ xs: 10, sm: 10, md: 20 }}
          >
            <Select
              defaultValue="Büyükçekmece"
              style={{
                width: "100%",
                padding: "12px 0",
              }}
              onChange={(value) => {
                options.find((a) => a.value === value);
                setActive({ data: data[value], index: value });
              }}
              options={options}
            />
          </Row>
          <Row
            className="BtnOfficeContainer"
            gutter={{ xs: 10, sm: 10, md: 20 }}
          >
            {data?.map(({ county, id }, index) => (
              <Col
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 6 }}
                lg={{ span: 4 }}
                key={id.id}
              >
                <Button
                  key={id.id}
                  className={`BtnOffice btn btn-blue full-width ${
                    active.index === index && "current"
                  }`}
                  onClick={() => {
                    setActive({ data: data[index], index: index });
                  }}
                >
                  {id?.id === "jpbkxBYL7XHe4qdQrMqpfgrn"
                    ? "Üsküdar 2"
                    : id?.id === "BDxvgzyVLYI4vVXgDnVZia8n"
                    ? "Hatay"
                    : county?.county}
                </Button>
              </Col>
            ))}
          </Row>
        </Spin>
        <div className="InfoBox p-20 mb-20">
          <h3 className="text-xl fw-bold text-red-pink">İletişim Merkezi</h3>
          <p className="text-lg ls-half mb-5">
            İş arayanlar, başvurularını kendilerine en yakın ofisleri ziyaret
            ederek yapabildikleri gibi{" "}
            <a href="tel:4448333" className="fw-bold text-xl text-blue">
              444 8 333
            </a>{" "}
            numaralı telefondan kariyer danışmanlarına ulaşabilir,{" "}
            <a
              href="https://bio.ibb.istanbul"
              className="text-xl text-pink fw-bold"
            >
              bio.ibb.istanbul
            </a>{" "}
            portlarımıza online başvuru yapıp iş ilanlarını takip edebiliyorlar.
          </p>
        </div>
        <div className="InfoBox p-20">
          <h3 className="text-xl fw-bold text-red-pink">
            {active?.data?.officeName}
          </h3>
          <article className="text-xl mb-20">
            {active?.data?.description}
          </article>
          <div className="Address d-flex mb-20">
            <div className="mr-15 text-center">
              <Icon name="pin" size={39} className="d-flex a-center" />
            </div>
            <div>
              <p className="text-xl text-red-pink fw-bold ls-half mb-5">
                Adres
              </p>
              <p className="text-xl ls-half">{active?.data?.address}</p>
            </div>
          </div>
          <div className="CareerCounselor d-flex">
            <div className="text-center mr-15">
              <Icon name="talking" size={39} />
            </div>
            <div>
              <p className="text-xl text-blue fw-bold ls-half mb-5">
                Kariyer Danışmanlarımız
              </p>
            </div>
          </div>
          <div className="ant-row mt-20">
            {active?.data?.relatedSystemUsers?.map((value, index) => (
              <div
                key={index}
                className="ant-col-lg-8 ant-col-md-12 ant-col-24 mb-20"
              >
                <div className="col text-md-xl text-lg text-cap">
                  {value.firstName} {value.lastName}
                </div>
                <a
                  href={`mailto:${value.email}`}
                  className="col text-md-lg text-md fw-bold text-blue"
                >
                  {value.email}
                </a>
              </div>
            ))}
          </div>
        </div>
        <h3 className="text-red-pink fw-bold mt-20 mb-20">Haritada Nerede?</h3>
        <div className="Map" style={{ height: 465 }}>
          <Spin spinning={loading}>
            {/* <Map
              center={[
                get(active.data, "locationLat", 0),
                get(active.data, "locationLon", 0),
              ]}
              markers={[
                {
                  lat: get(active.data, "locationLat", 0),
                  lng: get(active.data, "locationLon", 0),
                  title: get(active.data, "officeName"),
                },
              ]}
            /> */}
            <HaritaIstanbul data={active?.data} />
            <div style={{ display: "flex", justifyContent: "center" }}>
              <a
                style={{ width: "100%" }}
                // href={`${getGoogleRoute(active.data)}`}
                href={`${getDirectionsMapIstanbul(active.data)}`}
                target="_blank"
                rel="noreferrer"
              >
                <Button
                  className="ant-btn ant-btn-primary"
                  style={{
                    width: "100%",
                    margin: "12px 0",
                    minHeight: 48,
                    fontSize: 18,
                    fontWeight: 600,
                  }}
                >
                  Yol Tarifi Al
                </Button>
              </a>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Offices;
