import { Col, Modal, Row, Switch } from "antd";
import { useState } from "react";
// import CookiePolicy from "../../../../assets/policy/Bio.ibb.istanbul Çerez Politikası ve Çerez Aydınlatma Metni.pdf";
import CookiePolicy from "../../../../assets/policy/Bio.ibb.istanbul Çerez Politikası ve Çerez Aydınlatma Metni_yeni.pdf";
import "./style.css";

const CookieManagement = ({ open, setOpen }) => {
  const [checked, setChecked] = useState(
    localStorage.getItem("cookieAllowed") ? true : false
  );

  const cookieManagementSubmit = () => {
    if (checked) {
      localStorage.setItem("cookieAllowed", true);
      localStorage.removeItem("cookieRejected");
      window.location.reload();
    } else {
      localStorage.setItem("cookieRejected", true);
      localStorage.removeItem("cookieAllowed");
      window.location.reload();
    }
  };

  return (
    <Modal
      title="Çerez Yönetimi"
      centered
      visible={open}
      onCancel={() => setOpen(false)}
      footer={[
        <button className="cookie_btn" onClick={cookieManagementSubmit}>
          Seçimleri Onayla
        </button>,
      ]}
      width={800}
    >
      <p>
        Kesinlikle Gerekli Çerezler dışındaki çerezlerin kullanımına izin
        vermeniz durumunda kişisel verileriniz yurt içi ve yurtdışında
        işlenecektir. Çerezler hakkında detaylı bilgi için{" "}
        <a
          target="_blank"
          style={{ textDecoration: "underline" }}
          href={CookiePolicy}
          rel="noreferrer"
        >
          Çerez Politikası Ve Aydınlanma Metni
        </a>
        'ni ziyaret edebilirsiniz.
      </p>
      <Row>
        {/* <Col>
          <div className="cookie_management_item">
            <Row>
              <Col md={{ span: 20 }}>
                <h3 className="cookie_management_title">
                  Kesinlikle Gerekli Çerezler
                </h3>
                <p className="cookie_management_text">
                  Bu çerezler, internet sitemizin düzgün çalışmasını ve temel
                  işlevlerini yerine getirebilmesini sağlar. Tarayıcı
                  ayarlarınızı değiştirerek bu çerezleri devre dışı
                  bırakabilirsiniz; ancak bu çerezler devre dışı bırakıldığı
                  takdirde internet sitemizin bir kısmının veya tümünün düzgün
                  çalışmasını engelleyebilir.
                </p>
              </Col>
              <Col md={{ span: 4 }}>
                <Switch checked disabled />
              </Col>
            </Row>
          </div>
        </Col> */}
        <Col>
          <div className="cookie_management_item">
            <Row>
              <Col md={{ span: 20 }}>
                <h3 className="cookie_management_title">
                  Performans & Analiz Çerezleri
                </h3>
                <p className="cookie_management_text">
                  Bu çerezler internet sitemiz ile ilgili analiz yapmamıza
                  yardımcı olur. Bu çerezleri kullanmamıza izin verdiğinizde,
                  kullancı sayısını ve kullanıcıların en çok hangi sayfaları
                  ziyaret ettiği hakkında bilgi sahibi olabilir, böylece
                  internet sitesi deneyiminizi iyileştirebiliriz. Bu çerezlerden
                  bir kısmının sağlayıcıları yurt dışında bulunmaktadır. Bu
                  çerezlerin kullanımını etkinleştirmeniz halinde çerezler
                  aracılığıyla işlenen kişisel verileriniz yurt dışına
                  aktarılacaktır.
                </p>
              </Col>
              <Col md={{ span: 4 }}>
                <Switch
                  checked={checked}
                  onChange={() => setChecked(!checked)}
                />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default CookieManagement;
