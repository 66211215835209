import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import useGet from 'hooks/useGet';
import queryString from 'query-string';
import {Helmet} from "react-helmet";
import printBack from '../../../../../assets/printback.png';
import bio from '../../../../../assets/static/img/logo.png';
import '../../../../../styles/components/print.less';
import ibbalt from '../../../../../assets/ibbalt.png';
import biohead from '../../../../../assets/logo-logo.svg';
import MainInfo from "./MainInfo";
import Experience from "./Experience";
import Education from "./Education";
import LanguageSkills from "./LanguageSkills";
import ComputerSkills from "./ComputerSkills";
import {getBase64, printFunction} from "../../../../../helpers/methods";
import axios from "axios";
import config from "../../../../../plugins/ibb/config";
import {message} from "antd";

const Detail = (props) => {
  const search = useHistory().location.search;
  let params = queryString.parse( props.location.search )
  let sourceParam = params.source;
  const [candidateId, setCandidateId] = useState();
  const [jobId, setJobId] = useState();
  const url = (sourceParam === 'APP') ? (candidateId && jobId) && `/candidates/${candidateId}/resumes?applicationId=${jobId}` : (candidateId && jobId) && `/candidates/${candidateId}/resumes?advertisementId=${jobId}`;
  const { data, loading } = useGet({ url: url, isArray: false });
  const currentDate = new Date();
  const fullName = data?.firstName + ' ' + data?.lastName;
  const [ imageUrl,setImageUrl ] = useState( biohead )

  useEffect(() => {
    const query = queryString.parse(search);
    setCandidateId(query.candidateId);
    setJobId(query.jobId);
  }, []);

  useEffect(() => {
    data && data?.photoId == null && !loading && printFunction( data.firstName + '_' + data.lastName )
  }, [data, loading]);

  useEffect( () => {
    if( data?.photoId ) {
      ( () => {
        axios.get( `${config.BASE_API_URL}/companies/candidate-resume/profile-photos/${ data?.photoId }`, {
          responseType:"blob",
          headers: {
            'Content-Type': 'image/jpg, image/png'
          }
        })
          .then( response => {
            console.log(response,'response')
            getBase64( response.data, img => {
              setImageUrl( img );
              printFunction( data.firstName + '_' + data.lastName )
            })
          })
          .catch( err => {
            message.error(err)
            printFunction( data.firstName + '_' + data.lastName )
          })
      })()
    }

  }, [ data ]);

  return (
    <div className="CandidatePrintDetail">
      <Helmet>
        <title>İş Ara İş Bul | Özgeçmiş Yazdır</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <div className="print">
          <div className="bio-logo">
             <img src={bio} alt="bölgesel istihdam ofisi logo"/>
          </div>
          <div className="banner">
            <img style={{width:'100%'}} src={printBack} className="printBack"/>
            <p className="name" style={{ fontSize:`${ fullName.length > 10 ? '18px' : '24px' }` }}>{ fullName }</p>
            <p className="title"></p>
            <div className="profile-photo" style={{backgroundImage: `url(${ imageUrl })`, backgroundSize:'cover' }}></div>
          </div>
      </div>
      <p className="description">
        { data?.coverLetter?.replace( /(<([^>]+)>)/ig, '') }
      </p>
      <section className="section-info">
        <div className="section-title-wrapper">
          <h2 className="section-title">KİŞİSEL BİLGİLER</h2>
          <div className="line"></div>
        </div>
        <MainInfo candidate={ data }/>
      </section>
      {
        data?.experiences.length > 0 && (
          <section className="section-info">
            <div className="section-title-wrapper">
              <h2 className="section-title">İŞ DENEYİMLERİ</h2>
              <div className="line"></div>
            </div>
            {
              data && data.experiences?.map(( experience, index ) => {
                return <Experience
                  key={index}
                  company={experience.companyTitle}
                  description={experience.description}
                  era={experience.startDate?.split('-')?.shift() + ' - ' + ( experience.endDate ? experience.endDate?.split('-')?.shift() : 'devam ediyor' ) }
                  duration={ (-1 * ( experience.startDate?.split('-')?.shift() - experience.endDate?.split('-')?.shift() ) === 0 ? 1 : -1 * ( experience.startDate?.split('-')?.shift() - ( experience.endDate ? experience.endDate?.split('-')?.shift() : currentDate.getFullYear()) )) + ' Yıl' }
                />
              })
            }
          </section>
        )
      }
      {
        data?.educations.length > 0 && data?.educations[0]?.level?.description !== 'Belirtilmemiş' && (
          <section className="section-info">
            <div className="section-title-wrapper">
              <h2 className="section-title">EĞİTİM BİLGİLERİ</h2>
              <div className="line"></div>
            </div>
            {
              data && data.educations?.map(( education, index ) => {
                if( education.departmentName ){
                return <Education
                  key={index}
                  schoolName={ education.instituteName }
                  faculty={ education.departmentName }
                  duration={ education?.startDate + ' - ' + (education.endDate ? education.endDate : 'devam ediyor') }
                  average={ education.level.description }
                />
                } else {
                  return <p>{ education.level.description }</p>
                }
              })
            }
          </section>
        )
      }
      {
        data?.computerCompetencies.length < 1 || data?.languages.length > 0 && (
          <section className="section-info">
            <div className="section-title-wrapper">
              <h2 className="section-title">YETKİNLİKLER</h2>
              <div className="line"></div>
            </div>
            { data?.languages.length > 0 && <LanguageSkills data = { data?.languages } /> }
            { data?.computerCompetencies.length > 0 && <ComputerSkills data = { data?.computerCompetencies } /> }
          </section>
        )
      }
      {
        data?.certificationAndSeminars !== null && (
        <section className="section-info">
          <div className="section-title-wrapper">
            <h2 className="section-title">SERTİFİKALAR</h2>
            <div className="line"></div>
          </div>
          {
            data?.certificationAndSeminars?.map(( certificate, index ) => {
              return (
                <Experience
                  key={index}
                  title={certificate?.subject }
                  company={ certificate.instituteName }
                  description={ certificate.description }
                  era={certificate.start + ' - ' + ( certificate.end ? certificate.end : 'devam ediyor')  }
                  duration={ certificate.duration }
                />
              )
            })
          }
        </section>
      )
      }
      <img src={ibbalt} className="alt-image" alt="ibb"/>
    </div>
  );
}

export default Detail;

