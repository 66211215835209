import seninLogo from "../../../../assets/seninLogo.svg";
import config from "../../../../plugins/ibb/config";
import { useEffect, useState } from "react";
import axios from "axios";
import { message } from "antd";
import { changeUrl } from "helpers/methods";

const IstanbulSeninButton = ({ modal = false }) => {
  const [loginUrl, setLoginUrl] = useState(null);

  const loginSubmit = (loginUrl) => {
    if (loginUrl) {
      message.success("İstanbul senin giriş ekranına yönlendiriliyorsunuz.");
      window.open(loginUrl, "_self");
    } else {
      message.error("Beklenmeyen bir hata oluştu");
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const res = await axios.get(changeUrl("istsenin/auth/oidc"));
        console.log(res);

        if (res.data.code !== 200) {
          message.error(res.data.message);
        }

        setLoginUrl(res.data.result.composedUrl);
      } catch {
        console.log("something went wrong");
      }
    })();
  }, []);

  return (
    <>
      {modal ? (
        <button
          className="istanbul-senin-button ModalSeninButton"
          type="button"
          onClick={() => loginSubmit(loginUrl)}
        >
          <div className="logo-container">
            <img src={seninLogo} className="logo" alt="logo" />
          </div>
          <div>
            <span className="text-container">İSTANBUL SENİN’LE GİRİŞ</span>
          </div>
        </button>
      ) : (
        <button
          className="istanbul-senin-button"
          type="button"
          onClick={() => loginSubmit(loginUrl)}
        >
          <div className="logo-container">
            <img src={seninLogo} className="logo" alt="logo" />
          </div>
          <div>
            <span className="text-container">İSTANBUL SENİN’LE GİRİŞ</span>
          </div>
        </button>
      )}
    </>
  );
};

export default IstanbulSeninButton;
