const Experience = ({ company, era, duration, description, profession }) => {
  return (
    <>
      <div className="experience-wrapper">
        <div className="title-container">
          <h4 className="company-title" style={{ fontSize: `18px` }}>
            {company}
          </h4>
          <p className="candidate-title">{profession}</p>
        </div>
        <div className="time-container">
          <p className="date">{era}</p>
          <p className="experience-time">
            {duration ? duration + " saat" : ""}
          </p>
        </div>
      </div>
      <div className="experience-description">{description}</div>
    </>
  );
};

export default Experience;
