import {Row, Col, Input, Button, Form} from 'antd';

export default ({ index, remove}) => {
  return (
    <Row className="Reference" justify="space-between">
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
          <Form.Item name="fullName" rules={[{required:true, message:"Ad Soyad Giriniz"}, { whitespace:true, message: "Boşluk Dışında Bir Değer Giriniz" }]}>
            <Input className="form-input mb-20" placeholder="Ad Soyad" />
          </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
          <Form.Item name="company" rules={[{required:true, message:"Firma Giriniz"}, { whitespace:true, message: "Boşluk Dışında Bir Değer Giriniz" }]}>
            <Input className="form-input mb-20" placeholder="Firma" />
          </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
          <Form.Item name="position" rules={[{required:true, message:"Pozisyon Giriniz"}, { whitespace:true, message: "Boşluk Dışında Bir Değer Giriniz" }]}>
            <Input className="form-input mb-20" placeholder="Pozisyon" />
          </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
          <Form.Item name="email" rules={[{required:true,type:'email', message:"Email Giriniz"}, { whitespace:true, message: "Boşluk Dışında Bir Değer Giriniz" }]}>
            <Input className="form-input mb-20" placeholder="E Posta" />
          </Form.Item>
      </Col>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
          <h1 className="text-xl text-black ls-half fw-bold">Telefon</h1>
          <Form.Item name="phone" rules={[{required:false, pattern:/05[0-9]{9}/g,}]}>
            <Input className="form-input mb-20" placeholder="05** *** ** **"/>
          </Form.Item>
      </Col>
      <Row className="full-width mb-50">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 3 }}>
          <Button className="btn btn-blue-filled full-width text-md" onClick={() => remove(index)} style={{ height: 56 }}>Sil</Button>
        </Col>
      </Row>
    </Row>
  );
};