import {Spin} from "antd";
import {Banner} from "components/UIComponents";
import useGet from "hooks/useGet";
import {get} from "lodash";
import {useState} from "react";
import {useParams} from "react-router";
import ReactHtmlParser from 'react-html-parser';
import config from 'plugins/ibb/config'
import {Helmet} from "react-helmet";

const NewsDetail = () => {
  const [id] = useState(useParams().id);
  const {data, loading} = useGet({url: `/public/news/${id}`, isArray: false});
  return (
    <div className="NewsDetail">
      <Banner text="Haber Detayı" className="mb-30"/>
      <Spin spinning={loading}>
        <Helmet>
          <title>İş Ara İş Bul | Detaylar</title>
          <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
          <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
        </Helmet>
        <div className="content md">
          {data && data.imageUrl && <img src={config.BASE_API_URL.concat(data.imageUrl)} alt={data.title}/>}
          <h1 className="text-4xl fw-bold mb-10 mt-10">{get(data, 'title', '-')}</h1>
          <h3 className="text-xl fw-light">{get(data, 'summary', '-')}</h3>
          <p className="mt-50 mb-50">{ReactHtmlParser(get(data, 'content', '-'))}</p>
        </div>
      </Spin>
    </div>
  );
}

export default NewsDetail;
