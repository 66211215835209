import { Button, Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import Api from "../../../../../../../api/Api";
import { getData } from "../../../../../../../redux/services/request";
import { useSelector } from "react-redux";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const ref = useRef();
  const MAX_COUNT = 4000;
  const [text, setText] = useState(null);
  const resume = useSelector((state) =>
    state.data?.resumeS9 ? state.data?.resumeS9.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
    setPageLoading(true);
    getData({ url: "candidates/resumes/S9", key: "resumeS9" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
    refresh();
  }, []);

  useEffect(() => {
    if (resume && resume.detail) {
      setText(resume.detail.coverLetterText);
    }
  }, [resume]);

  const onClickNext = () => {
    setLoading(true);
    new Api()
      .putData({
        url: "/candidates/resumes/cover-letter",
        _data: { coverLetterText: text },
      })
      .then(() => {
        setPageLoading(true);
        getData({ url: "candidates/resumes/S9", key: "resumeS9" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            setPageLoading(false);
          });
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
      });
  };

  const checkCharacterCount = (e) => {
    if (
      ref.current &&
      ref.current.getEditor().getLength() > MAX_COUNT &&
      e.key !== "Backspace"
    )
      e.preventDefault();
  };
  function cleanString(input) {
    // Bu regex, boş paragrafları ve içinde sadece <br/> olan paragrafları eşleştirir
    const regex = /<p>\s*(<br\s*\/?>)?\s*<\/p>/gi;
    // Tüm eşleşen durumları boş string ile değiştirir
    const cleanedString = input.replace(regex, "");

    return cleanedString;
  }

  const onchangeText = (e) => {
    const str = cleanString(e);
    setText(str);
  };
  return (
    <div className="CoverLetter">
      <h1 className="fw-bold ls-full text-4xl text-black mb-30">Ön Yazı</h1>
      <Spin spinning={pageLoading}>
        <ReactQuill
          value={text}
          onChange={onchangeText}
          ref={ref}
          onKeyDown={checkCharacterCount}
          style={{ height: "300px" }}
        />
      </Spin>
      <div className="d-flex mt-50 pt-30">
        {loading ? (
          <Spin />
        ) : (
          <Button
            type="primary"
            className="BtnNextTab ml-auto"
            onClick={onClickNext}
          >
            Kaydet ve İlerle
          </Button>
        )}
      </div>
    </div>
  );
};
