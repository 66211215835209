import Footer16MilyonLogo from "../../../assets/static/img/footer-16-milyon-logo.svg";

const FooterLogo = () => {
  return (
    <>
      <div className="footer-line">
        <p className="footer-corporate-text">
          İstanbul Büyükşehir Belediyesi Bilgi İşlem Dairesi Başkanlığı
          tarafından ❤️ ile tasarlanmıştır.
        </p>
        <img
          className="footer-16-milyon-logo"
          src={Footer16MilyonLogo}
          alt="İstanbul Büyükşehir Belediyesi Bilgi İşlem Dairesi Başkanlığı"
        />
      </div>
    </>
  );
};
export default FooterLogo;
