import { useEffect, useState } from 'react';
import { Icon } from 'components/UIComponents';
import { languages, _languages } from 'helpers/constants';

import Row from './Row';


export default (props) => {
  const [foreignLanguages, setForeignLanguages] = useState([]);

  useEffect(() => {
    props.onChange && props.onChange(foreignLanguages.map(x => ({
      languageId: x.languageId,
      readingLevel: languages[x.readingLevel],
      writingLevel: languages[x.writingLevel],
      speakingLevel: languages[x.speakingLevel],
      translatingLevel: languages[x.translatingLevel]
    })));
    // eslint-disable-next-line
  }, [foreignLanguages]);

  const add = () => {
    const language = {
      languageId: '',
      readingLevel: 0,
      writingLevel: 0,
      speakingLevel: 0,
      translatingLevel: 0
    };
    setForeignLanguages([...foreignLanguages, language]);
  };

  const onChange = (i, language) => {
    const languages = [...foreignLanguages];
    languages[i] = language;
    setForeignLanguages(languages);
  }

  const remove = (languageId) => {
    setForeignLanguages(foreignLanguages.filter(x => x.languageId !== languageId));
  };

  useEffect(() => {
    if (props.value) {
      setForeignLanguages(props.value.map(x => ({
        languageId: x.languageId.languageId,
        readingLevel: _languages[x.readingLevel.id],
        writingLevel: _languages[x.writingLevel.id],
        speakingLevel: _languages[x.speakingLevel.id],
        translatingLevel: _languages[x.translatingLevel.id],
      })));
    }
  }, [props.value]);

  return (
    <div className="ForeignLanguage">
      <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">YABANCI DİL</h1>
      <div className="bg-softgrey2 p-20">
        {foreignLanguages.map((x, i) => <Row data={x} key={i} onChange={onChange} remove={remove} index={i} setLanguageDisabledBtn={props.setLanguageDisabledBtn}/>)}
        <div className="c-pointer mt-20" onClick={() => add()}>
          <Icon name="add" size={32} className="ml-95 pt-20" style={{ position: 'relative', left: -15 }} />
        </div>
      </div>
    </div>
  );
}