import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Row, Col, Select, Pagination, Divider, Spin, Empty, message } from 'antd';
import { get } from 'lodash';
import Banner from 'components/UIComponents/Banner';
import ListItem from './ListItem';
import useGet from 'hooks/useGet';
import Filter from './Filter';
import { putData } from 'redux/services/request';
import {Helmet} from "react-helmet";
import fileDownload from 'js-file-download';
import axios  from 'axios';
import config from 'plugins/ibb/config';

const ApplicationPool = () => {

  const [checks, setChecks] = useState([]);
  const [operation, setOperation] = useState(null);
  const [id] = useState(useParams().id);
  const [page, setPage] = useState(1);
  const [params, setParams] = useState(null);
  const [loadingIneligible, setLoadingIneligible] = useState(false);
  const { data, loading,refresh: _refresh } = useGet({ url: `/applications/jobs/${id}`, isArray: false, params });


  /** TODO: Bu istek sayfada en tepede duran ilan başlığı ve numarası için atılıyor
   * Burada sonradan backendden ilan numarası ve ilan başlığı gelen adaylar için ilanda istenebilir
    */
  const { data: advertisement, loading: loadingAdvertisement, refresh } = useGet({ url: data && data.results && `/jobs/${get(data, 'results[0].advertisementId')}`, isArray: false });

  const onChangeFilter = (params) => {
    setParams(params);
  }

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />
    else
      return originalElement;
  }

  const onCheckChange = (checked, applicationId) => {
    if (checked) {
      setChecks([...checks, applicationId]);
    } else {
      setChecks(checks.filter(x => x !== applicationId));
    }
  }

  const onChangeOperation = async (val) => {
    setOperation(val);
  }

  useEffect(() => {
    setParams({ ...params, page });
    // eslint-disable-next-line
  }, [page]);
  useEffect(() => {
    async function ineligibleBulk() {
      setLoadingIneligible(true);
      try {
        await putData({ url: '/applications/ineligible/bulk', data: { ids: checks } });
        message.success('Seçili adayların durumu "UYGUN DEĞİL" olarak güncellendi');
      } catch (err) {
        message.error('Aday bilgisi güncellenirken bir hata meydana geldi');
      } finally {
        setLoadingIneligible(false);
        setOperation(null);
      }
      await refresh()
    }

    //  async function excelExport  () {
    //   const idler = data?.results.map(item => item.applicationId);
    //   return axios({
    //     url:config.BASE_API_URL  + `/applications/${id}/export`,
    //     method: 'POST',
    //     data:  { ids: idler },
    //     responseType: 'blob',
    //     headers: {
    //       Authorization: 'Bearer ' + localStorage.getItem('accessToken')
    //     }
    //   }).then((response) => {
    //     fileDownload(response.data,  "application.xlsx");
    //   });
    // }
    operation === 'ineligible-bulk' && ineligibleBulk();
    // operation==='export' && excelExport();
    // eslint-disable-next-line
  }, [operation]);
  return (
    <div>
      <Banner text="İlan Havuzu" className="mb-0" />
      <div className="content md content-detail">
        <Spin spinning={loadingAdvertisement}>
          <Helmet>
            <title>İş Ara İş Bul | Firma İlan Havuzu</title>
            <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
            <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
          </Helmet>
          <Row align="top" justify="space-between" gutter={15}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <p className="fw-bold text-4xl">{get(advertisement, 'advertisementNo')} - {get(advertisement, 'positionTitle')}</p>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
              <Spin spinning={loadingIneligible}>
                <Select value={operation} className="form-select full-width" suffixIcon={<img src="/static/icons/select-arrow.svg" alt="Bölgesel İstihdam Ofisleri" />} placeholder="İşlemler" onChange={onChangeOperation}>
                  <Select.Option value="ineligible-bulk">Uygun Değil</Select.Option>
                </Select>
              </Spin>
            </Col>
          </Row>
        </Spin>
        <Filter onChange={onChangeFilter} />
        <Spin spinning={loading}>
          <Row className="List">
            {get(data, 'total') > 0 ?
              get(data, 'results', []).map(x => <ListItem refresh={_refresh} key={x.applicationId} data={x} onCheckChange={onCheckChange} onChange={e => onCheckChange(e.target.checked, get(data, 'applicationId'))} disabled={checks.length > 0} />
              )
              :
              <Empty className="full-width" description="İlan havuzunda herhangi bir aday yok" />
            }
          </Row>
        </Spin>
        <Row>
          <Col span={24}>
            <Divider className="Divider grey" />
          </Col>
        </Row>
        <div className="text-center">
          <Pagination
            current={get(data, 'page', 1)}
            onChange={(page, pageSize) => setPage(page)}
            total={get(data, 'total', 0)}
            itemRender={itemRender} />
        </div>
      </div>
    </div>
  );
};

export default ApplicationPool;
