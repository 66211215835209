import { useEffect, useState } from "react";
import { Row as AntRow, Col } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { LookupSelect } from "components/UIComponents/FormElements";

export default ({ onChangeDisability, remove, index }) => {
  const [id, setId] = useState(null);
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    (id || percentage) && onChangeDisability(id, percentage, index);
    // eslint-disable-next-line
  }, [id, percentage]);

  return (
    <div className="DisabilityContainer mb-20">
      <AntRow className="full-width" gutter={30} align="middle">
        <Col span={6}>
          <LookupSelect url="/disabilities" key="disabilities" value={id} valueKey="id" labelKey="disability" placeholder="Engel Tipi" onChange={setId} />
        </Col>
        <Col span={6}>
          <LookupSelect url="/disabilities/types" key="disabilityTypes" value={percentage} valueKey="name" labelKey="label" placeholder="Engel Yüzdesi" onChange={setPercentage} />
        </Col>
        <DeleteOutlined onClick={() => remove(id)} />
      </AntRow>
    </div>
  );
}