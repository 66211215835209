import { Banner } from "components/UIComponents";
import config from 'plugins/ibb/config'
import {Helmet} from "react-helmet";

export default () => {
  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | Bilgilendirme Metni</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Bilgilendirme Metni" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        {/* <img src={`${config.BASE_API_URL}${get(aboutUsData, 'data.imageUrl')}`} className="full-width mb-20" alt="Bölgesel İstihdam Hakkında" /> */}
        <article className="Article">
          <div>
            <p class="x_x_x_MsoNormal"><b><span>6698 SAYILI KİŞİSEL VERİLERİN KORUNMASI KANUNU (“KVKK”) UYARINCA</span></b><span></span></p>
            <p class="x_x_x_MsoNormal"><b><span>KİŞİSEL VERİLERİNİZİN KORUNMASI HAKKINDA BİLGİLENDİRME METNİ</span></b><span></span></p>
            <p class="x_x_x_MsoNormal" ><span>&nbsp;</span>Bölgesel İstihdam Ofisleri olarak; hangi kişisel verilerinizi ne amaçlarla işleyeceğimizi size anlatabilmek için BİLGİLENDİRME METNİ hazırladık. Aşağıda belirttiğimiz ve tarafımıza sağladığınız kişisel verilerinizin her koşulda;</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* duruma göre aşağıda belirtilen şekillerde elde ettiğimiz kişisel verilerinizin, hukuki ilişkilerimiz kapsamında,</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* işlenmelerini gerektiren amaç çerçevesinde ve bu amaçla bağlantılı, sınırlı ve ölçülü şekilde,</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* tarafımıza bildirdiğiniz veya bildirildiği şekliyle kişisel verilerin doğruluğunu ve en güncel halini koruyarak,</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* kaydedileceğini, depolanacağını, muhafaza edileceğini, yeniden düzenleneceğini, kanunen bu kişisel verileri talep etmeye yetkili olan kurumlar ile paylaşılacağını ve KVKK’nın öngördüğü şartlarda, yurtiçinde üçüncü kişilere aktarılacağını, devredileceğini, sınıflandırılabileceğini ve KVKK’da sayılan sair şekillerde işlenebileceğini bildiririz.</span><span></span></p>
            <div><br /></div>
            <p class="x_x_x_MsoNormal"><span>HANGİ KİŞİSEL VERİLERİNİZİ İŞLİYORUZ?</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>İş arayan vatandaşlarımızla çalışan arayan firmalarımızın aynı platformda birleştirerek istihdam yaratmayı amaçlayan platformumuzda, bu amaçları gerçekleştirebilmek ve bazı araştırmalar ve pazarlama faaliyetleri yaparak tüm vatandaşlarımıza daha iyi hizmet verebilmek için zaman zaman kişisel verilerinizi işleyebilmekteyiz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Tarafımızca, bizimle paylaşmanız veya gerekli olması halinde, işlemeye konu olabilecek kişisel verileriniz aşağıdaki gibidir:</span><span></span></p>
            <div><br /></div>
            <p class="x_x_x_MsoNormal"><span>Kimlik Verisi</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Adınız, soyadınız, T.C. Kimlik numaranız, doğum tarihiniz ve yeriniz, cinsiyetiniz, medeni durumunuz, milliyetiniz</span><span></span></p>
            <div><br /></div>
            <p class="x_x_x_MsoNormal"><span>İletişim Verisi</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>E-posta adresiniz, adresiniz, yaşadığınız il/ilçe, ev ve/veya cep telefon numaranız</span><span></span></p>
            <p class="x_x_x_MsoNormal">Özel Nitelikli Kişisel Veri</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Özgeçmişte yer vermeniz halinde dernek, vakıf, sendika, siyasi parti üyeliklerine ait bilgiler, dini veya felsefi görüşünüze, etnik kökeninize ve ırkınıza ait veya o konuda kanaat oluşturabilecek bilgiler, sağlığınıza ilişkin bilgiler veya destek hattında otomatik ses kayıt sistemine kaydedilen özel nitelikli kişisel verileriniz</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span>Eğitim Verisi</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Öğrenim durumu, sertifika ve diploma bilgileri, uzmanlık bilgisi, yabancı dil bilgileri, eğitim ve beceriler, katıldığı seminer ve kurslar, bilgisayar bilgisi</span><span></span></p>
            <p class="x_x_x_MsoNormal">İş Deneyimi Verisi</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal" ><span>Toplam tecrübe, çalışma durumu ve unvanı, iş deneyimleri (firma isimleri, çalışmış olduğu dönemler, iş tanımı)</span><span></span></p>
            <p class="x_x_x_MsoNormal">Görsel ve İşitsel Veri</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal" ><span>Gerçek kişiye ait fotoğraf,&nbsp; (video CV), ses kayıtları</span><span></span></p>
            <p class="x_x_x_MsoNormal">Web sitesi/Uygulama Kullanım Verileri</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal" ><span>Üye olduğu tarih, yaptığı başvurular / başvuru seviyesi, siteye login olma sıklığı/zamanları, ios/android/mobil site/ web site kullanıcısı, güncelleme tarihi, çalışmayı tercih ettiği pozisyonlar, iş habercisi / kayıtlı kriterleri, engellenen firmalar, çalışma durumu (evet/hayır), Vatandaş sektör adı, son login tarihi, son mesaj tarihi, ilk üyelik tarihi, uygulama silindi (evet/hayır), son mesaj seviyesi, son mesaj pozisyonu, son mesaj sektörü, son mesaj attığı firma, son iş seviyesi, mesajlaşma seçeneğinin mevcut olması halinde İşveren ile vatandaş arasındaki yazışmalar, uygulamaların kullanılışı bilgisi (uygulama içerisinde yapılan her hareket ve seçim),</span><span></span></p>
            <p class="x_x_x_MsoNormal">Diğer</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Sürücü belgesi verileri, yetkinlikleri, hobisi, maaş beklentisi, askerlik durumu, referans bilgileri (referans kişisinin adı soyadı, unvanı, işyeri, telefonu, e-postası) ve varsa CV'sine eklediği her türlü word, excel, sunum dosyaları, Profil ID, Login ID, IP adresi, log kayıtları, İşveren ’in vatandaşlardan talep edebileceği ek bilgiler</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span>KİŞİSEL VERİLERİNİZİ NE ŞEKİLDE VE HANGİ SEBEPLERLE TOPLUYORUZ?</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Bölgesel İstihdam Ofisleri olarak hukuki yükümlülüklerimizi yerine getirmek, aramızdaki istihdam hizmeti sözleşmesinin, Bölgesel İstihdam Ofisleri’ne başvuruyorsanız aramızda kurulabilecek iş akdinin ifası ve Bölgesel İstihdam Ofisleri’nin meşru menfaati gereği sizden talep ettiğimiz kişisel verilerinizi, sizin bize fiziksel veya elektronik ortamda iletmeniz, platformlarımıza bilgi, ses ve görüntü girişi yapmanız ve internet ortamına 3.kişilerin ulaşımına açık bilgi kaydetmeniz suretiyle topluyoruz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Bölgesel İstihdam Ofisleri’nin platformunu geliştirmeye ve daha efektif hale getirmeye yönelik meşru menfaati gereği web portalımızda kullanılan çerezler vasıtasıyla kişisel verilerinizi elektronik olarak topluyoruz.</span><span></span></p>
            <p class="x_x_x_MsoNormal">KİŞİSEL VERİLERİNİZİ HANGİ AMAÇLARLA İŞLİYORUZ?</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Yukarıda belirttiğimiz kişisel verileriniz, size verdiğimiz istihdam hizmetine ilişkin bu Hizmet Sözleşmesi’nin ifası için aşağıdaki amaçlarla işlenmektedir:</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* İstihdam yaratmak ve işe alım sürecinize destek vermek</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Size iş bulmak, işveren müşterilerimize vatandaşa ulaşabilmeleri amacıyla paylaştığınız kişisel verilerinizden yararlanarak işveren ilanları ile eşleştirme yapmak</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Özgeçmişinizi, başvuru yaptığınız takdirde talep eden İşverene aktarmak</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Sizden gelen çağrıları karşılamak ve destek ihtiyaçlarınıza cevap vermek</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Verdiğimiz hizmetlere ilişkin bir şikâyet olduğu takdirde söz konusu şikâyeti sonuçlandırmak</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Talebiniz doğrultusunda veya sosyal medyada yer alan şikâyetlerin olması halinde Bölgesel İstihdam Ofisleri Portalı’ndaki hesabınızı incelemek</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Raporlama yapmak</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span>Size verdiğimiz hizmeti en iyi şekilde yerine getirebilmek için sürekli gelişmeye ve ilerlemeye gayret ediyoruz. Bunun için, kişisel verilerinizi aşağıdaki şekilde işleyebilmekteyiz:</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <div><br /></div>
            <p class="x_x_x_MsoNormal"><span>* Piyasa yorumlama, yönetimsel analizler, site kullanımını yorumlamak</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Size verdiğimiz hizmetin kalitesini arttırmak ve kalite kontrolünü sağlamak.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Başvuru yapmanıza yardımcı olmak adına toplu e-posta ve SMS göndermek.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Güncel ve Ar-Ge aşamasındaki uygulamalarımızın geliştirmek.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Bölgesel İstihdam Ofisleri içerisinde kullanılan yazılımları iç ve dış kaynak kullanarak geliştirmek.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Yalnız gerekli olduğunda, yurt içindeki ve yurt dışındaki işverene destek vermek.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span>KİŞİSEL VERİLERİNİZİ NERELERE AKTARIYORUZ?</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Size verdiğimiz hizmeti en iyi şekilde yerine getirebilmek için sürekli gelişmeye ve ilerlemeye gayret ediyoruz. Bunun için kişisel verilerinizi aşağıdaki şekilde yurt içindeki ve yurt dışındaki üçüncü kişilere aktarabilmekteyiz:</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Yukarıda belirttiğimiz kişisel verileriniz, size verdiğimiz istihdam hizmetine ilişkin bu Hizmet Sözleşmesi’nin ifası için aşağıdaki amaçlarla işlenmektedir:</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Size istihdam hizmetimizi sağlayabilmek adına özgeçmişinizde yer alan özel nitelikli kişisel verileriniz de dâhil olmak üzere özgeçmişinizde ekleri ile beraber yer alan kişisel verilerinizi kimlere görünmesini istediğiniz konusunda yaptığınız tercih doğrultusunda başvurduğunuz yurt içi ve/veya yurt dışı merkezli işveren(ler)e, üyemiz işverenlere ve firmaları altında açtıkları kullanıcı hesaplarına aktarabiliyoruz veya kişisel verileriniz, Platformumuzu ziyaret edenler tarafından görüntülenebiliyor. Ayrıca paylaşma seçeneğinize göre özgeçmişinize erişen kişiler özgeçmişinizi indirebilmektedir. Platformda yayınlanan herhangi bir ilana başvurduktan sonra aktarılan normal ve/veya özel nitelikli kişisel verileri ile ilgili her türlü sorumluluk ilan sahibi işverene geçmektedir ve Bölgesel İstihdam Ofisleri’nin ilana başvuru ile aktarılan bilgilerle ilgili herhangi bir yükümlülüğü ve/veya müdahale hakkı yoktur. Aynı şekilde herhangi bir tarihte başvurduğunuz ilan ile ilan sahibi işverene aktarılan normal ve/veya özel nitelikli kişisel verilerinin silinmesini, yok edilmesini ve/veya anonim hale getirilmesini isterseniz bu talebinizi doğrudan ilan sahibi firmaya iletmeniz gerekir. Bölgesel İstihdam Ofisleri’nin bu hususlarda sorumluluğu veya yükümlülüğü bulunmamaktadır.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Ayrıca savunma hakkımızı kullanabilmek amacıyla özel nitelikli kişisel verileriniz de dahil olmak üzere bütün kişisel verilerinizi avukatlarımıza veya hukuki danışmanlarımıza aktarabiliriz ve bize kişisel verilerinizin aktarılmasına ilişkin usulüne uygun yasal bir talep geldiği takdirde özel nitelikli kişisel verileriniz de dahil olmak üzere bütün kişisel verilerinizi aktarabiliriz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span>HAKLARINIZ</p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>KVKK’nın 11. maddesi gereği bize şahsen, kimliğinizi ispat etmeniz kaydıyla, kişisel verileriniz ile ilgili;</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Bölgesel İstihdam Ofisleri’nin hakkınızda kişisel veri işleyip işlemediğini öğrenmek, eğer işlemişse, buna ilişkin bilgi talep etmek,</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Kişisel verilerinizin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığı öğrenmek,</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>* Kişisel verilerin yurtiçi veya yurtdışına aktarılıp aktarılmadığı ve kimlere aktarıldığını öğrenmek haklarına sahipsiniz.</span><span></span></p>
            <p class="x_x_x_MsoNormal">Ayrıca, Bölgesel İstihdam Ofisleri’nden yanlış ve eksik kişisel verilerinizin düzeltilmesini ve verilerinin aktarıldığı veya aktarılmış olabileceği alıcıların bilgilendirilmesini talep etme hakkınız vardır.<span><br />
            </span></p>
            <p class="x_x_x_MsoNormal"><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Kişisel verilerinizin KVKK madde 7’de öngörülen şartlar çerçevesinde verilerinizin imha edilmesini (silinmesini, yok edilmesini veya anonim hale getirilmesini) Bölgesel İstihdam Ofisleri’nden talep edebilirsiniz. Aynı zamanda verilerin aktarıldığı veya aktarılabileceği 3. kişilerin söz konusu imha talebiniz ile ilgili bilgilendirilmesini talep edebilirsiniz. Ancak imha talebinizi değerlendirerek hangi yöntemin uygun olduğu somut olayın koşullarına göre tarafımızca değerlendirilecektir. Bu bağlamda seçtiğimiz imha yöntemini neden seçtiğimiz ile ilgili bizden her zaman bilgi talep edebilirsiniz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Münhasıran bir otomatik sistem kullanılarak oluşturulmuş kişisel veri analizinizin sonuçlarına bu sonuçlar çıkarlarınıza aykırıysa itiraz edebilirsiniz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle zarara uğramanız halinde zararın giderilmesini talep edebilirsiniz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Başvurunuzda yer alan talepleriniz, talebin niteliğine göre en geç otuz gün içinde ücretsiz olarak sonuçlandırılacaktır. Ancak, işlemin Bölgesel İstihdam Ofisleri için ayrıca bir maliyeti gerektirmesi hâlinde, Kişisel Verileri Koruma Kurulu tarafından Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ’de belirlenen tarifedeki ücret alınabilir.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Kişisel verilerinizin işlenmesi ile ilgili hususlarda başvurunuzu Bölgesel İstihdam Ofisleri Portalı’nın internet adresinde bulunan başvuru formunu doldurarak, yazılı olarak veya kayıtlı elektronik posta (KEP) adresi, güvenli elektronik imza, mobil imza ya da bize daha önce bildirdiğiniz ve kayıtlarımızda yer alan elektronik posta adresinizi kullanmak suretiyle Bölgesel İstihdam Ofisleri’ne teslim etmeniz gerekmektedir. Firma tarafından talebinizin mahiyetine ve başvuru yönteminize göre Bölgesel İstihdam Ofisleri tarafından başvurunun size ait olup olmadığının belirlenmesi ve böylece haklarınızı koruyabilmek amacıyla ek doğrulamalar (kayıtlı telefonunuza mesaj gönderilmesi, aranmanız gibi) istenebilir. Örneğin Bölgesel İstihdam Ofisleri’nde kayıtlı olan e-posta adresiniz aracılığıyla başvuru yapmanız halinde Bölgesel İstihdam Ofisleri’nde kayıtlı başka bir iletişim yöntemini kullanarak size ulaşabilir ve başvurunun size ait olup olmadığının teyidini isteyebiliriz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>Özel nitelikli kişisel verilerinizin işlenmesi ve kişisel verilerinizin yurt dışına aktarımı için lütfen 'Üyelik Sözleşmesi’ni okuyunuz.</span><span></span></p>
            <p class="x_x_x_MsoNormal"><span>&nbsp;</span></p>
          </div>
        </article>
      </div>
    </div>
  );
}