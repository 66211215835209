import { useEffect } from "react";
import { Icon } from "../../../../../../components/UIComponents";
import { Button, Form, Select, message, Modal } from "antd";
import useGet from "../../../../../../hooks/useGet";
import axios from "axios";
import config from "../../../../../../plugins/ibb/config";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyWorkForceData } from "redux/actions/companyWorkForceActions";
import { changeUrl } from "helpers/methods";

const { confirm } = Modal;

const WorkForceInput = ({ inputs, name, defaultValueTr, defaultValue34 }) => {
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCompanyWorkForceData());
  }, [dispatch]);

  const dataCompany = useSelector((state) => state.data.companyWorkForce);

  const showConfirm = () => {
    return new Promise((resolve, reject) => {
      confirm({
        title: "Bu işlemi gerçekleştirmek istediğinizden emin misiniz?",
        onOk() {
          resolve();
        },
        onCancel() {
          reject();
        },
      });
    });
  };

  const handleSubmit = async (data) => {
    const oldData = {
      companyWorkforce34Id: defaultValue34,
      companyWorkforceTrId: defaultValueTr,
    };

    const newData = {
      ...oldData,
      ...data,
    };

    try {
      await showConfirm();
      const res = await axios.put(
        changeUrl(`companies/${inputs?.id?.id}`),
        newData
      );
      message.success(`Çalışan sayısı başarıyla değiştirildi.`);
      history.go(0);
    } catch (e) {
      message.error(e);
    }
  };

  useEffect(() => {
    form.setFieldsValue({
      companyWorkforce34Id:
        defaultValue34 || "8egAPwz8vbc8Z6LRbErNtx9RQzOMbji9nKRRg94",
      companyWorkforceTrId:
        defaultValueTr || "8egAPwz8vbc8Z6LRbErNtx9RQzOMbji9nKRRg94",
    });
  }, [form, name, defaultValueTr, defaultValue34]);

  return (
    <>
      <Form
        form={form}
        initialValues={{
          companyWorkforce34Id: "8egAPwz8vbc8Z6LRbErNtx9RQzOMbji9nKRRg94",
          companyWorkforceTrId: "8egAPwz8vbc8Z6LRbErNtx9RQzOMbji9nKRRg94",
        }}
        style={{ display: "flex", justifyContent: "space-between" }}
        onFinish={handleSubmit}
      >
        <Form.Item
          name={`${name}Id`}
          style={{ width: "50%", maxWidth: 140 }}
          rules={[{ required: true, message: "Please select an option" }]}
        >
          <Select
            suffixIcon={
              dataCompany && dataCompany.loading ? undefined : (
                <Icon name="arrow-down" size={18} />
              )
            }
            className="auth-form-input"
            minLength={2}
            options={dataCompany?.data?.results?.map((amount) => ({
              value: amount?.id?.id,
              label: amount?.workforce,
            }))}
          ></Select>
        </Form.Item>
        <Button type="primary" className="but" htmlType="submit">
          Güncelle
        </Button>
      </Form>
    </>
  );
};

export default WorkForceInput;
