import { Spin } from "antd";
import Banner from "components/UIComponents/Banner"
import useGet from "hooks/useGet";
import { get } from "lodash";
import config from 'plugins/ibb/config'
import {Helmet} from "react-helmet";
 import logo from "assets/static/img/logo.png";

export default () => {

  const { data, loading } = useGet({ url: '/public/about-us', key: 'aboutUs', isArray: false });

  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | Hakkımızda</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Hakkımızda" className="mb-0" />
      <Spin spinning={loading}>
        <div className="PublicDetailContent content md content-detail">
          {data &&
            <img src={logo} className="mb-20" alt="Bölgesel İstihdam Ofisleri Hakkında" />
          }
          <article className="Article" dangerouslySetInnerHTML={{ __html: get(data, 'content', 'Burada gösterilecek hiçbir şey yok') }} />
        </div>
      </Spin>
    </div>
  );
}
