import { useState } from 'react';
import {Row, Col, Button, Input, DatePicker, Select, Divider, Form, InputNumber} from 'antd';
import { LookupSelect } from 'components/UIComponents/FormElements';
import { Icon } from 'components/UIComponents';

export default ({ data, index, remove }) => {
  const [id, setId] = useState()

  return (
    <Row>
      <Col span={24}>
        <Row className="full-width">
          <Col span={24} className="d-flex a-center mb-20 full-width">
            <Form.Item name={'examId'} rules={[{required:true}]} className="full-width">
              <LookupSelect url="/exams" valueKey="id" labelKey="description" placeholder="Sınav" onChange={setId}>
                <Select.Option value="other">Diğer</Select.Option>
              </LookupSelect>
            </Form.Item>
            <Button className="BtnDeleteItem btn btn-blue-filled full-width text-md mb-10" onClick={() => remove(index)}>Sil</Button>
          </Col>
          {id === 'other' &&
            <Col span={24}>
              <Form.Item name={'otherExam'} rules={[{required:true}]}>
                <Input placeholder="Sınav ismi giriniz" className="form-input mb-20" />
              </Form.Item>
            </Col>
          }
          <Col span={24}>
            <Form.Item name={'instituteTitle'} rules={[{required:true}]}>
              <Input placeholder="Kurum" className="form-input mb-20"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'date'} rules={[{required:true}]}>
              <DatePicker className="form-input full-width mb-20" placeholder="Tarih" suffixIcon={<Icon name="plus" size={23} />}  format="DD/MM/YYYY"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'score'} rules={[{required:true, min:0, type:'number'}]}>
              <InputNumber type={"number"} className="form-input full-width mb-20" placeholder="Puan" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name={'description'} rules={[{required:true}]}>
              <Input.TextArea className="form-input mb-20" placeholder="Açıklama" rows={5} />
            </Form.Item>
          </Col>
        </Row>
      </Col>
      <Divider className="Divider grey" />
    </Row>
  );
};
