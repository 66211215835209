import { useParams, withRouter } from 'react-router-dom';
import { Row, Col, Divider, Button, Checkbox, Dropdown, Menu,Space } from 'antd';
import { AlignCenterOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import InterviewResult from './Actions/InterviewResult';
import CoverLetter from './Actions/CoverLetter';
import NotSuitable from './Actions/NotSuitable';
import Invite from './Actions/Invite';
import { get } from 'lodash';
import '../../../../../../../../styles/layouts/authLayout.less';

const ListItem = ({ candidate, history, onCheckChange, disabled, refresh }) => {

  const [jobs,setJobs] = useState([]);
  const [preferred,setPreferred] = useState([]);
  const [inviteModal, setInviteModal] = useState(false);
  const [interviewModal, setInterviewModal] = useState(false);
  const [coverLetterModal, setCoverLetterModal] = useState(false);
  const [ineligibleModal, setIneligibleModal] = useState(false);
  const [jobId] = useState(useParams().id);
  const [applicationId, setApplicationId] = useState(get(candidate, 'applicationId.applicationId', null));


  useEffect(() => {
    candidate && setApplicationId(get(candidate, 'applicationId.applicationId', null));
  }, [candidate]);


  useEffect( () => {
    if(typeof get(candidate, 'jobExperience', '-') === 'string'){
      setJobs(get(candidate, 'jobExperience', '-').split(','))
    }

    if(typeof get(candidate, 'preferredPositions', '-') === 'string'){
      setPreferred(get(candidate, 'preferredPositions', '-').split(','))
      get(candidate, 'preferredPositions', '-')
    }
  },[candidate])

  let ilanNo=window.location.pathname.split('jobs/')[1].split('/')[0]
  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <Button disabled={!applicationId || disabled} style={{padding:'0 1rem'}} className={`btn-account-operation blue px-5 ${!applicationId ? 'disabled' : ''}`} onClick={() => setInviteModal(true)}>Mülakata Davet Et</Button>
          ),
        },
        {
          key: '2',
          label: (
        <Button disabled={disabled} className="btn-account-operation blue px-5" 
        onClick={() => history.push(`/account/employer/jobs/${jobId}/candidates/${get(candidate, 'candidateId.candidateId')}?source=JOB&advertisementNo=${ilanNo}&applicationStatus=${get(candidate,'applicationStatus.id')}&applicationId=${get(candidate,'applicationId.applicationId')} `)}>Özgeçmişi Görüntüle</Button>
        ),
          disabled: true,
        },
        {
          key: '3',
          label: (
        <Button disabled={disabled} className="btn-account-operation blue px-5" onClick={() => setCoverLetterModal(true)}>Önyazı Görüntüle</Button>
        ),
          disabled: true,
        },
        {
          key: '4',
          label: (
            <Button disabled={!applicationId || disabled} className={`btn-account-operation red mb-0 px-5 ${!applicationId ? 'disabled' : ''}`} onClick={() => setIneligibleModal(true)}>Uygun Değil</Button>
          ),
        },
      ]}
    />
  )

  return (
    <Row className="CandidateListItem full-width" style={{marginBottom: 20,border:'1px solid rgb(233 91 41 / 25%)',padding:25, borderRadius:5}}>
      <Row className="full-width" style={{display:'flex', justifyContent:'center'}}>
          <Row className="xs-flex-column" style={{ width:'100%',display:'flex',justifyContent:'space-between'}}>
            <div>
              <Checkbox className="form-check blue text-white mb-5 suitable-check" onChange={e => onCheckChange(e.target.checked, applicationId)} />
            </div>
            <p className="text-xl fw-medium mb-5" style={{margin:0}}>{get(candidate, 'firstName')} {get(candidate, 'lastName')}</p>
           <div>
           <span
              className={`fw-bold text-md mr-20 d-iflex text-uppercase
            ${get(candidate, 'applicationStatus.id') === 'APPLIED' ? 'text-success' : ''}
            ${get(candidate, 'applicationStatus.id') === 'SUITABLE' ? 'text-red-pink' : ''}
            ${get(candidate, 'applicationStatus.id') === 'SUGGESTED' ? 'text-blue' : ''}
            ${get(candidate, 'applicationStatus.id') === '' ? '' : ''}
            `}>
            {get(candidate, 'applicationStatus.description', '-')}
          </span>
          <span
              className={`fw-bold text-sm mr-20 d-iflex text-uppercase`}>

            {get(candidate, 'retirementStatus.description', '-')=== "Belirtilmemiş"? "":  <div style={{backgroundColor:'#E95B29',color:'white', marginRight:12,padding:7, borderRadius:12}}>{get(candidate, 'retirementStatus.description', '-')}</div>}
          </span>
            </div> 
          </Row>
        <Divider style={{width:'80%',color:'gray', margin: 24}}/>
      </Row>
      <Row className="full-width" style={{display:'flex',justifyContent:'space-between'}}>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 18 }}>
          <Row className="full-width" style={{display:'flex'}}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }} style={{marginBottom:12}}>
              <p className="fw-medium text-lg ls-half" style={{marginBottom:12}}>İLGİLENDİĞİ POZİSYONLAR</p>
              <div style={{display:'flex',gap:'5px' ,flexWrap:'wrap'}}>{ preferred && preferred.map(
                ( x, index) => <span key={index} style={{backgroundColor:'#E95B29',color:'white', marginBottom:3,padding:7, borderRadius:12}}>{x}</span>)}
              </div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
              <p className="fw-medium text-lg ls-half" style={{marginBottom:12}}>ÇALIŞTIĞI POZİSYONLAR</p>
              <div style={{display:'flex',gap:'5px' ,flexWrap:'wrap'}}>{ jobs && jobs.map( ( x, index) => <span key={index} style={{backgroundColor:'#E95B29',color:'white', marginRight:12,padding:7, borderRadius:12}}>{x}</span>)}</div>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }}>
              <p className="text-lg ls-half fw-medium mb-5">İLÇE</p>
              <p className="text-xl ls-half mb-5">{get(candidate, 'county')}</p>
            </Col>
          </Row>
        </Col>
        <Col style={{display:'flex' , flexDirection:'column'}}>
          <Dropdown overlay={menu}>
            <a onClick={(e) => e.preventDefault()}>
              <Space>
                <p style={{fontSize:18}}><AlignCenterOutlined style={{fontSize:14}} /> İşlemler </p>
              </Space>
            </a>
          </Dropdown>
        </Col>
      </Row>
      <Invite data={candidate} candidate={candidate} visible={inviteModal} setVisible={setInviteModal} />
      <InterviewResult data={candidate} visible={interviewModal} setVisible={setInterviewModal} />
      <CoverLetter data={candidate} visible={coverLetterModal} setVisible={setCoverLetterModal} />
      <NotSuitable applicationId={applicationId} data={candidate} visible={ineligibleModal} setVisible={setIneligibleModal} refresh={refresh}/>
    </Row>
  );
};

export default withRouter(ListItem);

{/*
 never used components :

 const SettingsMenu = ({ jobId, resumeId }) => {
  const history = useHistory();
  return (
    <Menu>
      <Menu.Item onClick={() => history.push(`/printcv?candidateId=${resumeId}&jobId=${jobId.applicationId}&source=APP`)}>
        PDF İndir
      </Menu.Item>
    </Menu>
  );
};

 */}
