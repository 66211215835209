import React, {useEffect, useState} from "react";
import {Button, Checkbox, Col, message, Row, Tooltip, Modal} from 'antd';
import Api from "../../../api/Api";
import {getConsents} from "../../../helpers/methods";
import SmsPolicy from '../../../assets/policy/EK-F Açık Rıza Metni_Ticari Elektronik İleti_SMS.pdf'
import EmailPolicy from '../../../assets/policy/EK-E Açık Rıza Metni_Ticari Elektronik İleti_e-posta.pdf'
import AcikRiza from '../../../assets/policy/EK-D İSPER_BİO_Özgeçmiş Paylaşımı Açık Rıza Metni (1).pdf'
import Aydinlatma from '../../../assets/policy/EK-C Platform Kullanım Sözleşmesi.pdf'

export default ({close}) => {
  const [kvkkExplicitChecked, setKvkkExplicitChecked] = useState( false );
  const [kvkkClarificationChecked, setKvkkClarificationChecked] = useState( false );
  const [emailChecked, setEmailChecked] = useState(false );
  const [smsChecked, setSmsChecked] = useState(false );

  const handleClarificationScroll = ({ target }) => target.scrollHeight - target.scrollTop - target.clientHeight <= 50 ;

  useEffect(() => {
    const consents = getConsents()
    setKvkkExplicitChecked( consents.kvkkExplicit )
    setKvkkClarificationChecked( consents.kvkkClarification )
    setSmsChecked( consents.sms )
    setEmailChecked( consents.email )
  }, [])

  const handleReject = async () => {
    localStorage.clear();
    try {
      //await OAuth.logout();
      window.location.replace("/");
    } catch (e) {
      message.error('Çıkış işleminde bir hata oluştu');
    }
  }

  const handleAccept = async () => {
    
    if (!kvkkClarificationChecked || !kvkkExplicitChecked ) {
      message.warning('Devam edebilmek için koşulları okuyup kabul etmelisiniz.');
      return
    }

    const payload = {
      kvkkExplicit: kvkkExplicitChecked,
      kvkkClarification: kvkkClarificationChecked,
      kvkkPlatformUsageAgreement:kvkkClarificationChecked,
      sms: smsChecked,
      email: emailChecked,
    }
    const result = await new Api().postData({url: 'auth/consent', _data: payload})
    if (result.code === 200) {
      localStorage.setItem('accessToken', result?.result?.accessToken)
      close()
      return
    }

    alert(result.message)
  }

  return <div className={'modal'}>
    <Row>
      <Col span={24} className={'mt-10 text-center'}>
        <h1>Platform Kullanım Sözleşmesi</h1>
        <Row className={'modal-content'} onScroll={handleClarificationScroll}>
          <Col span={24}>
            <a className="clarification-button text-underline fw-bold d-iblock" style={{border: '1px solid #D3D3D3', padding: '1rem', textDecoration:'none'}} target='_blank' href={Aydinlatma}>Platform Kullanım Sözleşmesini İncelemek İçin Tıklayınız.</a>
          </Col>
        </Row>
        <Row>
          <Col span={24} className={'mt-10'}>
            <Tooltip placement="bottom" color={'orange'}
              //  title={kvkkClarificationCheckClickable ? null : 'Onaylayabilmek için sözleşmeyi okumalısınız.'}
            >
              <Checkbox className="form-check" checked={kvkkClarificationChecked}
                //   disabled={!kvkkClarificationCheckClickable} 
                 onChange={e => setKvkkClarificationChecked(e.target.checked)}> Platform Kullanım Sözleşmesinde geçen koşulları kabul ediyorum.</Checkbox>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span={24} className={'mt-20 text-center'} >
        <h1>Açık Rıza Metni</h1>
        <Row>
          <Col span={24} className={'mt-10'}>
            <Tooltip placement="bottom"  color={'orange'} >
              <Checkbox className="form-check"  checked={kvkkExplicitChecked}  onChange={e => setKvkkExplicitChecked(e.target.checked)}>
                  <span className="clarification-button text-underline fw-bold d-iblock"  style={{ fontSize: '14px', color: '#E95B29',border: '1px solid #D3D3D3', padding: '0.5rem', textDecoration:'none'}}>
                    <a target="_blank" style={{textDecoration: 'underline'}} href={AcikRiza}>Açık rıza metni </a>nde belirtilen amaçlar dahilinde özgeçmiş bilgilerimin iş başvurusu yapmadığım işverenlere aktarılmasına onay veriyorum. 
                  </span> 
                </Checkbox>
            </Tooltip>
          </Col>
        </Row>
      </Col>
    </Row>
    <Row>
      <Col span={24} className={'mt-20 text-center'}>
        <Checkbox className="form-check" checked={emailChecked} onChange={e => setEmailChecked(e.target.checked)}><a  target="_blank" href={EmailPolicy}>Açık Rıza Metni</a> uyarınca e-posta yoluyla ticari elektronik ileti almak istiyorum.</Checkbox>
      </Col>
    </Row>
    <Row>
      <Col span={24} className={'mt-20 text-center'}>
        <Checkbox className="form-check" checked={smsChecked} onChange={e => setSmsChecked(e.target.checked)}>  <a target="_blank" href={SmsPolicy}>Açık Rıza Metni</a> uyarınca SMS yoluyla ticari elektronik ileti almak istiyorum.</Checkbox>
      </Col>
    </Row>
    <Row justify="center">
      <Col className={'mt-40'}>
        <Button type="primary" onClick={handleAccept}>Tamam</Button>
      </Col>
      <Col className={'mt-40 ml-10'}>
        <Button type="danger" onClick={handleReject}>İptal</Button>
      </Col>
    </Row>
  </div>
}
