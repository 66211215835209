import { Spin } from 'antd';

const Callback = () => {
  return (
    <div className="d-flex a-center j-center" style={{ height: '80vh' }}>
      <Spin spinning={true} size="large" />
    </div>
  );
};

export default Callback;
