import {Button, Col, Form, Input, Row, Select} from "antd";
import {LookupSelect} from "components/UIComponents/FormElements"
import useGet from "hooks/useGet";
import { useEffect, useState } from "react";

export default ({remove,disabilities,item}) => {

  const { data } = useGet({ url: "/disabilities", key: "disabilities", isArray: false });
  const [filteredDisability, setFilteredDisability] = useState([]);
  const [selectedDisability, setSelectedDisability] = useState("");

  useEffect(() => {
    if (item && Object.entries(item).length === 1) {
      setFilteredDisability(data?.results.filter((nameObj) => !disabilities.some((disability) => disability.disabilityType === nameObj.id && nameObj.id !== selectedDisability)));
    } else {
      setFilteredDisability(data?.results);
    }
  }, [item, data, selectedDisability,disabilities]);

  return <>
    <Row className="Disability mb-5" align="middle">
      <Col span={20}>
        <Form.Item name="disabilityPercentage" className="mb-0" rules={[
          {
            required: true,
            message: 'Engellilik yüzdesini belirtini.',
          },
        ]}>
          <LookupSelect url="/disabilities/types" placeholder="Yüzdesi (Zorunlu)" className="mt-10"/>
        </Form.Item>
      </Col>
      <Col span={4}>
        <Button className="btn btn-blue-filled full-width text-md ml-10 mt-10"  onClick={remove}>Sil</Button>
      </Col>
    </Row>
    <Row className="Disability mb-5" align="middle">
      <Col span={20}>
        <Form.Item name="disabilityType" className="mb-0" rules={[
          {
            required: true,
            message: 'Engellilik tipini belirtiniz.',
          },
        ]}>
          {/* <LookupSelect url="/disabilities" placeholder="Engel Tipi (Zorunlu)" labelKey='disability'
                        valueKey='id' className="mt-10 width-400"/> */}
                        <Select
            className="form-select full-width"
            onChange={(value) => setSelectedDisability(value)}
            placeholder="Engel Tipi (Zorunlu)"
            
          >
            {filteredDisability?.map((item, idx) => (
              <Select.Option value={item.id} key={idx}>
                {item.disability}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Col>
    </Row>
    <Row className="Disability mb-5" align="middle">
      <Col span={20}>
        <Form.Item name="disabilityDescription" className="mb-0">
          <Input.TextArea className="form-input mt-10" placeholder="Açıklaması" rows={3}
                          maxLength={4000}/>
        </Form.Item>
      </Col>
    </Row>
  </>
};