import { useEffect } from "react";
import {
  Form,
  Input,
  Col,
  Row,
  Checkbox,
  DatePicker,
  message,
  Alert,
  Select,
} from "antd";
import MaskedInput from "react-maskedinput";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import ModalAuth from "./Modals/ModalAuth";
import { useState } from "react";
import { postNewLogin, updateState } from "../../redux/services/request";
import { useHistory } from "react-router-dom";
import axios from "axios";
import useGet from "../../hooks/useGet";
import { Icon } from "../../components/UIComponents";
import Policy from "../../assets/policy/BİO_KVKİşverenAydınlatmaMetni v.18.5.23.pdf";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyWorkForceData } from "redux/actions/companyWorkForceActions";

export default function RegisterCorporate(props) {
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const [form] = Form.useForm();
  const passedPassword = props?.location?.state?.password;
  const passedTaxNoOrIdNo = props?.location?.state?.taxNoOrIdNo;
  const alert = props?.location?.state?.notification;
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadCompanyWorkForceData());
  }, []);
  const dataCompany = useSelector((state) => state.data.companyWorkForce);

  const { data: unvanData } = useGet({
    url: "/public/responsible-title",
    key: "title",
  });

  const handleSubmit = async (data) => {
    setLoading(true);

    const newData = {
      ...data,
      birthDate: data.birthDate?.format("YYYY-MM-DD"),
      phone: "0" + data.phone?.replace(/[()\s]/g, ""),
      userType: "CORPORATE",
      clarificationConfirmation: checked,
    };

    try {
      updateState({ registerInit: { data: null, loading: true } });
      const initResponse = await postNewLogin({
        url: "/auth/register/init",
        data: newData,
      });

      if (initResponse?.code !== 200) {
        message.error(initResponse?.message);
        setLoading(false);
        return;
      }

      const finishData = {
        idNo: initResponse?.result?.idNo,
        stateId: null,
        code: null,
      };

      if (initResponse.result.skipSmsVerification) {
        updateState({ loginInit: { data: null, loading: true } });
        const finishResponse = await postNewLogin({
          url: "/auth/register/finish",
          data: finishData,
        });

        if (finishResponse?.code !== 200) {
          message.error(finishResponse?.message);
          setLoading(false);
          return;
        }

        // local pushes
        localStorage.setItem("accessToken", finishResponse.result.accessToken);
        localStorage.setItem("type", finishResponse.result.userType);
        localStorage.setItem("fullName", finishResponse.result.fullName);
        localStorage.setItem(
          "companyName",
          finishResponse?.result?.selectedCompany?.name
        );

        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${localStorage.getItem("accessToken")}`;
        if (localStorage.getItem("type")) {
          axios.defaults.headers.common["userType"] =
            localStorage.getItem("type");
        }

        message.success(
          `İşleminiz Gerçekleşti Teşekkür Ederiz ${finishResponse?.result?.fullName}.`
        );
        setLoading(false);
        updateState({ loginInit: { data: finishResponse, loading: false } });

        message.success(
          "T.C. Kimlik Numaranız İle Kayıt İşleminiz Başarılı Bir Şekilde Gerçekleşti."
        );
        history.push("/account/employer/jobs/list");
        return;
      }

      message.success(
        "Telefon doğrulamadan sonra işleminiz tamamlanacaktır ilgili sayfaya yönlendiriliyorsunuz"
      );
      setLoading(false);
      updateState({ registerInit: { data: initResponse, loading: false } });
      history.push("/verification");
    } catch {
      setLoading(false);
    }
  };

  const initialValues = {
    idNo: "",
    firstName: "",
    lastName: "",
    birthDate: "",
    email: "",
    phone: "",
    password: passedPassword ? passedPassword : "",
    taxNoOrIdNo: passedTaxNoOrIdNo ? passedTaxNoOrIdNo : "",
    companyAlias: "",
    userType: "CORPORATE",
    clarificationConfirmation: false,
    companyWorkforce34: "Seçiniz",
    companyWorkforceTr: "Seçiniz",
    responsibleTitle: "Seçiniz",
  };

  return (
    <>
      <AuthLayoutForm
        initialValues={initialValues}
        form={form}
        formTitle="İşveren Kaydı"
        button="KAYIT OL"
        onFinish={handleSubmit}
        disabled={disabled}
        loading={loading}
        buttonStyle={{ marginBottom: 40 }}
        seoDescription="Bölgesel İstihdam Ofisi İşveren Kayıt"
        seoTitle="Bölgesel İstihdam Ofisi Aday Giriş"
        seoKeywords="İşveren, İşveren Girişi, Üye, İşveren Üye Girişi"
      >
        {alert && (
          <Alert
            message="Kişisel bilgilerinizi giriniz"
            showIcon
            description="Tebrikler son adım olarak mevcut şirketiniz ile ilişkilendirmek için kişisel bilgilerinizi yazınız."
            type="success"
            style={{ width: "100%", display: "flex", flexWrap: "wrap" }}
          />
        )}
        <Row>
          <Col lg={12} md={24} xs={24} style={{ paddingRight: "25px" }}>
            <AuthLayoutInput
              name="idNo"
              label="T.C. Kimlik No"
              input={<Input className="auth-form-input" maxLength={11} />}
            />
            <AuthLayoutInput
              name="firstName"
              label="Ad"
              input={<Input className="auth-form-input" minLength={2} />}
            />
            <AuthLayoutInput
              name="lastName"
              label="Soyad"
              input={<Input className="auth-form-input" minLength={2} />}
            />
            <AuthLayoutInput
              name="companyWorkforce34"
              label="İstanbul'daki çalışan sayısı"
              input={
                <Select
                  required
                  defaultValue="seciniz"
                  suffixIcon={
                    dataCompany && dataCompany.loading ? undefined : (
                      <Icon name="arrow-down" size={18} />
                    )
                  }
                  className="auth-form-input"
                  minLength={2}
                >
                  <Select.Option value={"seciniz"}>Seçiniz</Select.Option>
                  {dataCompany?.data?.results?.map((amount, index) => (
                    <Select.Option value={amount.id.id} key={index}>
                      {amount.workforce}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
            <AuthLayoutInput
              name="companyWorkforceTr"
              label="Türkiye'deki çalışan sayısı"
              input={
                <Select
                  defaultValue="seciniz"
                  suffixIcon={
                    dataCompany && dataCompany.loading ? undefined : (
                      <Icon name="arrow-down" size={18} />
                    )
                  }
                  className="auth-form-input"
                  minLength={2}
                >
                  <Select.Option value={"seciniz"}>Seçiniz</Select.Option>
                  {dataCompany?.data?.results?.map((amount, index) => (
                    <Select.Option value={amount.id.id} key={index}>
                      {amount.workforce}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
            <AuthLayoutInput
              name="birthDate"
              label="Doğum Tarihi"
              hasFeedback
              input={
                <DatePicker className="auth-form-input" format="DD-MM-YYYY" />
              }
            />
          </Col>
          <Col lg={12} md={24} xs={24}>
            <AuthLayoutInput
              name="taxNoOrIdNo"
              label="V.K.N"
              input={
                <Input
                  maxLength={11}
                  placeholder={passedTaxNoOrIdNo ? passedTaxNoOrIdNo : ""}
                  disabled={!!passedTaxNoOrIdNo}
                />
              }
            />
            <AuthLayoutInput
              name="companyAlias"
              label="Ticari Ünvan"
              input={<Input maxLength={250} />}
            />
            <AuthLayoutInput
              name="email"
              label="E-posta"
              input={<Input className="auth-form-input" />}
            />
            <AuthLayoutInput
              name="phone"
              label="Cep Telefonu"
              input={
                <MaskedInput
                  className="masked-input"
                  mask="(111) 111 11 11"
                  name="phone"
                  size="10"
                  placeholder="Telefon"
                />
              }
            />
            <AuthLayoutInput
              name="password"
              label="Şifre"
              hasFeedback
              input={
                <Input.Password
                  className="auth-form-input"
                  placeholder={
                    passedPassword ? passedPassword : "Şifre giriniz"
                  }
                />
              }
            />
            <AuthLayoutInput
              name="responsibleTitle"
              label="Ünvan"
              input={
                <Select
                  defaultValue="seciniz"
                  suffixIcon={
                    dataCompany && dataCompany.loading ? undefined : (
                      <Icon name="arrow-down" size={18} />
                    )
                  }
                  className="auth-form-input"
                  minLength={2}
                >
                  <Select.Option>Seçiniz</Select.Option>
                  {unvanData?.map((item, index) => (
                    <Select.Option value={item?.hashId} key={index}>
                      {item?.title}
                    </Select.Option>
                  ))}
                </Select>
              }
            />
          </Col>
          <Col lg={24} md={24} xs={24}>
            <AuthLayoutInput
              name="clarificationConfirmation"
              input={
                <Checkbox
                  checked={checked}
                  name="clarificationConfirmation"
                  onChange={(e) => {
                    setChecked(e.target.checked);
                    setDisabled(checked);
                  }}
                >
                  <a target="_blank" href={Policy}>
                    Aydınlatma metnini
                  </a>{" "}
                  okudum onayladım.
                </Checkbox>
              }
            />
          </Col>
        </Row>
      </AuthLayoutForm>
    </>
  );
}
