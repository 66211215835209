import {Input, Modal, Form, Alert, Button} from "antd";
import React, {useRef} from "react";
import AuthLayoutInput from "../Components/AuthLayoutInput";
import "../../../styles/layouts/authLayout.less";
import {useHistory} from "react-router-dom";

const ModalRegisterCorporatePassing = ({ visible, setVisible }) => {
  const taxRef = useRef();
  const passRef = useRef();
  const history = useHistory();
  const [form] = Form.useForm();

  const handleSubmit = values => {
     

    history.push({
      pathname: '/register-corporate',
      state: {
        taxNoOrIdNo : taxRef.current.input.value,
        password: passRef.current.input.value,
        notification: true
      }
    })
    setVisible( false );
  }

  return (
    <Modal
      closable={false}
      centered
      open = { visible }
      onCancel={ () => setVisible(false) }
      okText="Kaydet ve İlerle"
      footer={false}
    >
      <Form
        form={form}
        onFinish={ handleSubmit }
        className="auth-form auth-modal-form">
        <Alert
          message="Bilgilerinizi giriniz"
          showIcon
          description="Yeni sistem mevcuttaki şirketiniz ile T.C kimlik numaranız ile oluşturulacak hesabınızı ilişkilendirerek size daha kolay bir kullanım sunmayı amaçlamaktadır."
          type="warning"
          style={{ width: '100%', display:'flex', flexWrap: 'wrap' }}
        />
        <AuthLayoutInput
          label="Vergi Kimlik Numarası"
          name="taxNoOrIdNo"
          input = {<Input className="auth-form-input" maxLength={ 11 } ref={taxRef} />}
        />
        <AuthLayoutInput
          label="Şifre"
          name="password"
          input={ <Input.Password className="auth-form-input" ref={passRef} /> }
        />
        <Button htmlType="submit" style={{backgroundColor:'orange', color: 'white'}}>Kaydet ve İlerle</Button>
      </Form>
    </Modal>
  )
}
export default ModalRegisterCorporatePassing;



