import { useEffect, useState } from 'react';
import { Icon } from 'components/UIComponents';
import Row from './Row';
import { Tooltip, Checkbox } from 'antd';

export default (props) => {
  const [hasDisability, setHasDisability] = useState(false);
  const [rows, setRows] = useState(new Map());

  const addRow = () => {
    let map = new Map(rows);
    map.set(null, null);
    setRows(map);
  }

  const onChange = (id, percentage, index) => {
    let map = new Map(rows);
    setRows(new Map(Array.from(map).map((x, i) => {
      return i === index ? [id, percentage] : x
    })));
  };

  const remove = (id) => {
    let map = new Map(rows);
    map.delete(id);
    setRows(map);
  };

  useEffect(() => {
    props.onChange && props.onChange(rows);
    // eslint-disable-next-line
  }, [rows]);

  return (
    <div className="ForeignLanguage">
      <h1 className="text-4xl fw-bold ls-full mb-20 mt-30">ENGEL TİPİ / YÜZDESİ
        <Tooltip placement="rightTop" title="*Yasal şartlar ile engelli çalışan istihdam etme gerekliliğine tabii olabilir veya açılan ilandaki pozisyona seçilecek olan yüzdeye kadar engelli bir birey için de uygun olabilir.">
          <span className="ml-10 text-orange-bio fw-regular text-xxl c-pointer" style={{ position: 'relative', top: -4 }}>(i)</span>
        </Tooltip>
      </h1>
      <Checkbox className="form-check blue mb-20" onChange={e => setHasDisability(e.target.checked)}>
        Evet
      </Checkbox>
      {
        hasDisability &&
        <div className="bg-softgrey2 p-10">
          {
            Array.from(rows).map((x, i) =>
              <Row key={i} onChangeDisability={onChange} index={i} remove={remove} />
            )
          }
          <div className="c-pointer" onClick={addRow}>
            <Icon name="add-outline" size={32} className="ml-95 pt-20" style={{ position: 'relative', left: -15 }} />
          </div>
        </div>
      }
    </div>
  );
}