import {Badge} from "antd";

const badges = {
    ACTIVE: <Badge status="success" text="Aktif"/>,
    PASSIVE: <Badge status="processing" text="Pasif"/>,
    DEFAULT: '-'
}

export default ({status}) => {
    if (status in badges) return badges[status]

    return badges.DEFAULT
}
