import {Layout, Typography, Button, Row, Col, message} from 'antd';
import PersonalInfo from './Personal';
import UserLayout from "../../userLayout";
import MutableInput from "./MutableInput";
import {  useEffect, useState } from "react";
import Edit from "./Edit";
import { EditFilled, CloudDownloadOutlined } from "@ant-design/icons";
import config from "../../../../../plugins/ibb/config";
import useGet from "../../../../../hooks/useGet";
import {  useDispatch, useSelector } from "react-redux";
import { loadUserData } from 'redux/actions/userActions';

const UserAccount = () => {  
  const dispatch = useDispatch();
  const [ visible, setVisible ] = useState( false );
  const { data } = useGet({ url: `/candidates/${localStorage.getItem('userId')}/resumes`, isArray: false });


  useEffect(() => {
    if (localStorage.getItem('type') === 'CITIZEN'){
      dispatch(loadUserData());
    }
  }, []); 

  const resume = useSelector(state => state.data.resumeData);
  const daata = useSelector(state => state.data);
  const resumeData= resume?.result

  const handleOk = () => setVisible( false );
  const handleCancel = () => setVisible( false );
  const showModal = () => setVisible( true );
  
  const mutableInput = [
    {
      verifyUrl:"/auth/change-email/verify",
      url:"/auth/change-email",
      inputName:"email",
      placeholder:"Emailinizi giriniz",
      label:"Yeni Email Adresi",
      value: resumeData?.email,
      modalTitle:"Email Adres Güncelleme"
    },
    {
      verifyUrl:"/auth/change-phone-number/verify",
      url:"/auth/change-phone-number",
      inputName:"phoneNumber",
      placeholder:"Telefon numaranızı giriniz",
      label:"Yeni Telefon Numarası",
      value: resumeData?.phoneNo,
      modalTitle:"Telefon Numarası Güncelleme"
    },
    {
      verifyUrl:"/auth/change-password/verify",
      url:"/auth/change-password",
      inputName:"oldPassword",
      placeholder:"Şifre giriniz",
      label:"Mevcut Şifrenizi Giriniz",
      value: '****************',
      modalTitle:"Şifre Güncelleme"
    }
  ]

  return (
    <>
      <Edit handleCancel={handleCancel} handleOk={handleOk} visible={visible}/>
      <UserLayout title='Profilim' bannerText="Profilim" wrapperClass="account-wrapper">
      <Layout.Header className="account-header">
        <Row style={{ display:'flex', height:'100%', alignItems:'center' }}>
            <Col xs={16} sm={16} md={16} lg={16}>
                <Typography.Title level={3}>Hesap Bilgileriniz</Typography.Title>
            </Col>
            <Col xs={8} sm={8} md={8} lg={8} style={{ display:'flex',gap:8}}>
                  <Button
                    onClick={ showModal }
                    className='button-profile'>
                    <EditFilled className='input-icon'/>
                    <span className="update-input">Kişisel Bilgileri Düzenle</span>
                  </Button>
              <Button
                onClick={ () => {
                  data ?
                  window.open(`${config.BIO_URL}/account/candidate/account/print-cv`, 'blank')
                  : message.warn('Lütfen önce özgeçmişinizi doldurunuz.')
                }}
                className='button-profile'>
                <CloudDownloadOutlined className='input-icon' />
                <span className="update-input">Özgeçmiş İndir</span>
              </Button>
            </Col>
        </Row>
      </Layout.Header>
        <Row className="content">
            <Col xs={24} sm={24} md={24} lg={24}>
               <PersonalInfo/>
            </Col>
            <Col xs={24} sm={24} md={24} lg={21} className='input-with'>
            { mutableInput.map( input => {
              return (
                <MutableInput
                required
                  verifyUrl={ input.verifyUrl }
                  url={ input.url }
                  inputName={ input.inputName }
                  placeholder={ input.placeholder }
                  label={ input.label }
                  value={ input.value }
                  modalTitle={ input.modalTitle } />
              )
            }
          )}
            </Col>
        </Row>
      </UserLayout>
    </>
  );
};

export default UserAccount;
