import React, { useEffect, useState } from "react";
import { Button, Col, Form, message, Row, Spin } from "antd";
import { Icon } from "components/UIComponents";

import EducationItem from "./EducationItem";
import { getData } from "../../../../../../../redux/services/request";
import Api from "../../../../../../../api/Api";
import { useSelector } from "react-redux";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [educationLevels, setEducationLevels] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS3 ? state.data.resumeS3.data : undefined
  );
  const [loading, setLoading] = useState(false);

  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S3", key: "resumeS3" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch((err) => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    setLoading(true);
    if (resume && resume.detail && resume.detail.educations) {
      const _educationLevels = [];
      const formValues = {};

      resume.detail.educations.forEach((education) => {
        const educationLevelId = Math.random();
        const values = {
          ...education,
          city: education.highSchoolCityId?.highSchoolCityId,
          instituteId: education.instituteId
            ? education.instituteId.instituteId
            : undefined,
          educationLevelId: educationLevelId,
        };
        delete values.highSchoolCityId;
        formValues[educationLevelId] = values;
        _educationLevels.push(values);
      });
      setEducationLevels(_educationLevels);
      form.setFieldsValue(formValues);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [resume, form]);

  const onFinish = () => {
    setLoading(true);
    const request = Object.values(form.getFieldsValue());

    if (educationLevels.length === 0) {
      refresh();
      setLoading(false);
      message.error("Eğitim bilgisi doldurulduktan sonra ilerleyebiirsiniz.");
      return;
    }

    request.forEach((req) => {
      if (req.status === "ONGOING") req.endDate = undefined;
    });

    new Api()
      .putData({
        url: "/candidates/resumes/educations",
        _data: { educations: request },
      })
      .then(() => {
        message.success("Eğitim bilgileri güncellendi");
        setLoading(false);
        refresh();
        getData({ url: "candidates/resumes/S3", key: "resumeS3" });
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
      })
      .catch(() => {
        message.error("Eğitim bilgileri güncellenirken bir hata meydana geldi");
        setLoading(false);
      });
  };

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");
  const addEducation = () => {
    setEducationLevels([
      ...educationLevels,
      { educationLevelId: Math.random() },
    ]);
  };

  const remove = (index) => {
    const _educations = [...educationLevels];
    _educations.splice(index, 1);
    setEducationLevels(_educations);
  };

  return (
    <div className="Education">
      <Spin spinning={pageLoading}>
        <h1 className="fw-bold ls-full text-4xl text-black mb-30">
          Eğitim Bilgileri
        </h1>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row>
            <Col span={24}>
              {educationLevels.map((x, i) => (
                <Form.List name={x.educationLevelId}>
                  {(fields) => (
                    <EducationItem
                      key={x.educationLevelId}
                      data={x}
                      index={i}
                      remove={remove}
                    />
                  )}
                </Form.List>
              ))}
              <Button
                className="BtnAddEducation btn btn-pink-filled radius-lg"
                type="primary"
                icon={<Icon name="plus" size={26} className="mr-10" />}
                onClick={() => addEducation()}
              >
                Ekle
              </Button>
            </Col>
          </Row>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};
