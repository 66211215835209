import { useEffect, useState } from "react";
import useGet from "hooks/useGet";
import { Helmet } from "react-helmet";
import printBack from "../../../../../../assets/printback.png";
import bio from "../../../../../../assets/static/img/logo.png";
import "../../../../../../styles/components/print.less";
import ibbalt from "../../../../../../assets/ibbalt.png";
import biohead from "../../../../../../assets/logo-logo.svg";
import MainInfo from "./MainInfo";
import Experience from "./Experience";
import Education from "./Education";
import LanguageSkills from "./LanguageSkills";
import ComputerSkills from "./ComputerSkills";
import axios from "axios";
import config from "../../../../../../plugins/ibb/config";
import { message } from "antd";
import { getBase64 } from "../../../../../../helpers/methods";
import { useSelector } from "react-redux";

const Detail = () => {
  const { data, loading } = useGet({
    url: `/candidates/${localStorage.getItem("userId")}/resumes`,
    isArray: false,
  });
  const meData = useSelector((state) => state.data.companyResult);

  const [imageUrl, setImageUrl] = useState(biohead);
  const currentDate = new Date();
  const fullName = data?.firstName + " " + data?.lastName;

  const printFunction = (name) => {
    document.title = `${name}_bio_cv`;
    window.print();
  };

  useEffect(() => {
    if (meData && data && meData?.photoId == null && !loading) {
      printFunction(meData?.firstName + "_" + meData?.lastName);
    }
  }, [meData, data, imageUrl, loading]);

  useEffect(() => {
    if (meData?.photoId) {
      (() => {
        axios
          .get(
            `${config.BASE_API_URL}/candidates/resumes/profile-photos/${meData?.photoId}`,
            {
              responseType: "blob",
              headers: {
                "Content-Type": "image/jpg, image/png",
              },
            }
          )
          .then((response) => {
            getBase64(response.data, (img) => {
              setImageUrl(img);
              printFunction(meData?.firstName + "_" + meData?.lastName);
            });
          })
          .catch((err) => {
            message.error(err);
            printFunction(meData?.firstName + "_" + meData?.lastName);
          });
      })();
    }
  }, [meData]);

  return (
    <div className="CandidatePrintDetail">
      <Helmet>
        <title>İş ara İş Bul | Özgeçmiş yazdır</title>
        <meta
          name="description"
          content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
        />
        <meta
          name="keywords"
          content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
        />
      </Helmet>
      <div className="print">
        <div className="bio-logo">
          <img src={bio} alt="bölgesel istihdam ofisi logo" />
        </div>
        <div className="banner">
          <img
            style={{ width: "100%" }}
            src={printBack}
            className="printBack"
          />
          <p
            className="name"
            style={{ fontSize: `${fullName.length > 10 ? "18px" : "24px"}` }}
          >
            {fullName}
          </p>
          <p className="title"></p>
          <div
            className="profile-photo"
            style={{
              backgroundImage: `url(${imageUrl})`,
              backgroundSize: "cover",
            }}
          ></div>
        </div>
      </div>
      <p className="description">
        {data?.coverLetter?.replace(/(<([^>]+)>)/gi, "")}
      </p>
      <section className="section-info">
        <div className="section-title-wrapper">
          <h2 className="section-title">KİŞİSEL BİLGİLER</h2>
          <div className="line"></div>
        </div>
        <MainInfo candidate={data} />
      </section>
      {data?.experiences.length > 0 && (
        <section className="section-info">
          <div className="section-title-wrapper">
            <h2 className="section-title">İŞ DENEYİMLERİ</h2>
            <div className="line"></div>
          </div>
          {data &&
            data.experiences?.map((experience, index) => {
              if (experience.companyTitle && experience.startDate) {
                return (
                  <Experience
                    key={index}
                    company={experience.companyTitle}
                    profession={experience.professionName}
                    description={experience.description}
                    era={
                      experience.startDate?.split("-")?.shift() +
                      " - " +
                      (experience.endDate
                        ? experience.endDate?.split("-")?.shift()
                        : "devam ediyor")
                    }
                    duration={
                      (-1 *
                        (experience?.startDate?.split("-")?.shift() -
                          experience?.endDate?.split("-")?.shift()) ===
                      0
                        ? 1
                        : -1 *
                          (experience?.startDate?.split("-")?.shift() -
                            (experience.endDate
                              ? experience.endDate?.split("-")?.shift()
                              : currentDate.getFullYear()))) + " Yıl"
                    }
                  />
                );
              }
            })}
        </section>
      )}
      {data?.educations.length > 0 &&
        data?.educations[0]?.level?.description !== "Belirtilmemiş" && (
          <section className="section-info">
            <div className="section-title-wrapper">
              <h2 className="section-title">EĞİTİM BİLGİLERİ</h2>
              <div className="line"></div>
            </div>
            {data &&
              data.educations?.map((education, index) => {
                if (education.departmentName) {
                  return (
                    <Education
                      key={index}
                      schoolName={education.instituteName}
                      faculty={education.departmentName}
                      duration={
                        education?.startDate +
                        " - " +
                        (education.endDate ? education.endDate : "devam ediyor")
                      }
                      average={education.level.description}
                    />
                  );
                } else {
                  return <p>{education.level.description}</p>;
                }
              })}
          </section>
        )}
      {data?.computerCompetencies.length < 1 ||
        (data?.languages.length > 0 && (
          <section className="section-info">
            <div className="section-title-wrapper capabilities">
              <h2 className="section-title">YETKİNLİKLER</h2>
              <div className="line"></div>
            </div>
            {data?.languages.length > 0 && (
              <LanguageSkills data={data?.languages} />
            )}
            {data?.computerCompetencies.length > 0 && (
              <ComputerSkills data={data?.computerCompetencies} />
            )}
          </section>
        ))}
      {data?.certificationAndSeminars !== null && (
        <section className="section-info">
          <div className="section-title-wrapper">
            <h2 className="section-title">SERTİFİKALAR</h2>
            <div className="line"></div>
          </div>
          {data?.certificationAndSeminars?.map((certificate, index) => {
            return (
              <Experience
                key={index}
                title={certificate?.subject}
                company={certificate.instituteName}
                description={certificate.description}
                era={
                  certificate.start +
                  " - " +
                  (certificate.end ? certificate.end : "devam ediyor")
                }
                duration={certificate.duration}
              />
            );
          })}
          {data?.ismekCertifications?.length > 0 && (
            <>
              <hr /> <h4>İsmek Sertifikaları</h4>
            </>
          )}
          {data?.ismekCertifications?.length > 0 &&
            data?.ismekCertifications?.map((certificate, index) => {
              return (
                <Experience
                  key={index}
                  title={certificate?.course?.name}
                  company={certificate?.course?.name}
                  description={certificate?.certType?.name}
                  era={
                    certificate.startedAt +
                    " - " +
                    (certificate.endedAt ? certificate.endedAt : "devam ediyor")
                  }
                />
              );
            })}
        </section>
      )}
      <img src={ibbalt} className="alt-image" alt="ibb" />
    </div>
  );
};

export default Detail;
