const Education = ({ schoolName, faculty, duration, average }) => {
  return (
    <div className="education-wrapper">
      <div className="title-wrapper">
        <h4 className="education-title">
          { schoolName }
        </h4>
        <p className="education-content">
          { faculty }
        </p>
      </div>
      <p className="date">{ duration }</p>
      <p className="date">{ average }</p>
    </div>
  )
}

export default Education;

