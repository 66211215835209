import { useEffect, useState } from "react";
import { message, Progress, Spin, Switch, Tabs } from "antd";
import { useSelector } from "react-redux";
import { getData, postData2 } from "redux/services/request";
import Banner from "components/UIComponents/Banner";
import General from "./Tabs/General";
import Communication from "./Tabs/Communication";
import Education from "./Tabs/Education";
import Experience from "./Tabs/Experience";
import Seminars from "./Tabs/Certificates";
import ForeignLanguages from "./Tabs/ForeignLanguages";
import Skills from "./Tabs/Skills";
import References from "./Tabs/References";
import CoverLetter from "./Tabs/CoverLetter";
import Documents from "./Tabs/Documents";
import FreePass from "./Tabs/FreePass";
import { Helmet } from "react-helmet";
import CvTabs from "./Tabs/CvTab";

const cvTabData = [
  {
    tab: "Kişisel",
    page: 1,
  },
  {
    tab: "İletişim",
    page: 2,
  },
  {
    tab: "Eğitim",
    page: 3,
  },
  {
    tab: "İş Deneyimi",
    page: 4,
  },
  {
    tab: "Seminer ve Sertifika",
    page: 5,
  },
  {
    tab: "Yetkinlikler",
    page: 6,
  },
  {
    tab: "Yabancı Dil",
    page: 7,
  },
  {
    tab: "Referans",
    page: 8,
  },
  {
    tab: "Ön Yazı",
    page: 9,
  },
  {
    tab: "Ek Belge",
    page: 10,
  },
  {
    tab: "İBB Ulaşım Desteği",
    page: 11,
  },
];

const CV = () => {
  const [active, setActive] = useState("1");
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState("DRAFT");
  const [statusLoading, setStatusLoading] = useState(false);
  const [loading, setLoading] = useState("ACTIVE");

  const resume = useSelector((state) =>
    state?.data.resume ? state?.data.resume.data : undefined
  );

  const reload = () => {
    setLoading(true);
    getData({ url: "candidates/resumes", key: "resume" })
      .then((data) => {
        setStatus(data.status);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    if (!resume) return;
    setProgress(resume.progress);
  }, [resume]);

  const onTabChange = (key) => {
    setActive(key);
  };

  const handleSetActive = (activeTab) => {
    //reload()
    setActive(activeTab);
  };

  const handleProgressChange = (progress, status) => {
    setProgress(progress);
    setStatus(status);
  };

  const changeStatus = async (val) => {
    val ? setStatus("DRAFT") : setStatus("ACTIVE");
    setStatusLoading(true);

    const response = await postData2({
      url: `/candidates/resumes/${val ? "activate" : "deactivate"}`,
      data: {},
    });
    try {
      if (response.code !== 200) {
        message.error(response.message);
        setStatus("DRAFT");
        setStatusLoading(false);
        return;
      }

      setStatusLoading(false);
      if (val) {
        message.success("Özgeçmiş aktifleştirildi.");
        setStatus("ACTIVE");
      } else {
        message.success("Özgeçmiş pasif hale getirildi.");
        setStatus("DRAFT");
      }
    } catch (e) {
      message.error("something went wrong.");
      setStatusLoading(false);
    }
  };

  const [activeTab, setActiveTab] = useState({
    tab: "Kişisel",
    page: 1,
  });

  const renderTab = () => {
    if (activeTab?.page === 1) {
      return (
        <General
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          active={active}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 2) {
      return (
        <Communication
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          active={active}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 3) {
      return (
        <Education
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 4) {
      return (
        <Experience
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 5) {
      return (
        <Seminars
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 6) {
      return (
        <Skills
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 7) {
      return (
        <ForeignLanguages
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 8) {
      return (
        <References
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 9) {
      return (
        <CoverLetter
          active={active}
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          refresh={reload}
          status={status}
        />
      );
    } else if (activeTab?.page === 10) {
      return (
        <Documents
          onProgressChange={handleProgressChange}
          refresh={reload}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
        />
      );
    } else if (activeTab?.page === 11) {
      return <FreePass activeTab={activeTab} setActive={setActive} />;
    } else {
      return (
        <General
          setActive={handleSetActive}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
          cvTabData={cvTabData}
          onProgressChange={handleProgressChange}
          active={active}
          refresh={reload}
          status={status}
        />
      );
    }
  };

  return (
    <div className="CV">
      <Helmet>
        <title>İş Ara İş Bul | Özgeçmiş Oluşturma</title>
        <meta
          name="description"
          content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak."
        />
        <meta
          name="keywords"
          content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"
        />
      </Helmet>
      <Banner text="Özgeçmiş" className="mb-0" />
      <div className="content md content-detail">
        <div className="d-flex j-between mb-45">
          <h1 className="text-black text-4xl fw-bold ls-full">
            ÖZGEÇMİŞ OLUŞTUR
          </h1>
          <Switch
            checkedChildren="AKTİF"
            unCheckedChildren="PASİF"
            checked={status === "ACTIVE" ? true : false}
            onChange={changeStatus}
            loading={statusLoading}
          />
        </div>
        <h2 className="text-4xl ls-full fw-semibold mb-10">
          %{progress} Tamamlandı
        </h2>
        <Progress
          strokeColor="#F7AF3E"
          trailColor="rgb(142 63 175)"
          percent={progress}
          showInfo={false}
        />
        <p
          className={`${
            status === "ACTIVE" ? "text-success" : "text-orange-bio"
          }`}
        >
          {status === "ACTIVE"
            ? "Bu özgeçmiş ile ilanlara başvurulabilir"
            : "Bu özgeçmiş henüz aktifleştirilmemiş. İlanlara başvurulamaz"}
        </p>

        <CvTabs
          cvTabData={cvTabData}
          activeTab={activeTab}
          setActiveTab={setActiveTab}
        />
        <button
          className={`test_button ${activeTab?.page === 11 ? "active" : ""}`}
          onClick={() => setActiveTab({ tab: "İBB Ulaşım Desteği", page: 11 })}
        >
          İBB Ulaşım Desteği
        </button>

        <Spin spinning={loading}>{renderTab()}</Spin>
        {/* <Spin spinning={loading}>
          <Tabs
            defaultActiveKey="1"
            className="mt-30 tab-container"
            activeKey={active}
            onChange={onTabChange}
          >
            <Tabs.TabPane tab="Kişisel" key={1}>
              <General
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                active={active}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="İletişim" key={2}>
              <Communication
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                active={active}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Eğitim" key="3">
              <Education
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="İş Deneyimi" key="4">
              <Experience
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Seminer ve Sertifika" key="5">
              <Seminars
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Yetkinlikler" key="6">
              <Skills
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Yabancı Dil" key="7">
              <ForeignLanguages
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Referans" key="8">
              <References
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Ön yazı" key="9">
              <CoverLetter
                active={active}
                setActive={handleSetActive}
                onProgressChange={handleProgressChange}
                refresh={reload}
                status={status}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Ek Belge" key="10">
              <Documents
                onProgressChange={handleProgressChange}
                refresh={reload}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="İBB Ulaşım Desteği" key="11">
              <FreePass active={active} setActive={setActive} />
            </Tabs.TabPane>
          </Tabs>
        </Spin> */}
      </div>
    </div>
  );
};

export default CV;
