import { Icon } from "components/UIComponents";

import Exam from "./Exam";
import {Form, Button} from "antd";


export default ({exams, add, remove}) => {
  return (
    <div className="ComputerSkills">
      { exams.map((x, i) => (
          <Form.List name={x.id}>{fields =>
              <Exam key={x.id} data={x} index={i} remove={remove}/>
          }</Form.List>
      ))}


      <Button className="btn btn-pink-filled radius-lg p-20 text-md" type="primary" icon={<Icon name="plus" size={26} className="mr-10" />} onClick={() => add()}>
          Ekle
      </Button>
    </div>
  );
};
