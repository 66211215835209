import { Link } from "react-router-dom";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import MutableInput from "../../Dashboard/User/Candidate/Account/MutableInput";

export const validations = [
  {
    name: "idNo",
    rules: [
      {
        required: true,
        message: "T.C. Kimlik Numarası Alanı Zorunludur.",
      },
      {
        pattern: new RegExp("^[1-9]{1}[0-9]{9}[02468]{1}$"),
        message: "Lütfen geçerli T.C. kimlik numaranızı giriniz.",
      },
      {
        validator: (_, value) =>
          value.toString().length < 11
            ? Promise.reject("T.C. Kimlik No 11 Haneli Olmalıdır.")
            : Promise.resolve(),
      },
    ],
  },
  {
    name: "responsibleTitle",
    rules: [
      {
        required: true,
        message: "Ünvan Alanı Zorunludur.",
      },
    ],
  },
  {
    name: "password",
    rules: [
      {
        required: true,
        message: "Lütfen şifrenizi giriniz",
      },
      { min: 8, message: "Şifre minimum 8 karakter olmalıdır" },
      {
        // pattern: new RegExp(
        //   "^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{8,}$"
        // ),
        message:
          "Şifre en az 1 büyük 1 küçük harf, rakam ve 1 özel karakterden oluşmlıdır.",
      },
    ],
  },

  {
    name: "confirm",
    rules: [
      {
        required: true,
        message: "Lütfen şifrenizi doğrulayınız!",
      },
      ({ getFieldValue }) => ({
        validator(_, value) {
          if (!value || getFieldValue("password") === value) {
            return Promise.resolve();
          }
          return Promise.reject(new Error("Şifreniz eşleşmemektedir!"));
        },
      }),
    ],
  },
  {
    name: "phone",
    rules: [
      {
        required: true,
        message: "Telefon numarası alanı zorunludur",
      },
      {
        maxLength: 10,
        message: "Lütfen telefon numaranız 10 haneli olmalı.",
      },

      {
        minLength: 10,
        message: "Lütfen telefon numaranız 10 haneli olmalı.",
      },
      {
        validator: (_, value) =>
          value.toString()[1] !== "5"
            ? Promise.reject(
                "Lütfen cep telefonunuzu başında sıfır olmadan giriniz"
              )
            : Promise.resolve(),
      },
    ],
  },
  {
    name: "phoneNumber",
    rules: [
      {
        required: true,
        message: "Telefon numarası alanı zorunludur",
      },
      {
        maxLength: 15,
        message: "Lütfen telefon numaranız 10 haneli olmalı.",
      },

      {
        minLength: 15,
        message: "Lütfen telefon numaranız 10 haneli olmalı.",
      },
      {
        validator: (_, value) =>
          value.toString()[1] !== "5"
            ? Promise.reject(
                "Lütfen cep telefonunuzu başında sıfır olmadan giriniz"
              )
            : Promise.resolve(),
      },
    ],
  },
  {
    name: "firstName",
    rules: [
      {
        required: true,
        message: "Lütfen adınızı giriniz",
      },
      {
        maxLength: 50,
        message: "Lütfen harf sınırını aşmayınız.",
      },
      {
        min: 2,
        message: "Adınız 2 harften büyük olmalı!",
      },
      {
        pattern: new RegExp("^[a-zA-ZiİıIçÇşŞğĞÜüÖö ]*$"),
        message: "Lütfen harf giriniz",
      },
    ],
  },
  {
    name: "birthDate",
    rules: [
      {
        required: true,
        message: "Lütfen doğum tarihinizi giriniz.",
      },
    ],
  },
  {
    name: "lastName",
    rules: [
      {
        required: true,
        message: "Soyadınızı giriniz.",
      },
      {
        pattern: new RegExp("^[a-zA-ZiİıIçÇşŞğĞÜüÖö ]*$"),
        message: "Lütfen harf giriniz",
      },
    ],
  },
  {
    name: "email",
    rules: [
      {
        type: "email",
        message: "E-posta Adresi doğru Değil. Geçerli bir e-posta giriniz.",
      },
      {
        required: true,
        message: "Lütfen geçerli bir e-posta giriniz.",
      },
    ],
  },
  {
    name: "clarificationConfirmation",
    rules: [
      {
        required: true,
        message: "Lütfen aydınlatma metnini okuyunuz ve onaylayınız.",
      },
    ],
  },
  {
    name: "companyAlias",
    rules: [
      {
        required: true,
        message: "Lütfen ticari ünvanınızı giriniz",
      },
      {
        maxLength: 50,
        message: "Lütfen harf sınırını aşmayınız.",
      },
      {
        minLength: 2,
        message: "Ticari ünvanınız 2 harften büyük olmalı!",
      },
    ],
  },

  {
    name: "taxNoOrIdNo",
    rules: [
      {
        required: true,
        message: "Vergi kimlik numranızı giriniz.",
      },
      {
        pattern: /([1-9][0-9]*)|0/,
        message: "Lütfen rakam giriniz",
      },
      {
        validator: (_, value) =>
          value.toString().length < 10
            ? Promise.reject("Vergi Kimlik No 10 Haneli Olmalıdır.")
            : Promise.resolve(),
      },
    ],
  },
  {
    name: "companyWorkforceTr",
    rules: [
      {
        required: true,
        message: "Lütfen bir seçim yapın",
        validator: (rule, value) => {
          if (value === "seciniz") {
            return Promise.reject("Lütfen varsayılan seçeneği değiştirin");
          }
          return Promise.resolve();
        },
      },
    ],
  },
  {
    name: "companyWorkforce34",
    rules: [
      {
        required: true,
        message: "Lütfen bir seçim yapın",
        validator: (rule, value) => {
          if (value === "seciniz") {
            return Promise.reject("Lütfen varsayılan seçeneği değiştirin");
          }
          return Promise.resolve();
        },
      },
    ],
  },
];

// sublink generate
export const SubLinkGenerate = (question = "", linkName = "", url = "/") => {
  return (
    <p className="form-text">
      {question} Hemen{" "}
      <Link
        style={{
          color: "#F3590f",
          textDecoration: "underline",
          fontWeight: "bold",
        }}
        to={url}
      >
        {linkName}
      </Link>
    </p>
  );
};

{
  /*export const handleAuthorization = ( successMessage, code, content, history, path, userType ) => {
  if( code !== 200 ) {
    message.error( content);
  }

 /*  else if(code === 40404 || code === 40405 && userType === 'CORPORATE' ){
    message.error( content );
  }
else {
  message.success( successMessage );
  history( path );
}
}*/
}
