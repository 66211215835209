import { useEffect, useState } from "react";
import { Row as AntRow, Col } from "antd";
import { DeleteOutlined } from '@ant-design/icons';
import { LookupSelect } from "components/UIComponents/FormElements";

export default ({ onChangeDisability, remove, index, data }) => {
  const [id, setId] = useState(null);
  const [percentage, setPercentage] = useState(null);

  useEffect(() => {
    (id || percentage) && onChangeDisability(id, percentage, index);
    // eslint-disable-next-line
  }, [id, percentage]);

  useEffect(() => {
    data && setId(data[0]);
    data && setPercentage(data[1]);
  }, [data]);

  return (
    <div className="DisabilityContainer mb-20">
      <AntRow className="full-width" gutter={30} align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <LookupSelect url="/disabilities" key="disabilities" valueKey="id" labelKey="disability" placeholder="Engel Tipi" onChange={setId} value={id} />
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 6 }}>
          <LookupSelect url="/disabilities/types" key="disabilityTypes" valueKey="name" labelKey="label" placeholder="Engel Yüzdesi" onChange={setPercentage} value={percentage} />
        </Col>
        <DeleteOutlined onClick={() => remove(id)} />
      </AntRow>
    </div>
  );
}