
const LanguageSkills = ({ data }) => {
  return(
    <>
      <div className="skills-container">
        <div className="skill-title">
          Dil Bilgisi
        </div>
      {
        data && data?.map(( language, index ) => {
          return(
              <div key={ index }>
                <div className="skill-container">
                  <h3>{ language.languageName }</h3>
                  <div className="level-container">
                    <div className="level-type">
                      <h4>Okuma</h4>
                      <p>{ language.reading }</p>
                    </div>
                    <div className="level-type">
                      <h4>Yazma</h4>
                      <p>{ language.writing }</p>
                    </div>
                    <div className="level-type">
                      <h4>Konuşma</h4>
                      <p>{ language.speaking }</p>
                    </div>
                    <div className="level-type">
                      <h4>Çeviri</h4>
                      <p>{ language.translation }</p>
                    </div>
                  </div>
                </div>
              </div>
          )
        })
      }
      </div>
    </>
  )
}

export default LanguageSkills;

