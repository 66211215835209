import { Row, Col, Form, Input, Button, Modal, message } from "antd";
import React, {useState} from "react";
import { SyncOutlined } from "@ant-design/icons";
import { postData2 } from "../../../../../../redux/services/request";
import AuthLayoutInput from "../../../../../Auth/Components/AuthLayoutInput";
import MaskedInput from "react-maskedinput";
import {useHistory} from "react-router-dom";

export default ({ inputName = '', modalTitle = "Mutable Input", placeholder, value, label ="Yeni input", url, verifyUrl }) => {
  const [ form ] = Form.useForm();
  const [ secondModalForm ] = Form.useForm();
  const [ visible, setVisible ] = useState(false );
  const [ firstStepDisplay, setFirstStepDisplay ] = useState('block' );
  const [ secondStepDisplay, setSecondStepDisplay ] = useState('none' );
  const [ stateId, setStateId ] = useState('' );
  const history = useHistory()

  const handleOk = () => {
    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const showModal = () => {
    setVisible(true)
  }

  async function handleFirstStep( values ){
    try {
      if((values?.confirmNewPassword !== undefined && values?.newPassword !== undefined && values.oldPassword !=="") || (values.phoneNumber!== undefined)|| (values.email!== undefined)){
        const isPhone = values.hasOwnProperty( 'phoneNumber' );
        let data;
  
        if( isPhone ) {
          data = {
            ...values,
            phoneNumber: "0" + values?.phoneNumber?.replace(/[()\s]/g,'')
          }
        } else {
          data = {
            ...values
          }
        }
  
     
        const response = await postData2({ url, data });
        if( response.code !== 200 ){
          message.error( response.message )
          return
        }
  
        message.success('Doğrulamaya yönlendiriliyorsunuz..' )
        setStateId( response?.result?.stateId );
        setFirstStepDisplay('none' );
        setSecondStepDisplay('block' );
  
      }else{
        message.error( "Doğru değer girdiğinizden emin olunuz.")
      }
    
    } catch (e) {
     
    }
  }

  async function handleSecondStep( values ){
    const data = {
      ...values,
      stateId
    }

   try {
      const response = await postData2({ url: verifyUrl, data });
      if( response.code !== 200 ){
        message.error(response.message)
      } else {
        message.success(`${modalTitle} başarıyla gerçekleşti.`)
        setFirstStepDisplay('block' );
        setSecondStepDisplay('none' );
        setVisible(false);
        history.go([0])
      }
    } catch (e) {
      message.error('Birşeyler ters gitti')
    }
  }

    return (
      <div className="General">
        <Row justify="space-between mb-15">
        <Col xs={{ span: 16 }} sm={{ span: 16 }} md={{ span: 16 }} lg={16}>
              <Input
                name={ inputName }
                className="form-input"
                placeholder={ inputName }
                value={ value }
                disabled
                style={{ height: "52px", borderRadius: "6px 0px 0px 6px" }}
              />
          </Col>
          <Col xs={{ span: 8 }} sm={{ span: 8 }} md={{ span: 8 }} lg={8}>
              <Button
                type="primary"
                icon={<SyncOutlined className="input-icon"/>}
                onClick={showModal}
                style={{
                  height: "52px",
                  borderRadius: "0px 6px 6px 0px",
                  border: "1px solid rgba(85, 85, 85, 0.5)",
                  borderLeft: 0,
                }}
              >
                 <span className="update-input">Güncelle</span>
              </Button>
          </Col>
          
        </Row>
        <Modal
          title={ modalTitle }
          visible={visible}
          onOk={handleOk}
          onCancel={handleCancel}
          afterClose={ () => history.go([0]) }
          okButtonProps={{
            disabled: true,
          }}
          cancelButtonProps={{
            disabled: true,
          }}
          footer={null}
        >
          <Form
            style={{ display: firstStepDisplay }}
            form={form}
            name="modalForm"
            id="modalForm"
            onFinish={ handleFirstStep }
            initialValues={
              {
                [inputName]: ''
              }
            }
            autoComplete="off">
            <AuthLayoutInput label={label} name={ inputName } input={
                inputName === 'phoneNumber' ? (
                  <MaskedInput
                    className="masked-input"
                    mask="(111) 111 11 11"
                    name={inputName}
                    size="10"
                    style={{ display:'block',width:'100%' }}
                    placeholder={ value }
                  />
                ) : (
                  <Input placeholder={ value } name={ inputName } type={ inputName === 'oldPassword' ? "password" : inputName }  />
                )
            }/>
            {
              inputName === 'oldPassword' && (
              <>
                <AuthLayoutInput
                  label='Yeni Şifre :'
                  name="newPassword"
                  input={
                  <Input placeholder={ placeholder } name="newPassword" type="password" />
                }/>
                <AuthLayoutInput
                  label='Yeni Şifre Tekrar :' 
                  name="confirmNewPassword"
                  input={
                  <Input placeholder={ placeholder } name="confirmNewPassword" type="password" />
                }/>
              </>
              )
            }
            <Button type="primary" htmlType="submit" form="modalForm">
              Güncelle
            </Button>
          </Form>
          <Form
            style={{ display: secondStepDisplay }}
            form={ secondModalForm }
            onFinish={ handleSecondStep }
            name="secondModalForm"
            id="secondModalForm"
            initialValues={
              {
                code: ''
              }
            }
            autoComplete="off">
            <AuthLayoutInput
            label="Doğrulama Kodu" name="code" input={
              <Input placeholder="Telefonunuza gelen doğrulama kodunu giriniz"/>
            }/>
            <Button type="primary" htmlType="submit" form="secondModalForm">
              Doğrula
            </Button>
          </Form>
        </Modal>
      </div>
    );
  };

