import Banner from "components/UIComponents/Banner"
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { updateState } from "redux/services/request";
import { getData } from "redux/services/request";
import {Helmet} from "react-helmet";

export default () => {

  const aboutUsData = useSelector(state => state.aboutUs);

  useEffect(() => {
    async function fetchData() {
      updateState({ aboutUs: { data: null, loading: true } });
      const aboutUs = await getData({ url: '/public/about-us' });
      updateState({ aboutUs: { data: aboutUs, loading: false } });
    }

    fetchData();
  }, []);

  return (
    <div className="PublicDetail">
      <Helmet>
        <title>İş Ara İş Bul | Portal Üyelik Sözleşmesi</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Portal Üyelik Sözleşmesi" className="mb-0" />
      <div className="PublicDetailContent content md content-detail">
        <article className="Article">
          <p>&nbsp;<br /></p>
          <p  ><b><span>I. SÖZLEŞME HAKKINDA</span></b><span></span></p>
          <p   ><span>Üyeliğinizi başarıyla tamamladığınızda BÖLGESEL İSTİHDAM OFİSLERİ ile işbu Üyelik Sözleşmesini kabul etmiş olursunuz.</span><span></span></p>
          <p   ><span>Kişisel verilerinizin işlenmesi ile ilgili haklarınız ve buna ilişkin istenen rızalarınıza dair bilgilendirilmeniz için Kişisel Verilerin Korunması Kanunu Çerçevesinde&nbsp;<b><a href="/Sayfa/Icerik/BilgilendirmeMetni" target="_blank">Bilgilendirme Metni</a></b>ne bakabilirsiniz.</span><span></span></p>
          <p   ><span>&nbsp;</span></p>
          <p   ><b><span>II. HİZMETLERİMİZ</span></b><span></span></p>
          <p   ><span>İşbu Sözleşme, hâlihazırda Bölgesel İstihdam Ofisleri Portalı alan adı veya cep telefonu uygulaması üzerinde yer alan mobil uygulama aracılığı ile verilen istihdama yönelik hizmetlerle birlikte, bu hizmetler için “Portal” içinde yapılan veri toplama ve portal dışında yapılan araştırma aktiviteleri de dâhil olmak üzere bu “Sözleşme” altında sunulduğu ifade edilen diğer tüm hizmetler (Hizmetler) için geçerlidir. Hizmetlerimizin iş arayan kayıtlı kullanıcıları "İş arayan"; çalışan arayan kayıtlı kullanıcıları ise "işveren" olarak adlandırılır. Bu sözleşme İstanbullu vatandaşlarımız için geçerlidir.</span><span></span></p>
          <p   ><span>&nbsp;</span></p>
          <p   ><b><span>III. PORTAL KULLANIMINA İLİŞKİN HÜKÜMLER</span></b><span></span></p>
          <p   ><span>Vatandaş, Bölgesel İstihdam Ofisleri Portalı'nın, üyeliği kapsamında üyelik formunda ("form") verdiği ve Portal'da paylaştığı özgeçmiş dahil tüm bilgilerden yararlanarak kendisi için bir hesap ve profil oluşturmasına peşinen izin vermektedir. Formdaki ve özgeçmişteki bilgilerle ilgili olarak ortaya çıkabilecek her türlü hukuki, cezai ve/veya idari sorumluluk vatandaşa aittir.</span><span></span></p>
          <p   ><span>Vatandaş, Portal'da yayımlanan özgeçmişi ve dolayısı ile normal ve/veya özel nitelikli kişisel verileri ile ilgili tüm sorumluluğun özgeçmişi görüntüleyen ve/veya kaydeden firmalara geçtiğini, Bölgesel İstihdam Ofisleri’nin firmalar tarafından elde edilen bilgilerle ilgili herhangi bir yükümlülüğünün ve/veya müdahale hakkının olmadığını peşinen kabul eder.</span><span></span></p>
          <p   ><span>Herhangi bir tarihte vatandaş, firma tarafından elde edilmiş ve/veya firma tarafından kaydedilmiş normal ve/veya özel nitelikli kişisel verilerinin silinmesini, yok edilmesini ve/veya anonim hale getirilmesini isterse bu talebini direkt olarak söz konusu firmaya iletebilir. Bölgesel İstihdam Ofisleri'nin bu hususlarda sorumluluğu ve yükümlülüğünün olmadığını peşinen kabul etmektedir.</span><span></span></p>
          <p   ><span>Vatandaş, istediği bir zaman diliminde portalda yer alan özgeçmiş(ler)inde güncelleme yapabilmektedir. Vatandaş, özgeçmiş(ler)inde güncelleme işlemi gerçekleştirdiği anda, o tarihten sonra yapılacak ve o tarihten önce yapılmış tüm iş ilanı başvurularında, İşveren tarafından en son güncellenen özgeçmiş(ler)in &nbsp;görüleceğini kabul eder.</span><span></span></p>
          <p   ><span>Vatandaş, özgeçmişini "aktif" olarak ayarladığı ve görüntülenmesine bir kısıtlama getirmediği sürece, Portal'da yer alan kişisel bilgi ve özgeçmiş(ler)inin portal kullanıcıları tarafından görüntülenebileceğini kabul etmektedir.</span><span></span></p>
          <p   ><span>Portal'da verilen linkler takip edilerek vatandaş tarafından herhangi bir bilgi girişi yapıldığında, bu bilgilerin içeriği, doğruluğu, gizliliği ve/veya korunması hususunda Bölgesel İstihdam Ofisleri Portalı'nın hiçbir sorumluluğu bulunmamaktadır.</span><span></span></p>
          <p   ><span>Bölgesel İstihdam Ofisleri Portalı'nın formunda ("form") vatandaş tarafından verilen bilgiler ile varsa vatandaş tarafından Portal'a yerleştirilen özgeçmişindeki bilgilerin doğruluğunu araştırma yükümlülüğü bulunmamaktadır. Verilen bilgilerin doğruluğundan bizzat vatandaş sorumludur.</span><span></span></p>
          <p   ><span>Vatandaş işbu form ve özgeçmişte yer alan tüm bilgilerini, başvurduğu iş ilanını yayımlayan firmaların görüntüleyebileceğini peşinen kabul eder.</span><span></span></p>
          <p   ><span>Portalda yer alan iş ilanlarına başvuruda bulunan vatandaşa mülakat daveti, ilanı yayımlayan firma ve/veya kişiler tarafından Bölgesel İstihdam Ofisleri Portalı mülakat daveti arayüzünden gönderilmektedir. Vatandaş, söz konusu daveti&nbsp;<b>portal&nbsp;</b>&nbsp;üzerinden kabul eder ancak mülakata mazeretsiz olarak katılmazsa, ilan sahibi tarafından sistemde "Vatandaş Mülakata Mazeretsiz Katılmadı" işaretlemesi yapılabilmektedir. Vatandaş işbu durumu peşinen kabul eder.</span><span></span></p>
          <p   ><span>Vatandaş, Portalda yayımlanan herhangi bir ilana başvurduktan sonra aktarılan normal ve/veya özel nitelikli kişisel verileri ile ilgili her türlü sorumluluğun ilan sahibi işverene geçtiğini, Bölgesel İstihdam Ofisleri Portalı'nın, ilana başvuru dolayısıyla aktarılan bilgilerle ilgili herhangi bir yükümlülüğünün ve/veya müdahale hakkının olmadığını peşinen kabul etmektedir. Aynı şekilde vatandaş, herhangi bir tarihte başvurduğu ilan dolayısıyla ilan sahibi işverene aktarılan normal ve/veya özel nitelikli kişisel verilerinin silinmesini, yok edilmesini ve/veya anonim hale getirilmesini isterse, bu talebini direkt olarak ilan sahibi firmaya iletmesi gerekir. Vatandaş, Bölgesel İstihdam Ofisleri Portalı'nın bu hususlarda sorumluluk ve yükümlülüğünün olmadığını peşinen kabul eder.</span><span></span></p>
          <p   ><span>&nbsp;</span></p>
          <p   ><b><span>IV. VATANDAŞ İLE İLETİŞİM</span></b><span></span></p>
          <p   ><span>Bölgesel İstihdam Ofisleri Portalı, vatandaş tarafından portala kayıt esnasında girilen veya daha sonra kendisi tarafından güncellenen e-posta adresi üzerinden vatandaşa yeniliklerle ilgili bildirimde bulunma ve vatandaşla kurumsal diğer amaçlarla ilgili iletişim kurma hakkına sahiptir. Vatandaş, aksine bir bildirimi olmadığı takdirde Bölgesel İstihdam Ofisleri Portalı'nın kendisine yönelik yukarıda belirtilen iletişim faaliyetlerinde bulunabileceğini kabul ve beyan etmektedir. Vatandaş hesap ayarları üzerinden veya kendisine gönderilen e-postada yer alan "Listeden çıkmak istiyorum" bağlantısını kullanarak tek tıkla e-posta listesinden çıkabilir.</span><span></span></p>
          <p   ><span>&nbsp;</span></p>
          <p   ><b><span>V. YÜKÜMLÜLÜKLERİNİZ</span></b><span></span></p>
          <p   ><span>Aşağıda yer alan yükümlülüklerinizin ihlali halinde, Bölgesel İstihdam Ofisleri Portalı'nın, üçüncü kişiler nezdinde olanlar dahil olmak üzere, herhangi bir zarara uğraması durumunda, bu zarar ile ilgili size rücu edebilir veya zararın karşılanması için hukuki işlem ve eylemlerde bulunabilir, gerektiğinde sizi bilgilendirmek kaydıyla hesabınızı askıya alabilir veya kapatabilir.</span><span></span></p>
          <ul type="disc">
            <li   ><span>Portala girdiğiniz bütün kişisel verileriniz doğru ve güncel olmalıdır.</span><span></span>
            </li><li   ><span>Bölgesel İstihdam Ofisleri tarafından talep edildiği takdirde kişisel verilerinizi doğrulamanız gerekmektedir.</span><span></span>
            </li><li   ><span>Kişisel verilerinizde değişiklik veya güncelleme gerektiği takdirde ilgili kısımları güncellemeniz gerekmektedir. Aksi takdirde kayıtlı bilgilerin doğru olduğu kabul edilecektir.</span><span></span>
            </li><li   ><span>Başvuru süreçlerinde size iletilen kişisel verileri ilgisiz üçüncü kişilerle paylaşmayacağınızı ve/veya amaçları dışında işlemeyeceğinizi taahhüt edersiniz.</span><span></span>
            </li><li   ><span>Hesabınız size aittir. Bu nedenle güçlü ve güvenli bir şifre seçmeyi; şifrenizi güvende ve gizli tutmayı; hesabınızın herhangi bir kısmını başkasına vermemeyi ve bu kapsamda yasalara ve Sözleşme'ye uymayı kabul etmektesiniz. Ayrıca hesabınızın ele geçirilmesi veya elinizde olmayan sistemsel sebepler dışında hesabınız ile ilgili yapılan her türlü eylemden sorumlusunuz.</span><span></span>
            </li><li  ><span>Portalda herhangi bir kişiye ait bilgileri kullanarak taklit hesap açamazsınız.</span><span></span>
            </li><li  ><span>Özgeçmişiniz dâhil portalda girdiğiniz bilgiler, yanıltıcı, yanlış, eski işverenleriniz dâhil herhangi bir kişiyi kötüleyici, kişilerin temel hak ve özgürlüklerini ihlal edebilecek veya haksız rekabete yol açabilecek beyanlar içeremez.</span><span></span> </li><li  ><span>Portalda ve özgeçmişinizde, gerekmedikçe ve amacına uygun olmadıkça, ilgisiz üçüncü kişilerin, başta özel nitelikli kişisel veriler olmak üzere, kişisel verilerine yer veremezsiniz, herhangi bir tüzel veya gerçek kişi ile aranızdaki gizliliğe dair anlaşmaları ihlal edemezsiniz.</span><span></span> </li><li  ><span>Portalda 5237 sayılı Türk Ceza Kanunu kapsamında suç sayılabilecek hakaret, iftira, tehdit vb. nitelikteki içeriklere yer veremezsiniz.</span><span></span>
            </li><li  ><span>Hukuka aykırı veya suç teşkil eden eylemleri ve organizasyonları teşvik eden ve destekleyen içeriklere yer veremezsiniz.</span><span></span>
            </li><li  ><span>Kandırma, şaşırtma, haksız kazanç elde etme veya sair sebeplerle birden fazla hesap açamazsınız, hesabınızı başkalarına kendi yararları için veya üçüncü kişi yararına kullandıramazsınız.</span><span></span>
            </li><li  ><span>Üçüncü kişilerin marka ve telif hakkı gibi, fikri mülkiyet haklarını ihlal edecek nitelikte içeriklere yer veremezsiniz.</span><span></span>
            </li><li  ><span>Referans olarak gösterdiğiniz kişilerin verilerinin ilgili referans kişisinin bilgi ve rızasına uygun olarak paylaşıldığını ve referans sahibinin aksi yöndeki tüm talep ve iddialarının sorumluluğunun tarafınıza ait olduğunu kabul ve taahhüt etmeksiniz.</span><span></span> </li></ul>
          <p  ><span>&nbsp;</span></p>
          <p  ><b><span>VI. KİŞİSEL VERİLERİN İŞLENMESİ</span></b><span></span></p>
          <p  ><span>Hangi kişisel verilerinizi ne amaçlarla işlediğimize dair detaylı bilgilere Kişisel Verilerin Korunması Kanunu Çerçevesinde&nbsp;<b><a href="/Sayfa/Icerik/BilgilendirmeMetni" target="_blank">Bilgilendirme Metni</a></b>'nden ulaşabilirsiniz. Ancak zaman zaman bizimle paylaştığınız bilgiler, özel nitelikli kişisel verinizi içerebilmektedir. KVK Kanunu kapsamında, ırkınız, etnik kökeniniz, siyasi düşünceniz, felsefi görüşünüz, dini, mezhebi veya diğer inançlarınız, kılık ve kıyafetiniz, dernek, vakıf ya da sendika üyelikleriniz, sağlığınız, cinsel hayatınız, varsa mahkûmiyetiniz ve hakkınızdaki güvenlik tedbirleriyle ilgili verileriniz ile biyometrik ve genetik verileriniz sizin özel nitelikli verilerinizdir.</span><span></span></p>
          <p  ><b><span>6.1. Tarafımızca İşlenebilecek Özel Nitelikli Kişisel Verileriniz</span></b><span></span></p>
          <p  ><span>Tarafımızca, bizimle paylaşmanız veya gerekli olması halinde, işlemeye konu olabilecek özel nitelikli kişisel verileriniz aşağıdaki gibidir:</span><span></span></p>
          <ul type="disc">
            <li  ><span>Özgeçmişinizdeki "Kişisel Bilgiler" bölümü</span><span></span>
            </li><li  ><span>Askerlik durumunuzu belirtirken açıklama girmeniz istenmektedir. Burada vereceğiniz bilgiler siyasi ve felsefi görüşünüzü veya sağlık durumunuzu yansıtabilir (muafiyet sebepleri gibi).</span><span></span>
            </li><li  ><span>Kan grubu bilgileriniz, üye olunan topluluklar ve sigara kullanımı bilgileriniz.</span><span></span>
            </li><li  ><span>Hobi/ilgi alanları bölümüne yazacağınız bilgiler de paylaşmanız halinde özel nitelikli kişisel verileriniz bulunabilecektir.</span><span></span>
            </li><li  ><span>Hem sizin iş arayışınıza hem de işverenlerin çalışan arayışına yardımcı olabilmek amacıyla özgeçmişinizde yer alan bilgiler işverenlerle paylaşılmaktadır. İşverenler ise bunu kendi işe alım süreçlerinde değerlendirmek amacıyla işlemektedir.</span><span></span> </li><li  ><span>Özel nitelikli kişisel verileriniz, gerektiği hallerde savunma Bölgesel İstihdam Ofisleri olarak hakkımızı kullanabilmemiz için avukatlarımızla paylaşılabilir.</span><span></span>
            </li><li  ><span>Kişisel verileriniz, ilgili mevzuatta belirtilen veya işlendikleri amaç için gerekli olan azami süre ve her halde kanuni zamanaşımı süreleri kadar muhafaza edilecektir.</span><span></span>
            </li></ul>
          <p><b><span>6.2. Kişisel Verilerinizin Üçüncü Kişilere Aktarımı</span></b><span></span></p>
          <p><span>Kişisel verileriniz, aşağıda belirtilen durumlarda ve amaçlarda portföyümüzde yer alan firmalara belirli hizmetleri kullanabilmemiz için aktarılmaktadır.</span><span></span></p>
          <p><b><span>Kişisel verileriniz;</span></b><span></span></p>
          <p><span>Vatandaş, işbu Sözleşmeyi onaylayarak ve siteye özgeçmiş yükleyerek, mobil uygulamayı kullanarak kişisel ve/veya özel nitelikli kişisel verilerinin Bölgesel İstihdam Ofisleri Portalı tarafından işlenmesini peşinen kabul etmektedir. Vatandaş, kişisel verilerinin elde edilmesi aşamasında aşağıdaki bilgileri edindiğini peşinen kabul eder.</span><span></span></p>
          <ul type="disc">
            <li  ><span>Kişisel veri sorumlusu İBB’dir.</span><span></span>
            </li><li><span>Kişisel verilerin işlenme amacı; istihdam ve bilgilendirme faaliyetleridir.</span><span></span>
            </li><li><span>Kişisel ve/veya özel nitelikli kişisel veriler, istihdam amacıyla Bölgesel İstihdam Ofisleri Portalı’nı kullanan firma ve işverenlere, istatistiki amaçlarla Türkiye İş Kurumu’na ve kanunen üstlenilen yükümlülükler nedeni ile mahkeme ve resmi kurumlara aktarılmaktadır.</span><span></span> </li><li  ><span>Vatandaşa ait kişisel ve/veya özel nitelikli veriler, işbu Sözleşmeye dayanılarak özgeçmiş aracılığı ile ve/veya mobil uygulama aracılığı site üzerinden toplanmaktadır.</span><span></span>
            </li><li><span>Savunma hakkımızı kullanabilmek amacıyla özel nitelikli kişisel verileriniz de dahil olmak üzere bütün kişisel verilerinizi avukatlarımıza veya hukuki danışmanlarımıza aktarabiliriz.</span><span></span>
            </li><li><span>Bize kişisel verilerinizin aktarılmasına ilişkin usulüne uygun yasal bir talep geldiği takdirde, bu verilerinizi talep eden kuruma aktarabiliriz<b>.</b></span><span></span>
            </li></ul>
          <p><span>&nbsp;</span></p>
        </article>
      </div>
    </div>
  );
}