import moment from 'moment';
import { Icon } from 'components/UIComponents';
import { get } from 'lodash';

const InfoItem = ({ icon, text }) => {
  return (
    <div className="d-iflex a-center mr-40">
      <Icon name={icon} size={23} className="mr-10" />
      <p className="text-blue text-4xl fw-bold mb-0 ls-full">{text}</p>
    </div>
  );
};

const MainInfo = ({ candidate }) => {
  return (
    <div className="MainInfo">
      <InfoItem icon="cake" text={moment(get(candidate, 'birthDate'), 'YYYY-MM-DD').format('DD.MM.YYYY')} />
      <InfoItem icon="gender" text={get(candidate, 'genderType.description', '-')} />
      <InfoItem icon="military" text={get(candidate, 'militaryStatus.description', '-')} />
    </div>
  );
}

export default MainInfo;