import { Row, Col, Input, Button, Divider, Pagination, Empty, Form } from 'antd';
import { get } from 'lodash';
import SearchItem from "./SearchItem";

const Searchs = ({ data, refresh, onChangeFilter }) => {

  const [form] = Form.useForm();

  const itemRender = (current, type, originalElement) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />
    else
      return originalElement;
  }

  const onSubmit = (values) => {
    onChangeFilter && onChangeFilter('searches', values);
  };

  return (
    <div className="Searchs">
      <div className="d-flex">
        <Form form={form} onFinish={onSubmit} className="d-flex full-width">
          <Form.Item name="keyword" className="f-1">
            <Input className="form-input" placeholder="Kayıtlı Arama İsmini Yazınız" />
          </Form.Item>
          <Button type="primary" htmlType="submit" className="BtnSearch ls-full" icon={<img src="/static/icons/loupe.svg" alt="Bölgesel İstihdam Ofisleri" className="ml-10" />}>ARA</Button>
        </Form>
      </div>
      <p className="TotalText text-xl ls-half mt-30 pl-35">Toplam {get(data, 'total', 0)} Kayıtlı Arama Bulunmuştur</p>
      <Divider className="Divider grey" />
      {
        get(data, 'total', 0) !== 0 ?
          <>
            {
              get(data, 'results', []).map(x => (
                <>
                  <SearchItem data={x} refresh={refresh} />
                  <Divider className="Divider grey" />
                </>
              ))
            }
          </>
          :
          <Empty description="Veri bulunamadı" />
      }
      {
        get(data, 'total', 0) > 0 &&
        <Row>
          <Col span={24} className="d-flex j-center a-center">
            <Pagination
              current={get(data, 'page', 1)}
              onChange={(page, pageSize) => onChangeFilter('searches', page)}
              total={get(data, 'total', 0)}
              itemRender={itemRender} />
          </Col>
        </Row>
      }
    </div>
  );
};

export default Searchs;