import initialState from '../initialStates/lookupState';
import { set } from 'lodash';
import { 
  GLOBAL_REQUEST, 
  GLOBAL_SUCCESS, 
  GLOBAL_ERROR, 
  POST_DATA, 
  PUT_DATA, 
  DELETE_DATA,
  SET_STATE,
  LOGIN,
  USER_DATA_START,
  USER_DATA_SUCCESS,
  USER_DATA_ERROR,
  COMPANY_DATA_START,
  COMPANY_DATA_SUCCESS,
  COMPANY_DATA_ERROR,
  GET_COMPANY_WORK_FORCE,
  SUCCESS_COMPANY_WORK_FORCE,
  ERROR_COMPANY_WORK_FORCE,
  RESUME_DATA_START,
  RESUME_DATA_ERROR,
  RESUME_DATA_SUCCESS
} from '../actions/actionTypes';

const dataReducer = (state = initialState, action) => {
  const { key, data, error } = action.payload || {};
  let newState;

  switch (action.type) {
    case GLOBAL_REQUEST:
      newState = { ...state };
      if (key === 'me') {
        newState.lookups = {
          ...newState.lookups,
          me: {
            data,
            loading: true,
            error: null,
          },
        };
      } else {
        set(newState, key, { data, loading: true, error: null });
      }
      return newState;
      
    case GLOBAL_SUCCESS:
      newState = { ...state };
      set(newState, key, { data, loading: false, error: null });
      return newState;

    case GLOBAL_ERROR:
      newState = { ...state };
      set(newState, key, { data: null, loading: false, error });
      return newState;

    case POST_DATA:
    case PUT_DATA:
    case DELETE_DATA:
      return { ...state };

    case LOGIN:
      return { ...state, account: data };

    case SET_STATE:
      return { ...state, ...action.payload };

    case USER_DATA_START:
      return {
        ...state,
        pageLoading: true,
      };
    
    case USER_DATA_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        resumeData: action.payload,
      };
    
    case USER_DATA_ERROR:
      return {
        ...state,
        pageLoading: false,
        error: action.error,
      };
    
    case RESUME_DATA_START:
      return {
        ...state,
        pageLoading: true,
      };
    
    case RESUME_DATA_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        resumes: action.payload,
      };
    
    case RESUME_DATA_ERROR:
      return {
        ...state,
        pageLoading: false,
        error: action.error,
      };

    case COMPANY_DATA_START:
      return {
        ...state,
        pageLoading: true,
      };
    
    case COMPANY_DATA_SUCCESS:
      return {
        ...state,
        pageLoading: false,
        companyResult: action.payload,
      };
    
    case COMPANY_DATA_ERROR:
      return {
        ...state,
        pageLoading: false,
        error: action.error,
      };
    
    case GET_COMPANY_WORK_FORCE:
      return {
        ...state,
        pageLoading: true,
      };
    
    case SUCCESS_COMPANY_WORK_FORCE:
      return {
        ...state,
        pageLoading: false,
        companyWorkForce: action.payload,
      };
    
    case ERROR_COMPANY_WORK_FORCE:
      return {
        ...state,
        pageLoading: false,
        error: action.error,
      };  
      
  
    default:
      return { ...state };
  }
};

export default dataReducer;