import { useEffect, useState } from "react";
import {Spin, Descriptions, Upload, message, Button} from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Banner, Icon } from 'components/UIComponents';
import useGetNew from "hooks/useGetNew";
import { get } from "lodash";
import { postData } from "redux/services/request";
import config from 'plugins/ibb/config'
import CompanyStatus from "./CompanyStatus";
import {Helmet} from "react-helmet";
import {confirmMessage} from "../../../../../helpers/methods";
import WorkForceInput from "./WorkForceInput";
import { useDispatch, useSelector } from "react-redux";
import { loadCompanyData } from "redux/actions/companyActions";

function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
}

const Profile = () => {

  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(loadCompanyData());
    
  }, [dispatch]);
  const data = useSelector(state => state.data.companyResult);
  const pageLoading = useSelector(state => state.data.pageLoading);

  // const { data: logo, loading: loadingLogo } = useGet({ url: data && data.id && `/companies/${get(data, 'id.id')}/logo` });
  const [loadingUpload, setLoadingUpload] = useState(false );
  const [imageUrl, setImageUrl] = useState( false );

  useEffect(() => {
    data && setImageUrl(`${config.BASE_API_URL}${data?.result?.logoUri}`);
  }, [data]);

  /*
  const handleChange = async info => {
    setLoadingUpload(true);
    if (info.file.status === 'uploading') {
      const model = new FormData();
      model.append("image", info.file.originFileObj);

      try {
        await postData({ url: `/companies/${data?.result?.id?.id}/logo`, data: model })
        message.success("Şirket logosu güncellendi");
        // Get this url from response in real world.
        
        getBase64(info.file.originFileObj, img => {
            setLoadingUpload(false);
            setImageUrl(img);
          }
        );
      } catch (err) {
        message.error("Upload işlemi sırasında bir hata meydana geldi");
      } finally {
        setLoadingUpload(false);
      }
    }
  };
  
    <Upload
              name="avatar"
              className="avatar-uploader"
              showUploadList={false}
              beforeUpload={ null }
              onChange={ handleChange }
              multiple={false}
            >
              <div className="FirmImage">
                <PlusOutlined style={{ position: 'absolute' }} />
                <img
                  style={{ borderRadius: '50%' }}
                  width={200}
                  className="FirmImage"
                  src={ imageUrl || team }
                  alt="Bölgesel İstihdam Ofisleri"
                />
              </div>
            </Upload>
            */

  const companyType = get(data?.result, 'companyType')

  return (
    data?.data && data.data.code !== 200 ? (
      confirmMessage( data?.data?.message )
    ) : (
    <div className="Profile">
      <Helmet>
        <title>İş Ara İş Bul | Firma Profili</title>
        <meta name="description" content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
        <meta name="keywords" content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <Banner text="Firma Profili" className="mb-0" />
      <Spin spinning={pageLoading}>
        <div className="content md">
          <div className="ProfileHeader">
            <div className="FirmHeader">
              <h1 className="fw-bold">{get(data?.result, 'companyName')}</h1>
              <div className="d-flex a-center">
                <Icon size={18} name="pin" />
                <p className="text-xl mb-0 ml-10">{get(data?.result, 'county.city')} - {get(data?.result, 'county.county')}</p>
              </div>
            </div>
          </div>
          <Descriptions title="Şirket Bilgileri" className="mt-30 mb-30" bordered>
            <Descriptions.Item label="Vergi Numarası" span={2}>{ get(data?.result, 'taxNo', '-')}</Descriptions.Item>
            <Descriptions.Item label="Cep Telefonu" span={4}>{ get(data?.result, 'mobilePhoneNo', '-')}</Descriptions.Item>
            <Descriptions.Item label="Firma Durumu" span={2}><CompanyStatus status={data?.result && data?.result.companyStatus}/></Descriptions.Item>
            <Descriptions.Item label="Telefon" span={2}>{ get(data?.result, 'companyPhone')}</Descriptions.Item>
            <Descriptions.Item label="Sektör" span={2}>{ get(data?.result, 'sector.sector', '-')}</Descriptions.Item>
            <Descriptions.Item label="Email" span={2}>{ get(data?.result, 'email', '-')}</Descriptions.Item>
            <Descriptions.Item label="Firma Tipi" span={2}>{!companyType ? '-' : companyType === 'CORPORATE' ? 'Kurumsal' : 'Bireysel'}</Descriptions.Item>
            <Descriptions.Item label="Web Sitesi" span={4}>{data?.result && data?.result.companyWebsite ? <a href={get(data?.result, 'companyWebsite')}>{get(data, 'companyWebsite')}</a> : '-'}</Descriptions.Item>
            <Descriptions.Item label="Adres" span={4}>{ get(data?.result, 'address', '-') }</Descriptions.Item>
            <Descriptions.Item label="Yetkili Kişi" span={2}>{ get(data?.result, 'responsibleName', '-') || '-' }</Descriptions.Item>
            <Descriptions.Item label="Yetkili Unvanı" span={2}>{ get(data?.result, 'responsibleTitle', '-') || '-' }</Descriptions.Item>
            <Descriptions.Item label="İstanbul dışı çalışan sayısı" span={2}><WorkForceInput inputs={data?.result} name="companyWorkforceTr" defaultValueTr={data?.result?.companyWorkforceTr?.id?.id} defaultValue34={data?.result?.companyWorkforce34?.id?.id}/></Descriptions.Item>
            <Descriptions.Item label="İstanbul içi çalışan sayısı" span={2}><WorkForceInput inputs={data?.result} name="companyWorkforce34" defaultValueTr={data?.result?.companyWorkforceTr?.id?.id} defaultValue34={data?.result?.companyWorkforce34?.id?.id}/></Descriptions.Item>
          </Descriptions>
        </div>
      </Spin>
    </div>
  )
  )
};

export default Profile;
