import { Row, Col, Rate, Button, Form, Select } from "antd";
import useGet from "hooks/useGet";
import { useEffect, useState } from "react";

export default ({ item, remove, index,foreignLanguages }) => {
  const { data } = useGet({ url: "/languages", key: "languages", isArray: false });

  const [selectedForeignLanguage,setSelectedForeignLanguage] = useState('')
  const [filteredForeignLanguages,setFilteredForeignLanguages] = useState([]);



  useEffect(() => {
    if(item && !Object.keys(item).includes('languageId')){
      console.log('Burası')
      setFilteredForeignLanguages(data?.results.filter(el => !foreignLanguages.some((language) => language.languageId === el.id && el.id !== selectedForeignLanguage)));
    }else{
      setFilteredForeignLanguages(data?.results)
    }
  },[item,data,foreignLanguages,selectedForeignLanguage]);

  return (
    <div className="ForeignLanguage">
      <Row className="full-width" gutter={30} justify="space-between" align="middle">
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
          <Form.Item name="languageId" rules={[{ required: true, message:"Dil" }]}>
            {/* <LookupSelect url="/languages" valueKey="id" labelKey="name" className="form-select w-200" placeholder="Dil" /> */}
            <Select placeholder="Dil" className="form-select full-width" onChange={(value) => setSelectedForeignLanguage(value)}>
              {filteredForeignLanguages?.map((language,key) => (

              <Select.Option value={language?.id} key={key}>{language.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
          <p className="text-xl mb-10">Okuma</p>
          <Form.Item name="reading" rules={[{ required: true, message:"Derece Belirtiniz" }]}>
            <Rate
              className="text-4xl l-1"
              character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <g fill="currentColor">
                    <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                  </g>
                </g>
              </svg>}
            />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
          <p className="text-xl mb-10">Yazma</p>
          <Form.Item name="writing" rules={[{ required: true, message:"Derece Belirtiniz" }]}>
            <Rate
              className="text-4xl l-1"
              character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <g fill="currentColor">
                    <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                  </g>
                </g>
              </svg>} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
          <p className="text-xl mb-10">Konuşma</p>
          <Form.Item name="speaking" rules={[{ required: true, message:"Derece Belirtiniz" }]}>
            <Rate
              className="text-4xl l-1"
              character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <g fill="currentColor">
                    <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                  </g>
                </g>
              </svg>} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 5 }}>
          <p className="text-xl mb-10">Tercüme</p>
          <Form.Item name="translation" rules={[{ required: true, message:"Derece Belirtiniz" }]}>
            <Rate
              className="text-4xl l-1"
              character={<svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
                <g fill="none" fillRule="evenodd">
                  <g fill="currentColor">
                    <path d="M622 3194h20c1.105 0 2 .895 2 2v20c0 1.105-.895 2-2 2h-20c-1.105 0-2-.895-2-2v-20c0-1.105.895-2 2-2z" transform="translate(-620 -3194)" />
                  </g>
                </g>
              </svg>} />
          </Form.Item>
        </Col>
        <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 2 }} className="mb-20">
          <Button className="btn btn-blue-filled full-width text-md" onClick={() => remove(index)}>Sil</Button>
        </Col>
      </Row>
    </div>
  );
}
