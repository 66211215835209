import {Button, Col, Form, InputNumber, Row} from "antd";

const formRules = {
    convictionAge: {required: true, type:'number', min:1, max:99, message:"Yaş Giriniz"},
    convictionDuration: {required: true, type:'number', min:1, max:99, message:"Süre Giriniz"},
}

export default ({remove}) => {

    return (
        <Row className="ComputerSkill mb-20" align="middle">
            <Col span={16}>
                <Form.Item name="age" className="mt-10" rules={[formRules.convictionAge]}>
                    <InputNumber type={'number'} className="form-input full-width" placeholder="Yaş"/>
                </Form.Item>
            </Col>
            <Col span={4}>
                <Button className="btn btn-blue-filled full-width text-md ml-10" onClick={remove}>Sil</Button>
            </Col>
            <Form.Item name="duration" className="mt-10 mb-10" rules={[formRules.convictionDuration]}>
                <InputNumber className="form-input full-width" placeholder="Süre (Yıl)"/>
            </Form.Item>
        </Row>
    );
};