import Api from 'api/Api';
import FileSaver from 'file-saver';
import { message } from 'antd';
import {
  DELETE_DATA,
  GLOBAL_ERROR,
  GLOBAL_REQUEST,
  GLOBAL_SUCCESS,
  LOGIN,
  POST_DATA,
  PUT_DATA,
  SET_STATE,
  GET_COMPANY_WORK_FORCE,
  SUCCESS_COMPANY_WORK_FORCE,
  ERROR_COMPANY_WORK_FORCE
} from './actionTypes';
import { dataLayerPush } from 'helpers/methods';

export default {
    getData: ({ url, key, params }) => {
        return async dispatch => {
          key && dispatch({ type: GLOBAL_REQUEST, payload: { key } });
          try {
            if (url) {
              const { result } = await new Api().getData({ url, params });
              key &&
                result &&
                dispatch({ type: GLOBAL_SUCCESS, payload: { key, data: result } });
              return result;
            }
          } catch (error) {
            key && error && dispatch({ type: GLOBAL_ERROR, payload: { key, error } });
            throw error;
          }
        };
      },
    getDataNew: ({url, key, params}) => {
      return async dispatch => {
        key && dispatch({type: GLOBAL_REQUEST, payload: {key}});
        try {
          if (url) {
            const result = await new Api().getData({url, params});
            result && dispatch({type: GLOBAL_SUCCESS, payload: {key, data: result}});
            return result;
          }
        } catch (error) {
          console.log('istek gerçekleşirken bir hata oluştu');
          key && error && dispatch({type: GLOBAL_ERROR, payload: {key, error}});
          throw error;
        }
      }
    },
  
    getFile: ({url, key, params, fileName}) => {
      return async dispatch => {
        key && dispatch({type: GLOBAL_REQUEST, payload: {key}});
        try {
          const {data} = await new Api().getFile({url, params});
          FileSaver.saveAs(new Blob([data], {type: data.type}), fileName);
          return data;
        } catch (error) {
          if (error.status === 401) {
            //OAuth.logout();
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          } else {
            message.error('İstek gerçekleşirken bir hata oluştu');
          }
          key && error && dispatch({type: GLOBAL_ERROR, payload: {key, error}});
          throw error;
        }
      }
    },
    postData: ({url, data, params}) => {
      return async dispatch => {
        try {
          const {result} = await new Api().postData({url, _data: data, params});
          dispatch({type: POST_DATA, payload: result});
          return result;
        } catch (error) {
          throw error;
        }
      }
    },
    postData2: ({url, data, params}) => {
      return async dispatch => {
        try {
          const result = await new Api().postData({ url, _data: data, params });
          dispatch({ type: POST_DATA, payload: result });
          return result;
        } catch (error) {
          message.error( error.data.message )
  
          if (error.status === 401) {
            //OAuth.logout();
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          }
          throw error;
        }
      }
    },
  
    postNewLogin: ({url, data, params}) => {
      return async dispatch => {
        try {
          const res = await new Api().postData({ url , _data: data, params });
          dispatch({type: POST_DATA, payload: res });
          return res;
  
        } catch (error) {
          message.error( error.data.message )
  
          if (error.status === 401) {
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          }
  
          throw error;
        }
      }
    },
  
    putNew: ({url, data, params}) => {
      return async dispatch => {
        try {
          const res = await new Api().putData({ url , _data: data, params });
          dispatch({type: PUT_DATA, payload: res });
          return res;
  
        } catch (error) {
          message.error( error.data.message )
  
          if (error.status === 401) {
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          } else {
            return
          }
  
          throw error;
        }
      }
    },
  
    putData: ({url, data, params}) => {
      return async dispatch => {
        try {
          const {result} = await new Api().putData({url, _data: data, params});
  
          dispatch({type: PUT_DATA, payload: result});
          return result;
  
        } catch (error) {
          message.error( error.message )
  
          if (error.status === 401) {
            //OAuth.logout();
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          } else {
            message.error('İstek gerçekleşirken bir hata oluştu');
          }
          throw error.response;
        }
      }
    },
  
    deleteData: ({url, data, params}) => {
      return async dispatch => {
        try {
          const {result} = await new Api().deleteData({url, _data: data, params});
          dispatch({type: DELETE_DATA, payload: result});
          return result;
        } catch (err) {
          if (err.status === 401) {
            //OAuth.logout();
            localStorage.clear();
            message.error('Lütfen üye girişi yapınız');
          } else {
            message.error('İstek gerçekleşirken bir hata oluştu');
          }
          throw err.response;
        }
      }
    },
    login: () => {
      return async dispatch => {
        dispatch({type: LOGIN, payload: {data: {loading: true}}});
        try {
          let token = localStorage.getItem('ibb-bio_at');
          token = token && token.substring(1, token.length - 1);
  
          const userType = localStorage.getItem('type');
          let model = {token};
  
          if (userType) {
            model = {token, userType};
          } else {
            model = {token};
          }
          const res = await new Api().postData({url: '/auth/register/finish', _data: model});
  
          if (res.code && res.code === 3030) {
            dispatch({type: LOGIN, payload: {data: {code: 3030, status: 'APPROVAL'}}});
            return {...res};
          }
  
          if(res.result.userType === 'CITIZEN'){
            dataLayerPush({
              'event' : "uyeGiris",
              'result' : "uyeGirisBasarili"
            })
          }else if(res.result.userType === 'CORPORATE'){
            dataLayerPush({
              'event' : "isVerenGiris",
              'result' : "isVerenGirisBasarili"
            })
          }
  
  
          dispatch({type: LOGIN, payload: {data: res.result}});
          return {...res.result};
        } catch (error) {
          if (error.data && error.data.code === 3020) {
            message.error('Gelir İdaresi Başkanlığı üzerinden şirket bilgilerinize erişilemediği için iş veren olarak erişim sağlayamamaktasınız.')
            //setTimeout( () => OAuth.logout().then(() => localStorage.clear()), 3000)
          }
  
          const hasTokenError = error.data && error.data.code === 101
          const isCompany = JSON.parse(localStorage.getItem('ibb-bio_ad')).subject.length === 10
  
          if (hasTokenError &&  isCompany) {
            message.error('Portala işveren olarak giriş sağlayabilirsiniz.')
            //setTimeout( () => OAuth.logout().then(() => localStorage.clear()), 3000)
          }
  
          dispatch({type: LOGIN, payload: {data: {loading: false}}});
          // await OAuth.logout().then(() => {
          //   if (err.data.code !== 40400) {
          //     message.error('Üye için üye tarafından, firma için firma tarafından giriş yapıldığına emin olunuz');
          //   } else {
          //     message.error('API Hatası. Lütfen daha sonra tekrar deneyiniz');
          //   }
          //   localStorage.clear();
          // });
          throw error;
        }
      }
    },
    setState: (payload) => {
      return dispatch => {
        dispatch({type: SET_STATE, payload});
      }
    },
    getCompanyWorkForce: ({ url, key, params }) => {
      return async dispatch => {
        key && dispatch({ type: GET_COMPANY_WORK_FORCE, payload: { key } });
        try {
          if (url) {
            const { result } = await new Api().getData({ url, params });
            key &&
              result &&
              dispatch({ type: SUCCESS_COMPANY_WORK_FORCE, payload: { key, data: result } });
            return result;
          }
        } catch (error) {
          key && error && dispatch({ type: ERROR_COMPANY_WORK_FORCE, payload: { key, error } });
          throw error;
        }
      };
    },
    

}
