import { Input, Checkbox, message, Alert, Button } from "antd";
import { Link } from "react-router-dom";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import {useState} from "react";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import {useHistory} from "react-router-dom";
import {postNewLogin, updateState} from "../../redux/services/request";
import {dataLayerPush, selectRoute} from "../../helpers/methods";
import axios from "axios";
import ModalRegisterCorporatePassing from './Modals/ModalRegisterCorporatePassing'

export default function LoginCorporate() {
  const [loading, setLoading] = useState(false );
  const [ visibleModalRegisterInfo, setVisibleModalRegisterInfo] = useState(false);
  const history = useHistory();

  const handleSubmit = async data => {
    setLoading(true);
    const newData = {
      ...data,
      userType: 'CORPORATE'
    }

    try {
      updateState({ loginInit : {data: null, loading: true }});
      const response = await postNewLogin({ url: "/auth/login", data: newData });

      if( response?.code !== 200 ){

          if( response?.code === 316 ){
            message.warn('Şirket hesabınız ile kişisel bir hesabın bağlı olması gerekiyor. Bağlamak için yönlendiriliyorsunuz. Anlayışınız için teşekkür ederiz.')
            history.push({
              pathname: '/register-corporate',
              state: {
                taxNoOrIdNo : data.idNo,
                password: data.password,
                notification: true
              }
            });
            return;
          }

          message.error( response?.message )
          setLoading( false )
          return;
      }

      const route = selectRoute( response?.result?.routeTo, response?.result?.stateId, response?.result?.uid, '/login-corporate' );

        // local pushes
        localStorage.setItem( 'accessToken', response?.result?.accessToken );
        localStorage.setItem("type", response?.result?.userType );
        localStorage.setItem("fullName", response?.result?.fullName );
        localStorage.setItem("companyName", response?.result?.selectedCompany?.name );

        // datalayer
          dataLayerPush({
            'event' : "isVerenGiris",
            'result' : "isVerenGirisBasarili"
          })

        // bearer token
        axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem( 'accessToken' )}`;
        localStorage.getItem('type') && (
         axios.defaults.headers.common[ 'userType' ] = localStorage.getItem( 'type' )
       );

      if( response?.result?.routeTo !== 'PROFILE_UPDATE' ){
        message.success( `Giriş bilgileri başarılı teşekkür ederiz ${ response?.result?.fullName }.` );
      }

        updateState( { loginInit : { data: response, loading: false }});
        setLoading( false );
        history.push( route );

    } catch {
      setLoading(false);
    }
  }

  const initialValues = {
    idNo: "",
    password: ""
 }

  return (
    <div>
      <AuthLayoutForm
        formName="login-corporate"
        formTitle="İşveren Girişi"
        button="GİRİŞ YAP"
        onFinish={ handleSubmit }
        initialValues={ initialValues }
        redirect={ true }
        redirectQuestion="Üyeliğiniz yok mu ?"
        redirectUrl="/register-corporate"
        redirectButton="Kayıt Ol"
        loading={ loading }
        seoDescription="Bölgesel İstihdam Ofisi İşveren Giriş"
        seoTitle="Bölgesel İstihdam Ofisi İşveren Giriş"
        seoKeywords="İşveren, İşveren Girişi, Üye İşveren Girişi"
      >
        <Alert
           message="Önemli Bilgilendirme"
           showIcon
           description="Sayın kullanıcımız; Yeni sistemimizde VKN. ile giriş yapamamaktasınız. Lütfen bilgilerinizi güncelleyiniz. Eğer T.C kimlik numarası le kaydınız mevcut ise bu uyarıyı dikkate almayınız."
           type="warning"
           action={
          <Button type="button" size="small" style={{ display:'block', width:'100%', color: 'black'}} onClick={() => setVisibleModalRegisterInfo(true)}>
              Güncelle
          </Button>
      }
    />
       <AuthLayoutInput
        name="idNo"
        label="T.C. Kimlik No:"
        input = {<Input className="auth-form-input" maxLength={ 11 } />}
       />
       <AuthLayoutInput
         name="password"
         label="Şifre:"
         input={ <Input.Password className="auth-form-input" /> }
       />
       <AuthLayoutInput
      input={
        <>
        <Checkbox>Beni bu bilgisayarda hatırla</Checkbox>
        <Link
          style={{ color:"#F3590f", textDecoration:"underline", fontWeight:"bold" }}
          to={{ pathname:"/forgot-password", state: { from: 'CORPORATE' }}}>
          Şifremi Unuttum
        </Link>
        </>
      }/>
      </AuthLayoutForm>
      <ModalRegisterCorporatePassing visible={visibleModalRegisterInfo} setVisible={setVisibleModalRegisterInfo} />
    </div>
  );
}
