import { getEmbedMapIstanbul } from "./HaritaIstanbulRoute";

const HaritaIstanbul = ({ data }) => {
  return (
    <iframe
      src={`${getEmbedMapIstanbul(data)}`}
      width="100%"
      height="450"
      title={data?.officeName ?? "Harita İstanbul"}
      allowFullScreen="true"
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
    />
  );
};

export default HaritaIstanbul;
