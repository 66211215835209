import { Modal, Button, message } from 'antd';
import { get } from 'lodash';
import { useState } from 'react';
import { putData } from 'redux/services/request';
import Api from "../../../../../../../../../api/Api";

const NotSuitable = ({ data, visible, setVisible, applicationId, refresh }) => {
  const [loading, setLoading] = useState(false);

  const setNotEligible = async () => {
    try {
      await new Api().putData({url: `/applications/${applicationId}/ineligible`});
      message.success('Aday "UYGUN DEĞİL" olarak işaretlendi');
      refresh()
    } catch (err) {
      message.error('Aday bilgileri güncellenirken bir hata meydana geldi.');
    } finally {
      setLoading(false);
      setVisible(false);
    }
  }

  return (
    <Modal className="NotSuitableModal" open={visible} width={500} onCancel={() => setVisible(false)} footer={null} closeIcon={<div />}>
      <h1 className="mb-20 text-4xl">
        <span className="text-blue mr-5 fw-bold">{get(data, 'firstName', '-')} {get(data, 'lastName', '-')}</span>
        isimli aday <span className="fw-bold mr-5">"UYGUN DEĞİLDİR"</span> olarak güncellensin mi?
      </h1>
      <div className="d-flex j-center mt-30">
        <Button className="ModalBtn btn-blue" onClick={() => setVisible(false)}>İptal</Button>
        <Button className="ModalBtn btn-pink-filled" onClick={setNotEligible} loading={loading}>Evet</Button>
      </div>
    </Modal>
  );
};

export default NotSuitable;
