import {Button, Col, Form, Input, Row as AntRow, Row, Upload} from "antd";
import {LookupSelect} from "components/UIComponents/FormElements";
import {InboxOutlined} from '@ant-design/icons';
import {useEffect, useState} from "react";
import Api from "../../../../../../../api/Api";
import fileDownload from 'js-file-download';
import { DeleteOutlined, DownloadOutlined } from '@ant-design/icons';

export default ({index, remove, fileId, fileName, reload}) => {
  const [file, setFile] = useState()

  useEffect(() => {
    async function fetch() {
      if (fileName) {
        const _file = await new Api().getFile({ url: `candidates/resumes/attachments/${fileId}` })
        setFile(_file)
      }
    }

    fetch();
  }, [fileName, fileId]);

  const download = () => fileDownload(file.data, fileName)

  const deleteFile = async () => {
    await new Api().deleteData({url: `candidates/resumes/attachments/${fileId}`})
    remove(index);
    reload();
  }

  return (
      <Row className="Document mb-30" justify="space-between">
        <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
          <Form.Item name="fileType" rules={[{required: true, message:"Tip"}]}>
            <LookupSelect url="/candidates/resumes/attachments/file-types" labelKey="label" valueKey="name"
                          placeholder="Dosya Tipi (Zorunlu)" className="mb-20"/>
          </Form.Item>
          <Form.Item name="description">
            <Input.TextArea placeholder="Açıklama" className="form-input mb-20"/>
          </Form.Item>
        </Col>
        {!file && <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}}>
          <Form.Item name="file" rules={[{required: true, message:"Dosya Yükleyiniz"}]}>
            <Upload.Dragger beforeUpload={false} height={130} className="mb-20">
              <p className="ant-upload-drag-icon">
                <InboxOutlined max={1}/>
              </p>
              <p className="ant-upload-text">Dosyalarınızı seçmek için tıklayın ya da buraya sürükleyin</p>
            </Upload.Dragger>
          </Form.Item>
          <Button className="btn btn-blue-filled full-width text-md" onClick={() => remove(index)}>Sil</Button>
        </Col>}
        {file && <Col xs={{span: 24}} sm={{span: 24}} md={{span: 10}} className={'full-width  text-start'}>
          <Row className='full-width d-flex '>
            <Col><a className='mr-10 '  onClick={download}>{fileName}</a></Col>
            <Col>
              <DownloadOutlined  className='mr-10 '  onClick={() => download()} />
            </Col>
            <Col>
              <DeleteOutlined onClick={() => deleteFile()} />
            </Col>
          </Row>
        </Col>}
      </Row>
  );
};
