import { Helmet } from "react-helmet";

const BrowserHelmet = ({ description, keywords, title }) => {
  return(
    <Helmet>
      <title>{ title }</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={ keywords }/>
    </Helmet>
  )
}

export default BrowserHelmet;
