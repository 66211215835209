import {Select} from "antd";
import Icon from "components/UIComponents/Icon";
import useGet from "hooks/useGet";
import {useEffect, useState} from "react";

export default ({url, mode, placeholder, labelKey = 'label', valueKey = 'name', onChange, className, stateKey, suffixIcon, children, value, staticLoading, labelSeperator, disabled, onChangeObject, dataContainerKey, queryByApi, location, setLocation ,filterIds}) => {
//console.log("filterIds",filterIds)
  const [keyword, setKeyword] = useState('');
  let {data, loading} = useGet({
    url: keyword ? `${url}?q=${keyword}` : url,
    key: stateKey ? `lookups.${stateKey}` : '',
    dataContainerKey: dataContainerKey,
    loadOnce: true
  });
  const [val, setVal] = useState(undefined);
  const [labelKeys, setLabelKeys] = useState(null);

  
  useEffect(() => {
    if(value && data && isDataContainsValue()) {
      setVal(value);
    }
  }, [value,data]);

  useEffect(() => {
    Array.isArray(labelKey) && setLabelKeys(labelKey)
  }, [labelKey]);

  const onChangeValue = (_value) => {
    setVal(_value);
    onChange && onChange(_value);
    if (onChangeObject) {
      const item = data.find(x => x[valueKey] === _value);
      onChangeObject(item);
    }
  }

  useEffect( () => {
    if( setLocation && val !== undefined && data ) {
      val === 'FACE_TO_FACE' ? setLocation( true ) : setLocation( false )
    }
  },[ val ])

  const filterOption = (input, option) => {
    return option && option.children && option.children.replace(/İ/gim, "i").replace(/I/gim, "ı").replace(/Ğ/gim, "ğ").replace(/Ç/gim, "ç").replace(/Ş/gim, "ş").replace(/Ü/gim, "ü").replace(/Ö/gim, "ö")
        .toString().toLowerCase().includes(input.replace(/İ/gim, "i").replace(/I/gim, "ı").replace(/Ğ/gim, "ğ").replace(/Ç/gim, "ç").replace(/Ş/gim, "ş").replace(/Ü/gim, "ü").replace(/Ö/gim, "ö").toLowerCase());
  };

  const isDataContainsValue = () => data &&  (mode === 'multiple' || !!data.find(item => item[valueKey] === value))

  useEffect(() => {
    if(filterIds && data) {
      data=data.filter(item => !filterIds.includes(item.id));
    }
  }, [filterIds,data]);
  // console.log("value",value)
  // console.log("data",data)
  return (
      <Select
          showSearch
          showArrow
          allowClear
          mode={mode}
          value={isDataContainsValue() ?  val : null}
          disabled={disabled}
          open={loading ? false : undefined}
          loading={staticLoading ? staticLoading : loading}
          onChange={onChangeValue}
          placeholder={placeholder}
          filterOption={filterOption}
          filterSort={(optionA, optionB) =>
              optionA.children?.toLowerCase().localeCompare(optionB.children?.toLowerCase())
          }
          onKeyUp={event => {
            queryByApi && setKeyword(event.target.value)
          }}
          className={`form-select full-width ${className ? className : ''}`}
          suffixIcon={loading ? undefined : (suffixIcon ? suffixIcon : <Icon name="arrow-down" size={23}/>)}
      >
        {data && data.map((x, i) => (

         (labelKeys ?  labelKeys.map(label => x[label]).join(` ${labelSeperator} `)  : x[labelKey])     !== 'Belirtilmemiş'     ?
            <Select.Option key={i}  value={x[valueKey]}>
              {
                 (labelKeys ?  labelKeys.map(label => x[label]).join(` ${labelSeperator} `)  : x[labelKey])
              }
            </Select.Option>
            :
            null
        ))}
        {children}
      </Select>
  );
}

