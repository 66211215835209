import { Row, Col, Input, DatePicker, Button, Divider, Pagination, Empty, Form } from 'antd';
import { Icon } from 'components/UIComponents';
import { get } from 'lodash';
import Job from "../Job";

const Applications = ({ data, onChangeFilter, clearFilters, onChangePage }) => {
  const [form] = Form.useForm();

  const clear = () => {
    form.resetFields();
    clearFilters('favorites');
  }

  const itemRender = (current, type, originalElement, clear) => {
    if (type === 'prev')
      return <img src="/static/icons/pagination-left.svg" alt="Bölgesel İstihdam Ofisleri" />
    else if (type === 'next')
      return <img src="/static/icons/pagination-right.svg" alt="Bölgesel İstihdam Ofisleri" />
    else
      return originalElement;
  }

  return (
    <div className="Applications">
      <div className="d-flex">
        <Form form={form} onFinish={data => onChangeFilter('favorites', data)} className="CandidateJobFilter d-flex j-between full-width">
        <Form.Item name="advertisementNo" className="f-1">
            <Input className="form-input" placeholder="İlan Numarası" />
          </Form.Item>
          <Form.Item name="companyName" className="f-1">
            <Input className="form-input" placeholder="Firma Adı" />
          </Form.Item>
          <Form.Item name="positionTitle" className="f-1">
            <Input className="form-input" placeholder="Pozisyon" />
          </Form.Item>
          <Form.Item name="releaseDate" className="f-1">
            <DatePicker className="form-input full-width" placeholder="Tarih" suffixIcon={<Icon name="double-arrow" size={30} />} format="DD/MM/YYYY" />
          </Form.Item>
          <Button type="default" className="BtnFilter Blue" onClick={clear}>Temizle</Button>
          <Button type="primary" className="BtnFilter Pink" htmlType="submit">ARA</Button>
        </Form>
      </div>
      <Divider className="mt-40" />
      {
        get(data, 'results.length', 0) > 0 ?
          get(data, 'results', []).map(x => (
            <Job key={x.id} job={x} />
          ))
          :
          <Empty description="Veri Bulunamadı" />
      }
      {get(data, 'results.length', 0) > 0 &&
        <Row>
          <Col span={24} className="d-flex j-center">
            <Pagination
              current={get(data, 'page')}
              onChange={(page, pageSize) => onChangeFilter('recommended', page)}
              total={get(data, 'total')}
              itemRender={itemRender} />
          </Col>
        </Row>}
    </div>
  );
};

export default Applications;