import React, { useEffect, useState } from "react";
import "./style.less";
import { Table, Button, message, Spin } from "antd";
import { UsergroupAddOutlined } from "@ant-design/icons";
import UserLayout from "../../userLayout";
import useGet from "../../../../../hooks/useGet";
import AddStaff from "./AddStaff";
import { getDataNew, putNew } from "../../../../../redux/services/request";
import {
  checkMaster,
  confirmMessage,
  handleSetMasterButton,
  manageUserTransition,
} from "../../../../../helpers/methods";
import { useHistory } from "react-router-dom";
import useGetNew from "../../../../../hooks/useGetNew";
import { useSelector } from "react-redux";

const columns = [
  {
    title: "Yetki Devretme",
    dataIndex: "master",
    key: "master",
  },
  {
    title: "TCKN",
    dataIndex: "id",
    key: "id",
  },
  {
    title: "Mail Adresi",
    dataIndex: "email",
    key: "email",
  },
  {
    title: "Ad Soyad",
    dataIndex: "name",
    key: "name",
  },
  {
    title: "Cep Telefonu",
    dataIndex: "phone",
    key: "phone",
  },
  {
    title: "Ünvan",
    dataIndex: "responsibleTitle",
    key: "responsibleTitle",
  },
  {
    title: "Durum",
    dataIndex: "status",
    key: "status",
  },
];

export default () => {
  //const myCompany = useSelector((state) => state.data.companyResult);

  const { data: company, loading: companyLoading } = useGet({
    url: "/companies/me",
    key: "companiesMe",
    isArray: false,
  });

  //const companyLoading = useSelector((state) => state.data.pageLoading);

  const { data: user, loading: userLoading } = useGetNew({
    url: "/candidates/me",
    isArray: false,
  });

  console.log("User", user);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [userList, setUserList] = useState(null);
  const [status, setStatus] = useState(false);
  const [errorResponse, setErrorResponse] = useState(null);
  const [col, setCol] = useState(columns);
  const [master, setMaster] = useState(false);
  const history = useHistory();

  console.log(col);

  const showModal = () => {
    setIsModalVisible(true);
  };

  useEffect(() => {
    if (user?.result?.firstName && company?.responsibleName)
      setMaster(checkMaster(user?.result?.firstName, company?.responsibleName));
  }, [company, user]);

  const setUser = async (e, id, action, statusId) => {
    let response;
    e.preventDefault();
    setStatus(true);

    try {
      action === "master"
        ? (response = await putNew({
            url: `/companies/staff/${id}/${action}`,
            data: null,
          }))
        : (response = await putNew({
            url: `/companies/staff/${id}/status/${action}`,
            data: null,
          }));

      if (response?.code !== 200) {
        message.error(response.message);
        setStatus(false);
        return;
      }

      setStatus(false);
      action === "delete"
        ? message.success(`çalışan başarıyla silindi.`)
        : message.success(`çalışan ${statusId} duruma getirildi.`);

      history.go([0]);
    } catch (e) {
      setStatus(false);
    }
  };

  useEffect(() => {
    (async () => {
      const getList = await getDataNew({
        url: "/companies/staff",
        key: "staff",
      });

      if (getList.code !== 200) {
        setErrorResponse(getList);
        return;
      }

      const list = getList?.result?.results?.map((staff, index) => {
        if (master) {
          setCol([
            ...columns,
            {
              title: "Durum değişikliği",
              dataIndex: "changeStatus",
              key: "changeStatus",
            },
          ]);

          return {
            key: index,
            master: handleSetMasterButton(
              master,
              staff.master,
              staff.status.id,
              setUser,
              staff.id
            ),
            id: staff.idNo,
            email: staff.email,
            name: `${staff.firstName} ${staff.lastName}`,
            phone: staff.phone,
            status: staff.status.description,
            responsibleTitle: staff.responsibleTitle,
            // to manage user transitions we are using this function ( update, delete, approve, reject, passive, active )
            changeStatus: !master
              ? null
              : !staff.master &&
                manageUserTransition(
                  staff.status.description,
                  staff.id,
                  setUser,
                  companyLoading
                ),
          };
        }

        setCol(columns);

        return {
          key: index,
          master: handleSetMasterButton(
            master,
            staff.master,
            staff.status.id,
            setUser,
            staff.id
          ),
          id: staff.idNo,
          email: staff.email,
          name: `${staff.firstName} ${staff.lastName}`,
          phone: staff.phone,
          responsibleTitle: staff.responsibleTitle,
          status: staff.status.description,
        };
      });

      setUserList(list);
    })();
  }, [status, master]);

  return errorResponse !== null && errorResponse?.code !== 200 ? (
    confirmMessage(errorResponse?.message)
  ) : (
    <UserLayout
      countyName={company?.county?.county}
      cityName={company?.county?.city}
      bannerText="Firma Çalışanları"
      wrapperClass="container"
      title="Firma Çalışanları"
      company={true}
      companyName={company?.companyName}
    >
      {master && (
        <div className="buttonWrapper">
          <Button
            type="primary"
            icon={<UsergroupAddOutlined />}
            shape="rounded"
            block={false}
            loading={companyLoading}
            className="addPersonalButton"
            onClick={showModal}
          >
            Çalışan Ekle
          </Button>
        </div>
      )}
      <AddStaff
        visible={isModalVisible}
        setVisible={setIsModalVisible}
        history={history}
        setStatus={setStatus}
      />
      <Spin spinning={status}>
        <Table
          pagination={false}
          style={{ marginBottom: 50 }}
          dataSource={userList}
          columns={col}
        />
      </Spin>
    </UserLayout>
  );
};
