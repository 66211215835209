import { Row, Col, Form, Input, Button, message, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { getData } from "../../../../../../redux/services/request";
import { useSelector } from "react-redux";
import Api from "../../../../../../api/Api";

const formRules = {
  email: { type: "email", required: true },
  phoneNo: [
    {
      message: "Telefon numaranızı doğru girdiğinizden emin olunuz.",
    },
    {
      pattern: /^0/,
      message: "Telefon numaranızı 0 (sıfır) ile başlayacak şekilde giriniz",
    },
    {
      pattern: /^\d{11}$/,
      message: "Telefon numaranız tam 11 hane olmalıdır",
    },
    {
      pattern: /^\d+$/,
      message: "Sadece rakam girebilirsiniz",
    },
  ],
};

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const resume = useSelector((state) =>
    state.data.resumeS2 ? state.data.resumeS2.data : undefined
  );
  const meData = useSelector((state) => state.data.resumeData);

  //console.log(form)

  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const pattern = /0[0-9]{10}/g;

  const onFinish = () => {
    setLoading(true);
    const request = form.getFieldsValue();

    // if(pattern.exec(request.phoneNo) == null) {
    //   message.error('Telefon numaranızı 0 (sıfır) ile başlayacak şekilde giriniz')
    //   setLoading(false)
    //   return
    // }

    new Api()
      .putData({ url: "/candidates/resumes/communications", _data: request })
      .then(() => {
        getData({ url: "candidates/resumes/S2", key: "resumeS2" });
        refresh();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch((err) => {
        message.error(`${err.data.message}`);
        setLoading(false);
      });
  };

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S2", key: "resumeS2" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail) {
      // setCity(resume.detail.cityId)
      form.setFieldsValue(resume.detail);
    }
  }, [resume, form]);

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  return (
    <div className="General">
      <Spin spinning={pageLoading}>
        <h1 className="fw-bold ls-full text-4xl text-black mb-30">
          İletişim Bilgileri
        </h1>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <Row justify="space-between" gutter={50}>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <h1 className="text-xl text-black ls-half fw-bold mb-20">
                Mail Adresi <span className="text-orange-bio">(*)</span>
              </h1>
              <Form.Item name="email" rules={[formRules.email]}>
                <Input
                  className="form-input"
                  placeholder={meData?.result?.email}
                  value={meData && meData?.result?.email}
                />
              </Form.Item>
              <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                Cep Telefonu
              </h1>
              <Form.Item name="phoneNo" rules={formRules.phoneNo}>
                <Input
                  className="form-input"
                  placeholder={meData?.result?.phoneNo}
                  value={meData?.result?.phoneNo}
                  disabled
                />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }}>
              <h1 className="text-xl text-black ls-half fw-bold mb-20">
                Ev Telefonu
              </h1>
              <Form.Item name="landPhone" rules={formRules.phoneNo}>
                <Input className="form-input" placeholder="02** *** ** **" />
              </Form.Item>
              <h1 className="text-xl text-black ls-half fw-bold mt-40 mb-20">
                Diğer Telefon
              </h1>
              <Form.Item name="phoneNo2" rules={formRules.phoneNo}>
                <Input className="form-input" placeholder="05** *** ** **" />
              </Form.Item>
            </Col>
          </Row>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};
