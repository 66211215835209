
import { useState } from 'react';
import { Row, Col, Form, Input, DatePicker, Button, Modal, message } from 'antd';
import { LookupSelect } from 'components/UIComponents/FormElements';
import { Icon } from 'components/UIComponents';
import { postData, postData2 } from 'redux/services/request';
import { get } from 'lodash';
import { useParams } from 'react-router-dom';

const Invite = ({ data, visible, setVisible,applicationId }) => {
  const params = useParams();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [ faceToFace, setFaceToFace ] = useState( false );


  const invite = async (data) => {
    setLoading(true);
    data.applicationId = applicationId;
    try {
      const response = await postData2({url: '/interviews', data});
      if(response.code === 200){
        message.success('Aday mülakata davet edildi');
      }else{
        message.info(response.debugMessage);
      }
      setVisible(false);
    } catch (err) {
      message.error('Aday görüşmeye davet edilirken bir hata meydana geldi');
    } finally {
      setLoading(false);
    }
  }

  return (
    <Modal className="InviteModal" visible={visible} width={800} onCancel={() => setVisible(false)} footer={null} closeIcon={<img src="/static/icons/cancel.svg" alt="Bölgesel İstihdam Ofisleri" />}>
      <Form form={form} onFinish={invite}>
        <Row className="full-width">
          <Col span={24}>
            <h1 className="fw-bold mb-20">
              <span className="text-blue mr-10">{get(data, 'firstName', '')} {get(data, 'lastName', '-')}</span>isimli adayı Mülakata Davet Et
            </h1>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="type">
              <LookupSelect url="/interviews/types/interviews" labelKey="label" valueKey="name" placeholder="Görüşme Tipi" location={faceToFace} setLocation={setFaceToFace}/>
            </Form.Item>
          </Col>
          <Col span={24} className="d-flex a-center mb-15">
            <Icon name="calendar" size={42} />
            <p className="text-orange-bio-pink text-4xl fw-bold ls-full mb-0 ml-10">Mülakat Tarihi</p>
          </Col>
          <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 13 }}>
            <Form.Item name="interviewAt">
              <DatePicker className="form-input full-width mb-20" suffixIcon={<Icon name="double-arrow" size={30} />} placeholder="Tarih" showTime minuteStep={15} showSecond={false} />
            </Form.Item>
          </Col>
          {
            faceToFace && (
              <Col span={13}>
                <Form.Item name="location">
                  <Input className="form-input full-width mb-20" placeholder="Konum" maxLength={200} />
                </Form.Item>
              </Col>
            )
          }
          <Button htmlType="submit" className="InviteBtn" loading={loading}>Davet Et</Button>
        </Row>
      </Form>
    </Modal>
  );
};
export default Invite;
