export const GLOBAL_REQUEST = "GLOBAL_REQUEST";
export const GLOBAL_SUCCESS = "GLOBAL_SUCCESS";
export const GLOBAL_ERROR = "GLOBAL_ERROR";
export const POST_DATA = "POST_DATA";
export const PUT_DATA = "PUT_DATA";
export const DELETE_DATA = "DELETE_DATA";
export const LOGIN = "LOGIN";
export const SET_STATE = "SET_STATE";  
export const USER_DATA_START = "USER_DATA_START";
export const USER_DATA_SUCCESS="USER_DATA_SUCCESS";
export const USER_DATA_ERROR="USER_DATA_ERROR";
export const COMPANY_DATA_START = "COMPANY_DATA_START";
export const COMPANY_DATA_SUCCESS="COMPANY_DATA_SUCCESS";
export const COMPANY_DATA_ERROR="COMPANY_DATA_ERROR";
export const GET_COMPANY_WORK_FORCE = "GET_COMPANY_WORK_FORCE";
export const SUCCESS_COMPANY_WORK_FORCE="SUCCESS_COMPANY_WORK_FORCE";
export const ERROR_COMPANY_WORK_FORCE="ERROR_COMPANY_WORK_FORCE";
export const RESUME_DATA_START = "RESUME_DATA_START";
export const RESUME_DATA_SUCCESS="RESUME_DATA_SUCCESS";
export const RESUME_DATA_ERROR="RESUME_DATA_ERROR";