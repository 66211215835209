import React, { useEffect, useState } from "react";
import { Input, message } from "antd";
import AuthLayoutForm from "./Components/AuthLayoutForm";
import AuthLayoutInput from "./Components/AuthLayoutInput";
import { useSelector } from "react-redux";
import { postNewLogin, updateState } from "../../redux/services/request";
import { useHistory } from "react-router-dom";
import {dataLayerPush} from "../../helpers/methods";
import OtpTimerComponent from './Components/OtpTimerComponent';
import axios from "axios";

export default function Verification() {
  const [ loading, setLoading ] = useState(false );
  const [ data, setData ] = useState(null );
  const registerInitData = useSelector( state => state.data.registerInit );
  const history = useHistory();

  useEffect(() => {
    setData( registerInitData?.data?.result )
    },[ registerInitData ]
  );

  const initialValues = {
    idNo: "",
    stateId: "",
    code: ""
  }

  const handleSubmit = async( values ) => {
    setLoading(true );

    const newData = {
      ...values,
      idNo: data?.idNo ?? '',
      stateId: data?.stateId ?? ''
    }

    try {
      updateState({ loginInit : {data: null, loading: true }});
      const response = await postNewLogin({ url: "/auth/register/finish", data: newData });

      if( response.code !== 200 ) {
        message.error( response.message );
        setLoading( false );
        history.push( '/' );
        return;
      }

      // local pushes
      localStorage.setItem( 'accessToken', response.result.accessToken );
      localStorage.setItem("type", response.result.userType );
      localStorage.setItem("fullName", response.result.fullName );

      // datalayer
      if(response.result.userType === 'CITIZEN'){
        dataLayerPush({
          'event' : "uyeGiris",
          'result' : "uyeGirisBasarili"
        })

      } else if(response.result.userType === 'CORPORATE') {
        dataLayerPush({
          'event' : "isVerenGiris",
          'result' : "isVerenGirisBasarili"
        })
      }

      // bearer token
      axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem('accessToken')}`;
      if (localStorage.getItem('type')) {
         axios.defaults.headers.common['userType'] = localStorage.getItem('type');
       }

      const redirectRoute = response?.result?.userType === 'CITIZEN' ? `/account/candidate/${ response?.result?.routeTo }` : '/'
      message.success( `İşleminiz gerçekleşti teşekkür ederiz ${ response?.result?.fullName }.` )
      history.push(redirectRoute)
      setLoading(false );
      updateState({ loginInit : {data: response  , loading: false }});

    } catch (e) {
      setLoading(false );
    }
  };

  return (
    <>
      <AuthLayoutForm
          formTitle="Telefon doğrulama"
          button="Onayla"
          onFinish={handleSubmit }
          initialValues={ initialValues }
          loading={loading}
          formName="verification">
          <AuthLayoutInput
            name="code"
            label="Doğrulama kodu"
            input = { <Input className="auth-form-input" maxLength={8} placeholder="Lütfen 6 Haneli Doğrulama Kodunu Giriniz." /> }
          />
        { data?.stateId && (
          <div className="auth-timer" >
             <OtpTimerComponent/>
          </div>)
        }
      </AuthLayoutForm>
    </>
  );
}
