import {useEffect, useState} from "react";
import {Row, Col, Input, DatePicker, Radio, Button, Form, Switch} from 'antd';
import { LookupSelect } from "components/UIComponents/FormElements";
import { Icon } from "components/UIComponents";

export default ({ data, index, remove }) => {
  const [ongoing,setOngoing] = useState(false );

  const onGoingChange = (checked) => {
      setOngoing( checked );
  }

  useEffect( () => {
    if( data ) {
      data.endDate ? setOngoing( false ) : setOngoing(true);
    }
  },[ data ] )

  return (
    <Row>
      <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 10 }}>
        <Row className="full-widtdh">
          <Col span={24} className="d-flex a-center mb-20">
            <Form.Item name="companyTitle" rules={[{required:true, message:"Firma Adı Giriniz"}, { whitespace:true, message: "Bir firma adı belirtiniz" }]}>
              <Input placeholder="Firma Adı" className="form-input"/>
            </Form.Item>
            <Button className="BtnDeleteItem btn btn-blue-filled full-width text-md mb-10" onClick={() => remove(index)}>Sil</Button>
          </Col>
          <Col span={24}>
            <Form.Item name="professionId" rules={[{required:true, message:"Meslek"}, { whitespace:true, message: "Bir meslek belirtiniz" }]}>
              <LookupSelect url="/professions" labelKey="profession" valueKey="id" placeholder="Meslek" className="mb-20"/>
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="workingType" rules={[{required:true, message:"Çalışma Şekli"}, { whitespace:true, message: "Bir çalışma şekli belirtiniz" }]}>
              <LookupSelect url="/working-types" labelKey="description"  valueKey="id"  placeholder="Çalışma Şekli" className="mb-20" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item name="description" rules={[{required:true, message:"Açıklama Giriniz"}]}>
              <Input.TextArea className="form-input mb-20" placeholder="Açıklama"rows={5} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <h1 className="text-xl fw-bold mb-20 mt-30">Devam Durumu</h1>
            <Switch
              checked={ongoing} onChange={onGoingChange} style={{width:10}}/>
            <p className="mt-10 mb-30">{ ongoing ? 'devam ediyor' : 'devam etmiyor' }</p>
          </Col>
          <Col span={24} className="d-flex a-center mb-20">
            <Icon name="calendar" size={42} />
            <Form.Item name="startDate" rules={[{required:true, message:"Yıl"}]}>
              <DatePicker placeholder="Başlangıç Tarihi" className="form-input text-center f-1 ml-10 suffix-none" suffixIcon={null} format="DD/MM/YYYY"/>
            </Form.Item>
            {
              ongoing ? null : (
                <Form.Item name="endDate" rules={[{required: true, message: "Yıl"}]}>
                  <DatePicker placeholder="Bitiş Tarihi" className="form-input text-center f-1 ml-10 suffix-none" suffixIcon={null} format="DD/MM/YYYY"/>
                </Form.Item>
              )
            }
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
