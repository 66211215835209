import { useState,useEffect } from 'react';
import { Col, Divider, Row, Spin } from 'antd';
import { Icon } from 'components/UIComponents';
import moment from 'moment';
import Banner from 'components/UIComponents/Banner';
import Education from './Education';
import Experience from './Experience';
import MainInfo from './MainInfo';
import Resume from './Communication';
import Actions from './Actions';
import { useParams } from 'react-router-dom';
import useGet from 'hooks/useGet';
import { get } from 'lodash';
import queryString from 'query-string';
import {Helmet} from "react-helmet";
import { putNew } from 'redux/services/request';

const Detail = (props) => {
  let params = queryString.parse(props.location.search)
  let sourceParam = params.source
  let ilanNo=params.advertisementNo
  let applicationStatus=params.applicationStatus
  let applicationId=params.applicationId


  const [candidateId] = useState(useParams().candidateId);
  const [jobId] = useState(useParams().applicationId);

  const url = (sourceParam === 'APP') ? `/candidates/${candidateId}/resumes?applicationId=${jobId}` : `/candidates/${candidateId}/resumes?advertisementId=${jobId}`
  const { data, loading, refresh } = useGet({ url: url, isArray: false });


 useEffect(() => {
  const cvUrl = `/candidate/view/tracking` 
  const response = putNew({ url: cvUrl, data: {
    "candidateId" : candidateId,
    "jobId": ilanNo,
    "advertisementStatus" :applicationStatus
  }
 });
}, []);

  return (
    <div className="CandidateDetail">
      <Banner text="Aday Başvurusu" className="mb-0" />
      <Helmet>
        <title>İş Ara İş Bul | Aday Başvuru Özgeçmişi</title>
          <meta
            name="description"
            content="İş Ara İş Bul | İstanbul Büyükşehir Belediyesi - Bölgesel İstihdam Ofisleri İle İş Arayan İş Bulacak." />
          <meta
            name="keywords"
            content="istanbul iş ilanları, İş ara, iş arama, iş bul, ibb bio, iş ilanı, iş ilanları, ibb iş arama, bio iş arama"/>
      </Helmet>
      <div className="content md content-detail">
        <Spin spinning={loading}>
          <Row justify="space-between" className="mb-10">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}>
              <p className="text-4xl fw-bold ls-full">
                ÖZGEÇMİŞ NO: {get(data, 'resumeNo', '-')}
              </p>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} className="text-right">
              <p className="text-xl ls-half mb-10">Güncellenme
                  Tarihi: {moment(get(data, 'updatedAt')).format('DD MMMM YYYY')}</p>
              <p className="text-xl ls-half">Son Giriş
                  Tarihi: {moment(get(data, 'publishedAt')).format('DD MMMM YYYY')}</p>
            </Col>
          </Row>
          <div className="d-flex a-center">
            <div className="Avatar mr-20">
              <Icon name="user" className="h-100" size={100} />
            </div>
            <div className="MainInfo full-width">
              <h1 className="Name mb-10 text-uppercase">{get(data, 'firstName')} {get(data, 'lastName')}</h1>
              <MainInfo candidate={data} />
            </div>
          </div>
          <Row gutter={{ xs: 0, sm: 0, md: 80 }} justify="space-between">
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 16 }}>
              <Row className="full-width">
                <Divider className="Divider grey" />
                <h1 className="text-4xl ls-full fw-bold full-width mb-30">ÖZGEÇMİŞ</h1>
                <Resume data={data} />
                <Divider className="Divider grey" />
                <Education data={get(data, 'educations', [])} />
                <Divider className="Divider grey" />
                <Experience data={get(data, 'experiences', [])} />
                <Divider className="Divider grey" />
              </Row>
            </Col>
            <Col xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 8 }}>
              <Actions candidateIdForPrint={candidateId} jobId={jobId} data={data} source={sourceParam} refresh={refresh} applicationStatus={applicationStatus} applicationId={applicationId}/>
            </Col>
          </Row>
        </Spin>
      </div>
    </div>
  );
}

export default Detail;
