import React, { useEffect, useState } from "react";
import { Button, Form, message, Spin } from "antd";
import { Icon } from "components/UIComponents";

import Reference from "./Reference";
import { useSelector } from "react-redux";
import Api from "../../../../../../../api/Api";
import { getData } from "../../../../../../../redux/services/request";

export default ({
  active,
  setActive,
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [references, setReferences] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS8 ? state.data?.resumeS8.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S8", key: "resumeS8" })
      .then((data) => {
        setPageLoading(false);
        onProgressChange(data.progress, data?.status);
      })
      .catch(() => {
        setPageLoading(false);
      });
  }, []);

  useEffect(() => {
    if (resume && resume.detail && resume.detail.results) {
      const _references = [];
      const formValues = {};
      resume.detail.results.forEach((item) => {
        const id = Math.random();
        const values = {
          ...item,
          id: id,
        };
        formValues[id] = values;
        _references.push(values);
      });

      setReferences(_references);
      form.setFieldsValue(formValues);
    }
  }, [resume, form]);

  const add = () => {
    const reference = {
      id: Math.random(),
      nameSurname: null,
      firm: null,
      position: null,
      email: null,
      phone: null,
    };

    setReferences([...references, reference]);
  };

  const remove = (index) => {
    const _references = [...references];
    _references.splice(index, 1);
    setReferences(_references);
  };

  const onFinish = () => {
    setLoading(true);
    const request = form.getFieldsValue();

    new Api()
      .putData({
        url: "/candidates/resumes/references",
        _data: { references: Object.values(request) },
      })
      .then(() => {
        message.success("Referanslar güncellendi");
        setPageLoading(true);
        getData({ url: "candidates/resumes/S8", key: "resumeS8" })
          .then(() => {
            setPageLoading(false);
          })
          .catch(() => {
            setPageLoading(false);
          });
        refresh();
        setActive((parseInt(active) + 1).toString());
        setActiveTab(
          cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
        );
        setLoading(false);
      })
      .catch(() => {
        message.error("Referanslar güncellenirken bir hata meydana geldi");
        setLoading(false);
      });
  };

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  return (
    <div className="References">
      <Spin spinning={pageLoading}>
        <Form form={form} onFinish={onFinish} onFinishFailed={onFinishFailed}>
          <h1 className="fw-bold ls-full text-4xl text-black mb-30">
            Referanslar <span className="text-orange-bio">(*)</span>
          </h1>
          {references.map((x, i) => (
            <Form.List name={x.id}>
              {(fields) => (
                <Reference data={x} key={x.id} index={i} remove={remove} />
              )}
            </Form.List>
          ))}
          <Button
            className="BtnAddEducation btn btn-pink-filled radius-lg"
            type="primary"
            icon={<Icon name="plus" size={26} className="mr-10" />}
            onClick={add}
          >
            Ekle
          </Button>
          <div className="d-flex mt-50 pt-30">
            {loading ? (
              <Spin />
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab ml-auto"
              >
                Kaydet ve İlerle
              </Button>
            )}
          </div>
        </Form>
      </Spin>
    </div>
  );
};
