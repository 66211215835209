import store from '../store';
import actions from '../actions/dataActions';

export const getData = ({ url, key, params }) => {
  return store.dispatch(actions.getData({ url, key, params }));
}
export const getCompanyWorkForceData= ({ url, key, params }) => {
  return store.dispatch(actions.getCompanyWorkForce({ url, key, params }));
}
export const getDataNew = ({ url, key, params }) => {
  return store.dispatch(actions.getDataNew({ url, key, params }));
}

export const getFile = ({ url, key, params, fileName }) => {
  return store.dispatch(actions.getFile({ url, key, params, fileName }));
}

export const postData = ({ url, data, params }) => {
  return store.dispatch(actions.postData({ url, data, params }));
}

export const postData2 = ({ url, data, params }) => {
  return store.dispatch(actions.postData2({ url, data, params }));
}

export const postNewLogin = ({ url, data, params }) => {
  return store.dispatch(actions.postNewLogin({ url, data, params }));
}

export const putNew = ({ url, data, params }) => {
  return store.dispatch(actions.putNew({ url, data, params }));
}

export const putData = ({ url, data, params }) => {
  return store.dispatch(actions.putData({ url, data, params }));
}

export const deleteData = ({ url, data, params }) => {
  return store.dispatch(actions.deleteData({ url, data, params }));
}

export const login = () => {
  return store.dispatch(actions.login());
}

export const updateState = (payload) => {
  return store.dispatch(actions.setState(payload));
}
