import { logoutIstSenin } from "helpers/methods";
import { getDataNew } from "../services/request";
import {  message } from "antd";

export const loadUserData = () => async (dispatch) => {
  dispatch({ type: 'USER_DATA_START' });
  
  const logout = async () => {
    localStorage.clear();
    logoutIstSenin();
    try {
      window.location.replace("/");
    } catch (e) {
      message.error('Çıkış işleminde bir hata oluştu');
    }
  }
  try {
    const data = await getDataNew({ url: 'candidates/me', key: 'candidatesMe' });
    //console.log("logout için :" + JSON.stringify(data))
    
    (data.code!==100 && data.code!==101) ? dispatch({ type: 'USER_DATA_SUCCESS', payload: data }) : logout()
    
  } catch (error) {
    dispatch({ type: 'USER_DATA_ERROR', error });
  }
};

export const loadResumeData = () => async (dispatch) => {
  dispatch({ type: 'RESUME_DATA_START' });
  try {
    const data = await getDataNew({ url: 'candidates/resumes', key: 'resume' });
    dispatch({ type: 'RESUME_DATA_SUCCESS', payload: data }) 
  } catch (error) {
    dispatch({ type: 'RESUME_DATA_ERROR', error });
  }
};

