import { Button, Form, message, Spin } from "antd";
import { Icon } from "components/UIComponents";
import React, { useEffect, useState } from "react";
import Document from "./Document";
import { useSelector } from "react-redux";
import { getData } from "../../../../../../../redux/services/request";
import Api from "../../../../../../../api/Api";
import AydinlatmaMetni from "../../../../../../../assets/policy/EK-A İSPER_BİO_KVK Aydınlatma Metni v.18.5.23.pdf";

export default ({
  onProgressChange,
  refresh,
  activeTab,
  setActiveTab,
  cvTabData,
}) => {
  const [form] = Form.useForm();
  const [documents, setDocuments] = useState([]);
  const resume = useSelector((state) =>
    state.data?.resumeS10 ? state.data?.resumeS10.data : undefined
  );
  const [loading, setLoading] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);

  useEffect(() => {
    reload();
  }, []);

  useEffect(() => {
    if (resume && resume.detail && resume.detail.results) {
      const _documents = [];
      const formValues = {};
      resume.detail.results.forEach((item) => {
        formValues[item.id] = {
          ...item,
          fileType: item.type,
        };
        _documents.push(item);
      });

      setDocuments(_documents);
      form.setFieldsValue(formValues);
    }
  }, [resume, form]);

  const reload = () => {
    setPageLoading(true);
    getData({ url: "candidates/resumes/S10", key: "resumeS10" })
      .then((data) => {
        onProgressChange(data.progress, data?.status);
        setPageLoading(false);
        setLoading(false);
      })
      .catch(() => {
        setPageLoading(false);
        setLoading(false);
      });
  };

  const add = () => {
    const document = {
      id: Math.random(),
      description: null,
      fileType: null,
      file: null,
    };
    setDocuments([...documents, document]);
  };

  const remove = (index) => {
    const _documents = [...documents];
    _documents.splice(index, 1);
    setDocuments(_documents);
  };

  const save = () => {
    setLoading(true);
    const request = Object.values(form.getFieldsValue());
    const files = request.filter((item) => item.file && item.file.fileList);

    if (!files || files.length === 0) {
      refresh();
      message.success("Özgeçmişiniz güncellendi.");
      setActiveTab(
        cvTabData?.find((el) => el.page === parseInt(activeTab?.page) + 1)
      );
      setLoading(false);
      return;
    }

    files.forEach((item) => {
      item.file.fileList.forEach((file) => {
        const formData = new FormData();
        formData.append("file", file.originFileObj);
        formData.append("fileType", item.fileType);
        formData.append(
          "description",
          item.description ? item.description : ""
        );

        new Api()
          .postMultipart({
            url: "candidates/resumes/attachments",
            formData: formData,
          })
          .then(() => {
            setTimeout(reload, 1000);
          })
          .catch(() => setLoading(false));
        refresh();
      });
    });
  };

  const onFinishFailed = () =>
    message.error("Form bilgileri eksik ya da hatalı.");

  return (
    <div className="Documents">
      <Spin spinning={pageLoading}>
        <Form form={form} onFinish={save} onFinishFailed={onFinishFailed}>
          <h1 className="fw-bold ls-full text-4xl text-black mb-30">
            Ek Belgeler
          </h1>
          {documents.map((x, i) => (
            <Form.List name={x.id}>
              {(fields) => (
                <Document
                  key={x.id}
                  index={i}
                  fileId={x.id}
                  fileName={x.name}
                  remove={remove}
                  reload={reload}
                />
              )}
            </Form.List>
          ))}
          <Button
            className="BtnAddEducation btn btn-pink-filled radius-lg"
            type="primary"
            icon={<Icon name="plus" size={26} className="mr-10" />}
            onClick={add}
          >
            Belge Ekle
          </Button>

          {loading ? (
            <Spin />
          ) : (
            <div className="d-flex mt-50 pt-30">
              <Button
                type="primary"
                htmlType="submit"
                className="BtnNextTab mr-30"
              >
                Kaydet
              </Button>
              <span>
                *Kaydet butonuna tıklayarak{" "}
                <a
                  className="clarification-button text-underline fw-bold d-iblock"
                  target="_blank"
                  href={AydinlatmaMetni}
                >
                  Kişisel Verilerin İşlenmesi Hakkında Aydınlatma Metni
                </a>
                ’ni okuduğunuzu beyan ediyorsunuz
              </span>
            </div>
          )}
        </Form>
      </Spin>
    </div>
  );
};
