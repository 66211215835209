import { useState} from 'react';
import {Button, Empty, message, Spin} from 'antd';
import Invite from './Actions/Invite';
import NotSuitable from './Actions/NotSuitable';
import Email from './Actions/Email';
import {get} from 'lodash';
import {getFile} from 'redux/services/request';
import {useHistory, useParams} from 'react-router';

const Actions = ({ data, jobId, source, refresh,applicationStatus,applicationId }) => {

  const history = useHistory();
  const [inviteVisible, setInviteVisible] = useState(false);
  const [emailModal, setEmailModal] = useState(false);
  const [notSuitableVisible, setNotSuitableVisible] = useState(false);
  const [candidateId] = useState(useParams().candidateId);
  const [loading, setLoading] = useState(false);

  const downloadFile = async (id, fileName) => {
    setLoading(true);
    try {
      await getFile({ url: `/candidates/resumes/${candidateId}/attachments/${id}`, fileName });
    } catch (e) {
      message.error("Dosya indirilirken bir hata oluştu");
    } finally {
      setLoading(false);
    }
  }


  return (
    <div className="Actions mt-20 p-20">
      <Button className="btn-account-operation blue" onClick={() => setEmailModal(true)}>E-Posta Gönder</Button>
      <Button className="btn-account-operation blue mb-40" onClick={() => history.push(`/printcv?candidateId=${candidateId}&jobId=${jobId}&source=${source}`)}>Özgeçmişi Yazdır</Button>
      <h3 className="text-blue text-xl fw-semibold">Dosyalar</h3>
      <Spin spinning={loading}>
        {get(data, 'attachments.length') > 0 ?
          get(data, 'attachments').map(x => (
            <div className="p-20 bg-softgrey text-center">
              <p href="/" className="File" onClick={() => downloadFile(x.id, x.name)}>{x.name}</p>
            </div>
          ))
          :
          <Empty description="Adaya ait dosya bulunmamaktadır" />
        }
      </Spin>
      <h3 className="text-blue text-xl fw-semibold mt-20">Notlar</h3>
      <div className="p-20 bg-softgrey text-xl fw-semibold">
        {get(data, 'adviceNote') || <Empty description="Kariyer danışmanı notu bulunmamaktadır" />}
      </div>
      <Button className={`btn-account-operation blue mt-40 ${applicationStatus && applicationStatus !== 'APPLIED' ? 'disabled':''}`} disabled={applicationStatus && applicationStatus !== 'APPLIED'} onClick={() => setInviteVisible(true)}>Mülakata Davet Et</Button>
      <Button className="btn-account-operation red" onClick={() => setNotSuitableVisible(true)}>Uygun Değildir</Button>
      <Email data={data} visible={emailModal} setVisible={setEmailModal} candidateId={candidateId} />
      <Invite data={data} visible={inviteVisible} setVisible={setInviteVisible}  applicationId={applicationId}/>
      <NotSuitable data={data} visible={notSuitableVisible} setVisible={setNotSuitableVisible} jobId={jobId} refresh={refresh}/>
    </div>
  );
};

export default Actions;
